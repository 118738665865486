import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NotificaWarning } from "../../controller/util";
import { API } from "../../controller/api/api";
import { isMobileDevice } from "../../controller/mobile";
import { manterApenasNumeros } from "../../controller/data";
import { InputPedido } from "../../page/pedido/componente/input";
import { Button } from "../button";
import LoadingOverlay from "../load";
import { ShowModal } from "../modalPai";

export function ComponentComanda({ comanda, close }) {
  const estiloDivPrincipal = {
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: "9999", // Ajuste o valor conforme necessário para definir a ordem de sobreposição
  };

  const estiloDivFundo = {
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    background: "rgba(0, 0, 0, 0.5)",
    zIndex: "9998", // Um valor menor para colocá-lo atrás da div principal
  };

  const estiloDivCentralizada = {
    width: isMobileDevice() ? "90%" : "20%",
    background: "white",
    padding: "20px",
  };

  const [Comanda, setComanda] = useState(comanda);
  const [Mesa, setMesa] = useState("");
  const [DadosMesa, setDadosMesa] = useState(null);
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);

  function Close() {
    navigate(`/salao/${DadosMesa.idMesa}`);
    //console.log(`/salao/${DadosMesa.idMesa}`);
  }

  function AbrirComanda() {
    if (Comanda == 0 || Comanda == "") {
      const meuInput = document.getElementById("inputComanda");
      if (meuInput) {
        meuInput.focus();
      }
      NotificaWarning("Deve-se Informar Uma Comanda!");
      return false;
    }

    if (Mesa.length === 0) {
      const meuInput = document.getElementById("inputMesa");
      if (meuInput) {
        meuInput.focus();
      }
      NotificaWarning("Deve-se Informar Uma Mesa!");
      return false;
    }
    setloading(true);

    API.post(`v2/comanda/${DadosMesa.idMesa}/${Mesa}`).then(function (
      response
    ) {
      Close();
    });
  }

  useEffect(() => {
    if (Comanda != "") {
      //console.log(Comanda);
      // setloading(true);
      API.get(`v2/comanda/${Comanda}`).then((response) => {
        //console.log(response.data);
        setDadosMesa(response.data[0]);
        if (response.data[0]?.descricao != null) {
          setMesa(response.data[0].descricao);
        } else {
          setMesa("");
        }
        setloading(false);
      });
    } else {
      setDadosMesa(null);
      setloading(false);
    }
  }, [Comanda]);

  return (
    <>
      {loading && <LoadingOverlay />}
      {/* <div style={estiloDivFundo}></div> */}

      <ShowModal closeButton={close}>
        {/* <div style={estiloDivPrincipal}>
        <div style={estiloDivCentralizada}> */}
        {/* Conteúdo da sua div centralizada aqui */}
        <h1>Comanda</h1>
        <InputPedido
          Valor={Comanda}
          Set={setComanda}
          Tipo={"number"}
          id={"inputComanda"}
          Change={true}
        />

        {DadosMesa ? (
          <>
            <h1>Mesa</h1>
            <InputPedido
              Valor={Mesa}
              Set={setMesa}
              Change={true}
              // Tipo={"number"}
              id={"inputMesa"}
            />

            <div style={{ marginTop: "10px" }}>
              <Button
                fontColor={"black"}
                borderColor={"black"}
                descricao={"Abrir"}
                evento={AbrirComanda}
              />
            </div>
          </>
        ) : null}
        {/* </div>
      </div> */}
      </ShowModal>
    </>
  );
}
