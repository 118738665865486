import { useEffect, useState } from "react";
import ApexChart from "react-apexcharts";
import { API } from "../../../controller/api/api";
import {
  DataAtual,
  MascaraMonetaria,
  PrimeiroDiaDoMes,
  formatNumber,
  formatarDataHora,
  transformDatePadrao,
} from "../../../controller/data";
import { HeaderComponent } from "../../../component/header";
import { InputPedido } from "../../pedido/componente/input";
import LoadingOverlay from "../../../component/load";
import {
  MonitorSmall,
  Notifica,
  NotificaWarning,
  PrimeiraMaiuscula,
} from "../../../controller/util";
import { FaPrint, FaRegEye } from "react-icons/fa";
import { ShowModal } from "../../../component/modalPai";
import { IconButton, Tooltip } from "@mui/material";
import { PageCaixa } from "../../caixa/relatorio/component";
import { Button } from "../../../component/button";

let DataFinalPadrao = DataAtual();
let DataInicialPadrao = PrimeiroDiaDoMes();

export function DashBoard() {
  const [Data, setData] = useState({});
  const [DataInicial, setDataInicial] = useState(DataInicialPadrao);
  const [DataFinal, setDataFinal] = useState(DataFinalPadrao);
  const [loading, setLoading] = useState(false);

  const [ModalFiltro, setModalFiltro] = useState(false);
  const [ModalExtrato, setModalExtrato] = useState(false);
  const [URL, setURL] = useState("");

  useEffect(() => {
    BuscaDados();
    //console.log(window.screen.width, window.screen.height);
  }, []);

  useEffect(() => {
    BuscaDados();
  }, [DataInicial, DataFinal]);

  useEffect(() => {
    ////console.log(Data?.extrato)
    //console.log(LabelPagamento());
  }, [Data]);

  function BuscaDados() {
    if (new Date(DataInicial) > new Date(DataFinal)) {
      NotificaWarning("Data inicial não pode ser maior que a final");
      return false;
    }
    setLoading(true);
    API.get(`v2/dashboard/venda/${DataInicial}/${DataFinal}`)
      .then((response) => {
        setData(response.data);
        //console.log(response.data);
        setLoading(false);
      })
      .catch((err) => {});
  }

  function HorariosLabel() {
    let Horario = [];
    for (var i = 0; i < Data?.horario?.length; i++) {
      Horario.push(Data?.horario[i].intervaloHora);
    }
    return Horario;
  }

  function HorariosQuantidade() {
    let Horario = [];
    for (var i = 0; i < Data?.horario?.length; i++) {
      Horario.push(Data?.horario[i].quantidade);
    }
    return Horario;
  }

  function DiasLabel() {
    let Dia = [];
    for (var i = 0; i < Data?.dias?.length; i++) {
      Dia.push(Data?.dias[i].data);
    }

    return Dia;
  }

  function DiasQuantidade() {
    let Dia = [];
    for (var i = 0; i < Data?.dias?.length; i++) {
      Dia.push(Data?.dias[i].qtd);
    }

    return Dia;
  }

  function DiasTotal() {
    let Dia = [];
    for (var i = 0; i < Data?.dias?.length; i++) {
      Dia.push(Data?.dias[i].total);
    }

    return Dia;
  }

  function ExtratoQuantidade() {
    let quantidade = 0;
    for (var i = 0; i < Data?.extrato?.length; i++) {
      quantidade += Data?.extrato[i].quantidade;
    }

    return quantidade;
  }

  function ExtratoTotal() {
    let total = 0;
    for (var i = 0; i < Data?.extrato?.length; i++) {
      total += Data?.extrato[i].valorFechamento;
    }

    return total;
  }

  const CardValue = ({ descricao, quantidade, total, color }) => {
    return (
      <div
        style={{
          height: "100px",
          width: "160px",
          borderRadius: "20px 0px",
          backgroundColor: "#fff",
          padding: "10px",
          border: `5px solid #cfcfcf`,
        }}
      >
        <h1 style={{ color: color }}>
          <b>{descricao}</b>
        </h1>
        <h3>{formatNumber(quantidade)}</h3>
        <h3>{MascaraMonetaria(total)}</h3>
      </div>
    );
  };

  const state = {
    series: [
      Data?.pdv_qtd ? Data.pdv_qtd : 0,
      Data?.mesa_qtd ? Data?.mesa_qtd : 0,
      Data?.site_qtd ? Data?.site_qtd : 0,
      Data?.ifood_qtd ? Data?.ifood_qtd : 0,
    ],
    options: {
      chart: {
        type: "donut",
      },
      labels: ["PDV", "Mesa", "Site", "iFood"],
      colors: ["#FF6347", "#6A5ACD", "#8B0000", "#FF0000"], // Personalize as cores aqui
      // responsive: [
      //   {
      //     breakpoint: 480,
      //     options: {
      //       chart: {
      //         width: 200,
      //       },
      //       legend: {
      //         position: "bottom",
      //       },
      //     },
      //   },
      // ],
    },
  };

  const stateHora = {
    series: [
      {
        name: "Vendas",
        data: HorariosQuantidade(),
      },
    ],
    options: {
      chart: {
        type: "line",
        height: 350,
      },
      xaxis: {
        categories: HorariosLabel(),
        title: {
          text: "Horário",
        },
      },
      yaxis: {
        title: {
          text: "Quantidade",
        },
      },
    },
  };

  const stateDias = {
    series: [
      {
        name: "Vendas",
        data: DiasTotal(),
      },
    ],
    options: {
      chart: {
        type: "line",
        height: 350,
      },
      xaxis: {
        categories: DiasLabel(),
        title: {
          text: "Período",
        },
      },
      yaxis: {
        title: {
          text: "Valor R$",
        },
      },
    },
  };

  // const stateDias = {
  //   series: [
  //     {
  //       name: "Valor",
  //       data: DiasTotal(), // O valor em dinheiro
  //     },
  //   ],
  //   options: {
  //     chart: {
  //       type: "bar",
  //       events: {
  //         dataPointSelection: (event, chartContext, config) => {},
  //       },
  //     },
  //     title: {
  //       text: "Total por Dia",
  //       align: "center",
  //       margin: 20,
  //       offsetY: -30, // Você pode ajustar este valor conforme necessário
  //       floating: true,
  //     },
  //     xaxis: {
  //       categories: DiasLabel(), // O dia
  //     },
  //     dataLabels: {
  //       enabled: true,
  //       formatter: (val, opt) => {
  //         return `${MascaraMonetaria(val)}`;
  //       },
  //       offsetY: -20,
  //       style: {
  //         fontSize: "12px",
  //         colors: ["#304758"],
  //       },
  //     },
  //   },
  // };

  function ImprimirCaixa(codigo) {
    API.post(`/v1/imprimir/3/${codigo}`);
    API.post(`/v1/imprimir/5/${codigo}`);
  }

  function AlteraValoresInput(type, valor) {
    if (type == "Data Inicial") {
      DataInicialPadrao = valor;
      setDataInicial(valor);
      // BuscarDados();
    }
    if (type == "Data Final") {
      DataFinalPadrao = valor;

      setDataFinal(valor);
      // BuscarDados();
    }
  }

  function padLeftZeros(number, width) {
    // Converte para string e adiciona zeros à esquerda conforme necessário
    return String(number).padStart(width, "0");
  }

  function LabelPagamento() {
    let Dados = [];

    if (Data?.tipo_pagamento == undefined) {
      return Dados;
    }

    for (let i = 0; i < Data?.tipo_pagamento.length; i++) {
      Dados.push(
        PrimeiraMaiuscula(Data.tipo_pagamento[i].descricao) +
          ` ${MascaraMonetaria(Data.tipo_pagamento[i].total)}`
      );
    }
    return Dados;
  }

  function ValorPagamento() {
    let Dados = [];

    if (Data?.tipo_pagamento == undefined) {
      return Dados;
    }

    for (let i = 0; i < Data?.tipo_pagamento.length; i++) {
      Dados.push(Data.tipo_pagamento[i].quantidade);
    }

    return Dados;
  }

  const statePagamento = {
    series: ValorPagamento(),

    options: {
      chart: {
        type: "donut",
      },
      labels: LabelPagamento(),
      // colors: ["#FF6347", "#6A5ACD", "#8B0000", "#FF0000"], // Personalize as cores aqui
      // responsive: [
      //   {
      //     breakpoint: 480,
      //     options: {
      //       chart: {
      //         width: 200,
      //       },
      //       legend: {
      //         position: "bottom",
      //       },
      //     },
      //   },
      // ],
    },
  };

  // const statePagamento = {
  //   options: {
  //     chart: {
  //       id: "pie-chart",
  //     },
  //     labels: LabelPagamento(),
  //     responsive: [
  //       {
  //         breakpoint: 480,
  //         options: {
  //           chart: {
  //             width: 200,
  //           },
  //           legend: {
  //             position: "bottom",
  //           },
  //         },
  //       },
  //     ],
  //   },
  //   series: [44, 55, 13, 43, 22],
  // };

  function CardExtrato() {
    return (
      <>
        <div style={{ width: "380px", height: "380px" }}>
          <h1 style={{ textAlign: "center" }}>
            <b>Extrato Caixa</b>
          </h1>
          <div style={{ height: "290px", width: "100%", overflow: "auto" }}>
            {Data?.extrato?.map((item, index) => (
              <div style={{ position: "relative" }}>
                <Tooltip title="Imprimir">
                  <IconButton
                    aria-label="delete"
                    sx={{
                      position: "absolute",
                      bottom: "0",
                      left: "15",
                    }}
                  >
                    <FaPrint onClick={() => ImprimirCaixa(item.id)} />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Visualizar Caixa">
                  <IconButton
                    aria-label="visualizar"
                    sx={{
                      position: "absolute",
                      bottom: "0",
                      left: "40px",
                    }}
                  >
                    <FaRegEye onClick={() => setURL(item.id)} />
                  </IconButton>
                </Tooltip>

                <div
                  key={index}
                  style={{
                    borderBottom: "1px solid black",
                    marginLeft: "20px",
                  }}
                >
                  <h3 style={{ fontSize: "11px" }}>
                    <b>
                      {transformDatePadrao(item.dataAbertura)}{" "}
                      {item.horaAbertura
                        ? item.horaAbertura.substring(0, 5)
                        : ""}{" "}
                      até {transformDatePadrao(item.dataFechamento)}{" "}
                      {item.horaFechamento
                        ? item.horaFechamento.substring(0, 5)
                        : ""}
                    </b>
                  </h3>
                  <h3 style={{ textAlign: "end", fontSize: "14px" }}>
                    #{formatNumber(item.id ? padLeftZeros(item.id, 3) : 0)}
                  </h3>
                  <h3 style={{ textAlign: "end", fontSize: "14px" }}>
                    {formatNumber(item.quantidade ? item.quantidade : 0)}{" "}
                    pedidos{" "}
                  </h3>
                  <h3 style={{ textAlign: "end", fontSize: "14px" }}>
                    {MascaraMonetaria(
                      item.valorFechamento ? item.valorFechamento : 0
                    )}
                  </h3>
                </div>
              </div>
            ))}
          </div>
          <h3 style={{ fontSize: "11px" }}>
            <b>Total</b>
          </h3>
          <h3 style={{ textAlign: "end", fontSize: "14px" }}>
            {formatNumber(ExtratoQuantidade())} pedidos{" "}
          </h3>
          <h3 style={{ textAlign: "end", fontSize: "14px" }}>
            {MascaraMonetaria(ExtratoTotal())}
          </h3>
        </div>
      </>
    );
  }

  return (
    <>
      <HeaderComponent ModalFiltro={() => setModalFiltro(true)}>
        {loading && <LoadingOverlay />}{" "}
        {/* Renderiza o overlay condicionalmente baseado no estado 'loading' */}
        {/* {MonitorSmall() ? "true" : "false"} */}
        <div style={{ height: "91vh", width: "100%" }}>
          <div style={{ height: "100%", width: "100%", padding: "10px" }}>
            <div
              style={{
                display: "flex",
                gap: "10px",
                justifyContent: MonitorSmall() ? "center" : "",
              }}
            >
              <CardValue
                descricao="Total"
                quantidade={Data?.total_qtd ? Data.total_qtd : 0}
                total={Data?.total_total ? Data.total_total : 0}
                color={"#00FF7F"}
              />
              {Data.pdv_qtd > 0 ? (
                <CardValue
                  descricao="PDV"
                  quantidade={Data?.pdv_qtd ? Data.pdv_qtd : 0}
                  total={Data?.pdv_total ? Data.pdv_total : 0}
                  color={"#FF6347"}
                />
              ) : null}
              <CardValue
                descricao="Mesa"
                quantidade={Data?.mesa_qtd ? Data.mesa_qtd : 0}
                total={Data?.mesa_total ? Data.mesa_total : 0}
                color={"#6A5ACD"}
              />
              <CardValue
                descricao="Site"
                quantidade={Data?.site_qtd ? Data.site_qtd : 0}
                total={Data?.site_total ? Data.site_total : 0}
                color={"#8B0000"}
              />
              {Data.ifood_qtd > 0 ? (
                <CardValue
                  descricao="iFood"
                  quantidade={Data?.ifood_qtd ? Data.ifood_qtd : 0}
                  total={Data?.ifood_total ? Data.ifood_total : 0}
                  color={"#FF0000"}
                />
              ) : null}
              {Data.media_total > 0 ? (
                <CardValue
                  descricao="Média Diaria"
                  quantidade={Data?.media_qtd ? Data.media_qtd : 0}
                  total={Data?.media_total ? Data.media_total : 0}
                  color={"#FF0000"}
                />
              ) : null}
            </div>

            {Data?.total_qtd > 0 ? (
              <>
                <div
                  style={{
                    display: "flex",
                    height: "380px",
                    width: "100%",
                    padding: "10px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "380px",
                      height: "380px",
                      alignItems: "center",
                      justifyItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <h1>Origem</h1>
                    <ApexChart
                      options={state.options}
                      series={state.series}
                      type="donut"
                      width={380}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      width: "480px",
                      height: "480px",
                      alignItems: "center",
                      justifyItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <h1>Forma Pagamento</h1>
                    <ApexChart
                      options={statePagamento.options}
                      series={statePagamento.series}
                      type="donut"
                      width={480}
                    />
                  </div>

                  {MonitorSmall() ? "" : CardExtrato()}
                </div>
              </>
            ) : null}
            {Data?.total_qtd > 0 ? (
              <>
                <div>
                  <div style={{ width: "100%", height: "380px" }}>
                    <ApexChart
                      options={stateHora.options}
                      series={stateHora.series}
                      type="line"
                      height={380}
                      // width={100}
                    />
                  </div>

                  <div>
                    <ApexChart
                      options={stateDias.options}
                      series={stateDias.series}
                      type="bar"
                      height={350}
                    />
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </HeaderComponent>

      {ModalFiltro ? (
        <>
          <ShowModal closeButton={() => setModalFiltro(false)}>
            <div
              style={{
                width: "320px",
                paddingTop: "20px",
                display: "flex",
                gap: "10px",
                flexDirection: "column",
              }}
            >
              <InputPedido
                Tipo="date"
                Descricao="Data Inicial"
                Retorno={AlteraValoresInput}
                Valor={DataInicial}
              />
              <InputPedido
                Tipo="date"
                Descricao="Data Final"
                Retorno={AlteraValoresInput}
                Valor={DataFinal}
              />
              <Button
                descricao="Exibir Extrato"
                evento={() => [setModalExtrato(true), setModalFiltro(false)]}
                BorderRadius="5px"
              />
            </div>
          </ShowModal>
        </>
      ) : null}

      {ModalExtrato ? (
        <>
          <ShowModal closeButton={() => setModalExtrato(false)}>
            <div style={{ width: "100%" }}>{CardExtrato()}</div>
          </ShowModal>
        </>
      ) : null}

      {URL != "" ? (
        <>
          <ShowModal closeButton={() => setURL("")}>
            <div style={{ width: "98vw" }}>
              <PageCaixa Header={false} ID={URL} />
            </div>
            {/* <iframe
              style={{
                width: "98vw",
                height: "98vh",
              }}
              src={URL}
            ></iframe> */}
          </ShowModal>
        </>
      ) : null}
    </>
  );
}
