import { FaPen } from "react-icons/fa";
import { Toggle } from "../../../../component/toggle";
import { MascaraMonetaria, RemoveMascara } from "../../../../controller/data";
export function CardCupom({ dados, index, click }) {
  //console.log(dados);
  function formatarData(data) {
    const meses = [
      "jan",
      "fev",
      "mar",
      "abr",
      "mai",
      "jun",
      "jul",
      "ago",
      "set",
      "out",
      "nov",
      "dez",
    ];

    const partesData = data.split("-");
    const dia = partesData[2];
    const mes = meses[parseInt(partesData[1], 10) - 1];
    const ano = partesData[0];

    return `${dia} ${mes} ${ano}`;
  }

  function validarDataMaiorQueAtual(data) {
    const dataAtual = new Date();
    const partesData = data.split("-");
    const ano = parseInt(partesData[0]);
    const mes = parseInt(partesData[1]) - 1; // Os meses em JavaScript são indexados a partir de zero (janeiro é 0)
    const dia = parseInt(partesData[2]);

    const dataInformada = new Date(ano, mes, dia);

    return dataInformada > dataAtual;
  }

  function StatusCupom() {
    if (parseInt(dados.total_vezes) > 0) {
      return validarDataMaiorQueAtual(dados.data_validade);
    } else {
      return false;
    }
  }

  let size = "150px";
  return (
    <>
      <div>
        <div class="ticket" style={{ height: size }}>
          <div class="ticket-desconto" style={{ width: size }}>
            <h1>
              <b style={{ fontSize: "1.3rem" }}>
                {parseInt(dados.primeira) == 1 ? "1º Pedido" : "Desconto"}
              </b>
            </h1>
          </div>
          <div
            style={{
              marginLeft: "60px",
              // backgroundColor: "red",
              marginRight: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "5px",
              }}
            >
              <h1 style={{ margin: 0, color: "#ffffff" }}>
                <b>{dados.ativacao}</b>
              </h1>

              {dados.type_discount == 2 ? (
                <>
                  <span
                    style={{
                      textAlign: "right",
                      margin: 0,
                      color: "rgb(9,192,103)",
                    }}
                  >
                    <b>{MascaraMonetaria(RemoveMascara(dados.fixed_value))}</b>
                  </span>
                </>
              ) : (
                <>
                  <span
                    style={{ textAlign: "right", margin: 0, color: "#999999" }}
                  >
                    <b>10%</b>
                  </span>{" "}
                </>
              )}
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                textAlign: "center",
                justifyItems: "center",
                alignItems: "center",
                fontSize: "0.7rem",
                marginTop: "15px",
                marginRight: "15px",
              }}
            >
              <Toggle Titulo="Ativo" Checked={StatusCupom()} />
              <h1>Quantidade: {dados.total_vezes}</h1>
            </div>
            <div
              style={{
                padding: "5px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
                <p style={{ fontSize: "0.7rem" }}>Validade</p>
                <p>
                  <b>{formatarData(dados.data_validade)}</b>
                </p>
              </div>
              <div
                style={{
                  alignItems: "flex-end",
                  justifyContent: "right",
                  display: "flex",
                }}
              >
                <div
                  style={{
                    border: "1px solid black",
                    padding: "5px 15px 5px 15px",
                    borderRadius: "50px",
                    cursor: "pointer",
                  }}
                  onClick={() => click(dados)}
                >
                  <FaPen size={18} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
