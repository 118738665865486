import DndProviderWrapper from "../dnd";
import { useState, useEffect } from "react";
import TableProduto from "../tabela";
import { API } from "../../../../controller/api/api";
import {
  BiChevronDown,
  BiDotsVerticalRounded,
  BiChevronUp,
} from "react-icons/bi";
import { Button } from "../../../../component/button";
import { NovoProduct } from "../novoproduct";
import { PrimeiraMaiuscula } from "../../../../controller/util";
import { StatusComponent } from "../tabela/item/status";
import { MascaraMonetaria, RemoveMascara } from "../../../../controller/data";
import { FaPizzaSlice, FaPlus } from "react-icons/fa";
import { ShowModal } from "../../../../component/modalPai";
import { TextField } from "@mui/material";

export function ComponenteCategoria({
  categoria,
  Retorno,
  pesquisa,
  AlteraCategoria,
}) {
  const [DadosProduto, setDadosProduto] = useState([]);
  const [SaboresPizza, setSaboresPizza] = useState([]);
  const [isVisualizaProduto, setVisualizaProduto] = useState(true);
  const [isAddProduto, setAddProduto] = useState(false);
  const [CodigoProduto, setCodigoProduto] = useState(0);
  const [saborSelecionado, setSelecionado] = useState([]);
  const [isVisualizarSabores, setVisualizarSabores] = useState(false);
  const [loading, setLoading] = useState(false);

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    LoadProduto();
  }, []);

  function LoadProduto() {
    API.get(`v1/produto/categoria/${categoria.codigo}`)
      .then((response) => {
        //console.log(response.data);
        let dados = response.data;
        dados.sort((a, b) => a.position - b.position);
        setDadosProduto(dados);
        Retorno();
        //console.log(`/v2/flavor/${categoria.codigo}`);
        API.get(`/v2/flavor/${categoria.codigo}`).then((response) => {
          setSaboresPizza(response.data);
          //console.log(response.data);
        });
      })
      .catch((err) => {});
  }

  const InputSabor = ({ sabor, Pai, index }) => {
    const [Valor, setValor] = useState(MascaraMonetaria(sabor.value));

    const handleChange = (e) => {
      setValor(e.target.value);
      //console.log(e.target.value);
      // Você pode optar por enviar o valor para a função AlteraValor aqui
      // ou passar a função AlteraValor como uma propriedade para este componente
      // e chamá-la no evento onBlur ou em outro lugar apropriado.
    };

    return (
      <TextField
        sx={{ marginBottom: "15px", width: "100%" }}
        size="small"
        label={PrimeiraMaiuscula(sabor.name)}
        value={Valor}
        id={Pai + "-" + index}
        onChange={handleChange}
        onBlur={(e) => [
          AlteraValor(Pai + "-" + index, RemoveMascara(e.target.value)),
          setValor(MascaraMonetaria(e.target.value)),
        ]}
      />
    );
  };

  const SaboresInput = ({ Sabores, Pai }) => {
    return (
      <>
        {Sabores.map((sabor, index) => (
          <div
            key={index}
            style={{ width: "100%", marginRight: "10px", zIndex: 1 }}
          >
            <InputSabor sabor={sabor} Pai={Pai} index={index} />
          </div>
        ))}
      </>
    );
  };

  // const inputSabor = ({ sabor, Pai }) => {
  //   const [Valor, setValor] = useState(sabor.value);
  //   return (
  //     <TextField
  //       sx={{ marginBottom: "15px", width: "100%" }}
  //       size="small"
  //       label={PrimeiraMaiuscula(sabor.name)}
  //       value={MascaraMonetaria(Valor)}
  //       id={Pai + "-" + index}
  //       onChange={(e) => setValor(e.target.value)}
  //       // onBlur={(e) => AlteraValor(Pai + "-" + index, e.target.value)}
  //     />
  //   );
  // };

  // const SaboresInput = ({ Sabores, Pai }) => {
  //   return (
  //     <>
  //       {Sabores.map((sabor, index) => (
  //         <div style={{ width: "100%", marginRight: "10px", zIndex: 1 }}>
  //           <inputSabor sabor={sabor} Pai={Pai} />
  //         </div>
  //       ))}
  //     </>
  //   );
  // };

  function AlteraValor(index, value) {
    let array = index.split("-");
    let MySabor = [...SaboresPizza];
    MySabor[array[0]].product[array[1]].value = RemoveMascara(value);

    setSaboresPizza(MySabor);

    let url = `/v2/flavor/${MySabor[array[0]].product[array[1]].id}/${
      MySabor[array[0]].flavor
    }/${RemoveMascara(value)}`;
    API.post(url);
  }

  function AtivaSabor(index, sabor) {
    let MySabor = [...SaboresPizza];
    MySabor[index].status = 1;
    setSaboresPizza(MySabor);
    API.post(`/v2/flavor/${sabor}/1`);
  }

  function InativaSabor(index, sabor) {
    let MySabor = [...SaboresPizza];
    MySabor[index].status = 0;
    setSaboresPizza(MySabor);
    API.post(`/v2/flavor/${sabor}/0`);
  }

  function AlteraSabor(sabor) {
    setAddProduto(true);
    setSelecionado(sabor);
  }

  function Close() {
    setAddProduto(false);
    LoadProduto();
  }

  return (
    <>
      {/* {loading && <LoadingOverlay />}{" "} */}
      <div
        key={categoria.codigo}
        id={categoria.codigo}
        name="categoria"
        style={{
          backgroundColor: "white",
          width: "100%",
          padding: "1rem",
          borderRadius: "0.5rem",
          border: "2px solid rgba(166,166,166,0.5)",
          marginBottom: "35px",
        }}
      >
        <div
          style={{
            borderBottom: "1px solid rgba(166,166,166,0.5)",
            height: "2.9rem",
            marginBottom: "10px",
          }}
        >
          <div style={{ display: "flex" }}>
            <div
              style={{ width: "100%" }}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <h1
                style={{
                  fontSize: "1.3rem",

                  cursor: "pointer",
                  color: isHovered ? "#a4001b" : "black", // Alterando a cor da fonte ao passar o mouse
                }}
                onClick={() => AlteraCategoria(categoria.codigo)}
              >
                <b>{PrimeiraMaiuscula(categoria.descricao)} </b>
                {"  "}
                {DadosProduto.length > 0 ? (
                  <span style={{ color: "#999999", fontSize: "0.9rem" }}>
                    {" "}
                    {DadosProduto.length} itens
                  </span>
                ) : null}
                {categoria.impressora == 0 ? (
                  <span style={{ color: "#999999", fontSize: "0.9rem" }}>
                    {" "}
                    Sem Impressora Configurada
                  </span>
                ) : null}

                {categoria.local > 0 ? (
                  <strong
                    style={{
                      backgroundColor: "#a4001b",
                      color: "white",
                      marginRight: "10px",
                      marginLeft: "10px",
                      paddingRight: "10px",
                      paddingLeft: "10px",
                      borderRadius: "5px",
                      fontSize: "0.9rem",
                    }}
                  >
                    <b>Local</b>
                  </strong>
                ) : null}
              </h1>
            </div>
            <div style={{ width: "80px" }}>
              {isVisualizaProduto ? (
                <BiChevronUp
                  style={{ cursor: "pointer" }}
                  size={32}
                  color={"#a4001b"}
                  onClick={() => setVisualizaProduto(false)}
                />
              ) : (
                <BiChevronDown
                  style={{ cursor: "pointer" }}
                  size={32}
                  color={"#a4001b"}
                  onClick={() => setVisualizaProduto(true)}
                />
              )}
            </div>
            {/* <div style={{ width: "80px",cursor: "pointer" }}>
              <BiDotsVerticalRounded size={32} />
            </div> */}
          </div>
        </div>

        {isAddProduto ? (
          <>
            <NovoProduct
              codigo={CodigoProduto}
              sabor={saborSelecionado}
              tipo={categoria.pizza}
              product={DadosProduto}
              category={categoria.codigo}
              close={Close}
            />
          </>
        ) : null}

        {isVisualizaProduto ? (
          <DndProviderWrapper>
            {DadosProduto.length > 0 ? (
              <TableProduto
                DadosProduto={DadosProduto}
                Tipo={categoria.pizza}
                Set={setDadosProduto}
                OnLoad={LoadProduto}
                pesquisa={pesquisa}
              />
            ) : (
              <></>
            )}
          </DndProviderWrapper>
        ) : null}

        {categoria.pizza ? (
          <>
            {" "}
            <div style={{ width: "210px", margin: "25px", height: "35px" }}>
              <Button
                icon={<FaPizzaSlice />}
                descricao="Sabores Pizza"
                evento={() => [setVisualizarSabores(true), setLoading(true)]}
                color="white"
                borderColor="#a4001b"
                fontColor="#a4001b"
                BorderRadius="5px"
              />
            </div>
          </>
        ) : null}

        {isVisualizarSabores ? (
          <ShowModal closeButton={() => setVisualizarSabores(false)}>
            <div>
              <div style={{ width: "100%" }}>
                <h1 style={{ fontSize: "1.3rem" }}>
                  <strong>Sabores</strong>
                </h1>
              </div>
              {SaboresPizza.map((sabor, index) => (
                <>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      padding: "10px",
                      borderBottom: "1px solid rgba(0,0,0,0.3)",
                    }}
                  >
                    <div style={{ width: "100%", padding: "10px" }}>
                      <h3
                        onClick={() => AlteraSabor(sabor)}
                        style={{ marginBottom: "10px" }}
                      >
                        <span>{PrimeiraMaiuscula(sabor.type)}</span> -{" "}
                        <b>{PrimeiraMaiuscula(sabor.flavor)}</b>
                      </h3>
                      <SaboresInput
                        Sabores={PrimeiraMaiuscula(sabor.product)}
                        Pai={index}
                      />
                    </div>
                    <div style={{ width: "190px" }}>
                      <StatusComponent
                        status={sabor.status}
                        Ativar={() => AtivaSabor(index, sabor.flavor)}
                        Pausar={() => InativaSabor(index, sabor.flavor)}
                      />
                    </div>
                  </div>
                </>
              ))}
            </div>
          </ShowModal>
        ) : null}

        <div
          style={{
            height: "80px",
            width: "100%",
            borderTop: "1px solid #a6a6a5",
          }}
        >
          <div style={{ width: "210px", margin: "25px", height: "35px" }}>
            <Button
              icon={<FaPlus />}
              descricao="Adicionar Item"
              evento={() => (setCodigoProduto(0), setAddProduto(true))}
              color="white"
              borderColor="#a4001b"
              fontColor="#a4001b"
              BorderRadius="5px"
            />
          </div>
        </div>
      </div>
    </>
  );
}
