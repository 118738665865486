import { useEffect, useState } from "react";
import { formatarData } from "../../../controller/data";
import { GraficoProdutos } from "./grafico";
import { API } from "../../../controller/api/api";
import { TotalizadorProduto } from "./totalizador";
export function VendasProdutosCardapio({ dataInicial, dataFinal, tipo }) {
  const [Dados, setDados] = useState([]);
  const [TipoDados, setTipoDados] = useState(0);

  useEffect(() => {
    let body = { inicial: dataInicial, final: dataFinal, tipo: TipoDados };
    API.post("/v2/relatorio/produtos/periodo", body).then((response) => {
      setDados(response.data);
    });
  }, [dataInicial, dataFinal, TipoDados]);

  const Selected = {
    color: "rgb(164, 0, 27)",
    borderBottom: "2px solid rgb(164, 0, 27)",
  };

  const NotSelected = {
    cursor: "pointer",
  };

  return (
    <>
      {" "}
      <div
        name="categoria"
        style={{
          backgroundColor: "white",
          width: "100%",
          padding: "1rem",
          borderRadius: "0.5rem",
          border: "2px solid rgba(166,166,166,0.5)",
          marginBottom: "35px",
        }}
      >
        <div>
          <div style={{ color: "#CCCCCC", fontSize: "0.85rem" }}>
            <b>
              Período de {formatarData(dataInicial)} até{" "}
              {formatarData(dataFinal)}
            </b>
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            <h1
              style={TipoDados == 0 ? Selected : NotSelected}
              onClick={() => setTipoDados(0)}
            >
              <b>Top 10 Mais Vendidos</b>
            </h1>
            <h1
              style={TipoDados == 1 ? Selected : NotSelected}
              onClick={() => setTipoDados(1)}
            >
              <b>Todos Produtos</b>
            </h1>
          </div>
          <div style={{ height: "350px", marginTop: "15px" }}>
            {TipoDados == 0 ? (
              <GraficoProdutos dados={Dados} tipo={tipo} />
            ) : (
              <TotalizadorProduto dados={Dados} />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
