import React, { useState, useEffect } from "react";
import { MdRemove, MdAdd } from "react-icons/md";
import { NotificaWarning, PrimeiraMaiuscula } from "../../../controller/util";
import { MascaraMonetaria } from "../../../controller/data";
import { InputPedido } from "../../pedido/componente/input";
import { IconButton } from "@mui/material";

var QuantAux = {};

export function PizzaComponent(props) {
  const [quantidades, setQuantidades] = useState(QuantAux);
  const [quantidadesCategoria, setQuantidadesCategoria] = useState({});
  const [quantidadeSelecionada, setQuantidadeSelecionada] = useState(0);
  const [Valor, setValor] = useState(0);
  const [ValorMedia, setValorMedia] = useState(0);

  useEffect(() => {
    props.atualizadados(quantidades);
  }, [quantidades]);

  useEffect(() => {
    if (props.dados) {
      if (props.dados.length > 0) {
        setData(props.dados);
        setResultadosFiltrados(props.dados);
      }
    }
  }, [props.dados]);

  const handleSubtrairQuantidade = (dados, index) => {
    const QuantidadeAtual = quantidadeSelecionada || 0;

    if (QuantidadeAtual > 0) {
      setQuantidadeSelecionada(QuantidadeAtual - 1);
      // props.remove(PrecoPizza(dados));
      QuantAux = (prevQuantidades) => {
        const quantidadeAtual = prevQuantidades[index] || 0;
        const novaQuantidade =
          quantidadeAtual - 1 >= 0 ? quantidadeAtual - 1 : 0;
        return {
          ...prevQuantidades,
          [index]: novaQuantidade,
        };
      };

      setQuantidades(QuantAux);
      let valorRemove = PrecoPizzaRemove(dados);

      props.add(valorRemove);
    }
  };

  const handleAdicionarQuantidade = (dados, index) => {
    const QuantidadeAtual = quantidadeSelecionada || 0;

    if (QuantidadeAtual < dados.quantidadeSabores) {
      const novaQuantidade = quantidades[index] || 0;

      setQuantidadeSelecionada(QuantidadeAtual + 1);

      QuantAux = (prevQuantidades) => ({
        ...prevQuantidades,
        [index]: novaQuantidade + 1,
      });

      setQuantidades(QuantAux);
      let valorAdd = PrecoPizzaSoma(dados);

      props.add(valorAdd);
    } else {
      NotificaWarning("Quantidade Máxima Selecionada!");
    }
  };

  const encontrarObjetoPorId = (idProcurado) => {
    const dadosFiltrados = Data.filter(
      (item) => parseInt(item.id) === parseInt(idProcurado)
    );

    // Se houver apenas um objeto com id igual a idProcurado, retorna esse objeto
    return dadosFiltrados.length > 0 ? dadosFiltrados[0] : null;
  };

  function MaiorValor() {
    let vl = 0;

    Object.entries(quantidades).forEach(([chave, valor]) => {
      if (valor > 0) {
        if (encontrarObjetoPorId(chave).vlVenda > vl) {
          vl = encontrarObjetoPorId(chave).vlVenda;
        }
      }
    });
    setValorMedia(vl);
    return vl;
  }

  function MediaValor() {
    let vl = 0;
    let qtd = 0;

    Object.entries(quantidades).forEach(([chave, valor]) => {
      if (valor > 0) {
        vl = vl + encontrarObjetoPorId(chave).vlVenda * valor;
        qtd = qtd + valor;
      }
    });

    setValorMedia(parseFloat(vl / qtd));
    return parseFloat(vl / qtd);
  }

  function PrecoPizzaSoma(dados) {
    props.remove(Valor);
    let value = ValorMedia;
    switch (dados.tipoPreco) {
      case 0:
        //Média
        return value;
      case 1:
        //Maior
        return value;
      case 2:
        //Soma
        value = value + dados.vlVenda;
        setValorMedia(value);
        return value;

      default:
        // put here the code you want to execute if tipoPreco is not 1, 2 or 3
        break;
    }
  }

  function PrecoPizzaRemove(dados) {
    props.remove(Valor);
    let value = ValorMedia;
    switch (dados.tipoPreco) {
      case 0:
        //Média
        return value;
      case 1:
        //Maior
        return value;
      case 2:
        //Soma
        value = value - dados.vlVenda;
        setValorMedia(value);
        return value;
      default:
        // put here the code you want to execute if tipoPreco is not 1, 2 or 3
        break;
    }
  }

  const [Data, setData] = useState([]);

  useEffect(() => {
    setValor(ValorMedia);
  }, [ValorMedia]);

  useEffect(() => {
    if (props.dados[0]?.tipoPreco == 0) {
      props.remove(Valor);
      props.add(MediaValor());
    }
    if (props.dados[0]?.tipoPreco == 1) {
      props.remove(Valor);
      props.add(MaiorValor());
    }
  }, [quantidades]);

  const [BuscaSabores, setBuscaSabores] = useState("");
  const [resultadosFiltrados, setResultadosFiltrados] = useState([]);

  useEffect(() => {
    // Função para remover acentuação de uma string
    const removerAcentos = (str) => {
      return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    };

    // Filtrar o array Data com base em BuscaSabores (ignorando acentuação e case-insensitive)
    const resultadosFiltrados = Data.filter((item) => {
      const nomeSemAcentos = removerAcentos(item.nome).toLocaleUpperCase();
      const buscaSemAcentos = removerAcentos(BuscaSabores).toLocaleUpperCase();
      return nomeSemAcentos.includes(buscaSemAcentos);
    });

    // Atualizar o estado com os resultados filtrados
    setResultadosFiltrados(resultadosFiltrados);
  }, [BuscaSabores, Data]);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: "#A8001C",
        }}
      >
        <div
          style={{
            textAlign: "left",
            marginLeft: "5px",
            color: "white",
            margin: "5px",
          }}
        >
          Sabores
        </div>
      </div>

      <div style={{ flex: "0 0 100%", padding: "10px", zIndex: 1 }}>
        <InputPedido
          Descricao="Buscar Sabor"
          Set={setBuscaSabores}
          Valor={BuscaSabores}
          Change={setBuscaSabores}
        />
      </div>

      {resultadosFiltrados.map((dados, index) => (
        <ul style={{ padding: "10px" }}>
          <li
            key={dados.id}
            style={{
              marginBottom: "10px",
              borderBottom: "1px dashed rgba(0,0,0,0.1)",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "5px",
                // justifyContent: "flex-end",
              }}
            >
              <IconButton
                color="#F0BA18"
                sx={{
                  width: "48px",
                  height: "48px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#F0BA18",
                  border: "1px solid #F0BA18",
                }}
                onClick={() => handleSubtrairQuantidade(dados, dados.id)}
              >
                <MdRemove size={24} />
              </IconButton>
              {/* <button
                style={{
                  backgroundColor: "#F0BA18",
                  color: "white",
                  width: "48px",
                  height: "48px",
                  marginRight: "8px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  //   borderRadius: "50px",
                }}
                onClick={() => handleSubtrairQuantidade(dados, dados.id)}
              >
                <MdRemove size={24} />
              </button> */}
              <span
                style={{
                  height: "35px",
                  width: "35px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {quantidades[dados.id] || 0}
              </span>
              <IconButton
                color="#269931"
                sx={{
                  width: "48px",
                  height: "48px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#269931",
                  border: "1px solid #269931",
                }}
                onClick={() => handleAdicionarQuantidade(dados, dados.id)}
              >
                <MdAdd size={24} />
              </IconButton>
              {/* <button
                style={{
                  backgroundColor: "#269931",
                  color: "white",
                  width: "48px",
                  height: "48px",
                  marginLeft: "8px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  //   borderRadius: "50px",
                }}
                onClick={() => handleAdicionarQuantidade(dados, dados.id)}
              >
                <MdAdd size={24} />
              </button> */}
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "20px",
                  // justifyContent: "flex-start",
                  color: "black",
                }}
              >
                <div>
                  <h6>{PrimeiraMaiuscula(dados.nome)}</h6>
                  {dados.vlVenda > 0 ? (
                    <span style={{ color: "#269931" }}>
                      {MascaraMonetaria(dados.vlVenda)}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          </li>
        </ul>
      ))}
    </>
  );
}
