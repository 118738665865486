export function GravaLocal(Categoria, Valor) {
    localStorage.setItem(Categoria, Valor);

    return Valor;
}

export function ApagaLocal(Categoria) {
    localStorage.removeItem(Categoria);
}

export function BuscaLocal(Categoria){
    return localStorage.getItem(Categoria);
}