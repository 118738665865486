import { FaMobile, FaSyncAlt } from "react-icons/fa";
import { BuscaLocal } from "../../../controller/storage";
import { Button } from "../../../component/button";

export function MobileBloqueado() {
  function Reload() {
    //console.log("reload");
    window.location = "/principal";
  }

  return (
    <>
      <div
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          alignItems: "center",
          justifyItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "10px",
            fontSize: "2rem",
            justifyItems: "center",
            alignItems: "center",
            // alignContent: "center",
            // justifyContent: "center",
          }}
        >
          <FaMobile />
          Celular Não Autorizado!
        </div>
        <div style={{ textAlign: "center" }}>
          <h1>Código de Autenticação</h1>
          <p>
            <b>{BuscaLocal("user_agent")}</b>
          </p>
          <div style={{ width: "98vw", padding: "10px" }}>
            <Button
              descricao="Atualizar"
              borderColor="black"
              fontColor="black"
              BorderRadius="5"
              icon={<FaSyncAlt />}
              evento={Reload}
            />
          </div>
        </div>
      </div>
    </>
  );
}
