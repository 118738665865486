import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Slider from "@mui/material/Slider";
import MuiInput from "@mui/material/Input";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import { MascaraMonetaria } from "../../../../../../controller/data";
import { API } from "../../../../../../controller/api/api";

const Input = styled(MuiInput)`
  width: 60px;
`;

export function SlideValue({
  Quantidade,
  Total,
  Ativo,
  Codigo,
  onQuantidadeChange,
  QuantidadeSelecionada,
  Servico,
}) {
  const [value, setValue] = React.useState(QuantidadeSelecionada);
  const [pessoas, setPessoas] = React.useState(1);
  const inputRef = React.useRef(null);

  // const [carregado, setCarregado] = useState(false);

  //

  // React.useEffect(() => {
  //   if (!carregado) {
  //   API.get(`/v2/pagamento/produtos/${Codigo}`).then(function (response) {
  //     //console.log(response.data);
  //     let quantidadeSelecionada = 0;
  //     for (var i = 0; i < response.data.length; i++) {
  //       quantidadeSelecionada =
  //         quantidadeSelecionada + response.data[i].quantidade;
  //     }
  //     setQuantidadeMin(quantidadeSelecionada);
  //     //console.log(quantidadeSelecionada);

  //   });

  // }
  // }, [Codigo,carregado]);

  React.useEffect(() => {
    if (document.activeElement !== inputRef.current) {
      // setValue(QuantidadeSelecionada);
    }
    document.querySelector(`input[name=por${Codigo}]`).value = 1;
    //console.log(QuantidadeSelecionada);
  }, [QuantidadeSelecionada]);

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
    onQuantidadeChange(Codigo, newValue);
  };

  const handleInputChange = (event) => {
    const newValue = event.target.value === "" ? 0 : Number(event.target.value);
    setValue(newValue);
    //
  };

  const handleInputChangePessoa = (event) => {
    const newValue = event.target.value === "" ? 0 : Number(event.target.value);

    if (newValue > 0) {
      setPessoas(newValue);
      setValue(Quantidade / newValue);
      onQuantidadeChange(Codigo, Quantidade / newValue);
    } else {
      setPessoas(1);
    }
  };

  const handleBlur = () => {
    if (value < 0) {
      setValue(0);
      onQuantidadeChange(Codigo, 0);
      return true;
    } else if (value > Quantidade) {
      setValue(Quantidade);
      onQuantidadeChange(Codigo, Quantidade);
      return true;
    }
    onQuantidadeChange(Codigo, value);
  };

  return (
    <Box>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <Grid item>
            <h3>Por</h3>
            <Input
              disabled={!Ativo}
              value={pessoas}
              size="small"
              onChange={handleInputChangePessoa}
              // onBlur={handleBlur}
              name={`por${Codigo}`}
              style={{ textAlign: "center", marginBottom: "5px" }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Typography id="input-slider" gutterBottom>
        <p name={`b${Codigo}`}>
          {MascaraMonetaria((Total / Quantidade) * value)}
        </p>
      </Typography>
    </Box>
  );
}
