import { useEffect, useState } from "react";
import SelectSearch from "react-select-search";
import { InputPedido } from "./componente/input";
import { HeaderComponent } from "../../component/header";
import { useParams } from "react-router-dom";
import { API } from "../../controller/api/api";
import {
  MascaraMonetaria,
  RemoveMascara,
  calcularTempoDecorrido,
  formatarData,
  formatarHora,
  transformarData,
} from "../../controller/data";
import { ListaProduto } from "../salaopage/componente/listaproduto";
import { ApagaLocal, BuscaLocal, GravaLocal } from "../../controller/storage";
import { CabecalhoPediodo } from "../../component/cabecalhopedido";
import {
  Notifica,
  NotificaWarning,
  PrimeiraMaiuscula,
  Upper,
} from "../../controller/util";
import { Button } from "../../component/button";
import Select from "react-select";
import LoadingOverlay from "../../component/load";
import { ComponentePedido } from "../../component/pedido";

const optionsType = [
  { name: "Delivery", value: "1" },
  { name: "Vem Buscar", value: "2" },
];

const styles = {
  container: {
    display: "flex",
  },
  menu: {
    width: "200px",
    backgroundColor: "#f0f0f0",
    padding: "20px",
  },
  menuItem: {
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
  },
  menuIcon: {
    marginRight: "10px",
  },
  menuText: {
    fontSize: "16px",
  },
  content: {
    flex: 1,
    padding: "20px",
  },
};
export function PedidoPage() {
  const { id } = useParams();

  function Sair() {
    API.get("/v1/produtos/pedido/2/" + id).then(function (result) {
      API.post("v1/imprimir", result.data);
    });
  }

  return (
    <>
      <HeaderComponent navegar={"/principal"} onVoltar={Sair}>
        <ComponentePedido id={id} key={id} />
      </HeaderComponent>
    </>
  );
}
