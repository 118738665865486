import { MdAdd, MdRemove } from "react-icons/md";
import { MascaraMonetaria, formatarValor } from "../../../../controller/data";
import { ParametroConfiguracao } from "../../../../controller/parametros";
import {
  NotificaWarning,
  PrimeiraMaiuscula,
} from "../../../../controller/util";
import { Checkbox } from "@mui/material";

export function ListProduct(props) {
  let foto = atob(props.data.img);

  if (foto == "") {
    foto = "./img/sem-foto.jpg";
  }

  if (props.data.ifood_id != undefined || props.data.ifood_id == "") {
    foto = props.data.ifood_img;
  }

  if (foto == "") {
    if (props.data.id == null) {
      foto = "https://fotos.goopedir.com/fotos/" + atob(props.data.id);
    }
  }

  if (foto.length == 0) {
    foto = "https://fotos.goopedir.com/fotos/" + btoa(props.data.externalCode);
  }

  let valorProduto = MascaraMonetaria(props.data.value);

  if (props.data.min > 0 && props.data.value == 0) {
    valorProduto = `de ${MascaraMonetaria(
      props.data.min
    )} até ${MascaraMonetaria(props.data.max)}`;
  }

  async function OnClique() {
    if (
      props.data.ncm <= 0 ||
      props.data.cest <= 0 ||
      props.data.cfop <= 0 ||
      props.data.csosn <= 0
    ) {
      let UsaNFCe = await ParametroConfiguracao("nfce");
      //console.log(UsaNFCe);
      if (UsaNFCe == 1) {
        NotificaWarning("Produto Não Possui Cadastro Fiscal!");
        return false;
      }
    }

    if (props.data.usaStock == 1) {
      if (props.data.stock <= 0) {
        NotificaWarning("Produto Fora de Estoque!");
      } else {
        props.clique();
      }
    } else {
      props.clique();
    }
  }

  function UtilizaEstoque() {
    return props.data.usaStock > 0;
  }

  function EstoqueZerado() {
    return props.data.stock <= 0;
  }

  return (
    <>
      <div
        // key={Codigo}
        style={{
          border: "1px solid #e0e0e0",
          borderRadius: "8px",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          marginBottom: "10px",
          padding: "10px",
          backgroundColor: "#ffffff",
          transition: "box-shadow 0.3s ease-in-out",
        }}
        onMouseEnter={(e) =>
          (e.currentTarget.style.boxShadow = "0 8px 12px rgba(0, 0, 0, 0.1)")
        }
        onMouseLeave={(e) =>
          (e.currentTarget.style.boxShadow = "0 4px 6px rgba(0, 0, 0, 0.1)")
        }
      >
        <div style={{ display: "flex" }}>
          {UtilizaEstoque() ? (
            EstoqueZerado() ? (
              <div
                style={{
                  flexShrink: 0,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "10px",
                }}
              >
                <Checkbox
                  // onChange={(e) => handleCheckboxChange(Codigo, Quantidade)}
                  checked={false}
                  // {...label}
                  disabled={false}
                />
              </div>
            ) : (
              <div
                style={{
                  flexShrink: 0,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "10px",
                }}
              >
                <Checkbox
                  onChange={() =>
                    props.product(
                      props.data.id,
                      props.data.value,
                      props.data.usaStock
                    )
                  }
                  checked={props.status(props.data.id)}
                  disabled={false}
                />
              </div>
            )
          ) : (
            <div
              style={{
                flexShrink: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px",
              }}
            >
              <Checkbox
                // onChange={(e) => handleCheckboxChange(Codigo, Quantidade)}
                onChange={() =>
                  props.product(
                    props.data.id,
                    props.data.value,
                    props.data.usaStock
                  )
                }
                checked={props.status(props.data.id)}
                disabled={props.data.value == 0}
              />
            </div>
          )}

          <div
            onClick={OnClique}
            style={{
              display: "flex",
              alignItems: "center",
              flex: "1 1 auto",
            }}
          >
            <img
              src={foto}
              alt={PrimeiraMaiuscula(props.data.name)}
              style={{
                height: "100px",
                width: "100px",
                marginRight: "10px",
                borderRadius: "10px",
              }}
            />
            <div>
              <h3 style={{ fontWeight: "bold", textTransform: "capitalize" }}>
                {PrimeiraMaiuscula(props.data.name)}
              </h3>
              <h6 style={{ color: "gray", textTransform: "capitalize" }}>
                {PrimeiraMaiuscula(props.data.description)}
              </h6>

              {UtilizaEstoque() ? (
                EstoqueZerado() ? (
                  <>Sem Estoque</>
                ) : (
                  <>{`${formatarValor(props.data.stock) + props.data.un}`}</>
                )
              ) : null}

              <h4>{valorProduto}</h4>
            </div>
          </div>

          {props.status(props.data.id) ? (
            <>
              <div style={{ display: "flex", flex: "0 0 auto" }}>
                <button
                  style={{
                    backgroundColor:
                      props.quantidade(props.data.id) == 1
                        ? "#808080"
                        : "#F0BA18",
                    color: "white",
                    width: "30px",
                    height: "30px",
                    borderRadius: "50px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => props.remove(props.data.id)}
                >
                  <MdRemove size={24} />
                </button>

                <input
                  style={{
                    height: "24px",
                    width: "48px",
                    display: "flex",

                    alignItems: "center",
                    justifyContent: "center",
                    background: "white",
                    textAlign: "center",
                  }}
                  value={props.quantidade(props.data.id)}
                  // onChange={(e) => setQuantidadeAtual(e.target.value)}
                ></input>
                <button
                  style={{
                    backgroundColor: "#269931",
                    color: "white",
                    width: "30px",
                    height: "30px",
                    borderRadius: "50px",
                    marginRight: "5px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => props.add(props.data.id)}
                >
                  <MdAdd size={24} />
                </button>
              </div>
            </>
          ) : null}
        </div>
        {props.status(props.data.id) ? (
          <>
            <div style={{ padding: "10px" }}>
              <div>Observação</div>
              <textarea
                value={props.getObs(props.data.id)}
                // onChange={handleChange}
                onChange={(e) => props.obs(props.data.id, e.target.value)}
                maxLength={200}
                style={{ width: "100%", height: "100px", border: "1px solid" }}
              />
            </div>
          </>
        ) : null}
      </div>
    </>
  );
}
