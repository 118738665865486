import { useState, useEffect } from "react";
import { API } from "../../../controller/api/api";
import { HeaderComponent } from "../../../component/header";

import ReactApexChart from "react-apexcharts";
import {
  DataAtual,
  MascaraMonetaria,
  PrimeiroDiaDoMes,
  transformDatePadrao,
} from "../../../controller/data";
import { InputPedido } from "../../pedido/componente/input";

let DataFinalPadrao = PrimeiroDiaDoMes();
let DataInicialPadrao = DataAtual();

export function RelatorioVendasPages() {
  const [DataInicial, setDataInicial] = useState(DataFinalPadrao);
  const [DataFinal, setDataFinal] = useState(DataInicialPadrao);
  // GravaLocal('VOLTAR','/principal')
  const [DadosPorStatus, setDadosPorStatus] = useState([]);
  const [DadosProdutos, setDadosProdutos] = useState([]);
  const [DadosAdicionais, setDadosAdicionais] = useState([]);
  const [DadosPorDiaStatus, setDadosPorDiaStatus] = useState([]);
  const [TodosPedidos, setTodosPedidos] = useState([]);
  const [Origem, setOrigem] = useState([]);
  const [options, setOptions] = useState({});

  function BuscaDados() {
    API.get(
      `v1/util/relatorio/financeiro/1/${transformDatePadrao(
        DataInicial
      )}/${transformDatePadrao(DataFinal)}`
    ).then(function (dados) {
      setDadosPorStatus(dados.data);
      ////console.log(dados.data)
    });

    API.get(
      `v1/util/relatorio/financeiro/2/${transformDatePadrao(
        DataInicial
      )}/${transformDatePadrao(DataFinal)}`
    ).then(function (dados) {
      setDadosProdutos(dados.data);
      ////console.log(dados.data)
    });

    API.get(
      `v1/util/relatorio/financeiro/3/${transformDatePadrao(
        DataInicial
      )}/${transformDatePadrao(DataFinal)}`
    ).then(function (dados) {
      setDadosAdicionais(dados.data);
      ////console.log(dados.data)
    });

    API.get(
      `v1/util/relatorio/financeiro/4/${transformDatePadrao(
        DataInicial
      )}/${transformDatePadrao(DataFinal)}`
    ).then(function (dados) {
      setDadosPorDiaStatus(dados.data);
      ////console.log(dados.data)
    });
  }
  useEffect(() => {
    BuscaDados();
  }, []);
  useEffect(() => {
    setOptions({
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: Origem,
      },
      yaxis: {
        title: {
          text: "Total",
        },
      },
      fill: {
        opacity: 1,
      },
      colors: ["#008FFB", "#00E396", "#FEB019", "#FF4560", "#775DD0"],
      tooltip: {
        y: {
          formatter: function (val) {
            return "Total " + MascaraMonetaria(val);
          },
        },
      },
    });

    let Cancelado = [];
    let EmEspera = [];
    let Producao = [];
    let Pronto = [];
    let DispRetirada = [];
    let SaiuEntrega = [];
    let Concluido = [];

    for (let i = 0; i < Origem.length; i++) {
      let cancelado = TodosPedidos.filter((item) => {
        return (
          item.origem === NameOrigem(Origem[i]) &&
          item.descricao === "Cancelado"
        );
      });
      if (cancelado.length > 0) {
        Cancelado.push(cancelado[0].total);
      } else {
        Cancelado.push(0);
      }

      let emespera = TodosPedidos.filter((item) => {
        return (
          item.origem === NameOrigem(Origem[i]) &&
          item.descricao === "Em Espera"
        );
      });
      if (emespera.length > 0) {
        EmEspera.push(emespera[0].total);
      } else {
        EmEspera.push(0);
      }

      let producao = TodosPedidos.filter((item) => {
        return (
          item.origem === NameOrigem(Origem[i]) &&
          item.descricao === "Em Produção"
        );
      });
      if (producao.length > 0) {
        Producao.push(producao[0].total);
      } else {
        Producao.push(0);
      }

      let pronto = TodosPedidos.filter((item) => {
        return (
          item.origem === NameOrigem(Origem[i]) && item.descricao === "Pronto"
        );
      });
      if (pronto.length > 0) {
        Pronto.push(pronto[0].total);
      } else {
        Pronto.push(0);
      }

      let dispretirada = TodosPedidos.filter((item) => {
        return (
          item.origem === NameOrigem(Origem[i]) &&
          item.descricao === "Disponível Para Retirada"
        );
      });
      if (dispretirada.length > 0) {
        DispRetirada.push(dispretirada[0].total);
      } else {
        DispRetirada.push(0);
      }

      let saiuentrega = TodosPedidos.filter((item) => {
        return (
          item.origem === NameOrigem(Origem[i]) &&
          item.descricao === "Saiu Para Entrega"
        );
      });
      if (saiuentrega.length > 0) {
        SaiuEntrega.push(saiuentrega[0].total);
      } else {
        SaiuEntrega.push(0);
      }

      let concluido = TodosPedidos.filter((item) => {
        return (
          item.origem === NameOrigem(Origem[i]) &&
          item.descricao === "Finalizado"
        );
      });
      if (concluido.length > 0) {
        Concluido.push(concluido[0].total);
      } else {
        Concluido.push(0);
      }
    }

    setSeries([
      {
        name: "Cancelado",
        data: Cancelado,
      },
      {
        name: "Espera",
        data: EmEspera,
      },
      {
        name: "Produção",
        data: Producao,
      },
      {
        name: "Pronto",
        data: Pronto,
      },
      {
        name: "Disp P. Retirada",
        data: DispRetirada,
      },
      {
        name: "Saiu P. Entrega",
        data: SaiuEntrega,
      },
      {
        name: "Concluido",
        data: Concluido,
      },
    ]);

    ////console.log(Cancelado);
  }, [Origem]);

  useEffect(() => {
    BuscaDados();
  }, [DataInicial, DataFinal]);

  function OrigemName(origem) {
    switch (origem) {
      case 1:
        return "Whatsapp";
        break;
      case 2:
        return "Site";
        break;
      case 3:
        return "PDV";
        break;
      case 4:
        return "iFood";
        break;
      case 5:
        return "Mesa";
        break;
      default:
        return "Origem desconhecida";
    }
  }

  function NameOrigem(name) {
    switch (name) {
      case "Whatsapp":
        return 1;
        break;
      case "Site":
        return 2;
        break;
      case "PDV":
        return 3;
        break;
      case "iFood":
        return 4;
        break;
      case "Mesa":
        return 5;
        break;
      default:
        return "Origem desconhecida";
    }
  }

  const [series, setSeries] = useState([]);

  function AlteraValoresInput(type, valor) {
    if (type == "Buscar") {
      // setSearchText(valor);
    }
    if (type == "Data Inicial") {
      DataInicialPadrao = valor;
      setDataInicial(valor);
      // BuscarDados();
    }
    if (type == "Data Final") {
      DataFinalPadrao = valor;
      //////console.log(valor);
      setDataFinal(valor);
      // BuscarDados();
    }
  }

  return (
    <>
      <HeaderComponent>
        <div style={{ height: "80vh", width: "100%", color: "#65676799" }}>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <div style={{ flex: "0 0 230px", marginRight: "10px", zIndex: 1 }}>
              <InputPedido
                Tipo="date"
                Descricao="Data Inicial"
                Retorno={AlteraValoresInput}
                Valor={DataInicial}
              />
            </div>
            <div style={{ flex: "0 0 230px", marginRight: "10px", zIndex: 1 }}>
              <InputPedido
                Tipo="date"
                Descricao="Data Final"
                Retorno={AlteraValoresInput}
                Valor={DataFinal}
              />
            </div>
          </div>
          <ReactApexChart
            options={options}
            series={series}
            type="bar"
            height={350}
          />
        </div>
      </HeaderComponent>
    </>
  );
}
