import { HeaderComponent } from "../../component/header";
import React, { useState, useEffect } from "react";
import { ListaProduto } from "./componente/listaproduto";
import { useParams } from "react-router-dom";
import { API } from "../../controller/api/api";

import { GravaLocal } from "../../controller/storage";
import { CabecalhoPediodo } from "../../component/cabecalhopedido";
import { MascaraMonetaria, TextIdioma } from "../../controller/data";
import { ParametroConfiguracao } from "../../controller/parametros";
import { Toggle } from "../../component/toggle";
import { isMobileDevice } from "../../controller/mobile";
import { PrimeiraMaiuscula } from "../../controller/util";
import { InputPedido } from "../pedido/componente/input";

var AcaoExecutar = 0;
let TaxServico = false;
let DadosImpressao = [];

export function SalaoPageId() {
  const { id } = useParams();
  const [hoveredIcon, setHoveredIcon] = useState(null);
  const [Pedido, setPedido] = useState(0);
  const [DescricaoPedido, setDescricaoPedido] = useState("Pedido");
  const [TotalPedido, setTotalPedido] = useState(0);
  const [isModal, setModal] = useState(false);
  const [MensagemConfirmacao, setMensagemConfirmacao] = useState("");
  const [DadosPedido, setDadosPedido] = useState([]);
  const [ProdutoPedido, setProdutoPedido] = useState([]);
  const [taxaServico, setTaxaServico] = useState(0);
  const [Servico, setServico] = useState(false);
  const [TipoMesa, setTipoMesa] = useState(false);

  const [DescricaoTipo, setDescricaoTipo] = useState("");
  const [Comanda, setComanda] = useState("");

  function truncarDuasCasas(num) {
    return num;
  }

  function TipoPedido() {
    if (id > 0) {
      return 1;
    } else {
      return 0;
    }
  }

  async function Parametros() {
    let servico = await ParametroConfiguracao("taxaServicoPedido");

    if (servico > 0) {
      TaxServico = true;
      setServico(true);
      setTaxaServico(servico);
    }
  }

  useEffect(() => {
    GravaLocal("VOLTAR", "/salao/" + id);
    AtualizaDados();
    Parametros();
  }, []);

  function AtualizaDados() {
    API.get("v1/mesa/" + id).then(function (dados) {
      setDescricaoPedido(
        PrimeiraMaiuscula(dados.data[0].descricaoMesa) +
          " " +
          dados.data[0].nrMesa
      );
      setPedido(dados.data[0].pedido);
      setTotalPedido(dados.data[0].totMesa);
    });
  }

  useEffect(() => {
    if (DescricaoTipo.length > 0) {
      API.post(`/v2/comanda/descricao/${Pedido}/${DescricaoTipo}`);
      setDescricaoPedido(PrimeiraMaiuscula(`${Comanda} - ${DescricaoTipo}`));
    }
  }, [DescricaoTipo]);

  useEffect(() => {
    if (Pedido != 0) {
      //Essa requisição esta demorando, analizar
      API.get("v1/pedido/produtos/" + Pedido).then(function (dados) {
        setProdutoPedido(dados.data);
      });
      API.get("/v1/dados/pedido/" + Pedido).then(function (dados) {
        setDadosPedido(dados.data);

        if (dados.data.idFicha > 0) {
          API.get(`v2/comanda/id/${dados.data.idFicha}`).then((response) => {
            if (response.data[0]?.descricao != undefined) {
              setDescricaoTipo(response.data[0]?.descricao);
              setComanda(dados.data.descFicha);
              setDescricaoPedido(
                PrimeiraMaiuscula(
                  `${dados.data.descFicha} - ${response.data[0]?.descricao}`
                )
              );
            } else {
              setDescricaoPedido(PrimeiraMaiuscula(`${dados.data.descFicha}`));
            }
          });
        }
      });
    }
  }, [Pedido]);

  function Sair() {
    if (TipoPedido() == 1) {
      API.get("/v1/produtos/pedido/1/" + id).then(function (result) {
        API.post("v1/imprimir", result.data);
      });

      //Mesa
    } else {
      API.post("/v1/imprimir/1/" + Pedido);
    }
  }

  function CalculaTaxaServico(Valor) {
    if (Servico) {
      let taxa = truncarDuasCasas((Valor * taxaServico) / 100);
      return truncarDuasCasas(taxa + Valor);
    } else {
      return truncarDuasCasas(Valor);
    }
  }

  function RecebeLista(dados) {
    //console.log(dados);
    DadosImpressao = dados;
  }

  return (
    <>
      <HeaderComponent navegar="/salao" onVoltar={Sair}>
        <CabecalhoPediodo
          Descricao={DescricaoPedido}
          Pedido={Pedido}
          id={id}
          Servico={Servico ? taxaServico : 0}
        />

        <div style={{ padding: "15px", marginBottom: "50px" }}>
          <ListaProduto
            dados={ProdutoPedido}
            atualizar={AtualizaDados}
          ></ListaProduto>
        </div>

        <div
          style={{
            position: "fixed",
            bottom: "0",
            height: isMobileDevice() ? "auto" : "80px",
            display: isMobileDevice() ? "" : "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            backgroundColor: "white",
            width: "100%",
          }}
        >
          {taxaServico > 0 ? (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  width: "100%",
                }}
              >
                <Toggle
                  Titulo={`Taxa Serviço (${taxaServico}%) ${MascaraMonetaria(
                    CalculaTaxaServico(TotalPedido) - TotalPedido
                  )}`}
                  Change={() => setServico(!Servico)}
                  Checked={Servico}
                />
              </div>
            </>
          ) : null}
          <span
            style={{
              fontWeight: "bold",
              fontSize: "18px",
              marginRight: "20px",
              width: "100%",
              textAlign: "right",
            }}
          >
            {TextIdioma("Total ") +
              MascaraMonetaria(CalculaTaxaServico(TotalPedido))}
          </span>
        </div>
      </HeaderComponent>
    </>
  );
}
