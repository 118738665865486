import { HeaderComponent } from "../../component/header";
import { FaTimesCircle } from "react-icons/fa"; // Ícone de "X" do react-icons

export function PageSemAcesso({ NomeModulo }) {
  return (
    <>
      <HeaderComponent />
      <div style={styles.container}>
        <FaTimesCircle style={styles.icon} />
        <h2 style={styles.title}>Acesso Bloqueado</h2>
        <p style={styles.message}>
          Você não tem acesso ao módulo <strong>{NomeModulo}</strong>. Entre em
          contato com o comercial!
        </p>
      </div>
    </>
  );
}

const styles = {
  container: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "80vh", // Mantém o conteúdo centralizado verticalmente
    padding: "20px",
  },
  icon: {
    color: "rgb(168, 0, 28)",
    fontSize: "80px",
    marginBottom: "20px",
  },
  title: {
    fontSize: "24px",
    marginBottom: "10px",
  },
  message: {
    fontSize: "18px",
    maxWidth: "400px",
    lineHeight: "1.5",
  },
};
