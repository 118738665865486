import { useEffect, useMemo, useState } from "react";
import { HeaderComponent } from "../../component/header";
import { ShowModal } from "../../component/modalPai";
import { InputPedido } from "../pedido/componente/input";
import { Toggle } from "../../component/toggle";
import { API } from "../../controller/api/api";
import { Button } from "../../component/button";
import { NotificaWarning, PrimeiraMaiuscula } from "../../controller/util";
import { ComponentCadastro } from "../../component/header/cadastro";
import { FormControlLabel, IconButton, Switch } from "@mui/material";
import { FaPen } from "react-icons/fa";
import Table from "../../component/tabela";
import { ModalGravarDados } from "../../component/gravar";

let DadosEstatico = [];

export function Usuario() {
  const [Finalizar, setFinalizar] = useState(false);
  const [Excluir, setExcluir] = useState(false);
  const [Dashboard, setDashboard] = useState(false);
  const [Entrada, setEntrada] = useState(false);
  const [Desconto, setDesconto] = useState(false);
  const [AberturaCaixa, setAberturaCaixa] = useState(false);
  const [Parametros, setParametros] = useState(false);
  const [Mesa, setMesa] = useState(false);
  const [Motoboy, setMotoboy] = useState(false);
  const [Taxa, setTaxa] = useState(false);
  const [Impressora, setImpressora] = useState(false);
  const [Cupom, setCupom] = useState(false);
  const [Produto, setProduto] = useState(false);
  const [Pagamento, setPagamento] = useState(false);
  const [Lancamento, setLancamento] = useState(false);
  const [Cancelamento, setCancelamento] = useState(false);
  const [Garcom, setGarcom] = useState(false);

  const [Codigo, setCodigo] = useState(0);
  const [Nome, setNome] = useState("");
  const [Senha, setSenha] = useState("");

  const [isCadastro, setisCadastro] = useState(false);

  const [Usuarios, setUsuarios] = useState([]);

  useEffect(() => {
    BuscarDados();
  }, []);

  function BuscarDados() {
    setisCadastro(false);
    API.get(`v1/consulta/generica/usuario/*/*/*`).then((response) => {
      DadosEstatico = response.data;
      setUsuarios(response.data);
    });
  }

  useEffect(() => {
    //console.log(Finalizar);
  }, [Finalizar]);

  function Novo() {
    setCodigo(0);
    setNome("");
    setFinalizar(true);
    setExcluir(true);

    setDashboard(true);
    setEntrada(true);
    setDesconto(true);
    setAberturaCaixa(true);
    setParametros(true);
    setMesa(true);
    setMotoboy(true);
    setTaxa(true);
    setImpressora(true);

    setCupom(true);
    setProduto(true);
    setPagamento(true);
    setLancamento(true);
    setCancelamento(true);
    setGarcom(false);
    setisCadastro(true);
  }

  function Salvar(codigo) {
    let Body = {};
    if (Codigo == 0) {
      if (Nome == "") {
        NotificaWarning("Usuário Deve Ser Preenchido!");
        return false;
      }

      if (Senha == "") {
        NotificaWarning("Senha Deve Ser Preenchida!");
        return false;
      }
    }

    Body.codigo = Codigo;
    Body.nome = Nome;
    Body.senha = Senha;
    Body.finalizar = Finalizar;
    Body.excluir = Excluir;
    Body.dashboard = Dashboard;
    Body.entrada = Entrada;
    Body.desconto = Desconto;
    Body.aberturacaixa = AberturaCaixa;
    Body.parametros = Parametros;
    Body.mesa = Mesa;
    Body.taxa = Taxa;
    Body.impressora = Impressora;
    Body.cupom = Cupom;
    Body.produto = Produto;
    Body.pagamento = Pagamento;
    Body.lancamento = Lancamento;
    Body.cancelar = Cancelamento;
    Body.motoboy = Motoboy;
    Body.garcom = Garcom;

    API.post("/v2/usuario", Body).then(function (response) {
      BuscarDados();
    });
  }

  const columns = useMemo(
    () => [
      {
        Header: "Usuário",
        accessor: "nome",
        size: "100px",
        textAlign: "center",
        format: PrimeiraMaiuscula,
      },
      {
        Header: "Permissão",
        accessor: "senha",
        size: "100px",
        textAlign: "center",
        formatOption: PintaValorPadrao,
      },
      {
        Header: "Alterar",
        size: "50px",
        component: (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IconButton color="primary">
              <FaPen />
            </IconButton>
          </div>
        ),
        func: AlterarDados,
      },
    ],
    []
  );

  function AlterarDados(dados) {
    const objetoEncontrado = DadosEstatico.find(
      (objeto) => objeto.codigo === dados
    );

    setCodigo(objetoEncontrado.codigo);
    setNome(objetoEncontrado.nome);
    setFinalizar(objetoEncontrado.encerra == 1);
    setExcluir(objetoEncontrado.deleta == 1);

    setDashboard(objetoEncontrado.dashboard == 1);
    setEntrada(objetoEncontrado.estoque == 1);
    setDesconto(objetoEncontrado.desconto == 1);
    setAberturaCaixa(objetoEncontrado.caixa == 1);
    setParametros(objetoEncontrado.param == 1);
    setMesa(objetoEncontrado.cadMesa == 1);
    setMotoboy(objetoEncontrado.cadMotoboy == 1);
    setTaxa(objetoEncontrado.cadTaxa == 1);
    setImpressora(objetoEncontrado.deleta == 1);

    setCupom(objetoEncontrado.cadCupom == 1);
    setProduto(objetoEncontrado.cadProd == 1);
    setPagamento(objetoEncontrado.cadPaga == 1);
    setLancamento(objetoEncontrado.cadPedido == 1);
    setCancelamento(objetoEncontrado.cancelar == 1);
    setGarcom(objetoEncontrado.garcom == 1);

    setisCadastro(true);
  }

  function PintaValorPadrao(valor, dataFiltro) {
    //console.log(valor);
    return (
      <>
        <div style={{ display: "flex" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <FormControlLabel
              disabled
              control={<Switch checked={valor.encerra == 1} />}
              label="Finalizar Pedido"
            />
            <FormControlLabel
              disabled
              control={<Switch checked={valor.deleta == 1} />}
              label="Excluir Produto"
            />
            <FormControlLabel
              disabled
              control={<Switch checked={valor.dashboard == 1} />}
              label="Acesso Dashboard"
            />
            <FormControlLabel
              disabled
              control={<Switch checked={valor.estoque == 1} />}
              label="Estoque"
            />
            <FormControlLabel
              disabled
              control={<Switch checked={valor.desconto == 1} />}
              label="Desconto"
            />
            <FormControlLabel
              disabled
              control={<Switch checked={valor.caixa == 1} />}
              label="Caixa"
            />
            <FormControlLabel
              disabled
              control={<Switch checked={valor.param == 1} />}
              label="Parametros"
            />
            <FormControlLabel
              disabled
              control={<Switch checked={valor.cadMesa == 1} />}
              label="Mesa / Comanda"
            />
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <FormControlLabel
              disabled
              control={<Switch checked={valor.cadMotoboy == 1} />}
              label="Motoboy"
            />
            <FormControlLabel
              disabled
              control={<Switch checked={valor.cadTaxa == 1} />}
              label="Taxa"
            />
            <FormControlLabel
              disabled
              control={<Switch checked={valor.cadImpressora == 1} />}
              label="Impressora"
            />
            <FormControlLabel
              disabled
              control={<Switch checked={valor.cadCupom == 1} />}
              label="Cupom"
            />
            <FormControlLabel
              disabled
              control={<Switch checked={valor.cadProd == 1} />}
              label="Produto"
            />
            <FormControlLabel
              disabled
              control={<Switch checked={valor.cadPaga == 1} />}
              label="Forma de Pagamento"
            />
            <FormControlLabel
              disabled
              control={<Switch checked={valor.cadPedido == 1} />}
              label="Lançamento de Pedido"
            />
            <FormControlLabel
              disabled
              control={<Switch checked={valor.cancelar == 1} />}
              label="Cancelamento de Pedido"
            />
            <FormControlLabel
              disabled
              control={<Switch checked={valor.garcom == 1} />}
              label="Garçom"
            />
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <HeaderComponent>
        <ComponentCadastro Titulo="Usuário" Funcao={Novo} />
        <Table columns={columns} data={Usuarios} codigo={"codigo"} />

        {isCadastro ? (
          <>
            <ModalGravarDados
              Descricao={Codigo == 0 ? "Cadastro" : "Alteração"}
              closeButton={() => setisCadastro(false)}
              Funcao={Salvar}
            >
              <InputPedido
                Descricao="Nome"
                Set={setNome}
                Valor={Nome}
                sx={{ marginBottom: "15px" }}
              />
              <InputPedido
                Descricao="Senha"
                Set={setSenha}
                Valor={Senha.toUpperCase()}
                Tipo={"password"}
              />
              <h3>
                <b>Parametros</b>
              </h3>
              <Toggle
                Titulo={`Finalizar Pedido`}
                Clique={() => setFinalizar(!Finalizar)}
                Checked={Finalizar}
              />
              <Toggle
                Titulo={`Excluir Produto`}
                Clique={() => setExcluir(!Excluir)}
                Checked={Excluir}
              />
              <Toggle
                Titulo={`Acesso Dashboard`}
                Clique={() => setDashboard(!Dashboard)}
                Checked={Dashboard}
              />
              <Toggle
                Titulo={`Controle Estoque (Entrada)`}
                Clique={() => setEntrada(!Entrada)}
                Checked={Entrada}
              />
              <Toggle
                Titulo={`Desconto`}
                Clique={() => setDesconto(!Desconto)}
                Checked={Desconto}
              />
              <Toggle
                Titulo={`Abertura / Fechamento Caixa`}
                Clique={() => setAberturaCaixa(!AberturaCaixa)}
                Checked={AberturaCaixa}
              />
              <Toggle
                Titulo={`Parametros`}
                Clique={() => setParametros(!Parametros)}
                Checked={Parametros}
              />
              <h3>
                <b>Cadastros</b>
              </h3>
              <Toggle
                Titulo={`Mesa / Comanda`}
                Clique={() => setMesa(!Mesa)}
                Checked={Mesa}
              />
              <Toggle
                Titulo={`Motoboy`}
                Clique={() => setMotoboy(!Motoboy)}
                Checked={Motoboy}
              />
              <Toggle
                Titulo={`Taxa`}
                Clique={() => setTaxa(!Taxa)}
                Checked={Taxa}
              />
              <Toggle
                Titulo={`Impressora`}
                Clique={() => setImpressora(!Impressora)}
                Checked={Impressora}
              />
              <Toggle
                Titulo={`Cupom`}
                Clique={() => setCupom(!Cupom)}
                Checked={Cupom}
              />
              <Toggle
                Titulo={`Produto`}
                Clique={() => setProduto(!Produto)}
                Checked={Produto}
              />
              <Toggle
                Titulo={`Forma de Pagamento`}
                Clique={() => setPagamento(!Pagamento)}
                Checked={Pagamento}
              />
              <Toggle
                Titulo={`Lançamento de Pedidos`}
                Clique={() => setLancamento(!Lancamento)}
                Checked={Lancamento}
              />
              <Toggle
                Titulo={`Cancelamento de Pedidos`}
                Clique={() => setCancelamento(!Cancelamento)}
                Checked={Cancelamento}
              />
              <Toggle
                Titulo={`Garçom`}
                Clique={() => setGarcom(!Garcom)}
                Checked={Garcom}
              />
            </ModalGravarDados>
          </>
        ) : null}
      </HeaderComponent>
    </>
  );
}
