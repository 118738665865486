import { TextField } from "@mui/material";
import { FaBookmark, FaCloudUploadAlt } from "react-icons/fa";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import Typography from "@mui/material/Typography";

const containerStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop: "15px",
};

const imageStyle = {
  maxWidth: "350px",
  width: "350px",
  marginBottom: "15px",
};

const buttonStyle = {
  marginTop: "10px",
  padding: "8px 16px",
  backgroundColor: "#007bff",
  color: "#fff",
  border: "none",
  cursor: "pointer",
};

export function MensagemImagemMarketing({
  label,
  Value,
  AtualizaMensagem,
  Set,
  Imagem,
  SetImagem,
  Dia,
}) {
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      SetImagem(reader.result);
      AtualizaMensagem(Dia, Value, reader.result);
    };
  };
  const handleFileChangeClear = (event) => {
    SetImagem("");
    AtualizaMensagem(Dia, Value, "");
  };

  return (
    <>
      <h1 style={{ marginTop: "10px" }}>
        <b>{label}</b>
      </h1>
      <div style={{ display: "flex", gap: "10px" }}>
        <div style={{ width: "380px" }}>
          <div style={containerStyle}>
            <img
              src={
                Imagem
                  ? Imagem
                  : "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJwAAACUCAMAAABRNbASAAAA/1BMVEX///8AAADU1NT//fiOhXfHuaOUiHf07+JCPjSqvMKYqbTOzs7///sQGSpmYFcjIyP5+fn3/P9VVVWzs7OVi4UwLy/c29vR0MzQ09fr5+bv8/b19O1sZlkjIRsWGyCOmaSakYUWFhcKFBmousbVyrp4iJvGuaogHRttWUQVHil6enxZYXEnLDBiZGbDz9cADAxBKhKSpLhqdX8WLTZdV0vj1sZJTk02ODZZc4UfLDu3tKtMUliQkY5mU0W7vsIxGwBYRzQAEyE1KhNCW3I0OUKom5g+QkUuRVdYQSmto5cADCIdAAA8Wmp+dmspAAASBwAAIjsAABJVLgYAITJEMSCyK4h3AAAC1UlEQVR4nO3YXVPaQBQG4D2kCpZgQpSQLCim0KrB0JZqUpRGrYpFRWrb//9benYjI53pteuM73Ohm/XCd/YjnIMQAAAAAAAAAAAAAAAAAK+d29zYCFwhwqomeaKqnpWmejao1d4i2u7U7Z3IiaLoXVf0Isd5r//2YdeJuibD7dF+HFf60n5DBwkbiBX6SKQX7BMRvTWYzSL6zFsaCA43rOupFfpyqDMdzdPMaLgW0Vc9WA43OqYTHmzSeN1oOPGNqJ9LHe4kz/NAhwtP9wciPDsP2mbD+d/5YCV6W5ULFa5mt6kjenRpG145YV1ViEY+h5uMx+NchxNTupZ7fBpNh+Nzx4vWFXzmikcVrkyU85U1vXLqdVve5QxLF6ImxA9yqC8ss+GOhtUwnBINeFuTaqlUkkW4FT5/a6bD9YjSlHjR1IVwHGe7y7lu+M13S6PAdDhx5WVZ2uHBsafxx1ea8DWZpmP+uZmumQwn7DB0jQYAAICXJywpgRpa3CDmIQ/c0iNfTfs86xvJZu84DfVZz5WSv84NImWdumjOHafB07t3nHiWqdm+iXDWatEN1lVtchnHHlcnouol6xFNEm8oWvd0HsfJnC4NdNYcbihd1xV2hXsarohnXNcJ1y1XuJTjYmCHatxSu9O5ibpJhdODnirTGRfnqtXX4fhEnpEqpUTrkPrPX7dwuMQPQ8ntzM/i1N+r7msRrnn7UHwXMaPR8+8rh4uyLLvg7vm6+O+/6G4pHHHzqqzSbyPhnDRNVTh15F5cuESqbb1abOs/Z645fyguwmyxsM8crugGFxfi8ffThdD35ahSXAxD4aw/dBNI2azQtfsUTvVjHen6MzoIDIZTS/Zw4s2ppg/ZYzjROuW+0dsy8x2dPZ2Mi3AinKWNRhoXT+V2WtwEa9NrNCbJwEA2fvkvvVul78v/zC/NAgAAAAAAAAAAAAAAAAAAAAAAAAC8en8BMTpE+tyKElkAAAAASUVORK5CYII="
              }
              alt={Dia}
              style={imageStyle}
            />

            <div style={{ display: "flex", gap: "10px" }}>
              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<FaCloudUploadAlt />}
              >
                Carregar Foto
                <VisuallyHiddenInput type="file" onChange={handleFileChange} />
              </Button>
              {Imagem ? (
                <>
                  {" "}
                  <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<FaBookmark />}
                    onClick={handleFileChangeClear}
                  >
                    Excluir Foto
                    {/* <VisuallyHiddenInput
                type="file"
                onChange={handleFileChangeClear}
              /> */}
                  </Button>
                </>
              ) : null}
            </div>
          </div>
        </div>
        <TextField
          label={"Mensagem"}
          multiline
          fullWidth
          rows={14}
          value={Value}
          onChange={(e) => [
            AtualizaMensagem(Dia, e.target.value, Imagem),
            Set(e.target.value),
          ]}
          sx={{ marginTop: "10px" }}
          variant="outlined"
        />
      </div>
    </>
  );
}
