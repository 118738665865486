import { useEffect, useMemo, useState } from "react";
import { ComponentCadastro } from "../../component/header/cadastro";
import { ShowModal } from "../../component/modalPai";
import { InputPedido } from "../pedido/componente/input";
import { Button } from "../../component/button";
import { FaRegClock, FaRegTrashAlt, FaTrash } from "react-icons/fa";
import { API, GooPedirAPI } from "../../controller/api/api";
import { HeaderComponent } from "../../component/header";
import {
  formatarDataHora,
  formatarHora,
  formatarHoraMin,
} from "../../controller/data";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
} from "@mui/material";
import { ComponentPausa } from "./pausa";
import LoadingOverlay from "../../component/load";
import { Notifica, PrimeiraMaiuscula } from "../../controller/util";
import Table from "../../component/tabela";

const Card = ({ Descricao, Value, Set }) => (
  <div>
    <div
      style={{
        display: "flex",
        height: "48px",
        width: "48px",
        borderRadius: "50px",
        border: Value ? "1px solid #A8001C" : "1px solid rgba(0,0,0,0.45)",
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
      }}
      onClick={() => Set(!Value)}
    >
      <span>
        <b>{Descricao}</b>
      </span>
    </div>
  </div>
);

let DadosMemorizados = [];

export function Pagehorario() {
  const [ModalHorario, setModalHorario] = useState(false);
  const [Segunda, setSegunda] = useState(false);
  const [Terca, setTerca] = useState(false);
  const [Quarta, setQuarta] = useState(false);
  const [Quinta, setQuinta] = useState(false);
  const [Sexta, setSexta] = useState(false);
  const [Sabado, setSabado] = useState(false);
  const [Domingo, setDomingo] = useState(false);
  const [Abertura, setAbertura] = useState("00:00");
  const [Fechamento, setFechamento] = useState("00:00");
  const [Horarios, setHorario] = useState(null);

  const [ModalPausaProgramada, setModalPausaProgramada] = useState(false);
  const [UserID, setUserID] = useState(0);
  const [loading, setLoading] = useState(false);

  const [DadosPausas, setDadosPausas] = useState([]);

  function NovoHorario() {
    setModalHorario(true);
  }

  function NovaPausa() {
    setModalPausaProgramada(true);
  }

  function SalvarHorario() {
    let body = {
      segunda: Segunda,
      terca: Terca,
      quarta: Quarta,
      quinta: Quinta,
      sexta: Sexta,
      sabado: Sabado,
      domingo: Domingo,
      abertura: Abertura,
      fechamento: Fechamento,
    };

    API.post("/v2/cadastro/horario", body).then(function (response) {
      fetchData();
      setModalHorario(false);
    });
  }

  function DadosDiaSemana(dia) {
    for (var i = 0; i < Horarios.length; i++) {
      if (Horarios[i].diaDaSema == dia) {
        return Horarios[i];
      }
    }
  }

  function horarioDe(dia) {
    const Dados = DadosDiaSemana(dia);
    if (Dados == undefined) {
      return "Fechado";
    }
    return (
      formatarHoraMin(Dados.abertura) +
      " até " +
      formatarHoraMin(Dados.fechamento)
    );
  }

  function TempoEmHora(dia) {
    const Dados = DadosDiaSemana(dia);
    if (Dados == undefined) {
      return 0;
    }
    // Convertendo as strings de horário para objetos Date
    const horaAbertura = new Date(`2000-01-01T${Dados?.abertura}`);
    const horaFechamento = new Date(`2000-01-01T${Dados?.fechamento}`);

    // Calculando a diferença em milissegundos
    let diferencaMilissegundos = horaFechamento - horaAbertura;

    // Caso a diferença seja negativa (fechamento antes da abertura), somamos 24 horas
    if (diferencaMilissegundos < 0) {
      diferencaMilissegundos += 24 * 60 * 60 * 1000; // Adicionando 24 horas em milissegundos
    }

    // Convertendo a diferença de milissegundos para horas
    let diferencaHoras = diferencaMilissegundos / (1000 * 60 * 60);

    // Arredondando para a próxima hora inteira
    diferencaHoras = Math.ceil(diferencaHoras);

    //console.log(formatarHora(Dados?.fechamento));

    // Se a diferença for 0 (00:00 até 23:59), retorne 24 horas
    if (formatarHora(Dados?.fechamento) === "23:59:00") {
      diferencaHoras = 24;
    }

    return diferencaHoras;
  }

  useEffect(() => {
    API.get("/v2/status").then((response) => {
      setUserID(response.data.user);
    });
  }, []);

  useEffect(() => {
    if (UserID > 0) {
      fetchData().then((response) => {
        BuscaPausa();
      });
    }
  }, [UserID]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await API.get("/v1/consulta/generica/horario/*/*/*");
      setHorario(response.data);
    } catch (error) {}
  };

  function Deletar(dia) {
    API.post(`/v2/deleta/horario/${dia}`).then(function (response) {
      fetchData();
    });
  }

  function BuscaPausa() {
    GooPedirAPI.get(
      `ws/v1/generica2.php?tabela=ws_pausa&where=user_id = ${UserID} and datahora > DATE_SUB(now(), INTERVAL 3 HOUR)`
    ).then(function (response) {
      setDadosPausas(response.data ? response.data : []);
      DadosMemorizados = response.data ? response.data : [];
      //console.log(response.data);
      setLoading(false);
    });
  }

  const columns = useMemo(
    () => [
      {
        Header: "Data/Hora Termino Pausa",
        accessor: "datahora",
        size: "80%",
        format: formatarDataHora,
        textAlign: "",
      },
      {
        Header: "Motivo",
        accessor: "motivo",
        size: "50px",
        // format: PintaValores,
        // format: MascaraInteiro,
        formatOption: PrimeiraMaiuscula,
        textAlign: "center",
      },
      // {
      //   Header: "Conversões",
      //   accessor: "enviadas1",
      //   size: "100px",
      //   // format: PintaValores,
      //   format: MascaraInteiro,
      //   textAlign: "",
      // },

      //   {
      //     Header: "Notificação WPP",
      //     accessor: "naoreceber",
      //     size: "100px",
      //     // format: PintaValores,
      //     format: RecebeNotificacao,
      //     textAlign: "center",
      //   },

      {
        Header: "Ações",
        size: "50px",
        component: (
          <IconButton color="primary">
            <FaTrash />
          </IconButton>
        ),

        func: Excluir,
      },
    ],
    []
  );

  function Excluir(dados) {
    GooPedirAPI.delete(`ws/horario/delete.php?codigo=${dados}`).then(function (
      response
    ) {
      BuscaPausa();
      Notifica("Pausa Exluida Com Sucesso");
    });
  }

  return (
    <>
      {loading && <LoadingOverlay />}{" "}
      <HeaderComponent>
        <ComponentCadastro
          Titulo="Horário de funcionamento"
          Funcao={NovoHorario}
        />
        <div style={{ marginTop: "15px", marginBottom: "15px" }}>
          {Horarios ? (
            <>
              <div
                style={{
                  width: "80%",
                  justifyContent: "center",
                  marginLeft: "10%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "1.2rem",
                  }}
                >
                  <div style={{ width: "14.2%" }}>
                    <p style={{ flex: "1" }}>
                      <b>Segunda</b>
                    </p>
                    <p style={{ flex: "1" }}>
                      {TempoEmHora("seg") == 0 ? (
                        <br />
                      ) : (
                        `Aberta por ${TempoEmHora("seg")}h`
                      )}
                    </p>
                    <p style={{ flex: "1" }}>{horarioDe("seg")}</p>
                    <FaRegTrashAlt
                      style={{ cursor: "pointer" }}
                      onClick={() => Deletar("seg")}
                    />
                  </div>
                  <div style={{ width: "14.2%" }}>
                    <p style={{ flex: "1" }}>
                      <b>Terça</b>
                    </p>
                    <p style={{ flex: "1" }}>
                      {TempoEmHora("ter") == 0 ? (
                        <br />
                      ) : (
                        `Aberta por ${TempoEmHora("ter")}h`
                      )}
                    </p>
                    <p style={{ flex: "1" }}>{horarioDe("ter")}</p>
                    <FaRegTrashAlt
                      style={{ cursor: "pointer" }}
                      onClick={() => Deletar("ter")}
                    />
                  </div>

                  <div style={{ width: "14.2%" }}>
                    <p style={{ flex: "1" }}>
                      <b>Quarta</b>
                    </p>
                    <p style={{ flex: "1" }}>
                      {TempoEmHora("qua") == 0 ? (
                        <br />
                      ) : (
                        `Aberta por ${TempoEmHora("qua")}h`
                      )}
                    </p>
                    <p style={{ flex: "1" }}>{horarioDe("qua")}</p>
                    <FaRegTrashAlt
                      style={{ cursor: "pointer" }}
                      onClick={() => Deletar("qua")}
                    />
                  </div>

                  <div style={{ width: "14.2%" }}>
                    <p style={{ flex: "1" }}>
                      <b>Quinta</b>
                    </p>
                    <p style={{ flex: "1" }}>
                      {TempoEmHora("qui") == 0 ? (
                        <br />
                      ) : (
                        `Aberta por ${TempoEmHora("qui")}h`
                      )}
                    </p>
                    <p style={{ flex: "1" }}>{horarioDe("qui")}</p>
                    <FaRegTrashAlt
                      style={{ cursor: "pointer" }}
                      onClick={() => Deletar("qui")}
                    />
                  </div>

                  <div style={{ width: "14.2%" }}>
                    <p style={{ flex: "1" }}>
                      <b>Sexta</b>
                    </p>
                    <p style={{ flex: "1" }}>
                      {TempoEmHora("sex") == 0 ? (
                        <br />
                      ) : (
                        `Aberta por ${TempoEmHora("sex")}h`
                      )}
                    </p>
                    <p style={{ flex: "1" }}>{horarioDe("sex")}</p>
                    <FaRegTrashAlt
                      style={{ cursor: "pointer" }}
                      onClick={() => Deletar("sex")}
                    />
                  </div>

                  <div style={{ width: "14.2%" }}>
                    <p style={{ flex: "1" }}>
                      <b>Sábado</b>
                    </p>
                    <p style={{ flex: "1" }}>
                      {TempoEmHora("sab") == 0 ? (
                        <br />
                      ) : (
                        `Aberta por ${TempoEmHora("sab")}h`
                      )}
                    </p>
                    <p style={{ flex: "1" }}>{horarioDe("sab")}</p>
                    <FaRegTrashAlt
                      style={{ cursor: "pointer" }}
                      onClick={() => Deletar("sab")}
                    />
                  </div>

                  <div style={{ width: "14.2%" }}>
                    <p style={{ flex: "1" }}>
                      <b>Domingo</b>
                    </p>
                    <p style={{ flex: "1" }}>
                      {TempoEmHora("dom") == 0 ? (
                        <br />
                      ) : (
                        `Aberta por ${TempoEmHora("dom")}h`
                      )}
                    </p>
                    <p style={{ flex: "1" }}>{horarioDe("dom")}</p>
                    <FaRegTrashAlt
                      style={{ cursor: "pointer" }}
                      onClick={() => Deletar("dom")}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>

        <ComponentCadastro
          Titulo="Adicionar Pausa Programada"
          Funcao={NovaPausa}
        />

        <Table
          columns={columns}
          data={DadosPausas}
          codigo={"id"}
          //   func={AlterarDadosCliente}
        />

        {ModalPausaProgramada ? (
          <ComponentPausa Close={() => setModalPausaProgramada(false)} />
        ) : null}

        {ModalHorario ? (
          <>
            <ShowModal closeButton={() => setModalHorario(false)}>
              <h1>
                Escolha os <b>dias da semana</b>:
              </h1>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  marginBottom: "20px",
                  marginTop: "20px",
                }}
              >
                <Card Descricao={"Seg"} Value={Segunda} Set={setSegunda} />
                <Card Descricao={"Ter"} Value={Terca} Set={setTerca} />
                <Card Descricao={"Qua"} Value={Quarta} Set={setQuarta} />
                <Card Descricao={"Qui"} Value={Quinta} Set={setQuinta} />
                <Card Descricao={"Sex"} Value={Sexta} Set={setSexta} />
                <Card Descricao={"Sáb"} Value={Sabado} Set={setSabado} />
                <Card Descricao={"Dom"} Value={Domingo} Set={setDomingo} />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <h2>
                  Selecione o horário em que a loja <b>ficará aberta</b>:
                </h2>
                <InputPedido
                  Descricao="Abertura"
                  Tipo="time"
                  Valor={Abertura}
                  Set={setAbertura}
                />
                <InputPedido
                  Descricao="Fechamento"
                  Tipo="time"
                  Valor={Fechamento}
                  Set={setFechamento}
                />
                <Button
                  descricao="Gravar Horário"
                  evento={SalvarHorario}
                  borderColor="#A8001C"
                  fontColor="#A8001C"
                  BorderRadius={"5px"}
                  icon={<FaRegClock />}
                />
              </div>
            </ShowModal>
          </>
        ) : null}
      </HeaderComponent>
    </>
  );
}
