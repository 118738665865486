import { useEffect, useState } from "react";
import { API, GooPedirAPI } from "../../controller/api/api";
import { ShowModal } from "../../component/modalPai";
import { Center } from "@chakra-ui/react";
import { ApagaLocal, BuscaLocal, GravaLocal } from "../../controller/storage";
import { isMobileDevice } from "../../controller/mobile";
import { MascaraMonetaria, RemoveMascara } from "../../controller/data";

export function PageBloqueado({ children }) {
  const [Data, setData] = useState("");
  const [Dias, setDias] = useState(-1);
  const [Faturas, setFaturas] = useState([]);
  const [MostraNotificacao, setMostraNotificacao] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState("rgb(168, 0, 28)");
  const [MostraButtonFechar, setMostraButtonFechar] = useState(true);
  const handleMouseOver = () => {
    setBackgroundColor("rgba(168, 0, 28, 0.9)");
  };

  const handleMouseOut = () => {
    setBackgroundColor("rgb(168, 0, 28)");
  };

  useEffect(() => {
    let DataUltima = BuscaLocal("data-notificacao");
    let Data = new Date();
    let DataAtual = formatDateToYMD(Data);

    if (DataUltima != DataAtual) {
      fetchData();
    }
  }, []);

  const fetchData = async () => {
    const CACHE_KEY = "bloqueio_data"; // Chave para armazenar os dados no localStorage
    const CACHE_EXPIRATION_TIME = 60 * 60 * 1000; // Tempo de expiração do cache em milissegundos (5 minutos)
    // Verifica se os dados estão armazenados no cache e se ainda estão dentro do tempo de validade
    const cachedData = JSON.parse(localStorage.getItem(CACHE_KEY));
    //console.log(cachedData);
    if (
      cachedData &&
      Date.now() - cachedData.timestamp < CACHE_EXPIRATION_TIME
    ) {
      setData(cachedData.data.vencimento);
      setDias(cachedData.data.dias);
      //console.log(cachedData.data);
      if (parseInt(cachedData.data.dias) <= 7 && isMobileDevice) {
        setMostraNotificacao(true);
      }
      if (parseInt(cachedData.data.dias) < 1) {
        setMostraButtonFechar(false);
      }
      // return;
    }

    API.get(`v2/dados/bloqueio`).then((response) => {
      const data = response.data;
      if (
        data !==
        "REST request failed: Error sending data: (12002) O tempo limite da operação foi atingido"
      ) {
        if (data.faturas != "null") {
          setFaturas(data?.faturas ? JSON.parse(data.faturas) : []);
        }

        setData(data.vencimento);
        if (parseInt(data.dias) <= 7 && isMobileDevice) {
          setMostraNotificacao(true);
        }
        if (parseInt(data.dias) < 1) {
          setMostraButtonFechar(false);
        }
        setDias(data.dias);

        const newCachedData = { data, timestamp: Date.now() };
        localStorage.setItem(CACHE_KEY, JSON.stringify(newCachedData));
      }
    });
  };

  function formatDateToYMD(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  function CloseNotificacao() {
    let Data = new Date();
    GravaLocal("data-notificacao", formatDateToYMD(Data));
    setMostraNotificacao(!MostraNotificacao);
  }

  function AtualizarNotificacao() {
    ApagaLocal("bloqueio_data");
    ApagaLocal("data-notificacao");
    fetchData();
  }

  return (
    <>
      <div>
        {MostraNotificacao ? (
          <ShowModal altura={40} largura={0}>
            <div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  marginBottom: "20px",
                }}
              >
                <img
                  src="/goopedir-logo.png"
                  style={{ width: "100px", borderRadius: "100px" }}
                />
              </div>
              <h1
                style={{
                  fontSize: "1.2rem",
                  textAlign: "center",
                  marginBottom: "50px",
                }}
              >
                <b>Aviso de Bloqueio</b>
              </h1>

              {MostraButtonFechar ? (
                <>
                  <h4>
                    Seu sistema vence em <b>{Dias}</b> dias, após o vencimento
                    site/sistema ficarão inacessivel.
                  </h4>

                  {Faturas.map((faturas, index) => (
                    <a href={faturas.link} target="_blank">
                      <b>
                        Vencimento {faturas.vencimento}
                        {" Total "}
                        {MascaraMonetaria(RemoveMascara(faturas.total))}
                      </b>
                    </a>
                  ))}

                  <div
                    style={{
                      display: "flex",
                      backgroundColor: backgroundColor,
                      width: "100%",
                      borderRadius: "10px",
                      color: "white",
                      textAlign: "center",
                      height: "36px",
                      alignItems: "center",
                      marginTop: "10px",
                      cursor: "pointer",
                    }}
                    onMouseOver={handleMouseOver}
                    onMouseOut={handleMouseOut}
                    onClick={CloseNotificacao}
                  >
                    <h1 style={{ width: "100%" }}>
                      <b>Fechar</b>
                    </h1>
                  </div>
                </>
              ) : (
                <>
                  <h4>Sistema bloqueado!</h4>
                  <div
                    style={{
                      display: "flex",
                      backgroundColor: backgroundColor,
                      width: "100%",
                      borderRadius: "10px",
                      color: "white",
                      textAlign: "center",
                      height: "36px",
                      alignItems: "center",
                      marginTop: "10px",
                      cursor: "pointer",
                    }}
                    onMouseOver={handleMouseOver}
                    onMouseOut={handleMouseOut}
                    onClick={AtualizarNotificacao}
                  >
                    <h1 style={{ width: "100%" }}>
                      <b>Atualizar</b>
                    </h1>
                  </div>
                </>
              )}
            </div>
          </ShowModal>
        ) : null}

        {children}
      </div>
    </>
  );
}
