import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { BuscaLocal } from "../../../controller/storage";
import { FaEllipsisV, FaPlus } from "react-icons/fa";
import { IconButton } from "@mui/material";

export function AcoesMarketin({ Duplicar }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDuplicar = () => {
    let dados = JSON.parse(BuscaLocal("marketin_selecionado"));

    Duplicar(dados);
    setAnchorEl(null);
  };

  const handleExcluir = () => {
    // Duplicar();
    setAnchorEl(null);
  };

  return (
    <div
      style={{
        display: "flex",

        justifyContent: "center",
        width: "100%",
        height: "100%",
      }}
    >
      {/* <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        Dashboard
      </Button> */}
      <IconButton
        color="primary"
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <FaEllipsisV />
      </IconButton>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleDuplicar}>Duplicar</MenuItem>
        <MenuItem onClick={handleExcluir}>Excluir</MenuItem>
        {/* <MenuItem onClick={handleClose}>Logout</MenuItem> */}
      </Menu>
    </div>
  );
}
