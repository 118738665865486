import { useEffect, useState } from "react";
import {
  CardSangria,
  CardTotalSangria,
} from "../../relatorio/component/sangria";
import { API } from "../../../../controller/api/api";
import { PrimeiraMaiuscula } from "../../../../controller/util";

export function SangriaCaixa({ id, estorno, impressao, atualiza }) {
  const StyleTituloValue = {
    fontSize: "1.1rem",
  };

  function removerPrefixoSangria(descricao) {
    const prefixo = "SANGRIA - ";
    // Verifica se a descrição começa com o prefixo "SANGRIA - "
    if (descricao.startsWith(prefixo)) {
      // Se sim, remove o prefixo e retorna o restante da string
      return descricao.substring(prefixo.length);
    } else {
      // Se não, retorna a string original
      return descricao;
    }
  }

  const [Dados, setDados] = useState([]);
  const [Total, setTotal] = useState(0);

  useEffect(() => {
    API.get(`/v2/sangria/caixa/${id}`).then((response) => {
      setDados(response.data);

      var tot = 0;
      for (let i = 0; i < response.data.length; i++) {
        tot += response.data[i].valor;
      }
      setTotal(tot);
    });
  }, []);

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "100%",
          //   border: "1px solid #000000",
          display: "flex",
          flexDirection: "column", // Para organizar os itens em coluna
          padding: "5px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px",
          }}
        >
          <h1 style={StyleTituloValue}>
            <b>Sangria</b>
          </h1>
        </div>

        <div style={{ padding: "10px", overflowY: "scroll", flex: "1" }}>
          {Dados.map((item, index) => (
            <CardSangria
              Titulo={PrimeiraMaiuscula(removerPrefixoSangria(item.descricao))}
              Valor={item.valor}
              estorno={estorno}
              impressao={impressao}
              id={item.id}
              atualiza={atualiza}
            />
          ))}

          {/* Repetir esta estrutura conforme necessário para mais itens */}
        </div>

        <CardTotalSangria Total={Total} />
      </div>
    </>
  );
}
