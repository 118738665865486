export function StatusComponent({ status, Ativar, Pausar }) {
  let DescPausar = "Pausar";
  let DescAtivar = "Ativado";
  let CorPausar = "#f7f7f7";
  let CorAtivar = "#f7f7f7";
  let CorTituloPausar = "#000000";
  let CorTituloAtivar = "#000000";

  if (status == 1) {
    DescAtivar = "Ativo";
    CorPausar = "#f7f7f7";
    CorTituloPausar = "#000000";
    CorAtivar = "#a4001b";
    CorTituloAtivar = "#ffffff";
  } else {
    DescPausar = "Pausado";
    CorPausar = "#a4001b";
    CorTituloPausar = "#ffffff";
    CorAtivar = "#f7f7f7";
    CorTituloAtivar = "#000000";
  }

  return (
    <>
      <div style={{ display: "flex", fontSize: "0.8rem", cursor: "pointer" }}>
        <div
          style={{
            backgroundColor: CorPausar,
            color: CorTituloPausar,
            borderRadius: "5px 0 0 5px",
            width: "65px",
            textAlign: "center",
          }}
          onClick={() => Pausar()}
        >
          {DescPausar}
        </div>
        <div
          style={{
            backgroundColor: CorAtivar,
            color: CorTituloAtivar,
            borderRadius: "0 5px 5px 0",
            width: "65px",
            textAlign: "center",
          }}
          onClick={() => Ativar()}
        >
          {DescAtivar}
        </div>
      </div>
    </>
  );
}
