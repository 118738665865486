// import { PrimeiraMaiuscula } from "../../controller/util";

// export function Selected({ data, descricao, value, selected, funcaoSelected, ativo }) {
//   if (ativo == undefined) {
//     ativo = 0
//   }

//   let enabled = ativo == 1;

//   return (<>

//     <select style={{ height: '34px', width: '100%', border: '1px solid', marginBottom: '10px' }} enabled={enabled}
//       onChange={(e) => funcaoSelected(e.target.value)}
//     >

//       {data?.map((item, index) => (
//         <option key={index} value={item[value]} selected={selected == item[value] ? true : false}>
//           {(PrimeiraMaiuscula(item[descricao]))}
//         </option>
//       ))}
//     </select>

//   </>)
// }

import React from "react";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { PrimeiraMaiuscula } from "../../controller/util";

export function Selected({
  data,
  descricao,
  value,
  selected,
  funcaoSelected,
  ativo,
}) {
  if (ativo === undefined) {
    ativo = 0;
  }

  const enabled = ativo === 1;

  return (
    <FormControl
      fullWidth
      variant="outlined"
      margin="normal"
      // disabled={!enabled}
    >
      <InputLabel id="select-label">Selecione uma opção</InputLabel>
      <Select
        labelId="select-label"
        value={selected}
        onChange={(e) => funcaoSelected(e.target.value)}
        label="Selecione uma opção"
        style={{ height: "34px", width: "100%", marginBottom: "10px" }}
      >
        {data?.map((item, index) => (
          <MenuItem key={index} value={item[value]}>
            {PrimeiraMaiuscula(item[descricao])}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
