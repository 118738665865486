import { useEffect, useState } from "react";
import InputMask from "react-input-mask";

import { TextField } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material";

export function InputPedido(props) {
  const [ValorInput, setValorInput] = useState(props.Valor);
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    setValorInput(props.Valor);
  }, [props.Valor]);

  let tipo = "text";
  let mask = "";

  if (props.Tipo === "cnpj") {
    mask = "99.999.999/9999-99";
  } else if (props.Tipo === "celular") {
    mask = "(99) 99999-9999";
  } else if (props.Tipo === "cpf") {
    mask = "999.999.999-99";
  } else if (props.Tipo === "data") {
    mask = "99/99/9999";
  } else if (props.Tipo === "date") {
    tipo = "date";
  } else if (props.Tipo === "password") {
    tipo = "password";
  }

  const ChangeInput = (valor) => {
    if (props.Valor === valor) {
      return;
    }

    if (typeof props.Set === "function") {
      props.Set(valor);
    }
    if (typeof props.Retorno === "function") {
      setValorInput(valor);

      if (props.index !== undefined) {
        props.Retorno(props.index, valor);
      } else {
        props.Retorno(props.Descricao, valor);
      }
    }
  };

  function OnChange(valor) {
    setValorInput(valor);

    if (props.Change) {
      ChangeInput(valor);
    }
  }

  function OnBlur() {
    //console.log("Saiu do componente");
    ChangeInput(ValorInput);
  }

  function handleKeyDown(event) {
    if (event.key === "Enter") {
      // Impede o comportamento padrão de enviar o formulário
      event.preventDefault();

      // Verifique se o form existe antes de prosseguir
      const form = event.target.form;
      if (form) {
        const index = Array.prototype.indexOf.call(form, event.target);
        // Move o foco para o próximo elemento, se ele existir
        if (form.elements[index + 1]) {
          form.elements[index + 1].focus();
        }
      }
    }
  }

  return (
    <>
      {mask ? (
        <InputMask
          mask={mask}
          value={ValorInput}
          onChange={(e) => OnChange(e.target.value)}
          onBlur={OnBlur}
          onKeyDown={handleKeyDown}
          name={props.name ? props.name : null}
          disabled={props.disabled ? props.disabled : false}
        >
          {(inputProps) => (
            <TextField
              size="small"
              {...inputProps}
              fullWidth
              label={props.Descricao}
              disabled={props.disabled ? props.disabled : false}
            />
          )}
        </InputMask>
      ) : (
        <TextField
          size="small"
          type={tipo}
          fullWidth
          label={props.Descricao}
          value={ValorInput}
          onChange={(e) => OnChange(e.target.value)}
          onBlur={OnBlur}
          onKeyDown={handleKeyDown}
          sx={props.sx ? props.sx : null}
          name={props.name ? props.name : null}
          disabled={props.disabled ? props.disabled : false}
        />
      )}
    </>
  );
}
