import React, { useState } from "react";
import Cropper from "react-easy-crop";
import ImgDialog from "./ImgDialog";
import getCroppedImg from "./cropImage";
import { Button, Slider } from "@mui/material";
import "./styles.css";

const minZoom = 0.4;

export function AppCortar({ imagem, NovaImage }) {
  const [imageSrc, setImageSrc] = useState(imagem);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(minZoom);
  const [aspect, setAspect] = useState(1); // Aspect ratio for square crop
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  const onCropChange = (crop) => setCrop(crop);

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    //console.log(croppedArea, croppedAreaPixels);
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const onZoomChange = (zoom) => setZoom(zoom);

  const showCroppedImage = async () => {
    const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
    // //console.log(croppedImage);
    setCroppedImage(croppedImage);
    NovaImage(croppedImage);
  };

  const handleClose = () => setCroppedImage(null);

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        zIndex: "999999",
      }}
    >
      <div
        style={{
          height: "500px",
          width: "500px",
          position: "relative",
          margin: "0 auto",
          backgroundColor: "black",
        }}
      >
        <Cropper
          minZoom={minZoom}
          image={imageSrc}
          crop={crop}
          zoom={zoom}
          aspect={aspect}
          restrictPosition={false}
          onCropChange={onCropChange}
          onCropComplete={onCropComplete}
          onZoomChange={onZoomChange}
          style={{
            containerStyle: { height: "100%", width: "100%" },
            cropStyle: { objectFit: "contain" },
          }}
        />
      </div>
      <div style={{ padding: 16, backgroundColor: "#fff" }}>
        <Slider
          value={zoom}
          min={minZoom}
          max={3}
          step={0.1}
          aria-labelledby="Zoom"
          onChange={(e, zoom) => onZoomChange(zoom)}
          style={{ marginBottom: 16 }}
        />
        <Button
          onClick={showCroppedImage}
          variant="contained"
          color="primary"
          style={{ width: "100%" }}
        >
          Salvar Imagem
        </Button>
      </div>
      {/* <ImgDialog img={croppedImage} onClose={handleClose} /> */}
    </div>
  );
}
