import { IconButton } from "@mui/material";
import { HeaderComponent } from "../../component/header";
import LoadingOverlay from "../../component/load";
import { useEffect, useMemo, useState } from "react";
import {
  formatarCPForCNPJ,
  MascaraCelular,
  Notifica,
  PrimeiraMaiuscula,
} from "../../controller/util";
import { FaCheck, FaGift } from "react-icons/fa";
import {
  MascaraInteiro,
  MascaraMonetaria,
  MascaraNumerica,
} from "../../controller/data";
import { API, GooPedirAPI } from "../../controller/api/api";
import Table from "../../component/tabela";

export function PaginaPIX() {
  const [Dados, setDados] = useState([]);
  const [loading, setLoading] = useState(false);

  const columns = useMemo(
    () => [
      {
        Header: "Código",
        accessor: "id",
        size: "100px",
        format: MascaraInteiro,
        textAlign: "center",
      },
      {
        Header: "Celular",
        accessor: "telefone",
        size: "100px",
        // format: PintaValores,
        format: MascaraCelular,
        textAlign: "center",
      },
      {
        Header: "Nome",
        accessor: "nome",
        size: "100px",
        // format: PintaValores,
        format: PrimeiraMaiuscula,
        textAlign: "center",
      },
      {
        Header: "Pagamento",
        accessor: "forma_pagamento",
        size: "60%",
        format: PrimeiraMaiuscula,
      },
      {
        Header: "Total",
        accessor: "total",
        size: "100px",
        // format: PintaValores,
        format: MascaraPrivada,
        textAlign: "center",
      },
      {
        Header: "Taxa",
        accessor: "vl_taxa",
        size: "100px",
        // format: PintaValores,
        format: MascaraPrivada,
        textAlign: "center",
      },
      //   {
      //     Header: "Notificação WPP",
      //     accessor: "naoreceber",
      //     size: "100px",
      //     // format: PintaValores,
      //     format: RecebeNotificacao,
      //     textAlign: "center",
      //   },

      {
        Header: "Liberar",
        size: "50px",
        component: (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IconButton color="primary">
              <FaCheck />
            </IconButton>
          </div>
        ),

        func: AlterarDados,
      },
    ],
    []
  );

  function MascaraPrivada(valor) {
    return MascaraMonetaria(valor);
  }

  function AlterarDados(dados) {
    GooPedirAPI.get(`ws/v1/libera-pix.php?codigo=${dados}`).then(function (
      response
    ) {
      Notifica("Liberação feita com sucesso!");
      Buscar();
    });
    // //console.log(dados);
    // const objetoEncontrado = DadosEstatico.find(
    //   (objeto) => objeto.id === dados
    // );

    // setDadosSelecionados(objetoEncontrado);
  }

  function Buscar() {
    setLoading(true);
    API.get("v2/pix/pendente/tabela").then(function (response) {
      //console.log(response.data == null ? [] : response.data);
      setDados(response.data == null ? [] : response.data);

      setLoading(false);
    });
  }

  useEffect(() => {
    Buscar();
  }, []);

  return (
    <>
      <div>
        <HeaderComponent>
          {loading && <LoadingOverlay />}{" "}
          <Table
            columns={columns}
            data={Dados}
            codigo={"id"}
            //   func={AlterarDadosCliente}
          />
        </HeaderComponent>
      </div>
    </>
  );
}
