export function CardMarketin({ children, nome }) {
  return (
    <>
      <div
        style={{
          border: "1px solid #e0e0e0",
          borderRadius: "8px",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          marginBottom: "10px",
          padding: "10px",
          backgroundColor: "#ffffff",
          transition: "box-shadow 0.3s ease-in-out",
          width: "500px",
        }}
      >
        <h2>
          <b>{nome}</b>
        </h2>
        <div
          style={{
            display: "flex",
            gap: "10px",
            flexDirection: "column",
            marginTop: "10px",
          }}
        >
          {children}
        </div>
      </div>
    </>
  );
}
