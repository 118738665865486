import { Button } from "@mui/material";

export function NewButton({ Descricao, Color, Funcao, Icone, sx, disabled }) {
  let style = sx === undefined ? { width: "100%" } : sx;

  return (
    <>
      <Button
        variant="outlined"
        startIcon={Icone}
        onClick={Funcao}
        // color={Color}
        sx={style}
        disabled={disabled ? disabled : false}
      >
        {Descricao}
      </Button>
    </>
  );
}
