import React, { useMemo, useState } from "react";
import { useTable, usePagination, useGlobalFilter } from "react-table";
import { PrimeiraMaiuscula } from "../../controller/util";
import {
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaAngleLeft,
  FaAngleRight,
  FaEllipsisV,
} from "react-icons/fa";
import { IconButton, TextField } from "@mui/material";

function Table({ columns, data, codigo, func, ocultarPesquisa }) {
  const [hoverIcon, setHoverIcon] = useState(null);

  const iconStyle = (iconName) => ({
    cursor: "pointer",
    color: hoverIcon === iconName ? "#ffffff" : "inherit",
    backgroundColor: "rgb(168, 0, 28)",
    fontSize: "30px",
    padding: "5px",
    borderRadius: "50px",
    transform: hoverIcon === iconName ? "scale(1.2)" : "scale(1)",
    transition: "transform 0.3s ease",
  });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    usePagination
  );

  const [filterInput, setFilterInput] = useState("");

  const handleFilterChange = (e) => {
    const value = e.target.value || undefined;
    setGlobalFilter(value);
    setFilterInput(value);
  };

  return (
    <>
      {ocultarPesquisa == true ? null : (
        <>
          <TextField
            id="outlined-basic"
            label="Pesquisar"
            size="small"
            sx={{ width: "100%", marginBottom: "10px" }}
            value={filterInput}
            onChange={handleFilterChange}
          />
        </>
      )}

      <table {...getTableProps()} style={{ width: "100%" }}>
        <thead
          style={{
            border: "1px solid #A8001C",
            backgroundColor: "#A8001C",
            color: "white",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px 8px 0 0",
            overflow: "hidden",
          }}
        >
          {headerGroups.map((headerGroup, index) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              style={{ textAlign: "left" }}
              key={"tr" + index}
            >
              {headerGroup.headers.map((column, index) => (
                <th
                  {...column.getHeaderProps()}
                  style={{
                    width: column.render("size"),
                    padding: "10px 15px",
                    borderBottom: "2px solid #ffffff33",
                    fontFamily: "Arial, sans-serif",
                    fontWeight: "bold",
                  }}
                  key={"th" + index}
                >
                  {column.render("Header")}
                </th>
              ))}
              {/* <th>Editar</th> */}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <tr
                key={"tr2" + index}
                style={{
                  //   borderBottom: "1px solid black",
                  //   borderLeft: "1px solid black",
                  //   borderRight: "1px solid black",
                  height: "50px",
                }}
              >
                {row.cells.map((cell, index) => (
                  <td
                    key={"td2" + index}
                    {...cell.getCellProps()}
                    style={{
                      width: cell.column.size,
                      padding: "5px",
                      textAlign: cell.column.textAlign
                        ? cell.column.textAlign
                        : "",
                    }}
                  >
                    {cell.column.format ? cell.column.format(cell.value) : null}

                    {cell.column.formatOption
                      ? cell.column.formatOption(cell.row.original, data)
                      : null}

                    {cell.column.component ? (
                      <div
                        onClick={() => cell.column.func(row.original[codigo])}
                      >
                        {cell.column.component}
                      </div>
                    ) : null}
                  </td>
                ))}
                {/* <td style={{ textAlign: "center" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <FaEllipsisV
                      size={24}
                      style={{ cursor: "pointer" }}
                      onClick={() => func(row.original[codigo])}
                    />
                  </div>
                </td> */}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "5px",
            padding: "10px",
          }}
        >
          <IconButton color="primary" onClick={() => gotoPage(0)}>
            <FaAngleDoubleLeft />
          </IconButton>
          {/* 
          <FaAngleDoubleLeft
            style={iconStyle("doubleLeft")}
            onMouseEnter={() => setHoverIcon("doubleLeft")}
            onMouseLeave={() => setHoverIcon(null)}
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          /> */}

          <IconButton color="primary" onClick={() => previousPage()}>
            <FaAngleLeft />
          </IconButton>

          {/* <FaAngleLeft
            style={iconStyle("left")}
            onMouseEnter={() => setHoverIcon("left")}
            onMouseLeave={() => setHoverIcon(null)}
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          /> */}

          <span style={{ marginLeft: "5px", marginRight: "5px" }}>
            Página{" "}
            <strong>
              {pageIndex + 1} de {pageOptions.length}
            </strong>{" "}
          </span>

          <IconButton color="primary" onClick={() => nextPage()}>
            <FaAngleRight />
          </IconButton>

          {/* <FaAngleRight
            style={iconStyle("right")}
            onMouseEnter={() => setHoverIcon("right")}
            onMouseLeave={() => setHoverIcon(null)}
            onClick={() => nextPage()}
            disabled={!canNextPage}
          /> */}

          <IconButton
            color="primary"
            onClick={() => gotoPage(pageOptions.length - 1)}
          >
            <FaAngleDoubleRight />
          </IconButton>

          {/* <FaAngleDoubleRight
            style={iconStyle("doubleRight")}
            onMouseEnter={() => setHoverIcon("doubleRight")}
            onMouseLeave={() => setHoverIcon(null)}
            onClick={() => gotoPage(pageOptions.length - 1)}
            disabled={!canNextPage}
          /> */}
        </div>
      </div>
    </>
  );
}

export default Table;
