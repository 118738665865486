import React, { useState, useEffect } from "react";
import { API } from "../../controller/api/api";
import { ApagaLocal, BuscaLocal, GravaLocal } from "../../controller/storage";
import {
  FaPrint,
  FaFile,
  FaExchangeAlt,
  FaPlus,
  FaMoneyBillWave,
} from "react-icons/fa";
import { ModalComponent } from "../modal";
import { isMobileDevice } from "../../controller/mobile";
import { IconButton, Tooltip } from "@mui/material";
import { PrimeiraMaiuscula } from "../../controller/util";

var AcaoExecutar = 0;

export function CabecalhoPediodo({
  Descricao,
  Pedido,
  id,
  Faturado,
  Servico,
  Adicionar,
  ImpressaoCozinha,
}) {
  let faturado = false;
  if (Faturado != undefined) {
    faturado = Faturado;
  }
  ////console.log(Pedido)

  const [hoveredIcon, setHoveredIcon] = useState(null);
  const [MensagemConfirmacao, setMensagemConfirmacao] = useState("");
  const [isModal, setModal] = useState(false);

  const handleIconHover = (iconName) => {
    setHoveredIcon(iconName);
  };

  const handleIconClick = (acao) => {
    var status = true;
    AcaoExecutar = acao;
    if (acao == 1) {
      setMensagemConfirmacao("Imprimir na cozinha?");
    }
    if (acao == 2) {
      if (TipoPedido() == 1) {
        setMensagemConfirmacao("Imprimir conferência?");
      } else {
        setMensagemConfirmacao("Imprimir o pedido?");
      }
    }
    if (acao == 3) {
      setMensagemConfirmacao("Transferência de mesa?");
    }
    if (acao == 4) {
      setMensagemConfirmacao("Adicionar?");
      status = false;
      handleYesClick();
      window.location = "/add-produto/" + Pedido + "/" + id;
    }
    if (acao == 5) {
      setMensagemConfirmacao("Deseja faturar o pedido?");
    }
    setModal(status);
  };

  function TipoPedido() {
    if (id > 0) {
      return 1;
    } else {
      return 0;
    }
  }

  const handleYesClick = () => {
    if (AcaoExecutar == 1) {
      API.get("/v1/produtos/pedido/1/" + id).then(function (result) {
        ////console.log(result)

        const ImpressaoCozinha = JSON.parse(
          BuscaLocal("IMPRESSAO_SELECIONADA")
        );
        //console.log(ImpressaoCozinha);

        if (ImpressaoCozinha) {
          API.post("v2/reimprimir/cozinha/selecao", ImpressaoCozinha).then(
            function (result) {
              ApagaLocal("IMPRESSAO_SELECIONADA");
            }
          );
        } else {
          API.post("v1/imprimir", result.data);
        }
      });
    }
    if (AcaoExecutar == 2) {
      if (TipoPedido() == 1) {
        //console.log(`/v1/imprimir/1/${Pedido}/${Servico ? Servico : 0}`);
        API.post(`/v1/imprimir/1/${Pedido}/${Servico ? Servico : 0}`);
      } else {
        //console.log(`/v1/imprimir/1/${Pedido}/${Servico ? Servico : 0}`);
        API.post(`/v1/imprimir/1/${Pedido}/${Servico ? Servico : 0}`);
      }
    }
    if (AcaoExecutar == 3) {
      GravaLocal("VOLTAR", "/salao/" + id);
      window.location = "/salao/transferencia/" + id;
    }
    if (AcaoExecutar == 4) {
    }
    if (AcaoExecutar == 5) {
      window.location = "/fechamento/" + Pedido;
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          height: isMobileDevice() ? "4.125rem" : "3.125rem",
          border: "1px solid #e0e0e0",
          borderRadius: "8px 0px 8px 0px",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          marginBottom: "10px",
          padding: "10px",
          backgroundColor: "#ffffff",
          transition: "box-shadow 0.3s ease-in-out",
        }}
        onMouseEnter={(e) =>
          (e.currentTarget.style.boxShadow = "0 8px 12px rgba(0, 0, 0, 0.1)")
        }
        onMouseLeave={(e) =>
          (e.currentTarget.style.boxShadow = "0 4px 6px rgba(0, 0, 0, 0.1)")
        }
      >
        {/* <div
        style={{
          height: isMobileDevice() ? "4.125rem" : "3.125rem",
          display: "flex",
          alignItems: "center",
          padding: "0 10px",
          borderRadius: "20px 0 20px 0",
          border: "1px solid rgba(20,30,404,0.3)",
        }}
      > */}
        <div style={{ flex: "1", display: "flex", alignItems: "center" }}>
          <span style={{ fontWeight: "bold" }}>{Descricao}</span>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          {faturado || Descricao == "Pedido Nº 0" || Pedido == 0 ? null : (
            <Icon
              icon={<FaPrint />}
              text="Imprimir na Cozinha"
              acao={1}
              handleIconClick={handleIconClick}
            />
          )}
          {Pedido != 0 ? (
            <>
              <Icon
                icon={<FaFile />}
                text={
                  TipoPedido() == 1
                    ? "Imprimir Conferência"
                    : "Imprimir Comanda"
                }
                acao={2}
                handleIconClick={handleIconClick}
              />
            </>
          ) : null}

          {TipoPedido() == 1 && Pedido != 0 ? (
            <Icon
              icon={<FaExchangeAlt />}
              text="Transferência"
              acao={3}
              handleIconClick={handleIconClick}
            />
          ) : null}
          {faturado ? null : (
            <Icon
              icon={<FaPlus />}
              text="Adicionar Produtos"
              acao={4}
              handleIconClick={handleIconClick}
            />
          )}
          {faturado || Descricao == "Pedido Nº 0" || Pedido == 0 ? null : (
            <Icon
              icon={<FaMoneyBillWave />}
              text="Faturar Pedido/Mesa"
              acao={5}
              handleIconClick={handleIconClick}
            />
          )}
        </div>
      </div>
      {isModal ? (
        <ModalComponent
          question={MensagemConfirmacao}
          onYesClick={handleYesClick}
          onNoClick={() => setModal(false)}
        />
      ) : null}
    </>
  );
}

function ColorInText(text) {
  if (text == "Faturar Pedido/Mesa") {
    return "#269931";
  }
  if (text == "Adicionar Produtos") {
    return "rgba(22,83,126,1)";
  }
  if (text == "Transferência") {
    return "rgba(86,83,126,1)";
  }

  if (text == "Imprimir Conferência") {
    return "rgba(238,127,0,1)";
  }
  if (text == "Imprimir Comanda") {
    return "rgba(238,127,0,1)";
  }

  if (text == "Imprimir na Cozinha") {
    return "rgba(0,214,238,0.85)";
  }

  //  ? "Imprimir Conferência": "Imprimir Comanda"
}

function Icon({ icon, text, acao, handleIconClick }) {
  return (
    <Tooltip title={text}>
      <IconButton
        // color={ColorInText(text)}
        sx={{ color: ColorInText(text) }}
        onClick={() => handleIconClick(acao)}
      >
        {icon}
      </IconButton>
    </Tooltip>
  );
}
