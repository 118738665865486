import { useState } from "react";
import "./style.css";
import { FormControlLabel, Switch } from "@mui/material";

export function Toggle({ Titulo, Change, Checked, Clique, ID }) {
  const IDClass = ID ? ID : Titulo;

  const [Select, setSelect] = useState(Checked);
  // //console.log(Checked);
  function OnChange() {
    setSelect(!Select);
    if (typeof Change === "function") {
      Change();
    }
  }

  function OnClique() {
    setSelect(!Select);
    if (typeof Clique === "function") {
      Clique(ID, !Select);
    }
    if (typeof Change === "function") {
      Change();
    }
  }
  return (
    <>
      <FormControlLabel
        control={<Switch checked={Select} onClick={OnClique} />}
        label={Titulo}
      />
      {/* {" "}
      <div className="toggle-container">
        <input
          type="checkbox"
          id={`toggleCheckbox${IDClass}`}
          className="toggle-checkbox"
          checked={Select}
          onChange={OnChange}
          onClick={OnClique}
        />
        <label htmlFor={`toggleCheckbox${IDClass}`} className="toggle-label">
          <span className="toggle-slider"></span>
        </label>
        <p className="toggle-text" style={{ margin: "10px" }}>
          {Titulo}
        </p>
      </div> */}
    </>
  );
}
