import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { API } from "../../controller/api/api";
import { FaChair, FaClock } from "react-icons/fa";
import { MascaraMonetaria } from "../../controller/data";
import { ComponentComanda } from "../comanda";

export function CardMesa(props) {
  const [TotalMesa, setTotalmesa] = useState(0);
  const [TempoPercorrido, setTempoPercorrido] = useState(0);
  const [corCard, setCorCard] = useState("#808080");
  const [MostraComanda, setMostraComanda] = useState(false);
  const [Comanda, setComanda] = useState(0);

  useEffect(() => {
    if (props.selectMesa) {
      if (props.MesaSelecionada == props.id) {
        setCorCard("#67B86F");
      } else {
        setCorCard("#808080");
      }
    }
  }, [props.MesaSelecionada]);

  const SelecionouMesa = (id) => {
    if (props.selectMesa) {
      props.selectMesa();
      return true;
    }

    if (props.descricao == "Estorno") {
      window.location = "salao/" + id;
    }

    if (props.descricao != "Mesa") {
      setComanda(props.numero);
      setMostraComanda(true);
    } else {
      window.location = "salao/" + id;
    }
  };

  function segundosParaTempo(totalSegundos) {
    const horas = Math.floor(totalSegundos / 3600);
    const minutos = Math.floor((totalSegundos % 3600) / 60);
    const segundos = totalSegundos % 60;

    const formatoHora = [
      String(horas).padStart(2, "0"),
      String(minutos).padStart(2, "0"),
      String(segundos).padStart(2, "0"),
    ];

    return formatoHora.join(":");
  }

  function DadosMesa() {
    setTotalmesa(
      props?.total ? (props.total * props.servico) / 100 + props.total : 0
    );
    setTempoPercorrido(props?.tempo ? props.tempo : 0);

    if (props.selectMesa) {
      if (props.MesaSelecionada == props.id) {
        setCorCard("#67B86F");
      } else {
        setCorCard("#808080");
      }

      return true;
    }

    if (props?.total ? props?.total : 0 > 0) {
      setCorCard("#808080");
    } else {
      setCorCard("#67B86F");
    }
  }

  useEffect(() => {
    DadosMesa();
  }, []);

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const cardStyle = {
    height: props.Heigth,
    width: "calc(50% - 10px)", // 50% menos a largura do espaçamento entre os cards
    borderRadius: "10px",
    border: `5px solid ${
      isHovered && !props.MesaSelecionada ? "rgb(168, 0, 28)" : corCard
    }`,
    padding: "10px",
    cursor: "pointer",
  };

  return (
    <>
      <div
        style={{
          // height: "155px",
          // width: "155px",
          borderRadius: "10px",
          border: `5px solid ${
            isHovered && !props.MesaSelecionada ? "rgb(168, 0, 28)" : corCard
          }`,
          padding: "10px",
          cursor: isHovered ? "pointer" : "default",
        }}
        onClick={() => SelecionouMesa(props.id)}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div
          style={{
            display: "flex",
            color:
              isHovered && !props.MesaSelecionada ? "rgb(168, 0, 28)" : corCard,
            alignItems: "center",
          }}
        >
          <span>
            <FaChair
              style={{ height: "24px", width: "24px", marginRight: "5px" }}
            />
          </span>
          <div
            style={{
              color:
                isHovered && !props.MesaSelecionada
                  ? "rgb(168, 0, 28)"
                  : corCard,
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "flex-end",
              width: "100%",
              height: "100%",
            }}
          >
            <h3
              style={{
                fontSize: "1.5rem",
              }}
            >
              {props.descricao.slice(0, 8)}
            </h3>
          </div>
        </div>
        <div
          style={{
            color:
              isHovered && !props.MesaSelecionada ? "rgb(168, 0, 28)" : corCard,
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          <h3 style={{ fontSize: "2rem" }}>{props.numero}</h3>
        </div>
        <div
          style={{
            color:
              isHovered && !props.MesaSelecionada ? "rgb(168, 0, 28)" : corCard,
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          <h3 style={{ fontSize: "1.3rem" }}>{MascaraMonetaria(TotalMesa)}</h3>
        </div>

        {TotalMesa > 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              color:
                isHovered && !props.MesaSelecionada
                  ? "rgb(168, 0, 28)"
                  : corCard,
            }}
          >
            <FaClock
              style={{ height: "18px", width: "18px", marginRight: "5px" }}
            />
            <h3 style={{ fontSize: "0.8rem" }}>
              {segundosParaTempo(TempoPercorrido)}
            </h3>
          </div>
        ) : null}
      </div>
      {MostraComanda ? <ComponentComanda comanda={Comanda} /> : null}
    </>
  );
}
