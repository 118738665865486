import { Button } from "@mui/material";
import { ShowModal } from "../modalPai";
import { FaCheck } from "react-icons/fa";
import { Notifica } from "../../controller/util";

export function ModalGravarDados({
  Descricao,
  Funcao,
  closeButton,
  children,
  DescricaoButton,
}) {
  function Salvar() {
    const retorno = Funcao();
    if (!retorno) {
      return false;
    }
    if (!DescricaoButton) {
      Notifica("Salvo Com Sucesso!");
    }
  }
  return (
    <>
      <ShowModal closeButton={closeButton}>
        <div style={{ width: "450px" }}>
          <h1 style={{ textAlign: "center" }}>
            <b>{Descricao}</b>
          </h1>
          {children}
          <Button
            onClick={Salvar}
            sx={{ width: "100%", marginBottom: "10px" }}
            variant="outlined"
            startIcon={DescricaoButton ? "" : <FaCheck />}
          >
            {DescricaoButton ? DescricaoButton : "Salvar"}
          </Button>
        </div>
      </ShowModal>
    </>
  );
}
