import { useEffect, useState } from "react";
import {
  CardFormaPagamento,
  CardFormaPagamentoSangria,
  CardFormaPagamentoTotalizador,
} from "../../relatorio/component/pagamento";
import { API } from "../../../../controller/api/api";

export function FormaDePagamento({ id }) {
  const StyleTituloValue = {
    fontSize: "1.1rem",
  };

  const ColorSaida = {
    color: "#F00028",
  };

  const ColorEntrada = {
    color: "#67B86F",
  };

  const [Dados, setDados] = useState([]);
  const [Total, setTotal] = useState(0);

  useEffect(() => {
    API.get(`/v2/forma/pagamento/caixa/${id}`).then((response) => {
      //console.log(response.data);
      setDados(response.data);

      var tot = 0;
      for (let i = 0; i < response.data.length; i++) {
        tot += response.data[i].valor;
      }
      setTotal(tot);
    });
  }, []);

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "100%",
          //   border: "1px solid #fdfd",
          flexDirection: "column",
          display: "flex",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px",
          }}
        >
          <h1 style={StyleTituloValue}>
            <b>
              <span style={ColorEntrada}>Entrada</span> /{" "}
              <span style={ColorSaida}>Saída</span>
            </b>
          </h1>
        </div>

        <div style={{ padding: "10px", overflowY: "scroll", flex: "1" }}>
          {Dados.map((item, index) =>
            item.tipo == 2 ? (
              <CardFormaPagamentoSangria
                id={item.id}
                Delete={true}
                Titulo={item.descricao}
                Valor={item.valor}
              />
            ) : (
              <CardFormaPagamento Titulo={item.descricao} Valor={item.valor} />
            )
          )}
        </div>

        <CardFormaPagamentoTotalizador Valor={Total} />
      </div>
    </>
  );
}
