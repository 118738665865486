import React, { useEffect } from "react";

const IfoodWidget = ({ merchantId }) => {
  useEffect(() => {
    // Garantindo que o widget iFood esteja disponível
    if (merchantId != "") {
      if (window.iFoodWidget) {
        window.iFoodWidget.init({
          widgetId: "21359895-60eb-4e29-9862-e724aca407e5", // Substitua pelo UUID do seu widget
          merchantIds: [merchantId],
        });
      }
    }
  }, [merchantId]);

  return (
    <div id="ifood-widget-container" style={{ left: "0px" }}>
      {/* O widget provavelmente preencherá este container ou algum identificador específico que você especificar. */}
    </div>
  );
};

export default IfoodWidget;
