import { useEffect, useState } from "react";
import { isMobileDevice } from "../../../../controller/mobile";
import {
  FaAngleDown,
  FaAngleUp,
  FaCertificate,
  FaHashtag,
} from "react-icons/fa";
import { BuscaLocal, GravaLocal } from "../../../../controller/storage";
import { API } from "../../../../controller/api/api";

function verificarTexto(texto, parteDoTexto) {
  let partes = texto.split("/");
  let partes2 = parteDoTexto.split("/");
  return partes[1] == partes2[1];
}

function SubItem({ itens }) {
  const [isItemHovered, setIsItemHovered] = useState(false);

  const handleItemMouseEnter = () => {
    setIsItemHovered(true);
  };

  const handleItemMouseLeave = () => {
    setIsItemHovered(false);
  };

  return (
    <li
      style={{
        marginBottom: "1px",
        marginLeft: "15px",
        borderLeft: isItemHovered
          ? "2px solid white"
          : "2px solid rgba(255,255,255,0.5)",
        transition: "background-color 0.3s",
        backgroundColor:
          isItemHovered || verificarTexto(window.location.pathname, itens.link)
            ? "rgba(0, 0, 0, 0.3)"
            : "transparent",
        // transform: isItemHovered ? "scale(1.1)" : "scale(1)",
        display: "flex",
        padding: "5px",
        fontSize: "1rem",
        borderRadius: "5px",
        color: "white",
        marginBottom: "5px",
      }}
      onMouseEnter={handleItemMouseEnter}
      onMouseLeave={handleItemMouseLeave}
    >
      <div
        style={{
          color:
            isItemHovered ||
            verificarTexto(window.location.pathname, itens.link)
              ? "white"
              : "rgba(255,255,255,0.5)",
        }}
      >
        {itens.icone}
      </div>

      <a
        href={itens.link}
        style={{
          color: "white",
          textDecoration: "none",
          fontSize: isMobileDevice() ? "1.6rem" : "1rem",
          marginLeft: "5px",
        }}
      >
        {itens.descricao}
      </a>
      {itens.new ? (
        <>
          {" "}
          <span
            style={{
              backgroundColor: "#3CA346",
              fontSize: "0.7rem",
              borderRadius: "5px",
              padding: "2px 5px 2px 5px",
              color: "black",
              display: "flex",
              alignItems: "center",
              gap: "3px",
              marginLeft: "auto", // Alinha o "Novo" à direita
            }}
          >
            <FaHashtag />
            <b>Novo</b>
          </span>
        </>
      ) : null}
    </li>
  );
}
export function ItensSideBar({
  Descricao,
  link,
  Click,
  icone,
  sub,
  Navegacao,
  New,
}) {
  const [visualizarMenu, setVisualizarMenu] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [mylink, setMylink] = useState(link);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    if (link === "/pedido/0") {
      API.get("v1/codigo/pedido")
        .then(function (response) {
          setMylink(`/pedido/${response.data.codigo}`);
        })
        .catch(function (error) {
          console.error("Erro ao obter o código do pedido:", error);
        });
    }
  }, [link]);

  return (
    <>
      <li
        style={{
          marginBottom: "3px",
          transition: "background-color 0.3s",
          borderRadius: "5px",
          backgroundColor:
            isHovered || verificarTexto(window.location.pathname, link)
              ? "rgba(0, 0, 0, 0.3)"
              : "transparent",
          // transform: isHovered ? "scale(1.1)" : "scale(1)",
          color: "white",
          display: "flex",
          padding: "5px",
          alignItems: "center",

          cursor: "pointer",
          // marginLeft: "5px",
          fontSize: "1rem",
          marginBottom: "5px",
        }}
        onClick={() => {
          if (typeof Click === "function") {
            Click();
          }
          Navegacao(mylink);
          setVisualizarMenu(!visualizarMenu);
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div
          style={{
            color:
              isHovered || verificarTexto(window.location.pathname, link)
                ? "#ffffff"
                : "rgba(255,255,255,0.5)",
          }}
        >
          {icone}
        </div>
        <a
          // href={link}
          style={{
            color: "white",
            textDecoration: "none",
            // fontSize: isMobileDevice() ? "1.6rem" : "1rem",
            display: "flex",
            alignItems: "center",
            marginLeft: "5px",
            marginRight: "10px",
          }}
        >
          {Descricao}
          {sub ? (
            visualizarMenu ? (
              <FaAngleUp style={{ marginLeft: "10px" }} />
            ) : (
              <FaAngleDown style={{ marginLeft: "10px" }} />
            )
          ) : null}
        </a>
        {New ? (
          <>
            <span
              style={{
                backgroundColor: "#3CA346",
                fontSize: "0.7rem",
                borderRadius: "5px",
                padding: "2px 5px 2px 5px",
                color: "black",
                display: "flex",
                alignItems: "center",
                gap: "3px",
                marginLeft: "auto", // Alinha o "Novo" à direita
              }}
            >
              <FaHashtag />
              <b>Novo</b>
            </span>
          </>
        ) : null}
      </li>
      {sub ? (
        <ul
          style={{
            listStyle: "none",
            padding: 0,
            margin: 0,
            overflow: "hidden",
            maxHeight: visualizarMenu ? "1000px" : "0",
            transition: "max-height 0.5s ease",
          }}
        >
          {sub.map((itens, index) =>
            itens.visible ? <SubItem key={index} itens={itens} /> : null
          )}
        </ul>
      ) : null}
    </>
  );
}
