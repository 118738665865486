import { useEffect, useMemo, useState } from "react";
import { API } from "../../../controller/api/api";
import {
  Notifica,
  NotificaWarning,
  PrimeiraMaiuscula,
} from "../../../controller/util";
import { FaPen, FaRegTrashAlt } from "react-icons/fa";
import { HeaderComponent } from "../../../component/header";
import LoadingOverlay from "../../../component/load";
import { ComponentCadastro } from "../../../component/header/cadastro";
import Table from "../../../component/tabela";
import { MascaraMonetaria } from "../../../controller/data";
import { ModalGravarDados } from "../../../component/gravar";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { ModalComponent } from "../../../component/modal";

let DadosEstatico = [];
export function CadastroComandaMesa() {
  const [Dados, setDados] = useState([]);
  const [isCadastro, setIsCadastro] = useState(true);
  const [loading, setLoading] = useState(false);
  const [Codigo, setCodigo] = useState(0);
  const [Tipo, setTipo] = useState("Mesa");
  const [Min, setMin] = useState(0);
  const [Max, setMax] = useState(0);
  const [Minimo, setMinimo] = useState(1);
  const [CodigoTipo, setTipoCodigo] = useState(0);

  const [isExcluir, setisExcluir] = useState(false);
  const [DadosExcluir, setDadosExcluir] = useState([]);

  function SalvarDados() {
    if (Minimo > Min) {
      NotificaWarning(`O valor mínimo para a(o) ${Tipo} é de ${Min}.`);
      return false;
    }

    if (Max < Min) {
      NotificaWarning(
        `O Valor maximo deve-se ser maior que o valor mínimo para a(o) ${Tipo}.`
      );
      return false;
    }

    const body = { codigo: CodigoTipo, descricao: Tipo, min: Min, max: Max };
    setLoading(true);
    API.post("/v2/grava/mesa", body).then(function (response) {
      fetchData();
      setMax(0);
      setTipo("Mesa");
      BuscarDados();
      setIsCadastro(false);
      setLoading(false);
      return true;
    });
  }

  function BuscarDados() {
    setIsCadastro(false);
    API.get("v1/mesas/").then(function (data) {
      DadosEstatico = data.data;
      setDados(data.data);
      setLoading(false);
    });
  }

  useEffect(() => {
    BuscarDados();
  }, []);

  function NovoPedido() {
    setIsCadastro(true);
  }

  function PintaValores(valor, dataFiltro) {
    var item = dataFiltro.find(function (elemento) {
      return elemento.idMesa === valor.idMesa;
    });
    return (
      <>
        {item.totMesa > 0 ? (
          <span style={{ color: "#F00028" }}>
            <b>Ocupado</b>
          </span>
        ) : (
          <span style={{ color: "#67B86F" }}>
            <b>Livre</b>
          </span>
        )}
      </>
    );
  }

  function PintaValoresDescricao(valor, dataFiltro) {
    var item = dataFiltro.find(function (elemento) {
      return elemento.idMesa === valor.idMesa;
    });

    return (
      <>
        <span>
          {item.descricao
            ? PrimeiraMaiuscula(item.descricao)
            : PrimeiraMaiuscula(item.descricao1)}{" "}
          {item.nrMesa}
        </span>
      </>
    );
  }

  function PintaValoresConsumacao(valor, dataFiltro) {
    var item = dataFiltro.find(function (elemento) {
      return elemento.idMesa === valor.idMesa;
    });
    return (
      <>
        <span>{MascaraMonetaria(item.totMesa)}</span>
      </>
    );
  }

  function AlterarDados(dados) {
    const objetoEncontrado = DadosEstatico.find(
      (objeto) => objeto.idMesa === dados
    );
    //console.log(objetoEncontrado);
    setDadosExcluir(objetoEncontrado);
    const DescMesa = objetoEncontrado.descricao
      ? PrimeiraMaiuscula(objetoEncontrado.descricao)
      : PrimeiraMaiuscula(objetoEncontrado.descricao1) +
        " " +
        objetoEncontrado.nrMesa;
    if (objetoEncontrado.totMesa > 0) {
      NotificaWarning(DescMesa + " não pode ser excluida, ela está ocupada!");
      return false;
    }
    setisExcluir(true);
    // const Def = DadosEstaticoImpressora.find(
    //   (objeto) => objeto.driver.toUpperCase() === "DEFAULT"
    // );
    // const objetoImpressora = DadosEstaticoImpressora.find(
    //   (objeto) => objeto.driver === objetoEncontrado.driver
    // );
    // const codigoImpressora = objetoImpressora?.id
    //   ? objetoImpressora?.id
    //   : Def.id;
    // setDriver(codigoImpressora ? codigoImpressora : Def?.id);
    // setCodigo(objetoEncontrado.codigo);
    // setDescricao(objetoEncontrado.descricao);
    // setTipo(objetoEncontrado.tipoImpressao);
    // setPadrao(objetoEncontrado.impressoraPadrao);
    // setAtivo(objetoEncontrado.ativo);
    // setIsCadastro(true);
  }

  const columns = useMemo(
    () => [
      {
        Header: "Descrição",
        accessor: "descricao1",
        size: "100px",
        // textAlign: "center",
        formatOption: PintaValoresDescricao,
      },
      {
        Header: "Consumação",
        accessor: "selecionada",
        size: "10px",
        // textAlign: "center",
        formatOption: PintaValoresConsumacao,
      },
      {
        Header: "Status",
        accessor: "totMesa",
        size: "100px",
        // textAlign: "center",
        // format: PintaValores,
        formatOption: PintaValores,
      },
      {
        Header: "Excluir",
        size: "50px",
        component: (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IconButton color="primary">
              <FaRegTrashAlt />
            </IconButton>
          </div>
        ),
        func: AlterarDados,
      },
    ],
    []
  );

  const fetchData = async () => {
    try {
      const result = await API.get(
        `v1/consulta/generica/mesa_tipo/*/upper(descricao) = '${Tipo.toUpperCase()}'/*`
      );

      if (result.data[0]?.idMesaTipo) {
        const max = await API.get(
          `v1/consulta/generica/mesa/max(nr_mesa) as mesa, 0 as zero/fk_tipo_mesa = ${result.data[0].idMesaTipo}/*`
        );
        setMinimo(max.data[0]?.mesa ? max.data[0]?.mesa : 1);
        setMin(max.data[0]?.mesa ? max.data[0]?.mesa : 1);
        setTipoCodigo(result.data[0].idMesaTipo);
      } else {
        setMinimo(1);
        setMin(1);
        setTipoCodigo(0);
      }
    } catch (error) {
      console.error("Erro ao buscar os dados:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [Tipo]);

  function handleYesClick() {
    //console.log(`/v2/delete/mesa/${DadosExcluir.idMesa}`);
    API.post(`/v2/delete/mesa/${DadosExcluir.idMesa}`).then(function (data) {
      Notifica(
        `${DadosExcluir.descricao1} ${DadosExcluir.nrMesa} Excluido Com Sucesso!`
      );
      BuscarDados();
    });
  }

  return (
    <>
      <HeaderComponent>
        {loading && <LoadingOverlay />}{" "}
        <ComponentCadastro Titulo="Comanda" Funcao={NovoPedido} />
        <Table columns={columns} data={Dados} codigo={"idMesa"} />
        {isCadastro ? (
          <ModalGravarDados
            Descricao={Codigo == 0 ? "Cadastro" : "Alteração"}
            closeButton={() => setIsCadastro(false)}
            Funcao={SalvarDados}
          >
            <FormControl fullWidth>
              <InputLabel id="tipo">Tipo</InputLabel>
              <Select
                labelId="tipo"
                id="tipo"
                value={Tipo}
                label="Tipo"
                onChange={(e) => setTipo(e.target.value)}
                sx={{ width: "100%", marginBottom: "15px" }}
                size="small"
              >
                <MenuItem value={"Mesa"}>Mesa</MenuItem>
                <MenuItem value={"Comanda"}>Comanda</MenuItem>
                <MenuItem value={"Ficha"}>Ficha</MenuItem>
              </Select>
            </FormControl>

            <TextField
              id="min"
              label={Tipo + " Inicial"}
              size="small"
              sx={{ width: "100%", marginBottom: "15px" }}
              value={Min}
              onChange={(e) => setMin(e.target.value)}
            />
            <TextField
              id="max"
              label={Tipo + " Final"}
              size="small"
              sx={{ width: "100%", marginBottom: "15px" }}
              value={Max}
              onChange={(e) => setMax(e.target.value)}
            />
          </ModalGravarDados>
        ) : null}
        {isExcluir ? (
          <ModalComponent
            question={`Deseja excluir a(o) ${DadosExcluir.descricao1} ${DadosExcluir.nrMesa}?`}
            onYesClick={handleYesClick}
            onNoClick={() => setisExcluir(false)}
          />
        ) : null}
      </HeaderComponent>
    </>
  );
}
