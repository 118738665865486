// import Switch from "react-switch";
import { useState, useEffect } from "react";
import { API } from "../../../../controller/api/api";
import { FormControlLabel, FormGroup, Switch } from "@mui/material";

export function Selecao({ Descricao, Campo, Valor }) {
  const [Select, setSelect] = useState(Valor);

  function Atualiza() {
    if (Select) {
      let Body = { campo: Campo, valor: 0 };
      API.post(`/v2/parametro`, Body);
    } else {
      let Body = { campo: Campo, valor: 1 };
      API.post(`/v2/parametro`, Body);
    }
    setSelect(!Select);
  }

  return (
    <>
      <FormControlLabel
        control={<Switch checked={Select} onChange={() => Atualiza()} />}
        label={Descricao}
      />
    </>
  );
}
