import { useState } from "react";
import { PrimeiraMaiuscula } from "../../../../controller/util";
import { ShowModal } from "../../../../component/modalPai";
import { Button } from "../../../../component/button";
import { Select, MenuItem, InputLabel, FormControl } from "@mui/material";

export function ModalMotoboy(props) {
  //////console.log(props.data)

  const [Id, setId] = useState(0);

  const handleYesClick = () => {
    props.onYesClick(Id);
  };

  const handleNoClick = () => {
    props.onNoClick();
  };
  const buttonContainerStyle = {
    justifyContent: "center",
    marginTop: "20px",
  };

  const buttonStyle = {
    padding: "10px 20px",

    borderRadius: "4px",
    border: "none",
    color: "#fff",
    cursor: "pointer",
    width: "100%",
  };

  const yesButtonStyle = {
    ...buttonStyle,
    background: "green",
  };

  const noButtonStyle = {
    ...buttonStyle,
    background: "red",
    marginTop: "10px",
  };

  return (
    <>
      <ShowModal altura={25} largura={20} closeButton={handleNoClick}>
        <div style={{ width: "29vw" }}>
          <h2>Selecione o Motoboy</h2>

          <FormControl fullWidth sx={{ marginTop: "10px" }}>
            <InputLabel id="select-label">Selecione</InputLabel>
            <Select
              labelId="select-label"
              label="Selecione"
              value={Id}
              size="small"
              sx={{ marginBottom: "10px" }}
              onChange={(e) => setId(e.target.value)}
            >
              <MenuItem value={0}>Sem Motoboy</MenuItem>
              {props.data.map((item, index) => (
                <MenuItem key={index} value={item.codigo}>
                  {PrimeiraMaiuscula(item.nome)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* <select
              style={{
                height: "34px",
                width: "100%",
                border: "1px solid",
                marginBottom: "10px",
              }}
              onChange={(e) => setId(e.target.value)}
            >
              <option value={0} selected>
                Selecione
              </option>
              {props.data.map((item, index) => (
                <option key={index} value={item.codigo}>
                  {PrimeiraMaiuscula(item.nome)}
                </option>
              ))}
            </select> */}

          {Id != 0 ? (
            <>
              {/* <button style={yesButtonStyle} onClick={handleYesClick}>
                  Selecionar Motoboy
                </button> */}
              <Button
                icon={
                  <img
                    src={"/do-icone-localizacao.png"}
                    style={{ height: "16px", width: "16px" }}
                  />
                }
                descricao="Selecionar Motoboy"
                evento={handleYesClick}
                fontColor="rgb(0, 0, 0)"
                borderColor="rgb(0, 0, 0)"
                BorderRadius={5}
              />
            </>
          ) : null}

          {/* <button style={noButtonStyle} onClick={handleNoClick}>
              Cancelar
            </button> */}
        </div>
      </ShowModal>
    </>
  );
}
