import { useEffect, useState } from "react";
import { API } from "../../../../controller/api/api";
import { useNavigate } from "react-router-dom";
import { PageHomeMotoboy } from "./page/motoboy";
import { isMotoboy } from "../../../../controller/util";
import { SalaoPage } from "../../../salao";


export function MobilePage() {
    return (<>
        {isMotoboy() ? <PageHomeMotoboy /> : <SalaoPage />}

    </>)
}