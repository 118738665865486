import { useEffect, useMemo, useState } from "react";
import { API } from "../../controller/api/api";
import { HeaderComponent } from "../../component/header";
import { NotificaWarning, PrimeiraMaiuscula } from "../../controller/util";
import Table from "../../component/tabela";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Switch,
  TextField,
} from "@mui/material";
import { ComponentCadastro } from "../../component/header/cadastro";
import { FaPen } from "react-icons/fa";
import Select from "@mui/material/Select";
import { ModalGravarDados } from "../../component/gravar";
import LoadingOverlay from "../../component/load";

let DadosEstatico = [];
let DadosEstaticoImpressora = [];

export function Impressora() {
  const label = { inputProps: { "aria-label": "Size switch demo" } };
  const [Impressora, setImpressora] = useState([]);
  const [Dados, setDados] = useState([]);
  const [isCadastro, setIsCadastro] = useState(false);
  const [loading, setLoading] = useState(false);

  const [Codigo, setCodigo] = useState(0);
  const [Descricao, setDescricao] = useState("");
  const [Tipo, setTipo] = useState(1);
  const [Driver, setDriver] = useState(1);
  const [Padrao, setPadrao] = useState(true);
  const [Ativo, setAtivo] = useState(true);

  useEffect(() => {
    API.get("v1/impressora/servidor/").then(function (data) {
      // Ordena os dados pelo campo "id"
      const sortedData = data.data.sort((a, b) => a.id - b.id);
      // Define o estado Impressora com os dados ordenados
      DadosEstaticoImpressora = sortedData;
      setImpressora(sortedData);
    });
  }, []);

  useEffect(() => {
    //console.log(Driver);
  }, [Driver]);

  useEffect(() => {
    BuscarDados();
  }, [Impressora]);

  function BuscarDados() {
    setIsCadastro(false);
    API.get("/v1/consulta/generica/impressoras/*/*/descricao").then(function (
      data
    ) {
      DadosEstatico = data.data;
      //console.log(data.data);
      setDados(data.data);
      setLoading(false);
    });
  }

  function AlterarDados(dados) {
    const objetoEncontrado = DadosEstatico.find(
      (objeto) => objeto.codigo === dados
    );

    const Def = DadosEstaticoImpressora.find(
      (objeto) => objeto.driver.toUpperCase() === "DEFAULT"
    );

    const objetoImpressora = DadosEstaticoImpressora.find(
      (objeto) => objeto.driver === objetoEncontrado.driver
    );

    const codigoImpressora = objetoImpressora?.id
      ? objetoImpressora?.id
      : Def.id;

    setDriver(codigoImpressora ? codigoImpressora : Def?.id);
    setCodigo(objetoEncontrado.codigo);
    setDescricao(objetoEncontrado.descricao);
    setTipo(objetoEncontrado.tipoImpressao);
    setPadrao(objetoEncontrado.impressoraPadrao);
    setAtivo(objetoEncontrado.ativo);
    setIsCadastro(true);
  }

  function PintaValorPadrao(valor, dataFiltro) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Switch {...label} size="small" checked={valor == 1 ? true : false} />
      </div>
    );
  }

  function PintaValores(valor, dataFiltro) {
    return <>{valor == 1 ? "80mm" : "56mm"}</>;
  }

  const columns = useMemo(
    () => [
      {
        Header: "Descrição",
        accessor: "descricao",
        size: "100px",
        format: PrimeiraMaiuscula,
      },
      {
        Header: "Driver",
        accessor: "driver",
        size: "100px",
        format: PrimeiraMaiuscula,
        textAlign: "center",
      },
      {
        Header: "Tipo",
        accessor: "tipoImpressao",
        size: "100px",
        textAlign: "center",
        format: PintaValores,
      },
      {
        Header: "Padrão",
        accessor: "impressoraPadrao",
        size: "100px",
        format: PintaValorPadrao,
      },
      {
        Header: "Ativo",
        accessor: "ativo",
        size: "100px",
        format: PintaValorPadrao,
      },
      {
        Header: "Alterar",
        size: "50px",
        component: (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IconButton color="primary">
              <FaPen />
            </IconButton>
          </div>
        ),
        func: AlterarDados,
      },
    ],
    []
  );

  function SalvarDados() {
    if (Descricao.length == 0) {
      NotificaWarning("Descrição não informada!");
      return false;
    }
    let driver = "Default";
    for (let i = 0; i < Impressora.length; i++) {
      if (Impressora[i].id == Driver) {
        driver = Impressora[i].driver;
      }
    }

    const body = {
      tabela: "impressoras",
      campo: "codigo",
      valor: Codigo,
      campos: [
        { campo: "descricao", valor: Descricao },
        { campo: "driver", valor: driver },
        { campo: "tipo_impressao", valor: Tipo },
        { campo: "impressora_padrao", valor: Padrao ? 1 : 0 },
        { campo: "ativo", valor: Ativo ? 1 : 0 },
      ],
    };

    // //console.log(body);
    setLoading(true);
    API.post("v2/cadastro/geral", body).then(function (response) {
      BuscarDados();
    });
  }

  function NovoPedido() {
    const Def = Impressora.find(
      (objeto) => objeto.driver.toUpperCase() === "DEFAULT"
    );

    setCodigo(0);
    setDriver(Def.id);
    setDescricao("");
    setTipo(1);
    setPadrao(false);
    setAtivo(true);
    setIsCadastro(true);
  }

  return (
    <>
      <HeaderComponent>
        {loading && <LoadingOverlay />}{" "}
        <ComponentCadastro Titulo="Impressora" Funcao={NovoPedido} />
        <Table columns={columns} data={Dados} codigo={"codigo"} />
        {isCadastro ? (
          <>
            <ModalGravarDados
              Descricao={Codigo == 0 ? "Cadastro" : "Alteração"}
              closeButton={() => setIsCadastro(false)}
              Funcao={SalvarDados}
            >
              <TextField
                id="descricao"
                label="Descrição"
                size="small"
                sx={{ width: "100%", marginBottom: "15px" }}
                value={PrimeiraMaiuscula(Descricao)}
                onChange={(e) => setDescricao(e.target.value)}
              />
              <FormControl fullWidth>
                <InputLabel id="driver">Driver</InputLabel>
                <Select
                  labelId="driver"
                  id="driver"
                  value={Driver}
                  label="Driver Impressora"
                  onChange={(e) => setDriver(e.target.value)}
                  sx={{ width: "100%", marginBottom: "15px" }}
                  size="small"
                >
                  {Impressora.map((item) => (
                    <MenuItem value={item.id}>
                      {PrimeiraMaiuscula(item.driver)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="tipo">Tipo Impressora</InputLabel>
                <Select
                  labelId="tipo"
                  id="tipo"
                  value={Tipo}
                  label="Tipo Impressora"
                  onChange={(e) => setTipo(e.target.value)}
                  sx={{ width: "100%", marginBottom: "10px" }}
                  size="small"
                >
                  <MenuItem value={1}>80mm</MenuItem>
                  <MenuItem value={2}>56mm</MenuItem>
                </Select>
              </FormControl>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={Padrao}
                      onChange={(e) => setPadrao(!Padrao)}
                    />
                  }
                  label="Padrão"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={Ativo}
                      onChange={(e) => setAtivo(!Ativo)}
                    />
                  }
                  label="Ativo"
                />
              </FormGroup>
            </ModalGravarDados>
          </>
        ) : null}
      </HeaderComponent>
    </>
  );
}
