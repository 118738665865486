// import { HeaderComponent } from "../header";
import "./style.css";
import InputMask from "react-input-mask";
import { FaSave } from "react-icons/fa";

import React, { useState, useEffect } from "react";
import { PrimeiraMaiuscula } from "../../controller/util";
import { API } from "../../controller/api/api";
import { ShowModal } from "../modalPai";
import { Toggle } from "../toggle";
import { Button } from "../button";

const FormularioGenerico = ({ tabela, primary, campo, data, label }) => {
  const [formData, setFormData] = useState({});
  const [Alteracao, setAlteracao] = useState(false);
  const [ModalSalvar, setModalSalvar] = useState(false);
  useEffect(() => {
    const initialData = {};

    if (campo[0].default == -1) {
      setAlteracao(true);
    }

    campo.forEach((field) => {
      if (field.default !== undefined) {
        initialData[field.name] = field.default;
      } else {
        initialData[field.name] = "";
      }
    });
    setFormData(initialData);
  }, [campo]);

  function isNumero(str) {
    // Usa o isNaN para verificar se a conversão para número é válida
    return !isNaN(parseFloat(str)) && isFinite(str);
  }

  const handleChangeCheck = (name, value) => {
    //console.log(name);
    if (isNumero(value)) {
      if (value == 1) {
        value = 0;
      } else {
        value = 1;
      }
    }

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setAlteracao(true);
  };

  const handleChange = (name, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setAlteracao(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    //console.log(formData); // Aqui você pode fazer o que quiser com os dados

    ///v2/grava/generica/:tabela/:campo

    API.post(`/v2/grava/generica/${tabela}/${campo}`, formData);
    window.location.reload();
    Close();

    setAlteracao(false);
  };

  const MyLabel = ({ descricao }) => {
    return (
      <>
        <div style={{ marginBottom: "5px" }}>
          <b>{descricao}</b>
        </div>
      </>
    );
  };

  function Test(field) {
    // Itera sobre o array field.select.data
    for (let i = 0; i < field.select.data.length; i++) {
      const item = field.select.data[i];
      //   //console.log(field);
      //   //console.log(item);

      // Obtém o valor dinâmico do campo
      const dynamicFieldValue = item[field.select.feed[0].value];
      //   //console.log(
      //     PrimeiraMaiuscula(dynamicFieldValue) +
      //       "-" +
      //       PrimeiraMaiuscula(field.default)
      //   );
      //   //console.log(dynamicFieldValue);
      // Verifica se o valor é igual a field.default
      //   //console.log(item);
      //   //console.log(PrimeiraMaiuscula(dynamicFieldValue));
      //   //console.log(PrimeiraMaiuscula(field.default));
      if (
        PrimeiraMaiuscula(dynamicFieldValue) ===
        PrimeiraMaiuscula(field.default)
      ) {
        // Se for igual, retorna o valor desejado (por exemplo, .value)
        return item[field.select.feed[0].name];
        // Ou você pode retornar o valor, dependendo do contexto da sua aplicação
        // return item.value;
      }
    }
  }

  function Close() {
    setModalSalvar(false);
  }

  const buttonStyle = {
    backgroundColor: "transparent",
    padding: "10px",
    borderRadius: "50px",
    border: "1px solid black",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    textAlign: "center",
    width: "19vw",
    marginTop: "10px",
    transition: "transform 0.3s ease-in-out", // Adiciona a transição para a animação
  };

  const iconStyle = {
    color: "green",
    marginRight: "5px", // Espaço entre o ícone e o texto
  };

  const handleMouseEnter = (e) => {
    e.target.style.transform = "scale(1.01)";
  };

  const handleMouseLeave = (e) => {
    e.target.style.transform = "scale(1)";
  };

  return (
    <form
      className="container-generico"
      onSubmit={handleSubmit}
      style={{ padding: "10px" }}
    >
      {ModalSalvar ? (
        <>
          <ShowModal closeButton={Close}>
            <div style={{ width: "20vw" }}>
              {campo.map((field, index) => {
                switch (field.type) {
                  case "number":
                    return (
                      <>
                        <div
                          key={index}
                          style={{
                            maxWidth: field.maxSize,
                            marginRight: "10px",
                          }}
                        >
                          <MyLabel descricao={field.description} />
                          <InputMask
                            mask={field.mask}
                            type={field.type}
                            name={field.name}
                            maskChar={null}
                            onChange={(e) =>
                              handleChange(field.name, e.target.value)
                            }
                            required={field.obligatory}
                            style={{
                              width: "19vw",
                              maxWidth: "19vw",
                              border: "1px solid #cfcfcf",
                            }}
                            value={PrimeiraMaiuscula(formData[field.name])}
                          />
                        </div>
                      </>
                    );
                  case "text":
                    return (
                      <>
                        <div
                          key={index}
                          style={{
                            maxWidth: field.maxSize,
                            marginRight: "10px",
                            minWidth: field.min,
                          }}
                        >
                          <MyLabel descricao={field.description} />

                          <InputMask
                            mask={field.mask}
                            type={field.type}
                            name={field.name}
                            maskChar={null}
                            onChange={(e) =>
                              handleChange(field.name, e.target.value)
                            }
                            required={field.obligatory}
                            style={{
                              width: "19vw",
                              maxWidth: "19vw",
                              border: "1px solid #cfcfcf",
                            }}
                            // value={PrimeiraMaiuscula(field.default)}
                            value={PrimeiraMaiuscula(formData[field.name])}
                          />
                        </div>
                      </>
                    );
                  case "select":
                    return (
                      <>
                        <div
                          key={index}
                          style={{
                            maxWidth: field.maxSize,
                            marginRight: "10px",
                          }}
                        >
                          <MyLabel descricao={field.description} />
                          <select
                            name={field.name}
                            onChange={(e) =>
                              handleChange(field.name, e.target.value)
                            }
                            required={field.obligatory}
                            style={{
                              width: "19vw",
                              maxWidth: "19vw",
                              border: "1px solid #cfcfcf",
                            }}
                          >
                            {field.select.data.map((option) => (
                              <option
                                key={option.codigo}
                                value={option[field.select.feed[0].value]}
                                selected={
                                  PrimeiraMaiuscula(field.default) ==
                                  PrimeiraMaiuscula(
                                    option[field.select.feed[0].name]
                                  )
                                }
                              >
                                {PrimeiraMaiuscula(
                                  option[field.select.feed[0].name]
                                )}
                              </option>
                            ))}
                          </select>
                        </div>
                      </>
                    );
                  case "check":
                    return (
                      <>
                        <div
                          key={index}
                          style={{
                            marginRight: "10px",
                          }}
                        >
                          <MyLabel descricao={field.description} />
                          <Toggle
                            Clique={(e) => {
                              handleChangeCheck(
                                field.name,
                                formData[field.name]
                              );
                            }}
                            Checked={
                              formData[field.name] == field.check.valueTrue
                            }
                          />
                          {/* <input
                            type="checkbox"
                            name={field.name}
                            onChange={(e) =>
                              handleChange(
                                field.name,
                                e.target.checked
                                  ? field.check.valueTrue
                                  : field.check.valueFalse
                              )
                            }
                            // required={field.obligatory}
                            checked={
                              formData[field.name] == field.check.valueTrue
                            }
                            // value={PrimeiraMaiuscula(formData[field.name] || '')}
                            style={{
                              marginRight: "10px",
                              transform: "scale(1.5)",
                            }}
                          /> */}
                        </div>
                      </>
                    );
                  default:
                    return null;
                }
              })}
              <button
                type="submit"
                style={buttonStyle}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <FaSave style={iconStyle} /> Salvar
              </button>
            </div>
          </ShowModal>
        </>
      ) : null}

      <div
        style={{ display: "flex", gap: "5px" }}
        onClick={(e) => setModalSalvar(true)}
      >
        {campo.map((field, index) => {
          switch (field.type) {
            case "number":
              return (
                <>
                  <div>
                    <p>
                      <b style={{ color: "#808080" }}>{field.description}: </b>
                      <b>{PrimeiraMaiuscula(formData[field.name])}</b>
                    </p>
                  </div>
                </>
              );
            case "text":
              return (
                <>
                  <div>
                    <p>
                      <b style={{ color: "#808080" }}>{field.description}: </b>
                      <b>{PrimeiraMaiuscula(formData[field.name])}</b>
                    </p>
                  </div>
                </>
              );
            case "select":
              return (
                <>
                  {/* <div key={index}
                                style={{
                                    maxWidth: field.maxSize,
                                    marginRight: '10px'
                                }}
                            >
                                {label ? <MyLabel descricao={field.description} /> : null}
                                <select name={field.name} onChange={e => handleChange(field.name, e.target.value)} required={field.obligatory}
                                    style={{ minWidth: field.min, border: '1px solid #cfcfcf' }}>
                                    {field.select.data.map(option => (
                                        <option key={option.codigo} value={option[field.select.feed[0].value]} selected={PrimeiraMaiuscula(field.default) == PrimeiraMaiuscula(option[field.select.feed[0].name])}>
                                            {PrimeiraMaiuscula(option[field.select.feed[0].name])}
                                        </option>
                                    ))}
                                </select>
                            </div> */}
                  <div>
                    <p>
                      <b style={{ color: "#808080" }}>{field.description}: </b>
                      <b>{PrimeiraMaiuscula(Test(field))}</b>
                    </p>
                  </div>
                </>
              );
            case "check":
              return (
                <>
                  {/* <div
                    key={index}
                    style={{
                      marginRight: "10px",
                    }}
                  >
                    {label ? <MyLabel descricao={field.description} /> : null}
                    <input
                      type="checkbox"
                      name={field.name}
                      onChange={(e) =>
                        handleChange(
                          field.name,
                          e.target.checked
                            ? field.check.valueTrue
                            : field.check.valueFalse
                        )
                      }
                      // required={field.obligatory}
                      checked={formData[field.name] == field.check.valueTrue}
                      // value={PrimeiraMaiuscula(formData[field.name] || '')}
                      style={{ marginRight: "10px", transform: "scale(1.5)" }}
                    />
                  </div> */}
                </>
              );
            default:
              return null;
          }
        })}
      </div>
    </form>
  );
};

export default FormularioGenerico;
