import { IconButton } from "@mui/material";
import { FaPlus } from "react-icons/fa";

export function ComponentCadastro({ Titulo, Funcao }) {
  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h1 style={{ fontSize: "1.6rem" }}>
          <b>{Titulo}</b>
        </h1>
        <IconButton color="primary" onClick={Funcao}>
          <FaPlus />
        </IconButton>
      </div>
    </>
  );
}
