import { useEffect, useMemo, useState } from "react";
import { ShowModal } from "../../../../component/modalPai";
import { InputPedido } from "../../../pedido/componente/input";
import { API } from "../../../../controller/api/api";
import { MascaraMonetaria, formatarValor } from "../../../../controller/data";
import {
  MascaraCelular,
  Notifica,
  PrimeiraMaiuscula,
  formatarCPForCNPJ,
} from "../../../../controller/util";
import { CardBusca } from "../card";
import { Button } from "../../../../component/button";
import { FaMoneyBill, FaPen, FaUserPlus } from "react-icons/fa";
import { NovoCliente } from "../../../../component/Cliente";
import { CardRecebimentoFiado } from "../recebimentofiado";
import { IconButton } from "@mui/material";
import Table from "../../../../component/tabela";

let DadosEstatico = [];

export function ComponentBuscaCliente({ Close, Cliente, index, Receber }) {
  const [Busca, setBusca] = useState("");
  const [Data, setData] = useState([]);
  const [AddCliente, setAddCliente] = useState(false);

  const [DadosCliente, setDadosCliente] = useState([]);
  const [ModalFiado, setModalFiado] = useState(false);

  function RetornoInput(description, valor) {
    setBusca(valor);
  }

  useEffect(() => {
    BuscarDados();
  }, [Busca]);

  function BuscarDados() {
    if (Busca.length > 0) {
      API.get(`/v2/consulta/clientes/fiado/${Busca}`).then((response) => {
        setData(response.data);

        DadosEstatico = response.data;
      });
    } else {
      API.get(`/v2/consulta/clientes/fiado`).then((response) => {
        setData(response.data);

        DadosEstatico = response.data;
      });
    }
  }

  function SelecionaCliente(dados) {
    if (Receber != undefined) {
      if (dados.devedor > 0) {
        setDadosCliente(dados);
        setModalFiado(true);
      }
    } else {
      Cliente(dados, index);
      Close();
    }
  }

  function Modal(dados, valor) {
    SelecionaCliente(dados);
  }

  function CloseModal() {
    setModalFiado(false);
    Close();
  }

  const columns = useMemo(
    () => [
      {
        Header: "Nome",
        accessor: "nome",
        size: "100px",
        format: PrimeiraMaiuscula,
      },
      {
        Header: "Documento",
        accessor: "cpf",
        size: "100px",
        format: formatarCPForCNPJ,
        textAlign: "center",
      },
      {
        Header: "Celular",
        accessor: "celular",
        size: "100px",
        textAlign: "center",
        format: MascaraCelular,
        // format: PintaValores,
      },
      {
        Header: "Devedor",
        accessor: "devedor",
        size: "100px",
        textAlign: "center",
        format: MascaraMonetaria,
        // format: PintaValores,
      },
      {
        Header: "Pago",
        accessor: "pago",
        size: "100px",
        textAlign: "center",
        format: MascaraMonetaria,
        // format: PintaValores,
      },

      {
        Header: "Recebimento",
        size: "50px",
        component: (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IconButton color="primary">
              <FaMoneyBill />
            </IconButton>
          </div>
        ),
        func: AlterarDados,
      },
    ],
    []
  );

  function AlterarDados(dados) {
    const objetoEncontrado = DadosEstatico.find(
      (objeto) => objeto.codigo === dados
    );

    if (objetoEncontrado.devedor > 0) {
      Modal(objetoEncontrado, objetoEncontrado.devedor);
    } else {
      Modal(objetoEncontrado, objetoEncontrado.devedor);
    }

    // const Def = DadosEstaticoImpressora.find(
    //   (objeto) => objeto.driver.toUpperCase() === "DEFAULT"
    // );
    // const objetoImpressora = DadosEstaticoImpressora.find(
    //   (objeto) => objeto.driver === objetoEncontrado.driver
    // );
    // const codigoImpressora = objetoImpressora?.id
    //   ? objetoImpressora?.id
    //   : Def.id;
    // setDriver(codigoImpressora ? codigoImpressora : Def?.id);
    // setCodigo(objetoEncontrado.codigo);
    // setDescricao(objetoEncontrado.descricao);
    // setTipo(objetoEncontrado.tipoImpressao);
    // setPadrao(objetoEncontrado.impressoraPadrao);
    // setAtivo(objetoEncontrado.ativo);
    // setIsCadastro(true);
  }

  return (
    <>
      <ShowModal closeButton={Close}>
        <div style={{ width: "65vw" }}>
          <div
            style={{
              display: "flex",
              gap: "10px",
              marginTop: "15px",
              marginBottom: "20px",
            }}
          >
            <InputPedido
              Descricao={"Pesquisar"}
              Valor={Busca}
              Retorno={RetornoInput}
              sx={{ width: "85%" }}
            />

            <Button
              descricao="Cliente"
              icon={<FaUserPlus />}
              borderColor="black"
              fontColor="black"
              evento={() => setAddCliente(!AddCliente)}
              sx={{ width: "15%" }}
            />
          </div>

          <Table
            columns={columns}
            data={Data}
            codigo={"codigo"}
            ocultarPesquisa={true}
          />
          {/* <div
            style={{
              width: "70%",
              border: "1px solid black",
              borderRight: "1px solid transparent",
              borderBottom: "1px solid transparent",
              paddingLeft: "5px",
            }}
          >
            <b>Cliente</b>
          </div>
          <div
            style={{
              width: "15%",
              paddingLeft: "5px",
              borderTop: "1px solid black",
              //   borderLeft: "1px solid black",
              textAlign: "center",
            }}
          >
            <b>Devendo</b>
          </div>
          <div
            style={{
              width: "15%",
              paddingLeft: "5px",
              borderTop: "1px solid black",
              borderRight: "1px solid black",
              textAlign: "center",
            }}
          >
            <b>Pago</b>
          </div>
        </div>
        <div
          style={{ height: "80vh", width: "100%", border: "1px solid black" }}
        >
          {Data.map((dados, index) => (
            <>
              <CardBusca
                nome={dados.nome}
                cpf={dados.cpf}
                celular={dados.celular}
                devedor={dados.devedor}
                pago={dados.pago}
                onClique={() => Modal(dados, dados.devedor)}
              />
            </>
          ))} */}
        </div>
      </ShowModal>
      {ModalFiado ? (
        <CardRecebimentoFiado Close={CloseModal} Dados={DadosCliente} />
      ) : null}

      {AddCliente ? (
        <NovoCliente
          Close={() => (setAddCliente(!AddCliente), BuscarDados())}
        />
      ) : null}
    </>
  );
}
