import React, { useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { MdDragHandle } from "react-icons/md";
import { InputPedido } from "../../../../pedido/componente/input";
import {
  MascaraMonetaria,
  RemoveMascara,
} from "../../../../../controller/data";
import {
  NotificaWarning,
  PrimeiraMaiuscula,
} from "../../../../../controller/util";
import { StatusComponent } from "./status";
import { NovoProduct } from "../../novoproduct";

const TableItem = ({
  item,
  index,
  moveItem,
  UpdateStatus,
  UpdateValue,
  UpdatePosition,
  Tipo,
  OnLoad,
}) => {
  const [isAddProduto, setAddProduto] = useState(false);
  const [hovered, setHovered] = useState(false);

  const Pausar = () => {
    UpdateStatus(index, 0);
  };

  const Ativar = () => {
    UpdateStatus(index, 1);
  };

  const AtualizaProdutoiFood = () => {
    setAddProduto(true);
  };

  const AtualizaProduto = (descricao, valor) => {
    UpdateValue(index, valor);
  };

  const AtualizaPosicao = (descricao, valor) => {
    UpdatePosition(index, valor);
  };

  let foto = "";
  //console.log(item.img);
  // foto = item.img ? atob(item.img) : "";

  if (foto == "") {
    foto = "./img/sem-foto.jpg";
  }

  if (item.ifood_id != undefined || item.ifood_id == "") {
    foto = item.ifood_img;
  }

  if (foto == "") {
    // foto
    foto =
      document.querySelector('link[name="url-foto"]').href +
      "/fotos/" +
      btoa(item.externalCode);
  }

  const [, drop] = useDrop({
    accept: "tableItem",
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveItem(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: "tableItem",
    item: { id: item.id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.5 : 1;

  // function AtualizaProduto(descricao, valor) {
  //   UpdateValue(index, valor);
  // }

  // function AtualizaProduto(descricao, valor) {
  //   UpdateValue(index, valor);
  // }

  // function AtualizaPosicao(descricao, valor) {
  //   UpdatePosition(index, valor);
  // }

  // function Pausar() {
  //   UpdateStatus(index, 0);
  // }

  // function Ativar() {
  //   UpdateStatus(index, 1);
  // }

  // function AtualizaProdutoiFood() {
  //   setAddProduto(true);
  // }

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const DadosiFood = () => {
    ////console.log(Tipo);
    return (
      <>
        {item.ifood_id != "" ? (
          <h3>
            <strong>iFood</strong>{" "}
            <span style={{ color: "rgb(168, 0, 28)" }}>{`${MascaraMonetaria(
              RemoveMascara(item.ifood_value)
            )} `}</span>
            {calcularPercentual(
              RemoveMascara(item.value),
              RemoveMascara(item.ifood_value)
            )}
          </h3>
        ) : null}
      </>
    );
  };

  function calcularPercentual(valor1, valor2) {
    if (valor2 === 0) {
      return "Não é possível calcular o percentual com o segundo valor igual a zero.";
    }

    const percentual = 100 - (valor1 / valor2) * 100;
    return percentual.toFixed(2) + "%";
  }

  function Close() {
    setAddProduto(false);
    OnLoad();
  }

  return (
    <div>
      {isAddProduto ? (
        <>
          <NovoProduct
            tipo={0}
            pizza={Tipo == 1 ? true : false}
            codigo={item.id}
            data={item}
            category={item.category}
            close={Close}
          />
        </>
      ) : null}

      <div style={{ display: "flex" }}>
        <div style={{ width: "100px", display: "flex", alignItems: "center" }}>
          <div style={{ marginRight: "10px", zIndex: 1, textAlign: "center" }}>
            {Tipo == 0 ? (
              <InputPedido
                sx={{ textAlign: "center", fontSize: "0.9rem" }}
                Descricao=""
                Retorno={AtualizaPosicao}
                Valor={item.position}
              />
            ) : null}
          </div>
        </div>
        {/* <div
          ref={(node) => drag(drop(node))}
          style={{ opacity, width: "50px" }}
        >
          <MdDragHandle />
        </div> */}
        <div
          style={{
            width: "100%",
            display: "flex",
            height: "75px",
            padding: "10px",
          }}
        >
          <div
            style={{
              height: "60px",
              width: "60px",
              border: "1px dashed black",
              borderRadius: "5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundImage: `url(${foto})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></div>

          <div
            style={{
              height: "60px",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: "10px",
            }}
          >
            <p
              onClick={AtualizaProdutoiFood}
              style={{
                cursor: "pointer",
                color: hovered ? "#a4001b" : "initial", // Altera a cor quando o mouse estiver sobre o elemento
              }}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <b>{PrimeiraMaiuscula(item.name)}</b>
            </p>
            <DadosiFood />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <span style={{ color: "rgba(0,0,0,0.5)", textAlign: "left" }}>
                <b>PDV {item.id}</b>
              </span>
            </div>
          </div>
        </div>
        <div style={{ width: "200px" }}>
          <div style={{ flex: "0 0 210px", marginRight: "10px", zIndex: 1 }}>
            {Tipo == 0 ? (
              <InputPedido
                Descricao=""
                Retorno={AtualizaProduto}
                Valor={MascaraMonetaria(item.value)}
              />
            ) : null}
          </div>
        </div>
        <div style={{ width: "190px", marginTop: "7px" }}>
          <StatusComponent
            status={item.status}
            Ativar={Ativar}
            Pausar={Pausar}
          />
        </div>
      </div>
    </div>
  );
};

export default TableItem;
