import { useEffect } from "react";
import { Button } from "../../../../component/button";
import { ShowModal } from "../../../../component/modalPai";
import { API } from "../../../../controller/api/api";
import { isMobileDevice } from "../../../../controller/mobile";
import { Notifica } from "../../../../controller/util";

export function ComponentePIX(props) {
  var intervalId;

  function Atualizar() {
    API.post(props.url).then(function (dados) {
      //////console.log(dados.data);

      if (dados.data.transacao > 0) {
        props.PixRecebido(dados.data.transacao);
        clearInterval(intervalId);
        Notifica("PIX Recebido!");
      }
    });
  }

  useEffect(() => {
    intervalId = setInterval(() => {
      Atualizar();
    }, 5000);
  }, []);

  function OK() {
    props.PixRecebido(1);
    clearInterval(intervalId);
    Notifica("PIX Recebido!");
  }

  return (
    <>
      <ShowModal
        largura={isMobileDevice() ? 80 : 30}
        altura={isMobileDevice() ? 52 : 55}
      >
        <div
          style={{
            justifyItems: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h3 style={{ textAlign: "center" }}>
            <strong>Escanei o PIX</strong>
          </h3>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: "transparent",
              marginBottom: "10px",
              height: "250px",
              width: "100%",
            }}
          >
            <img
              src={`data:image/png;base64,${props.base64}`}
              alt="QrCod PIX"
              style={{ height: "250px", width: "250px" }}
            />
          </div>
          <h3 style={{ textAlign: "center" }}>
            <strong>{props.total}</strong>
          </h3>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "10px",
              height: "45px",
              width: "100%",
            }}
          >
            <Button descricao="Atualizar" evento={Atualizar} color="#138138" />
          </div>
          {isMobileDevice() ? null : (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "10px",
                  height: "45px",
                  width: "100%",
                }}
              >
                <Button
                  descricao="Adicionar Pagamento Sem Confirmação"
                  evento={OK}
                  color="rgb(0, 187, 175)"
                />
              </div>
            </>
          )}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "10px",
              height: "45px",
              width: "100%",
            }}
          >
            <Button descricao="Cancelar" evento={props.Cancelar} />
          </div>
        </div>
      </ShowModal>
    </>
  );
}
