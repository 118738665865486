import { FormControl, InputLabel, MenuItem } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { ModalGravarDados } from "../../../../component/gravar";

import { useState } from "react";
import {
  Notifica,
  NotificaWarning,
  PrimeiraMaiuscula,
} from "../../../../controller/util";

export function ModalCancelamentoiFood({
  id,
  closeButton,
  motivo,
  funcaoCancelar,
  Set,
}) {
  const [Motivo, setMotivo] = useState(0);
  function Cancelar() {
    if (Motivo == 0) {
      NotificaWarning("Você deve selecionar o motivo do cancelamento!");
      return false;
    }
    // Set(Motivo);
    funcaoCancelar(Motivo - 1, id);
  }
  return (
    <>
      <ModalGravarDados
        Descricao={"Cancelamento iFood"}
        closeButton={closeButton}
        Funcao={Cancelar}
        DescricaoButton="Cancelar"
      >
        <FormControl fullWidth>
          <InputLabel id="driver">Motivo Cancelamento</InputLabel>
          <Select
            labelId="driver"
            id="driver"
            value={Motivo}
            label="Motivo Cancelamento"
            onChange={(e) => setMotivo(e.target.value)}
            sx={{ width: "100%", marginBottom: "15px" }}
            size="small"
          >
            {motivo.map((item, index) => (
              <MenuItem value={index + 1}>
                {PrimeiraMaiuscula(item.description)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </ModalGravarDados>
    </>
  );
}
