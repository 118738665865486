import { useEffect, useState } from "react";
import { BuscaLocal } from "../../../../../controller/storage";
import { API } from "../../../../../controller/api/api";
import { MascaraMonetaria, formatarData } from "../../../../../controller/data";
import { Notifica } from "../../../../../controller/util";

let PedidosAtual = [];
let DadosPedido = [];

export function PageHomeMotoboy() {
    const [Tipo, setTipo] = useState(0);
    const [active, setActive] = useState(null);
    const [displayValue, setDisplayValue] = useState(''); // Estado para armazenar o valor do display
    const [Pedidos, setPedidos] = useState(PedidosAtual);
    const [DadosPedidos, setDadosPedidos] = useState(DadosPedido);
    const [StatusConexao, setStatusConexao] = useState(false);

    const tecladoNumeros = Array.from({ length: 10 }, (_, index) => index);
    // Mover o número "0" para a última posição do array
    tecladoNumeros.push(tecladoNumeros.splice(tecladoNumeros.indexOf(0), 1)[0]);

    const handleClick = (numero) => {
        setActive(numero);
        // Juntar o valor digitado ao display, incluindo o "0" com tamanho 100%
        setDisplayValue(displayValue === '0' ? String(numero) : displayValue + numero);
    };

    function Limpar() {
        setDisplayValue(0);
    }

    function AdicionarPedido() {
        const temCodigo1 = Pedidos.some(pedido => pedido.codigo === displayValue);
        let motoboy = JSON.parse(BuscaLocal('motoboy'));

        if (!temCodigo1) {
            // Se não tiver, adicionamos ao array
            PedidosAtual.push({ codigo: displayValue, tipo: Tipo, motoboy: motoboy.codigo });
            setPedidos(prevPedidos => [...prevPedidos, { codigo: displayValue, tipo: Tipo, motoboy: motoboy.codigo }]);
        }
        ////console.log(PedidosAtual);
        setDisplayValue('');
        Notifica('Pedido atribuido, basta esperar 5s para atualização.')

    }

    useEffect(() => {

        setInterval(() => {
            Busca();


        }, 5000);

    }, []);



    function Busca() {
        ////console.log(DadosPedidos)
        let motoboy = JSON.parse(BuscaLocal('motoboy'));
        API.get(`/v2/pedidos/motoboy/${motoboy.codigo}`)
            .then(response => {
                DadosPedido = response.data;
                setDadosPedidos(response.data)
                setStatusConexao(true)
                EnviaDados();
            })
            .catch(error => {
                setStatusConexao(false)

            });
    }

    function EnviaDados() {
        ////console.log(PedidosAtual)
        API.post('/v2/pedidos/motoboy', PedidosAtual).then(response => {
            PedidosAtual = [];
        });
    }

    return (<>
        <div style={{ display: 'flex', height: '50px', marginBottom: '10px' }}>
            <div
                onClick={() => (setTipo(0))}
                style={{ width: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', border: `${Tipo == 0 ? '3px solid rgb(168, 0, 28)' : 'none'}`, borderRadius: '5px' }}>
                {/* <img src="../../do-icone-balcao.png" alt="Ícone de balcão" style={{ height: '30px', width: '30px' }} /> */}
                <span style={{ marginLeft: '5px' }}>Entregue</span>
            </div>
            <div
                onClick={() => (setTipo(1))}
                style={{ width: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', border: `${Tipo == 1 ? '3px solid rgb(168, 0, 28)' : 'none'}`, borderRadius: '5px' }}>
                {/* <img src="../../do-icone-localizacao.png" alt="Ícone de localização" style={{ height: '30px', width: '30px' }} /> */}
                <span style={{ marginLeft: '5px' }}>Saindo Para Entregar</span>
            </div>
        </div>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <input
                style={{
                    width: '100%',
                    height: '80px',
                    border: '1px solid #ccc',
                    cursor: 'pointer',
                    transition: 'transform 0.3s ease',
                    textAlign: 'center',
                    // transform: active === numero ? 'scale(1.1)' : 'scale(1)', // Descomente se você estiver usando a variável 'active' e 'numero'
                }}
                type="tel"
                value={displayValue}
                onChange={(e) => setDisplayValue(e.target.value)}
            />


            <div
                style={{
                    width: '100%',
                    height: '80px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: '1px solid #ccc',
                    cursor: 'pointer',
                    transition: 'transform 0.3s ease',
                    // transform: active === numero ? 'scale(1.1)' : 'scale(1)',
                }}
                onClick={AdicionarPedido}
            >
                Adicionar
            </div>

        </div>


        <div style={{ marginTop: '20px', marginBottom: '20px' }}>
            <h3>Segue abaixo os ultimos 7 dias <strong>{StatusConexao ? '(Conectado)' : '(Sem Conexão)'}</strong></h3>
            {DadosPedidos.map((data, index) => (
                <div key={index} style={{ marginTop: '20px', width: '100%', border: '1px solid black', padding: '10px', borderRadius: '20px' }}>
                    <h3>Data: {formatarData(data.dataPedido)}</h3>
                    <h3>Pedidos: {data?.pedidos}</h3>
                    <h3>Taxa: {MascaraMonetaria(data?.taxa)}</h3>
                    <h3>Total: {MascaraMonetaria(data?.total)}</h3>
                </div>
            ))}

        </div>


    </>)
}