import { useState } from "react";

export function ComponentDiaSemana({ Texto, Selected, OnClick }) {
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const Color = hovered
    ? Selected
      ? "rgba(164, 0, 27,0.5)"
      : "#808080"
    : Selected
    ? "#a4001b"
    : "#CCCCCC";
  const FontColor = Selected ? "#FFFFFF" : "black";

  return (
    <>
      <strong
        style={{
          borderRadius: "20px",
          backgroundColor: Color,
          paddingLeft: "6px",
          paddingRight: "6px",
          color: FontColor,
          cursor: "pointer",
          transform: hovered ? "scale(1.1)" : "scale(1)",
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={OnClick}
      >
        {Texto}
      </strong>
    </>
  );
}
