import React, { useState } from "react";
import { FaLayerGroup } from "react-icons/fa";

function IconeFiltro({ onClick }) {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const rotationAngle = isHovered ? "rotate(90deg)" : "rotate(0)";
  const Transform = isHovered ? "scale(1.3)" : "scale(1)";

  return (
    <FaLayerGroup
      style={{
        cursor: "pointer",
        color: "black",
        transform: `${Transform} ${rotationAngle}`,
      }}
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    />
  );
}

export default IconeFiltro;
