import CardLegenda from "../../component/cardlegenda";
import { HeaderComponent } from "../../component/header";
import ApexChart from "react-apexcharts";
import {
  AbrirCaixa,
  AbrirCaixaAutomatico,
  FecharCaixa,
  FecharPedidosCaixa,
  MascaraMonetaria,
  RemoveMascara,
  getCaixa,
  getPedidosPeriodoCaixa,
  getTipoPagamentoCaixa,
  getTotalizadores,
} from "../../controller/data";
import { React, useEffect, useRef, useState } from "react";

import { ChartBar } from "../../component/grafic/bar";
import { Button } from "../../component/button";
import { NotificaWarning, PrimeiraMaiuscula } from "../../controller/util";
import { API } from "../../controller/api/api";
import { ParametroConfiguracao } from "../../controller/parametros";
import { ComponentBuscaCliente } from "../fechamento/component/busca";
import LoadingOverlay from "../../component/load";
import { ModalSangria } from "../../component/sangria";
import { ShowModal } from "../../component/modalPai";
import { SangriaCaixa } from "./component/sangria";
import {
  FaLongArrowAltDown,
  FaLongArrowAltUp,
  FaSortAmountUpAlt,
} from "react-icons/fa";
import { Movimentacoes } from "./component/movimentacao";
import { InputPedido } from "../pedido/componente/input";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";

let DadosTipoPagamentoComputado = [];

export function CaixaPage() {
  const [NumeroCaixa, setNumeroCaixa] = useState(0);
  const [ValueAbrirCaixa, setValueAbrirCaixa] = useState(0);
  const [TotalConcluido, setTotalConcluido] = useState(0);
  const [QtdConcluido, setQtdConcluido] = useState(0);
  const [TotalAberto, setTotalAberto] = useState(0);
  const [QtdAberto, setQtdAberto] = useState(0);
  const [Desconto, setDesconto] = useState(0);
  const [Taxa, setTaxa] = useState(0);
  const [Total, setTotal] = useState(0);
  const [TotalSangria, setTotalSangria] = useState(0);

  const [DadosTipoPagamento, setDadosTipoPagamento] = useState([]);
  const [TipoPagamentoComputado, setTipoPagamentoComputado] = useState([]);

  const [selectedItem, setSelectedItem] = useState(0);
  const [valueInputValor, setValueInputValor] = useState(0);

  const [ReceberFiado, setReceberFiado] = useState(false);
  const [loading, setloading] = useState(true);
  const [Sangria, setSangria] = useState(false);

  const [modalSangria, setmodalSangria] = useState(false);
  const [ModalMovimentacao, setModalMovimentacao] = useState(false);

  const handleSelectItem = (index) => {
    setSelectedItem(index);

    // Atualizar o input com o total
    const total = DadosTipoPagamentoComputado[index].total;

    setValueInputValor(total);
  };

  const handleAdicionaPagamento = () => {
    DadosTipoPagamentoComputado[selectedItem].total = parseFloat(
      RemoveMascara(valueInputValor)
    );
    setloading(true);
    BuscarDados()
      .then(() => {
        setloading(false); // Após a conclusão bem-sucedida da busca de dados
      })
      .catch((error) => {
        console.error("Erro ao buscar dados:", error);
        setloading(false); // Se ocorrer um erro, também precisamos parar o loading
      });
  };

  function fetchData() {
    setloading(true);
    AbrirCaixaAutomatico()
      .then((result) => {
        setNumeroCaixa(result.id);
      })
      .catch((error) => {});
  }

  useEffect(() => {
    // AbrirCaixaAutomatico()
    fetchData();
  }, []);

  function FechaCaixa() {
    let body = [];

    var informado = 0;

    DadosTipoPagamentoComputado.forEach((item, index) => {
      body.push({
        idTipoPagamento: item.idTipoPagamento,
        descricao: item.descricao,
        informado: item.total,
        // total: DadosTipoPagamento[index].total
        //   ? DadosTipoPagamento[index].total
        //   : 0,
      });
      informado += item.total;
    });

    if (informado == 0) {
      NotificaWarning("Seu Caixa Não Pode Ser Finalizado Zerado!");
      return false;
    }
    setloading(true);

    API.post(`/v1/caixa/fechamento/${NumeroCaixa}`, body).then((result) => {
      fetchImpressao();
    });
  }

  const fetchImpressao = async () => {
    //Resumo
    if ((await ParametroConfiguracao("impCaixaResumo")) == 1) {
      API.post(`/v1/imprimir/3/${NumeroCaixa}`);
    }
    //Completo
    if ((await ParametroConfiguracao("impCaixaCompleto")) == 1) {
      API.post(`/v1/imprimir/4/${NumeroCaixa}`);
    }
    //Produto
    if ((await ParametroConfiguracao("impCaixaProduto")) == 1) {
      API.post(`/v1/imprimir/5/${NumeroCaixa}`);
    }
    //Motoboy
    if ((await ParametroConfiguracao("impCaixaMotoboy")) == 1) {
      API.post(`/v1/imprimir/6/${NumeroCaixa}`);
    }
    setInterval(() => {
      window.location.reload();
    }, 1500);
  };

  useEffect(() => {
    BuscarDados()
      .then(() => {
        setloading(false); // Após a conclusão bem-sucedida da busca de dados
      })
      .catch((error) => {
        console.error("Erro ao buscar dados:", error);
        setloading(false); // Se ocorrer um erro, também precisamos parar o loading
      });
  }, [NumeroCaixa]);

  // function BuscarDados() {
  //   return new Promise((resolve, reject) => {
  //   getPedidosPeriodoCaixa(NumeroCaixa).then((result) => {

  //       let total = getTotalizadores(result);

  //       setTotalConcluido(total[0]["valorPedido"]);
  //       setQtdConcluido(total[0]["pedidos"]);

  //       setTotalAberto(total[1]["valorPedido"]);
  //       setQtdAberto(total[1]["pedidos"]);
  //       setDesconto(total[0]["Desconto"] + total[1]["Desconto"]);
  //       setTaxa(total[0]["TaxaEntrega"] + total[1]["TaxaEntrega"]);
  //       setTotal(total[0]["TotalPedido"] + total[1]["TotalPedido"]);
  //       setTotalSangria(result[0]?.sangria ? result[0]?.sangria : 0);
  //     });

  //     getTipoPagamentoCaixa(NumeroCaixa).then((result) => {
  //       let data = result;

  //       result.forEach((item) => {
  //         const exists = DadosTipoPagamentoComputado.some(
  //           (existingItem) =>
  //             existingItem.idTipoPagamento === item.idTipoPagamento
  //         );

  //         if (!exists) {
  //           DadosTipoPagamentoComputado.push({
  //             descricao: item.descricao,
  //             total: 0,
  //             idTipoPagamento: item.idTipoPagamento,
  //           });
  //         }
  //       });

  //       DadosTipoPagamentoComputado.sort(
  //         (a, b) => a.idTipoPagamento - b.idTipoPagamento
  //       );
  //       data.sort((a, b) => a.idTipoPagamento - b.idTipoPagamento);

  //       setTipoPagamentoComputado(DadosTipoPagamentoComputado);
  //       setDadosTipoPagamento(data);
  //     });
  //     resolve(); // ou reject() se houver erro
  //   });
  // }
  function BuscarDados() {
    return new Promise((resolve, reject) => {
      Promise.all([
        getPedidosPeriodoCaixa(NumeroCaixa),
        getTipoPagamentoCaixa(NumeroCaixa),
      ])
        .then(([pedidosResult, tipoPagamentoResult]) => {
          let total = getTotalizadores(pedidosResult);

          setTotalConcluido(total[0]["valorPedido"]);
          setQtdConcluido(total[0]["pedidos"]);

          setTotalAberto(total[1]["valorPedido"]);
          setQtdAberto(total[1]["pedidos"]);
          setDesconto(total[0]["Desconto"] + total[1]["Desconto"]);
          setTaxa(total[0]["TaxaEntrega"] + total[1]["TaxaEntrega"]);
          setTotal(total[0]["TotalPedido"] + total[1]["TotalPedido"]);
          setTotalSangria(
            pedidosResult[0]?.sangria ? pedidosResult[0]?.sangria : 0
          );

          let data = tipoPagamentoResult;

          tipoPagamentoResult.forEach((item) => {
            const exists = DadosTipoPagamentoComputado.some(
              (existingItem) =>
                existingItem.idTipoPagamento === item.idTipoPagamento
            );

            if (!exists) {
              DadosTipoPagamentoComputado.push({
                descricao: item.descricao,
                total: 0,
                idTipoPagamento: item.idTipoPagamento,
              });
            }
          });

          DadosTipoPagamentoComputado.sort(
            (a, b) => a.idTipoPagamento - b.idTipoPagamento
          );
          data.sort((a, b) => a.idTipoPagamento - b.idTipoPagamento);

          setTipoPagamentoComputado(DadosTipoPagamentoComputado);
          setDadosTipoPagamento(data);

          resolve(); // ou reject() se houver erro
        })
        .catch((error) => {
          reject(error); // Se houver um erro em qualquer uma das Promises
        });
    });
  }

  function FecharPedidos() {
    //FecharPedidosCaixa
    setloading(true);
    FecharPedidosCaixa(NumeroCaixa).then((result) => {
      BuscarDados();
    });
  }

  function Operacional() {
    let tot = 0;
    DadosTipoPagamento.forEach((item) => {
      tot += item.total;
    });

    return parseFloat(tot);
  }

  function Computado() {
    let tot = 0;
    DadosTipoPagamentoComputado.forEach((item) => {
      tot += item.total;
    });
    return parseFloat(tot) + parseFloat(TotalSangria);
  }

  function Diferenca() {
    let tot = Computado() - Operacional();
    return tot;
  }

  function handleAbrirCaixa() {
    setloading(true);
    AbrirCaixa(RemoveMascara(ValueAbrirCaixa)).then((result) => {
      fetchData();
    });
  }

  function showModalMovimentacao() {
    setModalMovimentacao(!ModalMovimentacao);
    BuscarDados();
  }

  function FechaModalFiado() {
    setReceberFiado(!ReceberFiado);
    BuscarDados();
  }

  function FecharModalSangria() {
    setSangria(!Sangria);
    BuscarDados();
  }

  const state = {
    series: [TotalConcluido, TotalAberto, Desconto, Taxa, TotalSangria],
    options: {
      chart: {
        type: "pie",
      },
      tooltip: {
        y: {
          formatter: (value) => `${MascaraMonetaria(value)}`,
        },
      },
      labels: [
        "Concluido",
        "Em Aberto",
        "Desconto",
        "Taxa",
        // "Total",
        "Sangria",
      ],
      colors: [
        "rgb(102, 205, 170)",
        "rgb(168, 0, 28)",
        "rgb(231, 0, 38)",
        "rgb(40, 5, 117)",
        // "rgb(40, 5, 117)",
        "rgb(40, 5, 117)",
      ], // Personalize as cores aqui
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };

  return (
    <>
      {loading && <LoadingOverlay />}
      <HeaderComponent>
        {/* {isMobileDevice() ? <MobilePage/> : <DesktopPage/>}  */}
        {NumeroCaixa > 0 ? (
          <>
            <h1 style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
              Caixa Nº
              <span style={{ color: "rgb(168, 0, 28)" }}>{NumeroCaixa}</span>
            </h1>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              {/* <ApexChart
                options={state.options}
                series={state.series}
                type="pie"
                width={380}
              /> */}
              <CardLegenda
                Titulo={MascaraMonetaria(TotalConcluido)}
                SubTitulo={QtdConcluido}
                Legenda="Concluido"
                Color="102, 205, 170"
                FontColor="#ffffff"
              />
              <CardLegenda
                Titulo={MascaraMonetaria(TotalAberto)}
                SubTitulo={QtdAberto}
                Legenda="Em Aberto"
                Color="168, 0, 28"
                FontColor="#ffffff"
                Click={FecharPedidos}
              />

              <CardLegenda
                Titulo={MascaraMonetaria(Desconto)}
                SubTitulo=""
                Legenda="Desconto"
                Color="231, 0, 38"
                FontColor="#ffffff"
              />
              <CardLegenda
                Titulo={MascaraMonetaria(Taxa)}
                SubTitulo=""
                Legenda="Taxa"
                Color="40, 5, 117"
                FontColor="#ffffff"
              />
              <CardLegenda
                Titulo={MascaraMonetaria(Total)}
                SubTitulo=""
                Legenda="Total"
                Color="40, 5, 117"
                FontColor="#ffffff"
              />
              <CardLegenda
                Titulo={MascaraMonetaria(TotalSangria)}
                SubTitulo=""
                Legenda="Sangria"
                Color="40, 5, 117"
                FontColor="#ffffff"
                Click={() => setmodalSangria(true)}
              />
            </div>
            <div
              style={{
                display: "flex",
                gap: "10px",
                marginBottom: "15px",
              }}
            >
              <Button
                descricao="Sangria"
                evento={FecharModalSangria}
                borderColor="#A8001C"
                fontColor="#A8001C"
                BorderRadius={"5px"}
                icon={<FaLongArrowAltDown />}
                sx={{ width: "33.33%" }}
              />
              <Button
                descricao="Recebimento Fiado"
                evento={FechaModalFiado}
                borderColor="#67B86F"
                fontColor="#67B86F"
                BorderRadius={"5px"}
                icon={<FaLongArrowAltUp />}
                sx={{ width: "33.33%" }}
              />
              <Button
                descricao="Movimentações"
                evento={showModalMovimentacao}
                borderColor="#808080"
                fontColor="#808080"
                BorderRadius={"5px"}
                icon={<FaSortAmountUpAlt />}
                sx={{ width: "33.33%" }}
              />
            </div>

            {ReceberFiado ? (
              <ComponentBuscaCliente Close={FechaModalFiado} Receber={true} />
            ) : null}

            {Sangria ? <ModalSangria Close={FecharModalSangria} /> : null}

            {TipoPagamentoComputado.length > 0 ? (
              <>
                <div
                  style={{ display: "flex", gap: "10px", marginBottom: "15px" }}
                >
                  <FormControl sx={{ width: "33.33%" }}>
                    <InputLabel id="pagamento">Tipo de Pagamento</InputLabel>
                    <Select
                      labelId="pagamento"
                      id="pagamento"
                      value={selectedItem}
                      label="Tipo de Pagamento"
                      onChange={(e) => handleSelectItem(e.target.value)}
                      size="small"
                    >
                      {TipoPagamentoComputado.map((item, index) => (
                        <MenuItem key={index} value={index}>
                          {PrimeiraMaiuscula(item.descricao)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <InputPedido
                    Descricao="Valor R$"
                    Valor={MascaraMonetaria(RemoveMascara(valueInputValor))}
                    Set={setValueInputValor}
                    sx={{ width: "33.33%" }}
                  />
                  <Button
                    descricao="Adicionar"
                    evento={handleAdicionaPagamento}
                    color="#A8001C"
                    borderColor="#A8001C"
                    fontColor="#A8001C"
                    sx={{ width: "33.33%" }}
                  />
                </div>

                <div
                  style={{ display: "flex", gap: "10px", marginBottom: "15px" }}
                >
                  <InputPedido
                    Descricao="Computado"
                    Valor={MascaraMonetaria(RemoveMascara(Operacional()))}
                    // Set={setValueAbrirCaixa}
                    sx={{ width: "100%" }}
                  />
                  <InputPedido
                    Descricao="Operacional"
                    Valor={MascaraMonetaria(RemoveMascara(Computado()))}
                    // Set={setValueAbrirCaixa}
                    sx={{ width: "100%" }}
                  />
                  <InputPedido
                    Descricao="Diferença"
                    Valor={MascaraMonetaria(RemoveMascara(Diferenca()))}
                    Set={setValueAbrirCaixa}
                    sx={{ width: "100%" }}
                  />
                </div>
                {/* 
                <CardLegenda
                  Titulo={MascaraMonetaria(Operacional())}
                  SubTitulo=""
                  Legenda="Computado"
                  Color="0, 143, 251"
                />
                <CardLegenda
                  Titulo={MascaraMonetaria(Computado())}
                  SubTitulo=""
                  Legenda="Operacional"
                  Color="0, 227, 150"
                />
                <CardLegenda
                  Titulo={MascaraMonetaria(Diferenca())}
                  SubTitulo=""
                  Legenda="Difrença"
                  Color={Diferenca() > 0 ? "28, 165, 3" : "255, 81, 81"}
                /> */}

                <ChartBar
                  data={DadosTipoPagamento}
                  operacional={TipoPagamentoComputado}
                />

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    // background: "#A8001C",
                    marginBottom: "10px",
                    height: "45px",
                    width: "100%",
                    padding: "10px",
                  }}
                >
                  <Button
                    descricao="Fechar o Caixa"
                    evento={FechaCaixa}
                    borderColor="#A8001C"
                    fontColor="#A8001C"
                  />
                </div>
              </>
            ) : null}
          </>
        ) : (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "90vh",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "80%",
                  maxWidth: 400, // Defina o tamanho máximo para evitar que o conteúdo fique muito largo
                  padding: 3,
                  borderRadius: 1,
                  bgcolor: "background.paper",
                  boxShadow: 3, // Adiciona uma sombra suave
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src="/logo-goopedir-p.png"
                  alt="Ícone"
                  style={{ width: "80%", height: "50%", marginBottom: 20 }}
                />
                <InputPedido
                  Descricao="Valor Abertura De Caixa"
                  Valor={MascaraMonetaria(RemoveMascara(ValueAbrirCaixa))}
                  Set={setValueAbrirCaixa}
                  sx={{ marginBottom: "15px", width: "100%" }}
                />
                <Button
                  descricao={"Abrir Caixa"}
                  variant="contained"
                  evento={handleAbrirCaixa}
                />
              </Box>
            </Box>
            {/* <InputPedido
              Descricao="Valor Abertura De Caixa"
              Valor={MascaraMonetaria(RemoveMascara(ValueAbrirCaixa))}
              Set={setValueAbrirCaixa}
              sx={{ marginBottom: "15px", width: "100%" }}
            />

            <Button
              descricao="Abrir Caixa"
              evento={handleAbrirCaixa}
              borderColor="#138138"
              fontColor="#138138"
            /> */}
          </>
        )}
        {modalSangria ? (
          <>
            <ShowModal closeButton={() => setmodalSangria(false)}>
              <div style={{ width: "30vw", padding: "10px" }}>
                <SangriaCaixa
                  id={NumeroCaixa}
                  estorno={true}
                  impressao={true}
                  atualiza={() => [BuscarDados(), setmodalSangria(false)]}
                />
              </div>
            </ShowModal>
          </>
        ) : null}
        {ModalMovimentacao ? (
          <>
            <ShowModal closeButton={showModalMovimentacao}>
              <div style={{ width: "98vw", padding: "10px" }}>
                <Movimentacoes id={NumeroCaixa} estorno={true} />
              </div>
            </ShowModal>
          </>
        ) : null}
      </HeaderComponent>
    </>
  );
}
