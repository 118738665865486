import { useState } from "react";
import { ShowModal } from "../modalPai";
import { InputPedido } from "../../page/pedido/componente/input";
import { Button } from "../button";
import { ParametroConfiguracao } from "../../controller/parametros";
import { API } from "../../controller/api/api";
import { Notifica, NotificaWarning } from "../../controller/util";

export function SenhaCancelamentoPedido({ pedido, close, cancelar, off }) {
  const [MotivoCancelamento, setMotivoCancelamento] = useState("");
  const [SenhaAdiministrador, setSenhaAdiministrador] = useState("");

  async function CancelarPedido() {
    let servico = await ParametroConfiguracao("taxaServicoPedido");
  }

  function CancelarPedido() {
    cancelar();
    let body = {
      motivo: MotivoCancelamento,
      senha: SenhaAdiministrador,
      codigo: pedido,
    };
    API.post("/v2/cancelar/pedido", body).then((response) => {
      let data = response.data;
      if (data.status) {
        Notifica(data.motivo);
        off();
        close();
      } else {
        NotificaWarning(data.motivo);
        off();
      }
    });
  }

  return (
    <>
      <ShowModal closeButton={close}>
        <div
          style={{ width: "20vw", textAlign: "center", marginBottom: "15px" }}
        >
          <h1>
            <b>Informe a Senha e o Motivo do Cancelamento</b>
          </h1>
        </div>
        <div>
          <h6 style={{ fontSize: "0.8rem" }}>Motivo Cancelamento</h6>
          <InputPedido
            Set={setMotivoCancelamento}
            Change={setMotivoCancelamento}
            Valor={MotivoCancelamento}
          />
          <h6 style={{ fontSize: "0.8rem" }}>Senha Adiministrador</h6>
          <InputPedido
            Set={setSenhaAdiministrador}
            Change={setSenhaAdiministrador}
            Valor={SenhaAdiministrador}
            Tipo={"password"}
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              marginTop: "10px",
            }}
          >
            <Button
              descricao="Cancelar Pedido"
              evento={CancelarPedido}
              color="#A8001C"
              borderColor="#A8001C"
              fontColor="#A8001C"
              BorderRadius="5px"
            />
          </div>
        </div>
      </ShowModal>
    </>
  );
}
