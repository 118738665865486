import ReactApexChart from "react-apexcharts";
import { MascaraMonetaria } from "../../../controller/data";
import { PrimeiraMaiuscula } from "../../../controller/util";

// export function  ChartBar(props){
//   // //////console.log(props);

//   const generateRandomColor = () => {
//     const letters = '0123456789ABCDEF';
//     let color = '#';
//     for (let i = 0; i < 6; i++) {
//       color += letters[Math.floor(Math.random() * 16)];
//     }
//     return color;
//   };

//   const options = {
//     chart: {
//       id: 'bar-chart',
//       toolbar: {
//         show: false,
//       },
//     },
//     xaxis: {
//       categories: props.data.map((item) => PrimeiraMaiuscula(item.descricao)),
//     },
//     tooltip: {
//       y: {
//         formatter: (value) => `Total ${MascaraMonetaria(value)}`,
//       },
//     },
//     plotOptions: {
//       bar: {
//         distributed: true,
//         colors: {
//           ranges: [
//             {
//               from: 0,
//               to: 0,
//               color: generateRandomColor(),
//             },
//           ],
//         },
//         dataLabels: {
//           position: 'none', // Exibe o valor no topo da barra
//           formatter: (val) => MascaraMonetaria(val), // Formata o valor exibido
//         },
//       },
//     },
//   };

//   const series = [
//     {
//       name: 'Computado',
//       data: props.data.map(item => item.total),
//     },
//     {
//         //computado
//       name: 'Operacional',
//       data: props.operacional.map(item => item.total),
//     },
//   ];

//   const plotOptions = {
//     bar: {
//       distributed: true,
//       colors: {
//         ranges: [
//           {
//             from: 0,
//             to: 0,
//             color: generateRandomColor(),
//           },
//         ],
//       },
//     },
//   };

//   const handleClick = ({ seriesIndex, dataPointIndex }) => {
//     // const idTipoPagamento = data[dataPointIndex].idTipoPagamento;
//     //////console.log('idTipoPagamento:', seriesIndex);
//   };

// return(<>
// {/* <ApexCharts options={options} series={series}/> */}
//   {/* <ReactApexChart options={options} series={series} type="line" height={350} /> */}
//     <ReactApexChart
//       options={options}
//       series={series}
//       type="bar"
//       height={450}
//       plotOptions={plotOptions}
//       onClick={handleClick}
//     />

// </>)
// }

export function ChartBar(props) {
  const options = {
    chart: {
      id: "bar-chart",
      toolbar: {
        show: false,
      },
    },
    yaxis: [
      {
        labels: {
          formatter: function (value) {
            // Formatar o valor para mostrar apenas duas casas decimais
            return `${MascaraMonetaria(parseFloat(value).toFixed(2))}`;
          },
        },
      },
    ],
    xaxis: {
      categories: props.data.map((item) => PrimeiraMaiuscula(item.descricao)),
    },
    tooltip: {
      y: {
        formatter: (value) => `Total ${MascaraMonetaria(value)}`,
      },
    },
    plotOptions: {
      bar: {
        distributed: true,
      },
    },
    // colors: ['#FF5733', '#3357FF'],
  };

  const series = [
    {
      name: "Computado",
      data: props.data.map((item) => parseFloat(item.total).toFixed(2)),
      // color: '#FF5733'  // cor para a série "Computado"
    },
    {
      name: "Operacional",
      data: props.operacional.map((item) => parseFloat(item.total).toFixed(2)),
      color: "#3357FF", // cor para a série "Operacional"
    },
  ];

  const handleClick = ({ seriesIndex, dataPointIndex }) => {
    //////console.log('idTipoPagamento:', seriesIndex);
  };

  //console.log(series);

  return (
    <>
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={450}
        onClick={handleClick}
      />
    </>
  );
}
