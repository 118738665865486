import { ComponentComanda } from "../../component/comanda";
import { HeaderComponent } from "../../component/header";
export function PageComanda() {
  return (
    <>
      <HeaderComponent>
        <ComponentComanda comanda={0} />
      </HeaderComponent>
    </>
  );
}
