import { useEffect, useState } from "react";
import { HeaderComponent } from "../../component/header";

import { CardCupom } from "./component/card";
import "./index.css";
import { API } from "../../controller/api/api";
import { ShowModal } from "../../component/modalPai";
import { InputPedido } from "../pedido/componente/input";
import {
  DataAtual,
  MascaraMonetaria,
  RemoveMascara,
  formatarValor,
} from "../../controller/data";
import { Notifica, NotificaWarning } from "../../controller/util";
import { Button } from "../../component/button";
import { FaGifts } from "react-icons/fa";
import { ComponentCadastro } from "../../component/header/cadastro";
import { ModalGravarDados } from "../../component/gravar";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormControlLabel,
  Switch,
} from "@mui/material";

export function CupomDesconto() {
  const [Data, setData] = useState([]);
  const [CodigoCupom, setCodigoCupom] = useState(0);
  const [Cupom, setCupom] = useState("");
  const [QuantidadeCupom, setQuantidadeCupom] = useState(0);
  const [ExpiracaoCupom, setExpiracaoCupom] = useState(DataAtual());
  const [TipoCupom, setTipoCupom] = useState(2);
  const [ValorCupom, setValorCupom] = useState(0);
  const [PedidoMinimoCupom, setPedidoMinimoCupom] = useState(0);
  const [PrimeiraCompra, setPrimeiraCompra] = useState(0);

  const [ModalCupom, setModalCupom] = useState(false);

  useEffect(() => {
    API.get("v2/cupom/desconto/site").then(function (data) {
      if (data.data != null) {
        setData(data.data);
      }
    });
  }, []);

  function SalvarDados() {
    if (Cupom.length < 5) {
      NotificaWarning("Cupom deve ter no mínimo 5 caracteres!");
      return false;
    }

    if (ValorCupom <= 0) {
      NotificaWarning("Valor do cupom invalido!");
      return false;
    }

    let body = {
      codigo: CodigoCupom,
      cupom: Cupom.toUpperCase(),
      quantidade: parseInt(QuantidadeCupom),
      data: ExpiracaoCupom,
      tipo: parseInt(TipoCupom),
      valor: TipoCupom == 2 ? RemoveMascara(ValorCupom) : 0,
      percentual: TipoCupom == 1 ? RemoveMascara(ValorCupom) : 0,
      min: RemoveMascara(PedidoMinimoCupom),
      primeira: PrimeiraCompra ? 1 : 0,
    };

    API.post("v2/cupom/desconto/site", body).then(function (data) {
      setData(data.data);
      Notifica("Cupom salvo com sucesso!");
      setModalCupom(false);
    });
  }

  function ClickEdicao(dados) {
    setCodigoCupom(dados.id_cupom);
    setCupom(dados.ativacao.toUpperCase());
    setQuantidadeCupom(RemoveMascara(dados.total_vezes));
    setExpiracaoCupom(dados.data_validade);
    setTipoCupom(dados.type_discount);
    setValorCupom(
      dados.type_discount == 2
        ? RemoveMascara(dados.fixed_value)
        : RemoveMascara(dados.porcentagem)
    );
    setPedidoMinimoCupom(MascaraMonetaria(RemoveMascara(dados.valor_min)));
    setModalCupom(true);
  }

  function Novo() {
    setCodigoCupom(0);
    setCupom("");
    setQuantidadeCupom(0);
    setExpiracaoCupom(DataAtual());
    setTipoCupom(2);
    setValorCupom(0);
    setPedidoMinimoCupom(MascaraMonetaria(RemoveMascara(0)));
    setModalCupom(true);
  }

  return (
    <>
      <HeaderComponent>
        <ComponentCadastro Titulo="Cupom" Funcao={Novo} />
        <div
          style={{
            padding: "10px",
            display: "flex",
            gap: "10px",
            flexWrap: "wrap", // Esta linha permite que os itens se movam para a próxima linha conforme necessário
            justifyContent: "center",
            width: "100%",
          }}
        >
          {Data.map((dados, index) => (
            <CardCupom dados={dados} index={index} click={ClickEdicao} />
          ))}
        </div>
        {ModalCupom ? (
          <>
            <ModalGravarDados
              Descricao={CodigoCupom == 0 ? "Cadastro" : "Alteração"}
              closeButton={() => setModalCupom(false)}
              Funcao={SalvarDados}
            >
              <InputPedido
                Descricao="Cupom"
                Set={setCupom}
                Valor={Cupom}
                sx={{ marginBottom: "15px" }}
              />
              <InputPedido
                Descricao="Quantidade Disponivel"
                Set={setQuantidadeCupom}
                Valor={QuantidadeCupom}
                sx={{ marginBottom: "15px" }}
              />
              <InputPedido
                Descricao="Expiração"
                Tipo="date"
                Set={setExpiracaoCupom}
                Valor={ExpiracaoCupom}
                sx={{ marginBottom: "15px" }}
              />
              <FormControl style={{ width: "100%", marginBottom: "10px" }}>
                <InputLabel>Tipo</InputLabel>
                <Select
                  label="Tipo"
                  size="small"
                  value={TipoCupom}
                  onChange={(e) => setTipoCupom(e.target.value)}
                  sx={{ marginBottom: "15px" }}
                >
                  <MenuItem value={1}>Percentual</MenuItem>
                  <MenuItem value={2}>Fixo</MenuItem>
                </Select>
              </FormControl>

              <InputPedido
                Descricao={TipoCupom == 1 ? "Valor %" : "Valor R$"}
                Set={setValorCupom}
                Valor={formatarValor(RemoveMascara(ValorCupom))}
                sx={{ marginBottom: "15px" }}
              />
              <InputPedido
                Descricao="Pedido Minimo"
                Set={setPedidoMinimoCupom}
                Valor={MascaraMonetaria(RemoveMascara(PedidoMinimoCupom))}
                sx={{ marginBottom: "15px" }}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={PrimeiraCompra}
                    onChange={() => setPrimeiraCompra(!PrimeiraCompra)}
                  />
                }
                label={"Mostrar Como Primeira Compra"}
              />
            </ModalGravarDados>
          </>
        ) : null}
      </HeaderComponent>
    </>
  );
}
