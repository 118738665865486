import { NewButton } from "./newButtom";
import "./styles.css";
import { useState } from "react";

export function Button({
  descricao,
  color,
  evento,
  load,
  tempo,
  borderColor,
  fontColor,
  BorderRadius,
  icon,
  heigth,
  sx,
  disabled,
}) {
  let time = tempo;
  let carrega = load;
  if (load === undefined) {
    carrega = true;
  }

  if (tempo === undefined) {
    time = 1000;
  }

  const [isClicked, setIsClicked] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    document.body.style.cursor = "pointer";
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    document.body.style.cursor = "default";
    setIsHovered(false);
  };

  const handleClick = () => {
    if (isClicked) {
      return;
    }

    setIsClicked(true);
    evento();
    setIsClicked(false);
  };

  return (
    <>
      <NewButton
        sx={sx}
        Descricao={descricao}
        Icone={icon}
        Color={borderColor}
        Funcao={handleClick}
        disabled={disabled ? disabled : false}
      />
      {/* <div
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          // background: isHovered ? "red" : color, // Altera a cor de fundo quando o mouse passa por cima
          // transform: isHovered ? "scale(1.02)" : "scale(1)",

          width: "100%",
          height: heigth ? heigth : "",
          border: `3px solid ${
            borderColor != undefined ? borderColor : "transparent"
          }`,
          borderRadius: BorderRadius,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: `${fontColor != undefined ? fontColor : "white"}`,
          }}
        >
          {icon ? (
            <>
              <span style={{ marginRight: "10px" }}>{icon}</span>
            </>
          ) : null}
          {descricao}
        </div>
      </div> */}
    </>
  );
}
