import FormularioGenerico, { TelaGenerica } from "../../component/generico";
import { React, useEffect, useMemo, useRef, useState } from "react";
import { API } from "../../controller/api/api";
import { HeaderComponent } from "../../component/header";
import {
  MascaraCelular,
  NotificaWarning,
  PrimeiraMaiuscula,
  SwitchPadraoTabela,
} from "../../controller/util";
import { FaPen, FaSave } from "react-icons/fa";
import { ComponentCadastro } from "../../component/header/cadastro";
import { FormControlLabel, FormGroup, IconButton, Switch } from "@mui/material";
import Table from "../../component/tabela";
import { RemoveMascara } from "../../controller/data";
import { ModalGravarDados } from "../../component/gravar";
import { InputPedido } from "../pedido/componente/input";
import LoadingOverlay from "../../component/load";

let DadosEstatico = [];
export function PageMotoboy() {
  const [Motoboy, setMotoboy] = useState([]);
  const [loading, setLoading] = useState(false);

  const [isCadastro, setIsCadastro] = useState(true);
  const [Codigo, setCodigo] = useState(0);
  const [Nome, setNome] = useState("");
  const [Celular, setCelular] = useState("");
  const [Ativo, setAtivo] = useState(1);

  useEffect(() => {
    BuscarDados();
  }, []);

  function BuscarDados() {
    setIsCadastro(false);
    API.get("/v1/consulta/generica/motoboy/*/*/nome").then(function (data) {
      setMotoboy(data.data);
      DadosEstatico = data.data;
      setLoading(false);
    });
  }

  const columns = useMemo(
    () => [
      {
        Header: "Nome",
        accessor: "nome",
        size: "100px",
        format: PrimeiraMaiuscula,
      },
      {
        Header: "Celular",
        accessor: "celularWpp",
        size: "50px",
        textAlign: "center",
        format: MascaraCelular,
      },
      {
        Header: "Ativo",
        accessor: "ativo",
        size: "100px",
        format: SwitchPadraoTabela,
      },
      {
        Header: "Alterar",
        size: "50px",
        component: (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IconButton color="primary">
              <FaPen />
            </IconButton>
          </div>
        ),
        func: AlterarDados,
      },
    ],
    []
  );

  function AlterarDados(dados) {
    //DadosEstatico
    const objetoEncontrado = DadosEstatico.find(
      (objeto) => objeto.codigo === dados
    );

    setCodigo(objetoEncontrado.codigo);
    setNome(objetoEncontrado.nome);
    setCelular(objetoEncontrado.celularWpp);
    setAtivo(objetoEncontrado.ativo);
    setIsCadastro(true);
  }

  function Add() {
    setCodigo(0);
    setNome("");
    setCelular("");
    setAtivo(1);
    setIsCadastro(true);
  }

  function SalvarDados() {
    if (Nome.length == 0) {
      NotificaWarning("Nome não informado!");
      return false;
    }
    //console.log(Celular);
    if (Celular.length == 0) {
      NotificaWarning("Celular não informada!");
      return false;
    }

    const body = {
      tabela: "motoboy",
      campo: "codigo",
      valor: Codigo,
      campos: [
        { campo: "nome", valor: Nome },
        { campo: "celular_wpp", valor: Celular },
        { campo: "ativo", valor: Ativo ? 1 : 0 },
        { campo: "modificado_site", valor: 0 },
      ],
    };
    setLoading(true);
    API.post("v2/cadastro/geral", body).then(function (response) {
      BuscarDados();
    });
  }

  return (
    <>
      <HeaderComponent>
        {loading && <LoadingOverlay />}{" "}
        <ComponentCadastro Titulo="Motoboy" Funcao={Add} />
        <Table columns={columns} data={Motoboy} codigo={"codigo"} />
        {isCadastro ? (
          <>
            <ModalGravarDados
              Descricao={Codigo == 0 ? "Cadastro" : "Alteração"}
              closeButton={() => setIsCadastro(false)}
              Funcao={SalvarDados}
            >
              <InputPedido
                Descricao="Nome"
                Set={setNome}
                Valor={PrimeiraMaiuscula(Nome)}
                sx={{ width: "100%", marginBottom: "15px" }}
              />
              <InputPedido
                Descricao="Celular"
                Set={setCelular}
                Valor={MascaraCelular(Celular)}
                Tipo="celular"
                sx={{ width: "100%", marginBottom: "15px" }}
              />

              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={Ativo}
                      onChange={(e) => setAtivo(!Ativo)}
                    />
                  }
                  label="Ativo"
                />
              </FormGroup>
            </ModalGravarDados>
          </>
        ) : null}
      </HeaderComponent>
    </>
  );
}
