import { useParams } from "react-router-dom";
import { FormaDePagamento } from "../../component/pagamento";
import { SangriaCaixa } from "../../component/sangria";
import { ResumoCaixa } from "../../component/resumo";
import { HeaderComponent } from "../../../../component/header";
import { Movimentacoes } from "../../component/movimentacao";

export function PageCaixa({ Header, ID }) {
  const { id } = useParams();

  let Codigo = ID == 0 ? id : ID;

  const StyleTituloValue = {
    fontSize: "1.1rem",
    textAlign: "center",
    // color: "#67B86F",
  };

  return (
    <>
      {Header ? (
        <>
          <HeaderComponent>
            <div style={{ width: "100%", display: "flex" }}>
              <div style={{ width: "50%", height: "500px" }}>
                <ResumoCaixa id={Codigo} />
                <div
                  style={{
                    width: "100%",
                    height: "50%",
                  }}
                >
                  <SangriaCaixa id={Codigo} />
                </div>
              </div>
              <div
                style={{
                  width: "50%",
                  height: "500px",
                }}
              >
                <FormaDePagamento id={Codigo} />
              </div>
            </div>
            <div style={{ marginTop: "20px", height: "300px" }}>
              <h1 style={StyleTituloValue}>
                <b>Movimentações</b>
              </h1>
              <Movimentacoes id={Codigo} estorno={false} />
            </div>
          </HeaderComponent>
        </>
      ) : (
        <>
          <div style={{ width: "100%", display: "flex" }}>
            <div style={{ width: "50%", height: "500px" }}>
              <ResumoCaixa id={Codigo} />
              <div
                style={{
                  width: "100%",
                  height: "50%",
                }}
              >
                <SangriaCaixa id={Codigo} />
              </div>
            </div>
            <div
              style={{
                width: "50%",
                height: "500px",
              }}
            >
              <FormaDePagamento id={Codigo} />
            </div>
          </div>
          <div style={{ marginTop: "20px", height: "300px" }}>
            <h1 style={StyleTituloValue}>
              <b>Movimentações</b>
            </h1>
            <Movimentacoes id={Codigo} estorno={false} />
          </div>
        </>
      )}
    </>
  );
}
