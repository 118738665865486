import { useEffect, useState } from "react";
import { InputPedido } from "../../page/pedido/componente/input";
import { ShowModal } from "../modalPai";
import { Toggle } from "../toggle";
import {
  Notifica,
  NotificaWarning,
  PrimeiraMaiuscula,
  validarCNPJ,
} from "../../controller/util";
import { API } from "../../controller/api/api";
import { Button } from "../button";

export function NovoCliente({ Close }) {
  const [TipoPessoa, setTipoPessoa] = useState(false);
  const [Documento, setDocumento] = useState("");
  const [Nome, setNome] = useState("");
  const [Celular, setCelular] = useState("");
  const [Rua, setRua] = useState("");
  const [Numero, setNumero] = useState("");
  const [Complemento, setComplemento] = useState("");
  const [Bairro, setBairro] = useState("");

  useEffect(() => {
    setDocumento("");
  }, [TipoPessoa]);

  function manterApenasNumeros(string) {
    return string.replace(/\D/g, ""); // Remove todos os caracteres não numéricos
  }

  function SetaDocumento(desc, valor) {
    let dados = manterApenasNumeros(valor);
    if (TipoPessoa) {
      const cnpjValid = validarCNPJ(valor);

      if (cnpjValid) {
        NotificaWarning("CNPJ inválido!");
        const inputElement = document.getElementById("documento");
        inputElement.focus();
      } else {
        setDocumento(dados);
        API.get(`/v2/cnpj/${dados}`).then(function (response) {
          setNome(PrimeiraMaiuscula(response.data.fantasia));
          setRua(PrimeiraMaiuscula(response.data.logradouro));
          setCelular(
            validarEFormatarTelefone(
              manterApenasNumeros(response.data.telefone)
            )
          );
          setNumero(response.data.numero);
          setComplemento(PrimeiraMaiuscula(response.data.complemento));
          setBairro(response.data.bairro);
        });
      }
    } else {
    }
  }

  function validarEFormatarTelefone(numero) {
    // Remove todos os caracteres não numéricos
    const numeroLimpo = numero.replace(/\D/g, "");

    // Verifica se o número tem pelo menos 10 dígitos
    if (numeroLimpo.length >= 10) {
      // Obtém o terceiro dígito
      const terceiroDigito = parseInt(numeroLimpo.charAt(2));

      // Verifica se é um número de celular (6, 7, 8 ou 9)
      if ([6, 7, 8, 9].includes(terceiroDigito)) {
        // Adiciona o "9" após os dois primeiros dígitos
        return `${numeroLimpo.substring(0, 2)}9${numeroLimpo.substring(2)}`;
      } else {
        // Retorna vazio para números de telefone fixo
        return "";
      }
    } else {
      // Retorna vazio para números com menos de 10 dígitos
      return "";
    }
  }

  function SalvaCliente() {
    //console.log("XX");
    let body = {
      documento: Documento,
      nome: Nome,
      celular: manterApenasNumeros(Celular),
    };

    API.post("/v2/novo/cadastro", body).then(function (response) {
      Notifica("Cliente Cadastrado Com Sucesso!");
      Close();
    });
  }

  return (
    <>
      <ShowModal zIndex={9999997} closeButton={Close}>
        <h1
          style={{ textAlign: "center", fontSize: "1.2rem", color: "#A8001C" }}
        >
          <b>Cadastro de Cliente</b>
        </h1>
        <div style={{ width: "30vw" }}>
          <h3 style={{ color: "#999999" }}>{TipoPessoa ? "CNPJ" : "CPF"}</h3>
          <InputPedido
            id={"documento"}
            Valor={Documento}
            Tipo={TipoPessoa ? "cnpj" : "cpf"}
            Retorno={SetaDocumento}
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <span style={{ marginRight: "10px" }}>
              {!TipoPessoa ? <b>Fisica</b> : "Fisica"}
            </span>
            <Toggle
              Change={() => setTipoPessoa(!TipoPessoa)}
              Checked={TipoPessoa}
            />
            <span>{TipoPessoa ? <b>Jurídica</b> : "Jurídica"}</span>
          </div>
          <h3 style={{ color: "#999999" }}>
            {TipoPessoa ? "Razão Social" : "Nome"}
          </h3>
          <InputPedido
            id="nomeCliente"
            Valor={PrimeiraMaiuscula(Nome)}
            Set={setNome}
          />
          <h3 style={{ color: "#999999" }}>Celular</h3>
          <InputPedido
            id="celularCliente"
            Tipo="celular"
            Valor={Celular}
            Set={setCelular}
          />
          {/* <h3 style={{ color: "#999999" }}>Rua</h3>
          <InputPedido Valor={PrimeiraMaiuscula(Rua)} Set={setRua} />
          <h3 style={{ color: "#999999" }}>Número Casa / Apartamento</h3>
          <InputPedido Valor={Numero} Set={setNumero} />
          <h3 style={{ color: "#999999" }}>Bairro</h3>
          <InputPedido Valor={Bairro} />
          <h3 style={{ color: "#999999" }}>Complemento</h3>
          <InputPedido
            Valor={PrimeiraMaiuscula(Complemento)}
            Set={setComplemento}
          /> */}
          <Button
            descricao={"Salvar"}
            borderColor={"#269931"}
            fontColor={"#269931"}
            evento={SalvaCliente}
          />
        </div>
      </ShowModal>
    </>
  );
}
