import React, { useState, useEffect } from "react";
import { MdRemove, MdAdd } from "react-icons/md";
import { PrimeiraMaiuscula } from "../../../controller/util";
import { MascaraMonetaria } from "../../../controller/data";
import { isMobileDevice } from "../../../controller/mobile";
import { IconButton } from "@mui/material";

var QuantAux = {};

export function AdicionaComponent(props) {
  const [quantidades, setQuantidades] = useState(QuantAux);
  const [quantidadesCategoria, setQuantidadesCategoria] = useState({});
  const [quantidadeSelecionada, setQuantidadeSelecionada] = useState(0);

  useEffect(() => {
    props.atualizadados(quantidades);
  }, [quantidades]);

  const handleSubtrairQuantidade = (registro, categoria) => {
    const QuantidadeAtual = quantidades[registro.codigo] || 0;

    if (QuantidadeAtual > 0) {
      props.remove(registro.valor);
      setQuantidadeSelecionada((prevQuantidades) => ({
        ...prevQuantidades,
        [categoria.categoria]: QuantidadeAtual - 1,
      }));
      QuantAux = (prevQuantidades) => {
        const quantidadeAtual = prevQuantidades[registro.codigo] || 0;
        const novaQuantidade =
          quantidadeAtual - 1 >= 0 ? quantidadeAtual - 1 : 0;
        return {
          ...prevQuantidades,
          [registro.codigo]: novaQuantidade,
        };
      };
    } else {
    }
    setQuantidades(QuantAux);
  };

  const handleAdicionarQuantidade = (registro, categoria) => {
    const QuantidadeAtual = quantidadeSelecionada[categoria.categoria] || 0;

    if (QuantidadeAtual < categoria.maximo) {
      const novaQuantidade = quantidades[registro.codigo] || 0;

      setQuantidadeSelecionada((prevQuantidades) => ({
        ...prevQuantidades,
        [categoria.categoria]: QuantidadeAtual + 1,
      }));

      QuantAux = (prevQuantidades) => ({
        ...prevQuantidades,
        [registro.codigo]: novaQuantidade + 1,
      });

      props.add(registro.valor);
    } else {
      //////console.log('quantidade maxima selecionada!');
    }
    setQuantidades(QuantAux);
  };

  return (
    <>
      {Object.entries(props.dados).map((dados) => (
        <div key={dados[1].categoria}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "#A8001C",
            }}
          >
            <div
              style={{
                textAlign: "left",
                marginLeft: "5px",
                color: "white",
                margin: "5px",
              }}
            >
              {PrimeiraMaiuscula(dados[1].categoria)}
            </div>
            <span
              style={{
                background: "white",
                color: "#A8001C",
                textAlign: "right",
                margin: "5px",
                borderRadius: "10px",
                width: "50px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {quantidadeSelecionada[dados[1].categoria] || 0}/{dados[1].maximo}
            </span>
          </div>

          <ul style={{ padding: "10px" }}>
            {dados[1].registros.map((registro) => (
              <li
                key={registro.codigo}
                style={{
                  marginBottom: "10px",
                  borderBottom: "1px dashed rgba(0,0,0,0.1)",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "5px",
                    // justifyContent: "flex-end",
                  }}
                >
                  <IconButton
                    color="#F0BA18"
                    sx={{
                      width: "48px",
                      height: "48px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "#F0BA18",
                      border: "1px solid #F0BA18",
                    }}
                    onClick={() => handleSubtrairQuantidade(registro, dados[1])}
                  >
                    <MdRemove size={24} />
                  </IconButton>
                  {/* <button
                    style={{
                      backgroundColor: "#F0BA18",
                      color: "white",
                      width: "48px",
                      height: "48px",
                      marginRight: "8px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={() => handleSubtrairQuantidade(registro, dados[1])}
                  >
                    <MdRemove size={24} />
                  </button> */}
                  <span
                    style={{
                      height: "35px",
                      width: "35px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {quantidades[registro.codigo] || 0}
                  </span>
                  <IconButton
                    color="#269931"
                    sx={{
                      width: "48px",
                      height: "48px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "#269931",
                      border: "1px solid #269931",
                    }}
                    onClick={() =>
                      handleAdicionarQuantidade(registro, dados[1])
                    }
                  >
                    <MdAdd size={24} />
                  </IconButton>
                  {/* <button
                    style={{
                      backgroundColor: "#269931",
                      color: "white",
                      width: "48px",
                      height: "48px",
                      marginLeft: "8px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={() =>
                      handleAdicionarQuantidade(registro, dados[1])
                    }
                  >
                    <MdAdd size={24} />
                  </button> */}

                  <div
                    style={{
                      height: "100%",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      // justifyContent: "flex-start",
                      color: "black",
                      marginLeft: "20px",
                    }}
                  >
                    <div>
                      <h6>{PrimeiraMaiuscula(registro.nome)}</h6>
                      {registro.valor > 0 ? (
                        <span style={{ color: "#269931" }}>
                          {MascaraMonetaria(registro.valor)}
                        </span>
                      ) : null}
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </>
  );
}
