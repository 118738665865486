import { Center } from "@chakra-ui/react";
import { ShowModal } from "../../../component/modalPai";
import { PrimeiraMaiuscula } from "../../../controller/util";
import { MascaraMonetaria } from "../../../controller/data";

export function ModalDadosMotoboy({ dados, close }) {
  //console.log(dados);

  function agruparPorForma(dados) {
    const agrupado = {};

    dados.forEach((item) => {
      // Normaliza o texto da forma de pagamento para garantir consistência
      const formaPagamento = item.forma.toUpperCase();

      // Se a forma de pagamento ainda não estiver no objeto agrupado, cria uma entrada para ela
      if (!agrupado[formaPagamento]) {
        agrupado[formaPagamento] = {
          forma: formaPagamento,
          total: 0,
        };
      }

      // Adiciona o valor ao total da forma de pagamento
      agrupado[formaPagamento].total += item.valor;
    });

    // Converte o objeto agrupado para um array de valores
    const resultadoAgrupado = Object.values(agrupado);

    return resultadoAgrupado;
  }

  function formatarPedidosComoString(dados) {
    const numerosPedidos = dados.map((item) => item.pedido);
    const pedidosString = numerosPedidos.join(",");

    return pedidosString;
  }

  //console.log(formatarPedidosComoString(dados.pagamento));

  return (
    <>
      <ShowModal closeButton={close}>
        <div style={{ width: "20vw", fontSize: "1.3rem", textAlign: "center" }}>
          <h1>
            <b>{PrimeiraMaiuscula(dados.name)}</b>
          </h1>
        </div>
        <div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h4>Entregas</h4>
            <span>
              <strong>{dados?.pedidos}</strong>
            </span>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h4>Pedido</h4>
            <span>
              <strong>{MascaraMonetaria(dados?.value_pedidos)}</strong>
            </span>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h4>Taxa</h4>
            <span>
              <strong>{MascaraMonetaria(dados?.value_taxa)}</strong>
            </span>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h4>Total</h4>
            <span>
              <strong>{MascaraMonetaria(dados?.value_tot)}</strong>
            </span>
          </div>
        </div>
        <div style={{ width: "20vw", fontSize: "1.3rem", textAlign: "center" }}>
          <h1>
            <b>Pagamentos</b>
          </h1>
        </div>

        {agruparPorForma(dados.pagamento).map((Motoboy, index) => (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "1px dashed rgba(0, 0, 0,0.5)",
              }}
            >
              <h4>{PrimeiraMaiuscula(Motoboy.forma)}</h4>
              <span>
                <strong>{MascaraMonetaria(Motoboy.total)}</strong>
              </span>
            </div>
          </>
        ))}

        <div style={{ width: "20vw", fontSize: "1.3rem", textAlign: "center" }}>
          <h1>
            <b>Pedidos</b>
          </h1>
        </div>
        <p style={{ width: "20vw" }}>
          {formatarPedidosComoString(dados.pagamento)}
        </p>
      </ShowModal>
    </>
  );
}
