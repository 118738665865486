import React, { useEffect, useState } from "react";
import { FaTimes, FaArrowUp, FaArrowDown } from "react-icons/fa";

import {
  MascaraMonetaria,
  RemoveMascara,
  TransformarValor,
  formatarValor,
} from "../../../controller/data";
import { API } from "../../../controller/api/api";
import { InputPedido } from "../../../page/pedido/componente/input";
import { PrimeiraMaiuscula } from "../../../controller/util";
import { ShowModal } from "../../modalPai";
import { Button } from "../../button";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

export function ComponenteEntradaSaidaEstoque({
  tipo,
  codigo,
  close,
  tipoEntradaSaida,
}) {
  //console.log(tipoEntradaSaida);
  const [activeButton, setActiveButton] = useState(
    tipoEntradaSaida ? tipoEntradaSaida : "entrada"
  );
  const [Option, setOption] = useState([
    { value: "KG", label: "KG" },
    { value: "UN", label: "UN" },
    { value: "CX", label: "CX" },
  ]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [Dados, setDados] = useState(false);
  const [Fator, setFator] = useState(1);
  const [FatorDiferente, setFatorDiferente] = useState(false);
  const [EstoqueNovo, setEstoqueNovo] = useState(0);
  const [ValorUnitario, setValorUnitario] = useState(0);
  const [ValorTotal, setValorTotal] = useState(0);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "#f5f5f5",
      minHeight: "34px",
      height: "34px",
      borderColor: "black",
      transition: "border-color 0.1s ease 0s",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#A8001C"
        : state.isFocused
        ? "#e5e5e5"
        : null,
      color: state.isSelected ? "white" : null,
    }),
  };

  useEffect(() => {
    API.get(`v2/estoque/produto/insulmo/${tipo}/${codigo}`).then(function (
      data
    ) {
      ////console.log(data.data[0]);
      setDados(data.data[0]);
      setSelectedOption({
        value: data.data[0].unidade,
        label: data.data[0].unidade,
      });
    });
  }, []);

  useEffect(() => {
    let select = selectedOption?.value ? selectedOption.value : "UN";
    let padrao = Dados?.unidade ? Dados.unidade : "UN";
    let test = padrao == select;
    setFatorDiferente(padrao == select);
    ////console.log(test);
    setFator(1);
    API.get(`v1/util/fator/conversao/${select}/${tipo}/${codigo}`).then(
      function (result) {
        if (result.data.length > 0) {
          ////console.log(result.data[0]);
          setFator(result.data[0].valor);
        }
      }
    );
  }, [selectedOption]);

  function AtualizaEstoque(nada, valor) {
    setEstoqueNovo(valor);

    let Total =
      parseFloat(EstoqueNovo) * parseFloat(RemoveMascara(ValorUnitario));
    setValorTotal(RemoveMascara(Total));
  }

  function AtualizaFator(nada, valor) {
    setFator(valor);
  }

  function AtualizaUnitario(nada, valor) {
    let Total = parseFloat(EstoqueNovo) * parseFloat(RemoveMascara(valor));

    setValorUnitario(RemoveMascara(valor));
    setValorTotal(RemoveMascara(Total));
  }

  function AtualizaTotal(nada, valor) {
    let Unitario = parseFloat(RemoveMascara(valor)) / parseFloat(EstoqueNovo);

    setValorTotal(RemoveMascara(valor));
    setValorUnitario(RemoveMascara(Unitario));
  }

  function EstoqueAtual() {
    return parseFloat(Dados.estoque);
  }

  function EstoqueAtualizado() {
    if (activeButton == "saida") {
      return (
        parseFloat(Dados.estoque) +
        parseFloat(TransformarValor(EstoqueNovo) * TransformarValor(Fator)) * -1
      );
    } else {
      return (
        parseFloat(Dados.estoque) +
        parseFloat(TransformarValor(EstoqueNovo) * TransformarValor(Fator))
      );
    }
  }

  function Salvar() {
    let Body = [
      {
        sequencial: 1,
        id: codigo,
        tipo: tipo,
        nome: Dados.nome,
        un: Dados.unidade, //Colocar a unidade do select
        qtd: TransformarValor(EstoqueNovo),
        custoun: TransformarValor(ValorUnitario),
        custototal: TransformarValor(ValorTotal),
      },
    ];

    if (activeButton == "saida") {
      Body[0].qtd = Body[0].qtd * -1;
    }
    ////console.log(Body);

    if (!FatorDiferente) {
      let FatorEnvio = `v1/util/fator/conversao/${
        selectedOption?.value ? selectedOption.value : "UN"
      }/${Dados.unidade}/${TransformarValor(Fator)}/${tipo}/${codigo}`;
      ////console.log(FatorEnvio);
      API.post(FatorEnvio, Body).then(function (response) {
        API.post("v1/util/estoque/produto/insumos", Body).then(function (
          response
        ) {
          close();
        });
      });
    } else {
      API.post("v1/util/estoque/produto/insumos", Body).then(function (
        response
      ) {
        close();
      });
    }
  }

  //
  // ////console.log(Body);

  return (
    <>
      {Dados != false ? (
        <ShowModal closeButton={close}>
          <h1 style={{ margin: 0, fontSize: "28px" }}>
            <b>Estoque</b>
          </h1>

          <div>
            <h1 style={{ textAlign: "initial" }}>
              {Dados.nome ? PrimeiraMaiuscula(Dados.nome) : ""}
            </h1>
            <h4 style={{ textAlign: "initial", color: "#B3B3B3" }}>
              Estoque Atual:{" "}
              <span
                style={{ color: EstoqueAtual() > 0 ? "#269931" : "#A8001C" }}
              >
                {formatarValor(EstoqueAtual())}
                {Dados?.unidade ? Dados.unidade : "UN"}
              </span>
            </h4>

            <h4 style={{ textAlign: "initial", color: "#B3B3B3" }}>
              Estoque Novo:{" "}
              <span
                style={{
                  color: EstoqueAtualizado() > 0 ? "#269931" : "#A8001C",
                }}
              >
                {formatarValor(EstoqueAtualizado())}
                {Dados?.unidade ? Dados.unidade : "UN"}
              </span>
            </h4>

            <div style={{ display: "flex", gap: "10px", marginTop: "15px" }}>
              <div
                style={{
                  width: "50%",
                  textAlign: "initial",
                }}
              >
                <FormControl fullWidth>
                  <InputLabel id="driver">Unidade</InputLabel>
                  <Select
                    labelId="driver"
                    id="driver"
                    value={selectedOption}
                    label="Unidade"
                    onChange={(e) => setSelectedOption(e.target.value)}
                    sx={{ width: "100%", marginBottom: "15px" }}
                    size="small"
                  >
                    {Option.map((item) => (
                      <MenuItem value={item.value}>
                        {PrimeiraMaiuscula(item.label)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {/* <h3>Unidade Entrada</h3> */}
                {/* <Select
                  value={selectedOption}
                  onChange={setSelectedOption}
                  options={Option}
                  isSearchable={true}
                  placeholder=""
                  styles={customStyles}
                /> */}
              </div>
              <div style={{ width: "50%", textAlign: "initial" }}>
                <InputPedido
                  Descricao="Quantidade"
                  Retorno={AtualizaEstoque}
                  Valor={formatarValor(EstoqueNovo)}
                />
              </div>
            </div>
            <div style={{ display: "flex", gap: "10px", marginBottom: "15px" }}>
              <div style={{ width: "50%", textAlign: "initial" }}>
                <InputPedido
                  Descricao="R$ Unitário"
                  Retorno={AtualizaUnitario}
                  Valor={MascaraMonetaria(ValorUnitario)}
                />
              </div>
              <div style={{ width: "50%", textAlign: "initial" }}>
                <InputPedido
                  Descricao="R$ Total"
                  Retorno={AtualizaTotal}
                  Valor={MascaraMonetaria(ValorTotal)}
                />
              </div>
            </div>
            {!FatorDiferente ? (
              <>
                <div style={{ textAlign: "initial" }}>
                  <InputPedido
                    Descricao="Fator de Conversão"
                    Retorno={AtualizaFator}
                    Valor={Fator}
                  />

                  <h4>
                    A Cada 1,000
                    {selectedOption?.value ? selectedOption.value : "UN"}{" "}
                    Converte em {formatarValor(Fator)}
                    {Dados?.unidade ? Dados.unidade : "UN"}
                  </h4>
                </div>
              </>
            ) : null}

            <div style={{ display: "flex", gap: "10px" }}>
              <div
                style={{
                  width: "50%",
                }}
              >
                <Button
                  descricao={"Entrada"}
                  fontColor={"#000"}
                  borderColor={activeButton === "entrada" ? "#269931" : "Black"}
                  icon={<FaArrowUp color="#269931" />}
                  evento={() => setActiveButton("entrada")}
                />
              </div>
              <div
                style={{
                  width: "50%",
                }}
              >
                <Button
                  descricao={"Saida"}
                  fontColor={"#000"}
                  borderColor={activeButton === "saida" ? "#A8001C" : "Black"}
                  icon={<FaArrowDown color="#A8001C" />}
                  evento={() => setActiveButton("saida")}
                />
                {
                  //
                  //Saida
                }
              </div>
            </div>
            <div
              style={{
                width: "100%",
                marginTop: "10px",
              }}
            >
              <Button
                descricao={`Executar ${
                  activeButton === "entrada" ? "Entrada" : "Saida"
                } do Estoque`}
                fontColor={"#000"}
                borderColor={activeButton === "entrada" ? "#269931" : "#A8001C"}
                icon={
                  activeButton === "entrada" ? (
                    <FaArrowUp color="#269931" style={{ marginRight: "5px" }} />
                  ) : (
                    <FaArrowDown
                      color="#A8001C"
                      style={{ marginRight: "5px" }}
                    />
                  )
                }
                evento={Salvar}
              />
            </div>
          </div>
        </ShowModal>
      ) : null}
    </>
  );
}
