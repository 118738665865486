import { useEffect, useState } from "react";
import { API } from "../../../../../controller/api/api";
import Select from "react-select";
import {
  Notifica,
  NotificaWarning,
  PrimeiraMaiuscula,
} from "../../../../../controller/util";
import { Button } from "../../../../../component/button";
import { InputPedido } from "../../../../pedido/componente/input";
import { FaFirstAid, FaPlus, FaTrash } from "react-icons/fa";
import { ModalComponent } from "../../../../../component/modal";
import {
  MascaraMonetaria,
  TransformarValor,
  formatarValor,
} from "../../../../../controller/data";

export function FichaTecnica({ codigo }) {
  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "#f5f5f5",
      minHeight: "34px",
      height: "34px",
      borderColor: "black",
      transition: "border-color 0.1s ease 0s",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#A8001C"
        : state.isFocused
        ? "#e5e5e5"
        : null,
      color: state.isSelected ? "white" : null,
    }),
  };

  const [Option, setOption] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [FichaProduto, setFichaProduto] = useState([]);
  const [isModal, setModal] = useState(false);
  const [mensagem, setmensagem] = useState("");
  const [Ingredientes, setIngredientes] = useState([]);
  const [CodigoDelet, setCodigoDelet] = useState(0);

  useEffect(() => {
    API.get(`v1/consulta/todos/ingredientes`).then((response) => {
      setIngredientes(response.data);

      let result = [];

      for (var i = 0; i < response.data.length; i++) {
        result.push({
          value: i,
          label: `${PrimeiraMaiuscula(response.data[i].descricao)} (${
            response.data[i].unidade
          })`,
        });
      }

      setOption(result);
    });

    API.get(`/v1/util/grava/ingrediente/ficha/produto/${codigo}`).then(
      (response) => {
        setFichaProduto(response.data);
      }
    );
  }, []);

  function AddIngreditente() {
    if (
      FichaProduto.some(
        (item) => item.idIngredientes === Ingredientes[selectedOption.value].id
      )
    ) {
      NotificaWarning("Ingrediente já compõem a ficha!");
      return false;
    }

    const novoItem = {
      id: 0,
      idProduto: codigo,
      idIngredientes: Ingredientes[selectedOption.value].id,
      quantidade: 0,
      descricao: Ingredientes[selectedOption.value].descricao,
      unidade: Ingredientes[selectedOption.value].unidade,
      custo: Ingredientes[selectedOption.value].custo,
    };

    setFichaProduto((prevState) => [...prevState, novoItem]);

    Notifica(
      `${PrimeiraMaiuscula(
        Ingredientes[selectedOption.value].descricao
      )} adicionado a ficha!`
    );
  }

  function Delete(id, descricao) {
    setCodigoDelet(id);
    setModal(true);
    setmensagem(`Deseja excluir o ${descricao}?`);
  }

  function ConfirmDelete() {
    const novosItens = FichaProduto.filter((_, idx) => idx !== CodigoDelet);
    setFichaProduto(novosItens);
    setModal(false);
  }

  function SalvarFicha() {
    API.post(`/v1/util/grava/ingrediente/ficha/produto`, FichaProduto);

    Notifica("Ficha Técnica Salva Com Sucesso!");
  }

  function Altera(Index, Valor) {
    const novosItens = [...FichaProduto];
    novosItens[Index].quantidade = TransformarValor(Valor);
    setFichaProduto(novosItens);
  }

  return (
    <>
      <Select
        value={selectedOption}
        onChange={setSelectedOption}
        options={Option}
        isSearchable={true}
        placeholder="Selecione ou pesquise..."
        styles={customStyles}
      />

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "10px",
          height: "35px",
          width: "100%",
          marginTop: "10px",
        }}
      >
        <Button
          descricao="Adicionar"
          evento={AddIngreditente}
          borderColor="#138138"
          fontColor="#138138"
          BorderRadius="5px"
          icon={<FaPlus />}
        />
      </div>

      <h1>
        <b>Composição do Produto</b>
      </h1>
      <div style={{ overflowY: "scroll", height: "500px" }}>
        {FichaProduto.map((dados, index) => (
          <div
            key={index}
            style={{
              border: "1px solid black",
              borderRadius: "5px",
              padding: "5px",
              marginBottom: "10px",
              position: "relative", // Adicione essa linha
            }}
          >
            <FaTrash
              style={{
                position: "absolute",
                top: "5px",
                right: "5px",
                cursor: "pointer", // Para dar aparência de clicável
                color: "#A8001C",
              }}
              onClick={() => {
                Delete(index, dados.descricao);
              }}
            />
            <div style={{ display: "flex", marginBottom: "10px" }}>
              <h1>{PrimeiraMaiuscula(dados.descricao)}</h1>
              <span
                style={{
                  display: "block",
                  backgroundColor: "#A8001C",
                  color: "white",
                  borderRadius: "10px",
                  width: "50px",
                  textAlign: "center",
                  marginLeft: "10px",
                }}
              >
                {dados.unidade}
              </span>
            </div>
            <h1>{dados.unidade}</h1>
            <InputPedido
              Descricao=""
              Retorno={Altera}
              index={index}
              Valor={formatarValor(dados.quantidade)}
            />
          </div>
        ))}
      </div>

      <Button
        descricao="Salvar Ficha Técnica"
        evento={SalvarFicha}
        color="white"
        borderColor="#a4001b"
        fontColor="#a4001b"
        BorderRadius="5px"
      />

      {isModal ? (
        <ModalComponent
          question={mensagem}
          onYesClick={ConfirmDelete}
          onNoClick={() => setModal(false)}
        />
      ) : null}
    </>
  );
}
