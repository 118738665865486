import { useState } from "react";
import { ModalGravarDados } from "../../component/gravar";
import { HeaderComponent } from "../../component/header";
import { ComponentCadastro } from "../../component/header/cadastro";
import {
  InputAdornment,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  FormGroup,
  Switch,
} from "@mui/material";
import { PrimeiraMaiuscula } from "../../controller/util";
import { MascaraMonetaria } from "../../controller/data";

export function PageFuncionario() {
  const [Codigo, setCodigo] = useState(0);
  const [isCadastro, setIsCadastro] = useState(true);
  const [Nome, setNome] = useState("");
  const [Celular, setCelular] = useState("");
  const [Funcao, setFuncao] = useState(1);
  const [Valor, setValor] = useState(0);
  const [PercentualGoopedir, setPercentualGoopedir] = useState(100);
  const [PercentualiFood, setPercentualiFood] = useState(100);
  const [Motoboy, setMotoboy] = useState(0);
  const [Segunda, setSegunda] = useState(0);
  const [Terca, setTerca] = useState(0);
  const [Quarta, setQuarta] = useState(0);
  const [Quinta, setQuinta] = useState(0);
  const [Sexta, setSexta] = useState(0);
  const [Sabado, setSabado] = useState(0);
  const [Domingo, setDomingo] = useState(0);
  const [Ativo, setAtivo] = useState(0);

  function Novo() {}

  function SalvarDados() {}

  function DescricaoValor(Funcao) {
    if (Funcao == 1) {
      return "Valor";
    }
    if (Funcao == 2) {
      return "Valor Arrancada";
    }
    if (Funcao == 3) {
      return "Salário";
    }
  }

  function CurrencyTextField(props) {
    return (
      <TextField
        label="Valor"
        variant="outlined"
        value={props.value}
        onChange={props.onChange}
        InputProps={{
          inputComponent: CurrencyTextField,
        }}
      />
      //   <InputMask
      //     mask="R$ 999.999.999,99"
      //     value={props.value}
      //     onChange={props.onChange}
      //     maskChar=""
      //   >
      //     {(inputProps) => (
      //       <TextField
      //         {...inputProps}
      //         id={props.id}
      //         label={props.label}
      //         variant="outlined"
      //         sx={{ width: "100%", marginBottom: "15px" }}
      //         // InputProps={{
      //         //   startAdornment: (
      //         //     <InputAdornment position="start">R$</InputAdornment>
      //         //   ),
      //         // }}
      //       />
      //     )}
      //   </InputMask>
    );
  }

  const handlePhoneChange = (event) => {
    setCelular(event.target.value);
  };

  return (
    <>
      <HeaderComponent>
        <ComponentCadastro Titulo="Funcionário" Funcao={Novo} />
        {isCadastro ? (
          <ModalGravarDados
            Descricao={Codigo == 0 ? "Cadastro" : "Alteração"}
            closeButton={() => setIsCadastro(false)}
            Funcao={SalvarDados}
          >
            <TextField
              id="nome"
              label="Nome"
              size="small"
              sx={{ width: "100%", marginBottom: "15px" }}
              value={PrimeiraMaiuscula(Nome)}
              onChange={(e) => setNome(e.target.value)}
            />
            {/* <PhoneTextField value={Celular} onChange={handlePhoneChange} /> */}

            <FormControl fullWidth>
              <InputLabel id="funcao">Função</InputLabel>
              <Select
                labelId="funcao"
                id="funcao"
                value={Funcao}
                label="Função"
                onChange={(e) => setFuncao(e.target.value)}
                sx={{ width: "100%", marginBottom: "15px" }}
                size="small"
              >
                <MenuItem value={1}>FreeLancer</MenuItem>
                <MenuItem value={2}>Motoboy</MenuItem>
                <MenuItem value={3}>Funcionario</MenuItem>
              </Select>
            </FormControl>

            {/* <CurrencyTextField
              id="valor"
              label={DescricaoValor(Funcao)}
              value={Valor}
              onChange={(e) => setValor(e.target.value)}
            /> */}

            <TextField
              id="valor"
              label={DescricaoValor(Funcao)}
              size="small"
              sx={{ width: "100%", marginBottom: "15px" }}
              value={MascaraMonetaria(Valor)}
              onBlur={(e) => setValor(e.target.value)}
            />
            {Funcao == 2 ? (
              <>
                <TextField
                  id="perc-goopedir"
                  label={"Percentual Taxa Entrega Goopedir"}
                  size="small"
                  sx={{ width: "100%", marginBottom: "15px" }}
                  value={PercentualGoopedir}
                  onChange={(e) => setPercentualGoopedir(e.target.value)}
                />
                <TextField
                  id="perc-ifood"
                  label={"Percentual Taxa Entrega iFood"}
                  size="small"
                  sx={{ width: "100%", marginBottom: "15px" }}
                  value={PercentualiFood}
                  onChange={(e) => setPercentualiFood(e.target.value)}
                />
              </>
            ) : null}
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={Segunda}
                    onChange={(e) => setSegunda(!Segunda)}
                  />
                }
                label="Segunda"
              />
              <FormControlLabel
                control={
                  <Switch checked={Terca} onChange={(e) => setTerca(!Terca)} />
                }
                label="Terça"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={Quarta}
                    onChange={(e) => setQuarta(!Quarta)}
                  />
                }
                label="Quarta"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={Quinta}
                    onChange={(e) => setQuinta(!Quinta)}
                  />
                }
                label="Quinta"
              />
              <FormControlLabel
                control={
                  <Switch checked={Sexta} onChange={(e) => setSexta(!Sexta)} />
                }
                label="Sexta"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={Sabado}
                    onChange={(e) => setSabado(!Sabado)}
                  />
                }
                label="Sábado"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={Domingo}
                    onChange={(e) => setDomingo(!Domingo)}
                  />
                }
                label="Domingo"
              />
            </FormGroup>
          </ModalGravarDados>
        ) : null}
      </HeaderComponent>
    </>
  );
}
