import { useEffect, useState } from "react";
import { HeaderComponent } from "../../component/header";
import { API } from "../../controller/api/api";
import {
  MascaraCelular,
  Notifica,
  NotificaWarning,
  PrimeiraMaiuscula,
  formatarCPForCNPJ,
  getInitials,
} from "../../controller/util";
import {
  DataAtual,
  MascaraMonetaria,
  RemoveMascara,
  formatarData,
  formatarDataHora,
  formatarHora,
  removerTresHoras,
  transformDate,
  verificarData,
} from "../../controller/data";
import {
  FaCartArrowDown,
  FaCheck,
  FaExpandArrowsAlt,
  FaFile,
  FaHamburger,
  FaHashtag,
  FaMoneyBillWave,
  FaPlus,
} from "react-icons/fa";
import { BuscaLocal, GravaLocal } from "../../controller/storage";
import { InputPedido } from "../pedido/componente/input";
import { ModalMotoboy } from "./component/motoboy";
import CardLegenda from "../../component/cardlegenda";
import { ShowModal } from "../../component/modalPai";
import IfoodWidget from "../../controller/ifood";
import { ParametroConfiguracao } from "../../controller/parametros";
import { ComponentePedido } from "../../component/pedido";
import { ModalDadosMotoboy } from "../motoboy/dadosEntrega";
import { Toggle } from "../../component/toggle";
import { CardTempo } from "../../component/cabecalhopedido/cardtempo";
import { isMobileDevice } from "../../controller/mobile";
import { ModalComponent } from "../../component/modal";
import { SenhaCancelamentoPedido } from "../../component/senhacancelamento";
import LoadingOverlay from "../../component/load";
import Box from "@mui/material/Box";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import SaveIcon from "@mui/icons-material/Save";
import PrintIcon from "@mui/icons-material/Print";
import ShareIcon from "@mui/icons-material/Share";

import { ModalCancelamentoiFood } from "./component/ifood";
import { alpha, hexToRgb, IconButton, TextField, Tooltip } from "@mui/material";
import { ComponentCadastro } from "../../component/header/cadastro";
import { FechamentoPages } from "../fechamento";
import { PermissaoModulo } from "../../controller/modulos";

let dataAtualInfo = [];
let PedidoClick = 0;

let DataFinalPadrao = DataAtual();
let DataInicialPadrao = DataAtual();

// const actions = [
//   { icon: <FileCopyIcon />, name: 'Copy' },
//   { icon: <SaveIcon />, name: 'Save' },
//   { icon: <PrintIcon />, name: 'Print' },
//   { icon: <ShareIcon />, name: 'Share' },
// ];

export function PedidosPage() {
  const [selectedIndices, setSelectedIndices] = useState([]); // Índices das divs selecionadas
  const [hoveredIndex, setHoveredIndex] = useState(-1); // Índice da div que está com o mouse em cima
  const [searchText, setSearchText] = useState(""); // Valor do input de pesquisa
  const [filteredData, setFilteredData] = useState([]); // Dados filtrados
  const [Data, setData] = useState([]);
  const [DadosMotoboy, setDadosMotoboy] = useState([]);
  const [DadosStatus, setDadosStatus] = useState([]);
  const [EntregasMotoboy, setEntregasMotoboy] = useState([]);

  const [DataInicial, setDataInicial] = useState(DataInicialPadrao);
  const [DataFinal, setDataFinal] = useState(DataFinalPadrao);

  const [CorPrimaria, setCorPrimaria] = useState(
    BuscaLocal("CorPrimaria") ? BuscaLocal("CorPrimaria") : "#ff5c00"
  );
  const [CorSecundaria, setCorSecundaria] = useState(
    BuscaLocal("CorSecundaria") ? BuscaLocal("CorSecundaria") : "#ff5c00"
  );
  const [CorConcluida, setCorConcluida] = useState(
    BuscaLocal("CorConcluida") ? BuscaLocal("CorConcluida") : "#66cdaa"
  );
  const [CorCancelada, setCorCancelada] = useState(
    BuscaLocal("CorCancelada") ? BuscaLocal("CorCancelada") : "#d23f3f"
  );
  const [CorEntrega, setCorEntrega] = useState(
    BuscaLocal("CorEntrega") ? BuscaLocal("CorEntrega") : "#4130c3"
  );

  const [isModalMotoboy, setisModalMotoboy] = useState(false);
  const [OcutarFaturados, setOcutarFaturados] = useState(
    BuscaLocal("OcutarFaturados") == "true" ? true : false
  );
  const [personalizarCores, setPersonalizarCores] = useState(false);
  const [OcultarEstimativa, setOcultarEstimativa] = useState(
    BuscaLocal("OcultarEstimativa") == "true" ? true : false
  );
  const [OcultarTotalizadores, setOcultarTotalizadores] = useState(
    BuscaLocal("OcultarTotalizadores") == "true" ? true : false
  );

  const [exibirDiv, setExibirDiv] = useState(false);

  const [urlExibir, seturlExibir] = useState("");

  const [CardTotalEntrega, setCardTotalEntrega] = useState(0);
  const [CardEntrega, setCardEntrega] = useState(0);
  const [CardTotalGeral, setCardTotalGeral] = useState(0);
  const [CardGeral, setCardGeral] = useState(0);
  const [CardTotalCancelado, setCardTotalCancelado] = useState(0);
  const [CardCancelado, setCardCancelado] = useState(0);
  const [CardTotalFinalizado, setCardTotalFinalizado] = useState(0);
  const [CardFinalizado, setCardFinalizado] = useState(0);
  const [CardTotalEmEspera, setCardTotalEmEspera] = useState(0);
  const [CardEmEspera, setCardEmEspera] = useState(0);
  const [isModalCard, setModalCard] = useState(false);
  const [isModalCancelamentoiFood, setisModalCancelamentoiFood] =
    useState(false);

  const [Ficha, setFicha] = useState(null);
  const [CaixaID, setCaixaID] = useState(null);
  const [iFoodID, setiFoodID] = useState(null);

  const [PedidoSelecionado, setPedidoSelecionado] = useState([]);
  const [MotivoCancelamentoiFood, setMotivoCancelamentoiFood] = useState([]);
  const [MotivoSelecionado, setMotivoSelecionado] = useState(-1);

  const [merchantId, setMerchantId] = useState("");

  const [DadosMotoboyEntrega, setDadosMotoboyEntrega] = useState(null);

  const [TempoDelivery, setTempoDelivery] = useState(0);
  const [TempoVemBuscar, setTempoVemBuscar] = useState(0);
  const [Estimativa, setEstimativa] = useState(0);

  const [ExibirDivImpressao, setExibirDivImpessao] = useState(false);

  const [ExibirDetalhesPedido, setExibirDetalhesPedido] = useState(false);
  const [ExibirPagamento, setExibirPagamento] = useState(false);
  const [DetalhesPedidoPagamento, setDetalhesPagamento] = useState(null);
  const [DadosDetalhesPedido, setDadosDetalhesPedido] = useState([]);

  const [PedidoCancelar, setPedidoCancelar] = useState(0);

  const [ModalFiltro, setModalFiltro] = useState(false);

  const [loading, setLoading] = useState(false);

  const [isHovered, setIsHovered] = useState(false);

  const handleClickBotao = (event) => {
    setPedidoSelecionado(event);
    setExibirDiv(true);
  };

  const handleClickBotaoImprimir = (event) => {
    setPedidoSelecionado(event);
    PedidoClick = event.codigo;
    // //console.log(event.codigo);
    setExibirDivImpessao(true);
  };

  function handleYesClick() {
    API.post(`/v1/imprimir/1/${PedidoClick}/${0}`);
  }

  const handleMouseEnter = (index) => {
    document.body.style.cursor = "pointer";
  };

  const DetalhesPedido = (id) => {
    API.get("/v2/dados/pedido/" + id).then((response) => {
      setDadosDetalhesPedido(response.data);

      setExibirDetalhesPedido(true);
    });
  };

  const handleMouseLeave = () => {
    document.body.style.cursor = "default";
  };

  const handleClick = (index) => {
    const isSelected = selectedIndices.includes(index);

    if (isSelected) {
      setSelectedIndices(selectedIndices.filter((i) => i !== index));
    } else {
      setSelectedIndices([...selectedIndices, index]);
    }
  };

  function BuscarDados() {
    dataAtualInfo = "0000-00-00";

    let url = `v1/pedidos/${transformDate(DataInicialPadrao)}/${transformDate(
      DataFinalPadrao
    )}/000000/235959/1,2,4,5/X`;

    API.get(url).then((dados) => {
      if (dados.data != Data) {
        if (dados.data != filteredData) {
          setData(dados.data);
          setFilteredData(dados.data);
        }
      }
      setLoading(false);
    });
  }

  const fetchParam = async () => {
    let ifood = await ParametroConfiguracao("ifoodIntegracao");
    let delivery = await ParametroConfiguracao("tempDelivery");
    let vemBuscar = await ParametroConfiguracao("tempVembuscar");

    setTempoDelivery(delivery);
    setTempoVemBuscar(vemBuscar);
    //console.log("vb: " + vemBuscar);

    if (ifood == 1) {
      //console.log(await ParametroConfiguracao("merchant"));
      setMerchantId(await ParametroConfiguracao("merchant"));
    }
  };

  useEffect(() => {
    // Função para buscar dados

    // Chamando as funções iniciais
    BuscarDados();
    GravaLocal("VOLTAR", "/principal");

    // Fazendo chamadas API
    API.get("/v1/motoboy/ativo/all/").then((response) => {
      setDadosMotoboy(response.data);
    });

    API.get("/v1/consulta/generica/status_pedido/*/*/*").then((dados) => {
      setDadosStatus(dados.data);
    });

    // Iniciando o intervalo
    const intervalId = setInterval(() => {
      if (ExibirPagamento === false) {
        BuscarDados();
      }
      //console.log("Buscando " + ExibirPagamento);
      //console.log(DetalhesPedidoPagamento);
    }, 30000);

    fetchParam();

    // Limpeza do intervalo quando o componente for desmontado
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    dataAtualInfo = "0000-00-00";
    const filtered = Data.filter((Pedido) =>
      `${Pedido.tipo} ${Pedido.motoboy} ${Pedido.data} ${
        Pedido.hora
      } ${Pedido.codigoDia.toString().padStart(5, "0")} ${MascaraMonetaria(
        RemoveMascara(Pedido.taxa)
      )} ${MascaraMonetaria(RemoveMascara(Pedido.total))} ${MascaraMonetaria(
        RemoveMascara(Pedido.valor)
      )} ${
        Pedido.tipo === "Delivery"
          ? PrimeiraMaiuscula(Pedido.enderecoCompleto)
          : null
      } ${MascaraCelular(Pedido.celular)} ${PrimeiraMaiuscula(
        Pedido.cliente
      )} ${Pedido.codigoDia} ${Pedido?.statusDescricao} ${Pedido.cliente} ${
        Pedido.celular
      }`
        .toLowerCase()
        .includes(searchText.toLowerCase())
    );
    setFilteredData(filtered);
  }, [searchText]);

  function convertToNumber(value) {
    // Verifica se o valor é nulo ou vazio
    if (value == null || value.trim() === "") {
      return null; // ou outro valor padrão que você prefira, como 0
    }
    // Substitui a vírgula por ponto
    const convertedValue = value.replace(",", ".");
    // Converte a string em um número
    return parseFloat(convertedValue);
  }

  useEffect(() => {
    setModalCard(false);
    let TotalEntrega = 0;
    let Entrega = 0;
    let TotalGeral = 0;
    let Geral = 0;
    let TotalCancelado = 0;
    let Cancelado = 0;
    let TotalFinalizado = 0;
    let Finalizado = 0;
    let TotalEmEspera = 0;
    let EmEspera = 0;
    let Tot = 0;

    let Motoboys = [];

    const newArray = filteredData.map((item) => {
      Tot++;

      if (item?.statusDescricao != "Cancelado") {
        TotalGeral += RemoveMascara(item.total);
        Geral++;

        if (item.clienteEndereco > 0) {
          Entrega++;
          TotalEntrega += RemoveMascara(item.taxa);
          let isMoto = false;
          for (let key in Motoboys) {
            if (Motoboys[key].name === item.motoboy) {
              isMoto = true;
              Motoboys[key].pedidos = Motoboys[key].pedidos + 1;
              Motoboys[key].value_tot =
                Motoboys[key].value_tot + convertToNumber(item.total);
              Motoboys[key].value_taxa =
                Motoboys[key].value_taxa + convertToNumber(item.taxa);
              Motoboys[key].value_pedidos =
                Motoboys[key].value_pedidos + convertToNumber(item.valor);
              Motoboys[key].pagamento.push({
                valor: convertToNumber(item.total),
                pedido: item.codigoDia,
                id: item.codigo,
                forma: item.pagamento1,
              });
            }
          }
          if (isMoto == false) {
            Motoboys.push({
              name: item.motoboy,
              pedidos: 1,
              value_tot: convertToNumber(item.total),
              value_taxa: convertToNumber(item.taxa),
              value_pedidos: convertToNumber(item.valor),
              pagamento: [
                {
                  valor: convertToNumber(item.total),
                  pedido: item.codigoDia,
                  id: item.codigo,
                  forma: item.pagamento1,
                },
              ],
            });
          }
        }

        if (item?.statusDescricao == "Finalizado") {
          TotalFinalizado += RemoveMascara(item.total);
          Finalizado++;
        } else {
          TotalEmEspera += RemoveMascara(item.total);
          EmEspera++;
        }
      } else {
        TotalCancelado += RemoveMascara(item.total);
        Cancelado++;
      }
    });

    setCardTotalEntrega(MascaraMonetaria(TotalEntrega));
    setCardTotalGeral(MascaraMonetaria(TotalGeral));
    setCardTotalCancelado(MascaraMonetaria(TotalCancelado));
    setCardTotalFinalizado(MascaraMonetaria(TotalFinalizado));
    setCardTotalEmEspera(MascaraMonetaria(TotalEmEspera));

    setCardEntrega(
      Entrega +
        " (" +
        ((Entrega / Tot) * 100).toFixed(2).replace(".", ",") +
        "%)"
    );
    setCardGeral(
      Geral + " (" + ((Geral / Tot) * 100).toFixed(2).replace(".", ",") + "%)"
    );
    setCardCancelado(
      Cancelado +
        " (" +
        ((Cancelado / Tot) * 100).toFixed(2).replace(".", ",") +
        "%)"
    );
    setCardFinalizado(
      Finalizado +
        " (" +
        ((Finalizado / Tot) * 100).toFixed(2).replace(".", ",") +
        "%)"
    );
    setCardEmEspera(
      EmEspera +
        " (" +
        ((EmEspera / Tot) * 100).toFixed(2).replace(".", ",") +
        "%)"
    );

    if (Tot > 0) {
      setModalCard(true);
    }

    setEntregasMotoboy(Motoboys);
    //console.log(filteredData);
  }, [filteredData]);

  useEffect(() => {
    if (CorPrimaria != "") {
      GravaLocal("CorPrimaria", CorPrimaria);
    }
  }, [CorPrimaria]);

  useEffect(() => {
    if (CorSecundaria != "") {
      GravaLocal("CorSecundaria", CorSecundaria);
    }
  }, [CorSecundaria]);

  useEffect(() => {
    if (CorConcluida != "") {
      GravaLocal("CorConcluida", CorConcluida);
    }
  }, [CorConcluida]);

  useEffect(() => {
    if (CorCancelada != "") {
      GravaLocal("CorCancelada", CorCancelada);
    }
  }, [CorCancelada]);

  useEffect(() => {
    if (CorEntrega != "") {
      GravaLocal("CorEntrega", CorEntrega);
    }
  }, [CorEntrega]);

  function AlteraValoresInput(type, valor) {
    if (type == "Buscar") {
      setSearchText(valor);
    }
    if (type == "Data Inicial") {
      DataInicialPadrao = valor;
      setDataInicial(valor);
      BuscarDados();
    }
    if (type == "Data Final") {
      DataFinalPadrao = valor;

      setDataFinal(valor);
      BuscarDados();
    }
  }

  function SetaMotoboy(idMotoboy) {
    const newArray = selectedIndices.map((item) => {
      API.put("v1/pedido/motoboy/" + item + "/" + idMotoboy + "/").then(
        (response) => {
          setLoading(true);
          setSelectedIndices([]);
          setisModalMotoboy(false);
          BuscarDados();
        }
      );
    });
  }

  function VisualizarPedido() {
    GravaLocal("VOLTAR_PEDIDO", "/pedidos");

    window.location.href = "/pedido/" + PedidoClick;
  }

  async function AlteraStatus(pedido, status, descricao) {
    //
    if (pedido == 0) {
      return false;
    }

    if (status == 0) {
      setPedidoCancelar(pedido);
      return false;
    }

    API.put(`v1/pedido/status/${pedido}/${DadosStatus[status].id}`).then(
      function (response) {
        BuscarDados();
      }
    );
  }

  function ColorGrid(status, type) {
    let primaryColor = alpha(hexToRgb(CorPrimaria), 0.5);
    let secondaryColor = alpha(hexToRgb(CorSecundaria), 0.5);

    if (status == 6 || status == 7) {
      primaryColor = alpha(hexToRgb(CorConcluida), 0.5);
    }
    if (status == 0) {
      primaryColor = alpha(hexToRgb(CorCancelada), 0.5);
    }

    if (type == "Delivery") {
      secondaryColor = alpha(hexToRgb(CorEntrega), 0.5);
    } else {
      secondaryColor = primaryColor;
    }

    return `linear-gradient(to bottom, ${primaryColor}, ${secondaryColor})`;
    // 'linear-gradient(to bottom, blue, green)',
  }

  let dataAnterior = "";
  const DadosPedido = (Pedido) => {
    const Igual = dataAnterior === Pedido.Pedido.data;
    console.log(Pedido.Pedido);

    if (!Igual) {
      dataAnterior = Pedido.Pedido.data;
    }
    let DescricaoTipo = Pedido.Pedido.tipo;
    let TempoPercorrido = Pedido.Pedido.tempo;
    let motoboy = false;
    if (Pedido.Pedido.tipo == "Delivery") {
      if (Pedido.Pedido.motoboy != undefined) {
        DescricaoTipo = getInitials(PrimeiraMaiuscula(Pedido.Pedido.motoboy));
        motoboy = true;
      }
    }

    return (
      <>
        <div key={Pedido.Pedido.codigo}>
          {Igual == false ? (
            <>
              <h3
                style={{
                  width: "100%",
                  textAlign: "center",
                  marginTop: "10px",
                }}
              >
                <span
                  style={{
                    width: "150px",
                    padding: "0 20px 0px 20px",
                    borderRadius: "20px",
                    fontSize: "1.3rem",
                    border: "1px solid rgba(101, 103, 103, 0.6)",
                    color: "rgba(101, 103, 103, 0.6)",
                    marginTop: "5px",
                  }}
                >
                  {verificarData(Pedido.Pedido.data)}
                </span>
              </h3>
            </>
          ) : null}
          <div
            style={{
              display: "flex",
              borderBottom: selectedIndices.includes(Pedido.Pedido.codigo)
                ? "2px solid rgba(168, 0, 28, 0.7)"
                : "1px solid #e0e0e0",
              alignItems: "center",
              padding: "1rem",
              fontSize: "1rem",
              color: "#333",
              marginTop: "0.5rem",
              background:
                Pedido.Pedido.codigo === hoveredIndex
                  ? "rgba(168, 0, 28, 0.1)"
                  : ColorGrid(Pedido.Pedido.status, Pedido.Pedido.tipo),
              borderRadius: "8px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              transition: "background-color 0.3s, box-shadow 0.3s",
            }}
          >
            <div
              style={{
                width: "110px",
                display: "flex",
                alignItems: "center",
                justifyContent: "left",
              }}
            >
              {Pedido.Pedido.tipo === "Delivery" && (
                <div
                  onMouseEnter={() => handleMouseEnter(Pedido.Pedido.codigo)}
                  onMouseLeave={handleMouseLeave}
                  onClick={() => handleClick(Pedido.Pedido.codigo)}
                  style={{
                    height: "1.5rem",
                    width: "1.5rem",
                    border: "2px solid rgba(168, 0, 28, 1)",
                    backgroundColor: selectedIndices.includes(
                      Pedido.Pedido.codigo
                    )
                      ? "rgba(168, 0, 28, 0.2)"
                      : "transparent",
                    borderRadius: "50%",
                    cursor: "pointer",
                    transition: "background-color 0.3s",
                  }}
                ></div>
              )}
            </div>

            <div
              style={{
                width: "110px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={
                  Pedido.Pedido.tipo === "Delivery"
                    ? "/do-icone-localizacao.png"
                    : "/do-icone-balcao.png"
                }
                style={{
                  height: "1.5rem",
                  width: "1.5rem",
                  marginBottom: "0.5rem",
                }}
                alt={
                  Pedido.Pedido.tipo === "Delivery" ? "Localização" : "Balção"
                }
              />
              <span
                style={{
                  fontWeight:
                    DescricaoTipo !== "Delivery" &&
                    DescricaoTipo !== "Vem Buscar"
                      ? "bold"
                      : "normal",
                }}
              >
                {DescricaoTipo}
              </span>
              <span
                style={{
                  fontWeight: "bold",
                }}
              >
                {formatarHora(TempoPercorrido)}
              </span>
            </div>

            <div style={{ width: "135px", textAlign: "center" }}>
              <p style={{ margin: "0" }}>
                #
                {Pedido.Pedido.codigoDia > 0
                  ? Pedido.Pedido.codigoDia.toString().padStart(4, "0")
                  : "0000"}
              </p>
              <p style={{ margin: "0" }}>
                {Pedido.Pedido.hora.substring(0, 5)}
              </p>
            </div>

            <div style={{ width: "15%" }}>
              <Status Pedido={Pedido.Pedido} />
            </div>

            <div style={{ width: "30%" }}>
              <div>
                <p style={{ margin: "0" }}>
                  {Pedido.Pedido.documento
                    ? `(${formatarCPForCNPJ(Pedido.Pedido.documento)}) `
                    : ""}
                  {PrimeiraMaiuscula(Pedido.Pedido.cliente)}
                </p>
                <p style={{ margin: "0" }}>
                  {Pedido.Pedido.tipo === "Delivery"
                    ? PrimeiraMaiuscula(Pedido.Pedido.enderecoCompleto)
                    : null}
                </p>
                <p style={{ margin: "0" }}>
                  {PrimeiraMaiuscula(Pedido.Pedido.pagamento1)}
                </p>
                <p style={{ margin: "0" }}>
                  {RemoveMascara(Pedido.Pedido.troco) > 0 &&
                  RemoveMascara(Pedido.Pedido.troco) !==
                    RemoveMascara(Pedido.Pedido.total)
                    ? `Troco ${MascaraMonetaria(
                        RemoveMascara(Pedido.Pedido.troco)
                      )}`
                    : null}
                </p>
                <p style={{ margin: "0", fontWeight: "bold" }}>
                  {Pedido.Pedido.descDescontoIfood !== ""
                    ? PrimeiraMaiuscula(Pedido.Pedido.descDescontoIfood)
                    : null}
                </p>
                {Pedido.Pedido.idIfood != null && (
                  <p style={{ margin: "0" }}>
                    {Pedido.Pedido.orderIfood !== "IMMEDIATE"
                      ? `Entrega Estimada ${formatarDataHora(
                          removerTresHoras(Pedido.Pedido.agendadaIfood)
                        )}`
                      : null}
                  </p>
                )}
              </div>
            </div>

            <div style={{ width: "150px", textAlign: "center" }}>
              <p style={{ margin: "0" }}>
                {RemoveMascara(Pedido.Pedido.taxa) > 0
                  ? MascaraMonetaria(RemoveMascara(Pedido.Pedido.taxa))
                  : null}
              </p>
              <p style={{ margin: "0" }}>
                <s>
                  {RemoveMascara(Pedido.Pedido.desconto) > 0
                    ? `-${MascaraMonetaria(
                        RemoveMascara(Pedido.Pedido.desconto)
                      )}`
                    : null}
                </s>
              </p>
            </div>

            <div style={{ width: "150px", textAlign: "center" }}>
              {MascaraMonetaria(RemoveMascara(Pedido.Pedido.valor))}
            </div>

            <div style={{ width: "150px", textAlign: "center" }}>
              <strong>
                {MascaraMonetaria(RemoveMascara(Pedido.Pedido.total))}
              </strong>
            </div>

            <div
              style={{
                width: "50px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div style={{ display: "flex", gap: "8px" }}>
                  <FaHamburger
                    onMouseEnter={() => handleMouseEnter(Pedido.Pedido.codigo)}
                    onMouseLeave={handleMouseLeave}
                    onClick={() => {
                      GravaLocal("VOLTAR", "/pedidos");
                      window.location = "/pedido/" + Pedido.Pedido.codigo;
                    }}
                  />
                  <FaFile
                    onMouseEnter={() => handleMouseEnter(Pedido.Pedido.codigo)}
                    onMouseLeave={handleMouseLeave}
                    onClick={() => handleClickBotaoImprimir(Pedido.Pedido)}
                  />
                  <FaPlus
                    style={{
                      color: [0, 6, 7, 9].includes(Pedido.Pedido.status)
                        ? "#ccc"
                        : "#333",
                      cursor: [0, 6, 7, 9].includes(Pedido.Pedido.status)
                        ? "not-allowed"
                        : "pointer",
                    }}
                    onMouseEnter={() => handleMouseEnter(Pedido.Pedido.codigo)}
                    onMouseLeave={handleMouseLeave}
                    onClick={() => {
                      if (![0, 6, 7, 9].includes(Pedido.Pedido.status)) {
                        GravaLocal("VOLTAR", "/pedidos");
                        window.location =
                          "/add-produto/" + Pedido.Pedido.codigo + "/0";
                      }
                    }}
                  />
                </div>

                <div style={{ display: "flex", gap: "8px", marginTop: "8px" }}>
                  <FaMoneyBillWave
                    style={{
                      color: [0, 6, 7, 9].includes(Pedido.Pedido.status)
                        ? "#ccc"
                        : "#333",
                      cursor: [0, 6, 7, 9].includes(Pedido.Pedido.status)
                        ? "not-allowed"
                        : "pointer",
                    }}
                    onMouseEnter={() => handleMouseEnter(Pedido.Pedido.codigo)}
                    onMouseLeave={handleMouseLeave}
                    onClick={() => {
                      if (![0, 6, 7, 9].includes(Pedido.Pedido.status)) {
                        GravaLocal("VOLTAR", "/pedidos");
                        window.location = "/fechamento/" + Pedido.Pedido.codigo;
                      }
                    }}
                  />
                  <FaHashtag
                    onMouseEnter={() => handleMouseEnter(Pedido.Pedido.codigo)}
                    onMouseLeave={handleMouseLeave}
                    onClick={() => DetalhesPedido(Pedido.Pedido.codigo)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const CardMotoboy = ({ data }) => {
    const NomeMotoboy = data.name
      ? PrimeiraMaiuscula(data.name)
      : "Sem Motoboy";

    const cardStyle = {
      width: "220px",
      border: "1px solid #ddd",
      borderRadius: "12px",
      marginRight: "15px",
      padding: "15px",
      position: "relative",
      boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
      transition: "transform 0.3s ease, box-shadow 0.3s ease",
      backgroundColor: "#fff",
      cursor: "pointer",
    };

    const handleMouseEnter = (e) => {
      e.currentTarget.style.transform = "scale(1.05)";
      e.currentTarget.style.boxShadow = "0px 8px 20px rgba(0, 0, 0, 0.2)";
    };

    const handleMouseLeave = (e) => {
      e.currentTarget.style.transform = "scale(1)";
      e.currentTarget.style.boxShadow = "0px 4px 12px rgba(0, 0, 0, 0.1)";
    };

    const textStyle = {
      display: "flex",
      justifyContent: "space-between",
      fontSize: "14px",
      margin: "8px 0",
      color: "#333",
    };

    const iconStyle = {
      position: "absolute",
      top: "10px",
      right: "10px",
      fontSize: "18px",
      color: "#555",
    };

    return (
      <div
        style={cardStyle}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={() => setDadosMotoboyEntrega(data)}
      >
        <FaExpandArrowsAlt style={iconStyle} />

        <h2 style={{ fontSize: "18px", color: "#333", marginBottom: "10px" }}>
          <strong>{NomeMotoboy}</strong>
        </h2>

        <div style={textStyle}>
          <h4>Entregas</h4>
          <span>
            <strong>{data?.pedidos}</strong>
          </span>
        </div>

        <div style={textStyle}>
          <h4>Pedido</h4>
          <span>
            <strong>{MascaraMonetaria(data?.value_pedidos)}</strong>
          </span>
        </div>

        <div style={textStyle}>
          <h4>Taxa</h4>
          <span>
            <strong>{MascaraMonetaria(data?.value_taxa)}</strong>
          </span>
        </div>

        <div style={textStyle}>
          <h4>Total</h4>
          <span>
            <strong>{MascaraMonetaria(data?.value_tot)}</strong>
          </span>
        </div>
      </div>
    );
  };

  function CancelamentoiFood(Motivo, Id) {
    API.post(
      `v1/util/ifood/cancelar/${Id}/${MotivoCancelamentoiFood[Motivo].cancelcodeid}/${MotivoCancelamentoiFood[Motivo].description}`
    );

    setMotivoCancelamentoiFood([]);
    setMotivoSelecionado(-1);
    setExibirDiv(false);
    setisModalCancelamentoiFood(false);
    Notifica("Pedido cancelado!");
  }

  function CancelariFood(PedidoSelecionado) {
    setiFoodID(PedidoSelecionado?.idIfood);

    if (MotivoSelecionado > -1) {
      API.post(
        `v1/util/ifood/cancelar/${PedidoSelecionado}/${MotivoCancelamentoiFood[MotivoSelecionado].cancelcodeid}/${MotivoCancelamentoiFood[MotivoSelecionado].description}`
      );

      setMotivoCancelamentoiFood([]);
      setMotivoSelecionado(-1);
      setExibirDiv(false);
      setisModalCancelamentoiFood(false);
    } else {
      API.get(`v1/util/ifood/lista/motivo/${PedidoSelecionado?.idIfood}`).then(
        function (dados) {
          setMotivoCancelamentoiFood(dados.data);
          //Fazer um modal para selecionar o motivo do cancelamento
          setisModalCancelamentoiFood(true);
        }
      );
    }
  }

  function PreparariFood(PedidoSelecionado) {
    API.post(`v1/util/ifood/preparar/${PedidoSelecionado?.idIfood}`);
  }

  function DespachariFood(PedidoSelecionado) {
    API.post(`v1/util/ifood/despachar/${PedidoSelecionado?.idIfood}`);
  }

  function RetiradaiFood(PedidoSelecionado) {
    API.post(`v1/util/ifood/retirar/${PedidoSelecionado?.idIfood}`);
  }

  function ConfirmariFood(PedidoSelecionado) {
    if (PedidoSelecionado?.statusIfood == "PLC") {
      API.post(`v1/util/ifood/confirmar/${PedidoSelecionado?.idIfood}`);
    }

    if (PedidoSelecionado?.statusIfood == "CFM") {
      if (PedidoSelecionado.orderIfood != "IMMEDIATE") {
        const dataFornecida = new Date(PedidoSelecionado.estimadaIfood);

        const agora = new Date();

        // Compara as duas datas/horas
        if (agora > dataFornecida) {
          NotificaWarning(
            "Só é possível preparar o pedido dentro da hora (" +
              formatarDataHora(PedidoSelecionado.estimadaIfood) +
              ")"
          );
        } else {
          API.post(`v1/util/ifood/preparar/${PedidoSelecionado?.idIfood}`);
        }
      } else {
        API.post(`v1/util/ifood/preparar/${PedidoSelecionado?.idIfood}`);
      }

      //
    }

    if (PedidoSelecionado?.statusIfood == "PRS") {
      if (PedidoSelecionado?.clienteEndereco > 0) {
        API.post(`v1/util/ifood/despachar/${PedidoSelecionado?.idIfood}`);
      } else {
        API.post(`v1/util/ifood/retirar/${PedidoSelecionado?.idIfood}`);
      }
    }
    setExibirDiv(false);
    setMotivoCancelamentoiFood([]);
    setMotivoSelecionado(-1);
  }

  function BotaoIfood(Descricao, Icone, Funcao) {
    return (
      <>
        <div>
          <Tooltip title={Descricao} onClick={Funcao} arrow>
            <IconButton onClick={Funcao}>{Icone}</IconButton>
          </Tooltip>

          <span onClick={Funcao}>{Descricao}</span>
        </div>
      </>
    );
  }

  function DescricaoiFood(Pedido) {
    if (Pedido?.statusIfood == "PLC") {
      return (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {BotaoIfood("Aceitar Pedido", <FaCartArrowDown />, () =>
            ConfirmariFood(Pedido)
          )}
          {BotaoIfood("Cancelar Pedido", <FaCartArrowDown />, () =>
            CancelariFood(Pedido)
          )}
        </div>
      );
    }

    if (Pedido?.statusIfood == "CAN") {
      return "Cancelado";
    }

    if (Pedido?.statusIfood == "DSP") {
      return (
        <div>
          {BotaoIfood(
            "Em Rota De Entrega",
            <img
              src={"/do-icone-balcao.png"}
              style={{ height: "1.5rem", width: "1.5rem" }}
            />,
            () =>
              Notifica("Esse pedido já está em rota de entrega até o cliente!")
          )}
          {BotaoIfood("Cancelar Pedido", <FaCartArrowDown />, () =>
            CancelariFood(Pedido)
          )}
        </div>
      );
    }

    if (Pedido?.statusIfood == "CFM") {
      if (PedidoSelecionado.orderIfood != "IMMEDIATE") {
        const dataFornecida = new Date(PedidoSelecionado.estimadaIfood);

        let agora = new Date();
        agora.setHours(agora.getHours() - 3);
        agora = agora.toISOString();

        // Compara as duas datas/horas
        if (agora > Pedido.estimadaIfood) {
          return (
            <div style={{ display: "flex", flexDirection: "column" }}>
              {BotaoIfood("Concluir Preparação", <FaCheck />, () =>
                PreparariFood(Pedido)
              )}
              {BotaoIfood("Cancelar Pedido", <FaCartArrowDown />, () =>
                CancelariFood(Pedido)
              )}
            </div>
          );
        } else {
          return "Preparar " + formatarDataHora(Pedido.estimadaIfood);
        }
      } else {
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            {BotaoIfood("Concluir Preparação", <FaCheck />, () =>
              PreparariFood(Pedido)
            )}
            {BotaoIfood("Cancelar Pedido", <FaCartArrowDown />, () =>
              CancelariFood(Pedido)
            )}
          </div>
        );
      }
    }

    if (Pedido?.statusIfood == "PRS") {
      if (Pedido?.clienteEndereco > 0) {
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            {BotaoIfood(
              "Despachar Pedido",
              <img
                src={"/do-icone-localizacao.png"}
                style={{ height: "1.5rem", width: "1.5rem" }}
              />,
              () => DespachariFood(Pedido)
            )}
            {BotaoIfood("Cancelar Pedido", <FaCartArrowDown />, () =>
              CancelariFood(Pedido)
            )}
          </div>
        );
      } else {
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            {BotaoIfood(
              "Disponivel Para Retirada",
              <img
                src={"/do-icone-localizacao.png"}
                style={{ height: "1.5rem", width: "1.5rem" }}
              />,
              () => RetiradaiFood(Pedido)
            )}
            {BotaoIfood("Cancelar Pedido", <FaCartArrowDown />, () =>
              CancelariFood(Pedido)
            )}
          </div>
        );
      }
    }
    if (Pedido?.statusIfood == "CON") {
      return (
        <>
          <p>Concluído,</p> Aguardando Faturamento
        </>
      );
    }
    if (Pedido?.statusIfood == "RTP") {
      return (
        <>
          <p>Concluído,</p> Aguardando Retirada
        </>
      );
    }
    return Pedido?.statusIfood;
  }

  const Status = (Pedido) => {
    return (
      <>
        {Pedido.Pedido?.idIfood != null ? (
          <>
            <h1>{DescricaoiFood(Pedido.Pedido)}</h1>
          </>
        ) : (
          <>
            <select
              disabled={
                Pedido.Pedido.status > 0 && Pedido.Pedido.status < 6
                  ? false
                  : true
              }
              style={{
                height: "34px",
                width: "90%",
                border: "1px solid",
                margin: "10px",
                background: "transparent",
                borderRadius: "20px",
              }}
              onChange={(e) =>
                AlteraStatus(Pedido.Pedido.codigo, e.target.value)
              }
            >
              {DadosStatus.map((item, index) => (
                <option
                  key={index}
                  value={index}
                  selected={
                    PrimeiraMaiuscula(item.descricao) ==
                    PrimeiraMaiuscula(Pedido.Pedido?.statusDescricao)
                      ? true
                      : false
                  }
                >
                  {PrimeiraMaiuscula(item.descricao)}
                </option>
              ))}
            </select>
          </>
        )}
      </>
    );
  };

  function NovoPedido() {
    GravaLocal("VOLTAR", "/pedidos");
    setLoading(true);
    API.get("v1/codigo/pedido")
      .then(function (response) {
        window.location = `/pedido/${response.data.codigo}`;
      })
      .catch(function (error) {
        window.location = "/pedido/0";
      });
  }

  function Reimportar(codigo) {
    //console.log(`https://ws.goopedir.com/v1/reimportar.php?codigo=${codigo}`);

    API.post(`/v2/reimportar/pedido/site/${codigo}`)
      .then(function (response) {
        Notifica("Solicitação feita com sucesso, aguarde!");
      })
      .catch(function (err) {
        NotificaWarning("Erro ao fazer a solicitação, tente novamente");
      });
  }

  return (
    <>
      <HeaderComponent
        ModalFiltro={() => setModalFiltro(true)}
        InativarAtualizacoes={false}
      >
        {PermissaoModulo("pdv") ? (
          <ComponentCadastro Titulo="Novo Pedido" Funcao={NovoPedido} />
        ) : null}

        <div
          style={{
            height: "80vh",
            width: "100%",
            color: "#65676799",
            overflow: ExibirPagamento ? "hidden" : "",
          }}
        >
          {isModalCard ? (
            <>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                {!OcultarEstimativa ? (
                  <CardTempo tipo={1} valor={TempoVemBuscar} />
                ) : null}
                {!OcultarEstimativa ? (
                  <CardTempo tipo={2} valor={TempoDelivery} />
                ) : null}
              </div>

              {!OcultarTotalizadores ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "center",
                      marginTop: "10px",
                    }}
                  >
                    <CardLegenda
                      Titulo={CardTotalEntrega}
                      SubTitulo={CardEntrega}
                      Legenda="Taxa de Entrega"
                      Color="65, 48, 195"
                      FontColor="#ffffff"
                    />
                    <CardLegenda
                      Titulo={CardTotalCancelado}
                      SubTitulo={CardCancelado}
                      Legenda="Cancelado"
                      Color="217, 63, 63"
                      FontColor="#ffffff"
                    />
                    <CardLegenda
                      Titulo={CardTotalFinalizado}
                      SubTitulo={CardFinalizado}
                      Legenda="Finalizado"
                      Color="102, 205, 170"
                      FontColor="#ffffff"
                    />
                    <CardLegenda
                      Titulo={CardTotalEmEspera}
                      SubTitulo={CardEmEspera}
                      Legenda="Em Espera"
                      Color="255, 92, 0"
                      FontColor="#ffffff"
                    />
                    <CardLegenda
                      Titulo={CardTotalGeral}
                      SubTitulo={CardGeral}
                      Legenda="Total"
                      Color="69, 193, 89"
                      FontColor="#ffffff"
                    />
                  </div>
                </>
              ) : null}
            </>
          ) : null}

          <div
            style={{
              padding: 0,
              display: "flex",
              marginBottom: "10px",
              color: "black",
            }}
          >
            {EntregasMotoboy.map((Motoboy, index) => (
              <CardMotoboy key={index} data={Motoboy} />
            ))}
          </div>

          {/* <div
            style={{
              display: "flex",
              borderBottom: "1px solid #dcdcdc",
              alignItems: "center",
              height: "2.35rem",
              padding: "1rem",
              fontSize: "1rem",
              top: "60px",
              left: 0,
              right: 0,
              backgroundColor: "white",
            }}
          >
            <div
              style={{ width: "110px" }}
              onClick={(e) => setSelectedIndices([])}
            >
              Selecionar
            </div>
            <div
              style={{
                width: "110px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Tipo
            </div>
            <div
              style={{
                width: "135px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Código
            </div>
            <div style={{ width: "15%" }}>Status</div>
            <div style={{ width: "30%" }}>Cliente</div>
            <div
              style={{
                width: "150px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Taxa
            </div>
            <div
              style={{
                width: "150px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Itens
            </div>
            <div
              style={{
                width: "150px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Total
            </div>
            <div
              style={{
                width: "50px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            ></div>
          </div> */}
          <div
            style={{
              display: "flex",
              borderBottom: "2px solid #e0e0e0",
              alignItems: "center",
              height: "3rem",
              padding: "0.5rem 1rem",
              fontSize: "1.1rem",
              position: "sticky",
              top: "60px",
              left: 0,
              right: 0,
              backgroundColor: "#f9f9f9",

              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <div
              style={{
                width: "120px",
                cursor: "pointer",

                fontWeight: "bold",
                textAlign: "center",
              }}
              onClick={(e) => setSelectedIndices([])}
            >
              Selecionar
            </div>

            <div
              style={{
                width: "120px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: "bold",
              }}
            >
              Tipo
            </div>

            <div
              style={{
                width: "135px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: "bold",
              }}
            >
              Código
            </div>

            <div
              style={{
                width: "15%",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              Status
            </div>

            <div
              style={{
                width: "30%",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              Cliente
            </div>

            <div
              style={{
                width: "150px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: "bold",
              }}
            >
              Taxa
            </div>

            <div
              style={{
                width: "150px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: "bold",
              }}
            >
              Itens
            </div>

            <div
              style={{
                width: "150px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: "bold",
              }}
            >
              Total
            </div>

            <div
              style={{
                width: "50px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            ></div>
          </div>

          <div style={{ padding: 0, margin: 0 }}>
            {filteredData.map((Pedido, index) =>
              OcutarFaturados &&
              (Pedido.idCaixa > 0 || [0, 9].includes(Pedido.status)) ? null : (
                <DadosPedido
                  Pedido={Pedido}
                  key={index}
                  setExibirPagamento={setExibirPagamento}
                  setDetalhesPagamento={setDetalhesPagamento}
                />
              )
            )}
          </div>
        </div>
        {isModalMotoboy ? (
          <ModalMotoboy
            onYesClick={SetaMotoboy}
            data={DadosMotoboy}
            onNoClick={() => setisModalMotoboy(false)}
          />
        ) : null}

        {exibirDiv && (
          <ShowModal closeButton={() => setExibirDiv(false)}>
            <div style={{ width: "99vw", padding: "10px" }}>
              <ComponentePedido id={PedidoSelecionado.codigo} />
            </div>
          </ShowModal>
        )}

        {ExibirDetalhesPedido && (
          <ShowModal
            altura={100}
            largura={100}
            closeButton={() => setExibirDetalhesPedido(false)}
          >
            <div style={{ width: "20vw", height: "20vh", padding: "5px" }}>
              <div
                style={{
                  width: "100%",
                  backgroundColor: "#CCCCCC",
                  borderRadius: "20px 0px 20px 0px",
                  textAlign: "center",
                  color: "#000000",
                }}
              >
                <b>Detalhes do Pedido</b>
              </div>
              <div>
                <p style={{ color: "#CCCCCC", marginBottom: "-6px" }}>Código</p>
                <p>
                  <b style={{ color: "#CCCCCC" }}>
                    #{DadosDetalhesPedido[0].codigo} -{" "}
                    <span style={{ color: "#000000" }}>
                      {DadosDetalhesPedido[0].codigoDia}
                    </span>
                  </b>
                </p>
                {DadosDetalhesPedido[0].codigoSite > 1 ? (
                  <>
                    <p style={{ color: "#CCCCCC", marginBottom: "-6px" }}>
                      Código Site
                    </p>
                    <p>
                      <b>{DadosDetalhesPedido[0].codigoSite}</b>
                    </p>
                    <p
                      style={{
                        textAlign: "center",
                        color: isHovered ? "red" : "black", // Altere as cores conforme necessário
                      }}
                      onClick={() =>
                        Reimportar(DadosDetalhesPedido[0].codigoSite)
                      }
                      onMouseEnter={() => setIsHovered(true)}
                      onMouseLeave={() => setIsHovered(false)}
                    >
                      <b>Clique aqui para reimportar o pedido do site!</b>
                    </p>
                  </>
                ) : null}

                <p style={{ color: "#CCCCCC", marginBottom: "-6px" }}>
                  Data / Hora
                </p>
                <p>
                  <b>
                    {formatarData(DadosDetalhesPedido[0].dataPedido)} ás{" "}
                    {DadosDetalhesPedido[0].horaPedido.substring(0, 5)}
                  </b>
                </p>

                <p style={{ color: "#CCCCCC", marginBottom: "-6px" }}>
                  Valor itens
                </p>
                <p>
                  <b>{MascaraMonetaria(DadosDetalhesPedido[0].valorItens)}</b>
                </p>

                <p style={{ color: "#CCCCCC", marginBottom: "-6px" }}>
                  Desconto
                </p>
                <p>
                  <b>-{MascaraMonetaria(DadosDetalhesPedido[0].desconto)}</b>
                </p>

                <p style={{ color: "#CCCCCC", marginBottom: "-6px" }}>
                  Acrécimo
                </p>
                <p>
                  <b>{MascaraMonetaria(DadosDetalhesPedido[0].acrecimo)}</b>
                </p>

                <p style={{ color: "#CCCCCC", marginBottom: "-6px" }}>
                  Taxa de Entrega
                </p>
                <p>
                  <b>{MascaraMonetaria(DadosDetalhesPedido[0].taxaEntrega)}</b>
                </p>
                <p style={{ color: "#CCCCCC", marginBottom: "-6px" }}>Total</p>
                <p>
                  <b>{MascaraMonetaria(DadosDetalhesPedido[0].valorTotal)}</b>
                </p>

                <p style={{ color: "#CCCCCC", marginBottom: "-6px" }}>
                  Forma de Pagamento
                </p>
                <p>
                  <b>
                    {PrimeiraMaiuscula(
                      DadosDetalhesPedido[0].pagamentoSelecionado
                    )}
                  </b>
                </p>
              </div>

              {DadosDetalhesPedido[0].pagamentoSelecionado != "" ? (
                <>
                  <div
                    style={{
                      width: "100%",
                      backgroundColor: "#CCCCCC",
                      borderRadius: "20px 0px 20px 0px",
                      textAlign: "center",
                      color: "#000000",
                    }}
                  >
                    <b>Detalhes do Faturamento</b>
                  </div>

                  {DadosDetalhesPedido.map((item, index) => (
                    <div key={index} style={{ textAlign: "left" }}>
                      <b>
                        {PrimeiraMaiuscula(item.pagamentoRealizadoDescricao)}
                      </b>{" "}
                      <span style={{ float: "right" }}>
                        {MascaraMonetaria(item.pagamentoRealizadoValor)}
                      </span>
                    </div>
                  ))}
                </>
              ) : (
                ""
              )}
            </div>
          </ShowModal>
        )}
        <IfoodWidget merchantId={merchantId} />
        {DadosMotoboyEntrega ? (
          <ModalDadosMotoboy
            dados={DadosMotoboyEntrega}
            close={() => setDadosMotoboyEntrega(null)}
          />
        ) : null}

        {ExibirDivImpressao ? (
          <ModalComponent
            question={"Deseja imprimir?"}
            onYesClick={handleYesClick}
            onNoClick={() => setExibirDivImpessao(false)}
          />
        ) : null}
      </HeaderComponent>
      {selectedIndices.length > 0 ? (
        <div class="div-localizacao" onClick={() => setisModalMotoboy(true)}>
          <img
            src={"/do-icone-localizacao.png"}
            style={{ height: "2.5rem", width: "2.5rem" }}
          />
        </div>
      ) : null}
      {PedidoCancelar > 0 ? (
        <>
          {" "}
          <SenhaCancelamentoPedido
            pedido={PedidoCancelar}
            cancelar={() => setLoading(true)}
            off={() => setLoading(false)}
            close={() => [setPedidoCancelar(0), BuscarDados()]}
          />
        </>
      ) : null}

      {ExibirPagamento ? (
        <>
          <ShowModal closeButton={() => setExibirPagamento(false)}>
            <div style={{ width: "98vw", padding: "10px" }}>
              <FechamentoPages
                PedidoID={DetalhesPedidoPagamento}
                closeButton={() => [setExibirPagamento(false), BuscarDados()]}
              />
            </div>
          </ShowModal>
        </>
      ) : null}
      {/* <FechamentoPages /> */}
      {ModalFiltro ? (
        <>
          <ShowModal closeButton={() => setModalFiltro(false)}>
            <div
              style={{
                width: "320px",
                paddingTop: "20px",
                display: "flex",
                gap: "10px",
                flexDirection: "column",
              }}
            >
              <InputPedido
                Tipo="date"
                Descricao="Data Inicial"
                Retorno={AlteraValoresInput}
                Valor={DataInicial}
              />
              <InputPedido
                Tipo="date"
                Descricao="Data Final"
                Retorno={AlteraValoresInput}
                Valor={DataFinal}
              />
              <InputPedido
                Descricao="Buscar"
                Retorno={AlteraValoresInput}
                Valor={searchText}
              />
              <Toggle
                Titulo={`Personalizar Cores`}
                Change={() => [
                  setPersonalizarCores(!personalizarCores),
                  GravaLocal("OcutarFaturados", !OcutarFaturados),
                ]}
                Checked={personalizarCores}
              />
              {personalizarCores ? (
                <>
                  <TextField
                    label="Cor Primaria"
                    type="color"
                    value={CorPrimaria}
                    onChange={(e) => setCorPrimaria(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    sx={{ width: "100%" }}
                  />
                  <TextField
                    label="Cor Secundaria"
                    type="color"
                    size="small"
                    value={CorSecundaria}
                    onChange={(e) => setCorSecundaria(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    sx={{ width: "100%" }}
                  />
                  <TextField
                    label="Cor Concluida"
                    type="color"
                    size="small"
                    value={CorConcluida}
                    onChange={(e) => setCorConcluida(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    sx={{ width: "100%" }}
                  />
                  <TextField
                    label="Cor Cancelada"
                    type="color"
                    size="small"
                    value={CorCancelada}
                    onChange={(e) => setCorCancelada(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    sx={{ width: "100%" }}
                  />
                  <TextField
                    label="Cor Delivery"
                    type="color"
                    size="small"
                    value={CorEntrega}
                    onChange={(e) => setCorEntrega(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    sx={{ width: "100%" }}
                  />
                </>
              ) : null}
              <div
                style={{
                  flex: isMobileDevice() ? "0 0 100%" : "0 0 350px",
                  marginRight: "10px",
                  zIndex: 1,
                  // display: "flex",
                }}
              >
                <Toggle
                  Titulo={`Ocultar Faturados e Cancelado`}
                  Change={() => [
                    setOcutarFaturados(!OcutarFaturados),
                    GravaLocal("OcutarFaturados", !OcutarFaturados),
                  ]}
                  Checked={OcutarFaturados}
                />
                <Toggle
                  Titulo={`Ocultar Tempo Estimado`}
                  Change={() => [
                    setOcultarEstimativa(!OcultarEstimativa),
                    GravaLocal("OcultarEstimativa", !OcultarEstimativa),
                  ]}
                  Checked={OcultarEstimativa}
                />

                <Toggle
                  Titulo={`Ocultar Totalizadores`}
                  Change={() => [
                    setOcultarTotalizadores(!OcultarTotalizadores),
                    GravaLocal("OcultarTotalizadores", !OcultarTotalizadores),
                  ]}
                  Checked={OcultarTotalizadores}
                />
              </div>
            </div>
          </ShowModal>
        </>
      ) : null}
      {loading && <LoadingOverlay />}
      {isModalCancelamentoiFood && (
        <ModalCancelamentoiFood
          id={iFoodID}
          closeButton={() => setisModalCancelamentoiFood(false)}
          motivo={MotivoCancelamentoiFood}
          funcaoCancelar={CancelamentoiFood}
          Set={setMotivoSelecionado}
        />
      )}
    </>
  );
}
