import { useEffect, useState } from "react";
import { InputPedido } from "../../page/pedido/componente/input";
import {
  MascaraInteiro,
  MascaraMonetaria,
  TransformarValor,
  getCaixa,
} from "../../controller/data";
import { ShowModal } from "../modalPai";
import { Button } from "../button";
import {
  Notifica,
  NotificaWarning,
  PrimeiraMaiuscula,
} from "../../controller/util";
import { API } from "../../controller/api/api";
import { ModalComponent } from "../modal";

export function ModalSangria({ Close }) {
  const [ValorSangria, SetValorSangria] = useState(0);
  const [DescricaoSangria, SetDescricaoSangria] = useState("");
  const [CodigoCaixa, setCodigoCaixa] = useState(0);
  const [isModal, setModal] = useState(false);

  async function Caixa() {
    const result = await getCaixa();

    if (result) {
      setCodigoCaixa(result.id);
    }
  }

  useEffect(() => {
    Caixa();
  }, []);

  function Sangria() {
    //Fazer confirmação

    if (ValorSangria < 0) {
      NotificaWarning("Valor da sangria inválido!");
      return true;
    }
    if (DescricaoSangria.length == 0) {
      NotificaWarning("Descrição da sangria inválida!");
      return true;
    }

    setModal(true);
  }

  function handleYesClick() {
    let valor = TransformarValor(ValorSangria).toString();
    let body = {
      caixa: CodigoCaixa,
      descricao: DescricaoSangria,
      valor: valor.replace(".", ","),
    };

    API.post(`/v1/caixa/sangria`, body).then((response) => {
      Notifica("Sangria feita com sucesso!");
      setModal(false);
      Close();
    });
  }

  return (
    <>
      {isModal ? (
        <ModalComponent
          question={"Deseja confirmar a sangria?"}
          onYesClick={handleYesClick}
          onNoClick={() => setModal(false)}
        />
      ) : null}

      <ShowModal closeButton={Close}>
        <h1
          style={{ textAlign: "center", fontSize: "1.2rem", color: "#A8001C" }}
        >
          <b>Sangria</b>
        </h1>
        <div style={{ width: "30vw" }}>
          <h3 style={{ color: "#999999" }}>Valor</h3>
          <InputPedido
            id={"sangria"}
            Valor={MascaraMonetaria(TransformarValor(ValorSangria))}
            Set={SetValorSangria}
          />
          <h3 style={{ color: "#999999" }}>Descrição</h3>
          <InputPedido
            id={"descricao"}
            Valor={DescricaoSangria}
            Set={SetDescricaoSangria}
            sx={{ marginBottom: "15px" }}
          />
          {CodigoCaixa > 0 ? (
            <>
              <Button
                fontColor={"black"}
                borderColor={"black"}
                descricao={"Sangria"}
                BorderRadius={"5px"}
                evento={PrimeiraMaiuscula(Sangria)}
              />
            </>
          ) : null}
        </div>
      </ShowModal>
    </>
  );
}
