import { useEffect, useMemo, useState } from "react";
import { HeaderComponent } from "../../component/header";
import { API } from "../../controller/api/api";
import { Notifica, PrimeiraMaiuscula } from "../../controller/util";
import { FormControlLabel, IconButton, Switch, TextField } from "@mui/material";
import Table from "../../component/tabela";
import {
  FaCheck,
  FaFilter,
  FaRegClock,
  FaRegTimesCircle,
  FaTimes,
} from "react-icons/fa";
import { Button } from "../../component/button";
import LoadingOverlay from "../../component/load";

let DadosAtual = [];
export function PageAtivaBloqueiaItens() {
  const [RetornoBusca, setRetornoBusca] = useState("");
  const [Dados, setData] = useState([]);
  const [filterInput, setFilterInput] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (RetornoBusca != "") {
      BuscarDados();
    }
  }, [RetornoBusca]);

  function ExecutarBusca() {
    setRetornoBusca(filterInput);
  }

  function BuscarDados() {
    let busca = RetornoBusca ? RetornoBusca : filterInput;
    //console.log(busca);
    if (busca != "") {
      API.get(`v2/busca/produtos/${busca}`).then(function (data) {
        if (data.data != null) {
          DadosAtual = data.data;
          setData(data.data);
          setLoading(false);
        }
      });
    } else {
      setLoading(false);
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "Nome",
        accessor: "nome",
        size: "80%",
        formatOption: PintaMensagem,
        textAlign: "left",
      },
      {
        Header: "Status",
        accessor: "status",
        size: "20%",
        formatOption: PintaValores,
        // format: MascaraInteiro,
        // formatOption: PintaValores,
        textAlign: "left",
      },
      {
        Header: "Tipo",
        accessor: "tipoDescricao",
        size: "20%",
        // format: PintaValores,
        // format: MascaraInteiro,
        formatOption: PintaValoresExecuta,
        textAlign: "left",
      },
    ],
    []
  );

  function PintaMensagem(valor, dataFiltro) {
    var item = DadosAtual.find(function (elemento) {
      return elemento.id === valor.id;
    });

    return (
      <>
        <p>
          <b>
            <span style={{ color: "#ccc" }}>
              {PrimeiraMaiuscula(item.tipoDescricao)}
            </span>{" "}
            - {item.nome}
          </b>
        </p>
        <p>{PrimeiraMaiuscula(item.descricao)}</p>
        <p style={{ color: "#ccc" }}>{item.produto}</p>
      </>
    );
  }

  function PintaValores(valor, dataFiltro) {
    var item = DadosAtual.find(function (elemento) {
      return elemento.id === valor.id;
    });

    return (
      <>
        <FormControlLabel
          disabled
          control={<Switch checked={item.status == 1} />}
          label="Status"
        />
      </>
    );
  }

  function AtivarInativar(codigo, tipo, status, descricao) {
    setLoading(true);
    //console.log("codigo: " + codigo + " status: " + status + " tipo: " + tipo);
    API.post(`/v2/ativa/inativa/itens/${codigo}/${status}/${tipo}`).then(
      function (response) {
        BuscarDados();
        Notifica(
          PrimeiraMaiuscula(descricao) + status == 0
            ? " Ativado Com Sucesso!"
            : " Pausado Com Sucesso!"
        );
      }
    );
  }

  function PintaValoresExecuta(valor, dataFiltro) {
    var item = DadosAtual.find(function (elemento) {
      return elemento.id === valor.id;
    });

    return (
      <>
        <Button
          descricao={item.status == 1 ? "Pausar" : "Ativar"}
          evento={() =>
            AtivarInativar(
              item.id,
              item.tipo,
              item.status == 1 ? 0 : 1,
              item.tipoDescricao
            )
          }
          borderColor="#A8001C"
          fontColor="#A8001C"
          BorderRadius={"5px"}
          icon={item.status == 1 ? <FaRegTimesCircle /> : <FaCheck />}
        />
      </>
    );
  }

  const handleFilterChange = (e) => {
    const value = e.target.value || undefined;

    setFilterInput(value);
  };

  return (
    <>
      <HeaderComponent>
        {loading && <LoadingOverlay />}{" "}
        <div style={{ display: "flex", gap: "10px" }}>
          <TextField
            id="outlined-basic"
            label="Pesquisar"
            size="small"
            sx={{ width: "100%", marginBottom: "10px" }}
            value={filterInput}
            onChange={handleFilterChange}
          />
          <Button
            descricao={"Filtrar"}
            size="small"
            evento={ExecutarBusca}
            sx={{ width: "10%", marginBottom: "10px" }}
            borderColor="#A8001C"
            fontColor="#A8001C"
            BorderRadius={"5px"}
            icon={<FaFilter />}
          />
        </div>
        <Table
          columns={columns}
          data={Dados}
          codigo={"id"}
          ocultarPesquisa={true}
        />
      </HeaderComponent>
    </>
  );
}
