import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { LoginPage } from "./page/login";
import { HomePage } from "./page/home";
import { Box, ThemeProvider, createTheme } from "@mui/system";
import { ChakraProvider } from "@chakra-ui/react";
import { SalaoPage } from "./page/salao";
import { SalaoPageId } from "./page/salaopage";
import { TransferenciaPage } from "./page/salaopage/componente/transferencia";
import { PedidoPage } from "./page/pedido";
import { AddProdutoPage } from "./page/addproduto";
import { CaixaPage } from "./page/caixa";
import { isLogin, isMotoboy } from "./controller/util";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RelatorioVendasPages } from "./page/relatorio/vendas";
import { FechamentoPages } from "./page/fechamento";
import { PedidosPage } from "./page/pedidos";

import { LoginPageMotoboy } from "./page/motoboylogin";
import { ProductPage } from "./page/produto";
import { Impressora } from "./page/impressora";
import { PageParameters } from "./page/parametro";
import { DashBoard } from "./page/relatorio/dashboard";
import { PageTaxaEntrega } from "./page/taxa";
import { PageMotoboy } from "./page/motoboy";
import { PageTipoPagamento } from "./page/pagamento";
import { CupomDesconto } from "./page/cupom";
import { PageClient } from "./page/cliente";

import { PageBloqueado } from "./page/bloqueado";
import { PageEstoque } from "./page/estoque";
import Test from "./component/testes";
import { PageComanda } from "./page/comanda";
import { Usuario } from "./page/usuario";
import { PermissaoAcesso } from "./controller/parametros";
import { PageCaixa } from "./page/caixa/relatorio/component";
import { FideldiadePage } from "./page/fidelidade";
import { ThemaUi } from "./controller/mobile";
import { CadastroComandaMesa } from "./page/comanda/cadastro";
import { Pagehorario } from "./page/horario";
import { PageFuncionario } from "./page/funcionario";
import { PaginaPIX } from "./page/pix";
import { PageMarketin } from "./page/marketin";
import { NomeModulo, PermissaoModulo } from "./controller/modulos";
import { PageSemAcesso } from "./page/semAcesso";
import PedidoMap from "./page/localizacao";
import { PageAtivaBloqueiaItens } from "./page/ativabloqueiaItens";

function App() {
  //Allan;
  return (
    <>
      <ToastContainer style={{ zIndex: 99999999999 }} />

      <ChakraProvider>
        <ThemeProvider theme={ThemaUi()}>
          <BrowserRouter>
            <Routes>
              <Route path="/login" element={<LoginPage />} />

              {isLogin() ? (
                <>
                  <Route path="/principal" element={<HomePage />} />
                  <Route
                    path="/salao"
                    // element={<SalaoPage />}
                    element={
                      PermissaoModulo("mesas") ? (
                        <SalaoPage />
                      ) : (
                        <PageSemAcesso NomeModulo={NomeModulo("mesas")} />
                      )
                    }
                  />
                  <Route
                    path="/comanda"
                    //element={<PageComanda />}
                    element={
                      PermissaoModulo("mesas") ? (
                        <PageComanda />
                      ) : (
                        <PageSemAcesso NomeModulo={NomeModulo("mesas")} />
                      )
                    }
                  />
                  <Route
                    path="/controle-rotas"
                    //element={<PageComanda />}
                    element={
                      PermissaoModulo("rotas") ? (
                        <PedidoMap />
                      ) : (
                        <PageSemAcesso NomeModulo={NomeModulo("rotas")} />
                      )
                    }
                  />
                  <Route
                    path="/salao/:id" //element={<SalaoPageId />}
                    element={
                      PermissaoModulo("mesas") ? (
                        <SalaoPageId />
                      ) : (
                        <PageSemAcesso NomeModulo={NomeModulo("mesas")} />
                      )
                    }
                  />
                  {/* Colocado função para possivel tradução no sistema acima /\ */}
                  <Route
                    path="/add-produto/:pedido"
                    element={<AddProdutoPage />}
                  />
                  <Route
                    path="/add-produto/:pedido/:mesa"
                    element={<AddProdutoPage />}
                  />
                  <Route
                    path="/salao/transferencia/:id"
                    element={<TransferenciaPage />}
                  />
                  <Route path="/pedido/:id" element={<PedidoPage />} />

                  {PermissaoAcesso("dashboard") ? (
                    <Route
                      path="/relatorio-vendas"
                      element={<RelatorioVendasPages />}
                    />
                  ) : null}
                  {PermissaoAcesso("dashboard") ? (
                    <Route path="/cardapio" element={<ProductPage />} />
                  ) : null}
                  {PermissaoAcesso("dashboard") ? (
                    <Route
                      path="/cardapio-ativa-bloqueia"
                      element={<PageAtivaBloqueiaItens />}
                    />
                  ) : null}
                  {PermissaoAcesso("dashboard") ? (
                    <Route path="/dashboard" element={<DashBoard />} />
                  ) : null}
                  {PermissaoAcesso("dashboard") ? (
                    <Route path="/cadastro-usuario" element={<Usuario />} />
                  ) : null}
                  {PermissaoAcesso("dashboard") ? (
                    <>
                      <Route
                        path="/pix-pendente"
                        element={
                          PermissaoModulo("pix") ? (
                            <PaginaPIX />
                          ) : (
                            <PageSemAcesso NomeModulo={NomeModulo("pix")} />
                          )
                        }
                      />
                    </>
                  ) : null}
                  {PermissaoAcesso("dashboard") ? (
                    <Route
                      path="/marketin"
                      element={
                        PermissaoModulo("campanha") ? (
                          <PageMarketin />
                        ) : (
                          <PageSemAcesso NomeModulo={NomeModulo("campanha")} />
                        )
                      }
                    />
                  ) : null}
                  {PermissaoAcesso("dashboard") ? (
                    <Route
                      path="/cadastro-funcionario"
                      element={<PageFuncionario />}
                    />
                  ) : null}

                  {PermissaoAcesso("caixa") ? (
                    <>
                      <Route
                        path="/caixa"
                        // element={<CaixaPage />}

                        element={
                          PermissaoModulo("caixa") ? (
                            <CaixaPage />
                          ) : (
                            <PageSemAcesso NomeModulo={NomeModulo("caixa")} />
                          )
                        }
                      />
                      <Route
                        path="/resumo-caixa/:id"
                        element={<PageCaixa Header={true} ID={0} />}
                      />
                      <Route
                        path="/cadastro-comanda"
                        element={<CadastroComandaMesa />}
                      />
                    </>
                  ) : null}
                  {PermissaoAcesso("encerra") ? (
                    <Route
                      path="/fechamento/:id"
                      element={
                        <FechamentoPages PedidoID={0} closeButton={null} />
                      }
                    />
                  ) : null}

                  {PermissaoAcesso("estoque") ? (
                    <Route
                      path="/estoque"
                      // element={<PageEstoque />}
                      element={
                        PermissaoModulo("estoque") ? (
                          <PageEstoque />
                        ) : (
                          <PageSemAcesso NomeModulo={NomeModulo("estoque")} />
                        )
                      }
                    />
                  ) : null}

                  {PermissaoAcesso("param") ? (
                    <Route path="/parametros" element={<PageParameters />} />
                  ) : null}

                  {PermissaoAcesso("cadTaxa") ? (
                    <Route
                      path="/cadastro-taxa-entrega"
                      element={<PageTaxaEntrega />}
                    />
                  ) : null}

                  {PermissaoAcesso("cadMotoboy") ? (
                    <Route path="/cadastro-motoboy" element={<PageMotoboy />} />
                  ) : null}

                  {PermissaoAcesso("cadImpressora") ? (
                    <Route
                      path="/cadastro-impressora"
                      element={<Impressora />}
                    />
                  ) : null}

                  {PermissaoAcesso("cadPaga") ? (
                    <Route
                      path="/cadastro-tipo-pagamento"
                      element={<PageTipoPagamento />}
                    />
                  ) : null}

                  {PermissaoAcesso("cadPedido") ? (
                    <Route path="/pedidos" element={<PedidosPage />} />
                  ) : null}

                  {PermissaoAcesso("cadCupom") ? (
                    <Route
                      path="/cadastro-cupom-desconto"
                      element={<CupomDesconto />}
                    />
                  ) : null}

                  {PermissaoAcesso("cadCli") ? (
                    <Route path="/cadastro-cliente" element={<PageClient />} />
                  ) : null}
                  <Route
                    path="/fidelidade"
                    // element={<FideldiadePage />}
                    element={
                      PermissaoModulo("fidelidade") ? (
                        <FideldiadePage />
                      ) : (
                        <PageSemAcesso NomeModulo={NomeModulo("fidelidade")} />
                      )
                    }
                  />
                  <Route path="/test" element={<Test />} />
                  <Route path="/horario" element={<Pagehorario />} />
                </>
              ) : null}
              {isMotoboy() ? (
                <>
                  <Route path="/principal" element={<HomePage />} />
                </>
              ) : null}
              <Route path="/motoboy" element={<LoginPageMotoboy />} />

              <Route
                path="*"
                element={isLogin() ? <HomePage /> : <LoginPage />}
              />
            </Routes>
          </BrowserRouter>
        </ThemeProvider>
      </ChakraProvider>
    </>
  );
}

export default App;
