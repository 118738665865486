import { Switch } from "@mui/material";
import { TextIdioma, getCodigoMotoboy, getCodigoUsuario } from "../data";
import { isMobileDevice } from "../mobile";
import { ApagaLocal } from "../storage";
import { toast } from "react-toastify";
import { API } from "../api/api";

function Interno(str) {
  str = str ? str.toLowerCase() : "";
  str = str.replace("pedido de teste -", "");
  str = str.replace("nao entregar -", "");
  str = str.replace("ifood -", "");

  if (str !== undefined) {
    return str
      .toLowerCase()
      .replace(/\b\w/g, function (match) {
        return match.toUpperCase();
      })
      .replace(/,(?! )/g, ", ")
      .replace(" , ", ", ");
  } else {
    return str;
  }
}

export function PrimeiraMaiuscula(str) {
  //
  if (typeof str !== "string") return str;

  str = str ? str.toLowerCase() : "";
  if (str == "") {
    return str;
  }
  str = Interno(str);

  if (str !== undefined) {
    return str.toLowerCase().replace(/(?:^|\s)\S/g, function (match) {
      return match.toUpperCase();
    });
  }
}

export function isLogin() {
  let user = getCodigoUsuario();
  if (user > 0) {
    return true;
  } else {
    return false;
  }
}

export function isMotoboy() {
  let user = getCodigoMotoboy();
  if (user > 0) {
    return true;
  } else {
    return false;
  }
}

export function Logoff() {
  ApagaLocal("user");
  ApagaLocal("motoboy");

  if ("caches" in window) {
    // Verifica se a Cache API está disponível
    caches
      .keys()
      .then(function (cacheNames) {
        // Itera sobre todos os nomes de caches
        cacheNames.forEach(function (cacheName) {
          // Apaga cada cache
          caches.delete(cacheName).then(function (success) {
            if (success) {
              //console.log(`Cache ${cacheName} foi apagado.`);
            } else {
              //console.log(`Falha ao apagar o cache ${cacheName}.`);
            }
          });
        });
      })
      .catch(function (error) {
        console.error("Erro ao acessar os caches:", error);
      });
  } else {
    //console.log("Cache API não é suportada neste navegador.");
  }
}

export function Notifica(mensagem) {
  mensagem = TextIdioma(mensagem);
  toast.success(mensagem, {
    autoClose: 1000,
    closeOnClick: true,

    position: isMobileDevice()
      ? toast.POSITION.TOP_RIGHT
      : toast.POSITION.BOTTOM_RIGHT,
  });
}
export function NotificaWarning(mensagem) {
  mensagem = TextIdioma(mensagem);
  toast.warning(mensagem, {
    autoClose: 1000,
    closeOnClick: true,

    position: isMobileDevice()
      ? toast.POSITION.TOP_RIGHT
      : toast.POSITION.BOTTOM_RIGHT,
  });
}

export function Upper(valor) {
  if (valor != null) {
    return valor.toUpperCase();
  } else {
    return "";
  }
}

export function MascaraCelular(phoneNumber) {
  // Remove todos os caracteres que não são dígitos
  try {
    phoneNumber = phoneNumber.replace(/\D/g, "");

    // Verifica o padrão e aplica a máscara correta
    if (phoneNumber.length === 11) {
      return phoneNumber.replace(/(\d{2})(\d{5})(\d{4})/, "($1)$2-$3");
    } else if (phoneNumber.length === 10) {
      return phoneNumber.replace(/(\d{2})(\d{4})(\d{4})/, "($1)$2-$3");
    }

    // Retorna o número de telefone sem alterações se não corresponder a nenhum padrão
  } catch (err) {}

  return phoneNumber;
}

export function getInitials(name) {
  const excludedWords = ["da", "do", "das", "dos"];
  const words = name.trim().toLowerCase().split(" ");

  const initials = words.reduce((result, word, index) => {
    const wordWithoutPunctuation = word.replace(/[.,]/g, ""); // Remove punctuation

    if (index === 0) {
      result.push(
        wordWithoutPunctuation.charAt(0).toUpperCase() +
          wordWithoutPunctuation.slice(1)
      );
    } else if (!excludedWords.includes(wordWithoutPunctuation)) {
      result.push(wordWithoutPunctuation.charAt(0).toUpperCase() + ".");
    }

    return result;
  }, []);

  return initials.join(" ");
}

export function formatarCPForCNPJ(value) {
  // Removendo todos os caracteres não numéricos
  if (value == null) {
    return "000.000.000-00";
  }
  if (value == "") {
    return "000.000.000-00";
  }

  const numeros = value.replace(/\D/g, "");

  if (numeros.length <= 11) {
    // Formatar como CPF
    return numeros
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})$/, "$1-$2");
  } else {
    // Formatar como CNPJ
    return numeros
      .replace(/^(\d{2})(\d)/, "$1.$2")
      .replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
      .replace(/\.(\d{3})(\d)/, ".$1/$2")
      .replace(/(\d{4})(\d)/, "$1-$2");
  }
}

export function validarCNPJ(cnpj) {
  const cnpjLimpo = cnpj.replace(/\D/g, "");

  if (cnpjLimpo.length !== 14) {
    return false;
  }

  const calcDigito = (cnpj, posicao) => {
    let soma = 0;
    let multiplo = posicao === 12 ? 2 : 9;

    for (let i = 0; i < posicao; i++) {
      soma += parseInt(cnpj[i]) * multiplo--;
      if (multiplo < 2) {
        multiplo = 9;
      }
    }

    const resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    return resultado;
  };

  const digitoVerificador1 = calcDigito(cnpjLimpo, 12);
  const digitoVerificador2 = calcDigito(cnpjLimpo, 13);

  return (
    parseInt(cnpjLimpo[12]) === digitoVerificador1 &&
    parseInt(cnpjLimpo[13]) === digitoVerificador2
  );
}

export function SwitchPadraoTabela(valor, dataFiltro) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Switch size="small" checked={valor == 1 ? true : false} />
    </div>
  );
}

export function GetCache(url, cacheTime) {
  let tempo = cacheTime * 60000;
  // Verificar se há dados em cache para a URL fornecida
  const cachedData = localStorage.getItem(url);
  const cachedTime = localStorage.getItem(`${url}_timestamp`);

  // Se houver dados em cache e o tempo de cache não tiver expirado, retornar os dados em cache
  if (cachedData && cachedTime && Date.now() - parseInt(cachedTime) < tempo) {
    return Promise.resolve(JSON.parse(cachedData));
  } else {
    // Caso contrário, fazer a requisição HTTP
    return API.get(url)
      .then((response) => {
        //console.log("XXX");
        // Salvar os dados da resposta e o timestamp no localStorage
        localStorage.setItem(url, JSON.stringify(response.data));
        localStorage.setItem(`${url}_timestamp`, Date.now().toString());
        return response.data;
      })
      .catch((error) => {
        // Se houver um erro, rejeitar a Promise com o erro
        return Promise.reject(error);
      });
  }
}

export function MonitorSmall() {
  return window.screen.width < 1500;
}
