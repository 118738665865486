import { useEffect, useState } from "react";
import { ShowModal } from "../../../../../component/modalPai";
import { Selected } from "../../../../../component/selected";
import { API } from "../../../../../controller/api/api";
import { border } from "@chakra-ui/react";
import { PrimeiraMaiuscula } from "../../../../../controller/util";
import { ModalComponent } from "../../../../../component/modal";

export function CopiaExtra({ Extra, Set, Close }) {
  const [Data, setData] = useState([]);
  const [isModal, setModal] = useState(false);
  const [MeuObjetoCopia, setMeuObjetoCopia] = useState([]);

  function criarArrayDeObjetos(nomesString, valoresString, descricaoString) {
    let nomes = nomesString.split(",");
    let valores = valoresString.split(",");
    let description = descricaoString.split(",");

    let arrayDeObjetos = [];

    if (nomes.length === valores.length) {
      for (let i = 0; i < nomes.length; i++) {
        let objeto = {
          id: 0,
          name: PrimeiraMaiuscula(nomes[i]),
          description: PrimeiraMaiuscula(description[i] ? description[i] : ""),
          value: parseFloat(valores[i]),
          status: 1,
        };
        arrayDeObjetos.push(objeto);
      }

      return arrayDeObjetos;
    } else {
      //console.log("Número de nomes e valores não coincide.");
      return null;
    }
  }

  function formatarRegistros(registros) {
    // Verifica se o array de registros está vazio
    if (registros.length === 0) {
      return "";
    }

    // Usa o método join para concatenar os registros com vírgulas
    return PrimeiraMaiuscula(registros.join(", "));
  }

  function CopiaExtra(data) {
    //console.log(data);
    let Objeto = {
      id: 0,
      max: data.max,
      min: data.min,
      status: 1,
      name: PrimeiraMaiuscula(data.juncao),
      extra: [],
    };

    const DadosObjeto = criarArrayDeObjetos(
      data.itens,
      data.valores,
      data.descricao
    );
    Objeto.extra = DadosObjeto;
    //console.log(Objeto);
    setMeuObjetoCopia(Objeto);
    setModal(true);
  }

  function handleYesClick() {
    Set((estadoAnterior) => [...estadoAnterior, MeuObjetoCopia]);
    Close();
  }

  useEffect(() => {
    API.get(`v1/util/extra/produtos`).then(function (data) {
      //console.log(data.data);

      // Objeto para armazenar os resultados agrupados
      const resultadosAgrupados = {};

      // Iterar sobre cada objeto no array de dados
      data.data.forEach((objeto) => {
        // Extrair informações relevantes
        const { juncao, extra, itens, valores, max, min, produto, descricao } =
          objeto;

        // Chave única para agrupar por itens, valores e juncao
        const chaveAgrupamento = `${itens}-${valores}-${juncao}`;

        // Se a chave de agrupamento não existe, inicialize um objeto vazio
        resultadosAgrupados[chaveAgrupamento] = resultadosAgrupados[
          chaveAgrupamento
        ] || {
          juncao,
          extra,
          itens,
          valores,
          max,
          min,
          produtos: [],
          descricao,
        };

        // Adicione o produto ao array de produtos no objeto de agrupamento
        resultadosAgrupados[chaveAgrupamento].produtos.push(produto);
      });

      // Converter o objeto de resultados agrupados em um array
      const resultadosArray = Object.values(resultadosAgrupados);

      // Ordenar o array final pela propriedade 'juncao' (ordem alfabética)
      resultadosArray.sort((a, b) => a.juncao.localeCompare(b.juncao));

      // Mostrar os resultados agrupados ordenados

      setData(resultadosArray);
    });
  }, []);
  return (
    <>
      <ShowModal>
        <div style={{ width: "29vw", gap: "10px" }}>
          {/* <Selected
                    data={TipoSabor}
                    descricao={"nome"}
                    value={"id"}
                    selected={TipoSaborSelecionado}
                    funcaoSelected={funcaoSelectedSabor}
                  /> */}
          {Data.map((extra, index) => (
            <div
              style={{
                border: "1px solid black",
                width: "100%",
                padding: "10px",
                marginBottom: "10px",
              }}
              onClick={() => CopiaExtra(extra)}
            >
              <p>
                <b>{PrimeiraMaiuscula(extra.juncao)}</b>
              </p>
              <p style={{ fontSize: "0.95rem" }}>
                {PrimeiraMaiuscula(extra.itens)}
              </p>
              <p>
                <b>Produtos Relacionados</b>
              </p>
              <p style={{ fontSize: "0.8rem" }}>
                {formatarRegistros(extra.produtos)}
              </p>
            </div>
          ))}
        </div>

        {isModal ? (
          <ModalComponent
            question={`Deseja Copiar o/as ${MeuObjetoCopia.name}?`}
            onYesClick={handleYesClick}
            onNoClick={() => setModal(false)}
          />
        ) : null}
      </ShowModal>
    </>
  );
}
