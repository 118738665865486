import { useEffect, useState } from "react";
import {
  Notifica,
  NotificaWarning,
  PrimeiraMaiuscula,
} from "../../../../controller/util";
import { API } from "../../../../controller/api/api";
import { ShowModal } from "../../../../component/modalPai";
import { InputPedido } from "../../../pedido/componente/input";
import {
  MascaraMonetaria,
  TransformarValor,
  getCaixa,
} from "../../../../controller/data";
import { Button } from "../../../../component/button";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

export function CardRecebimentoFiado({ Dados, Close }) {
  const [TipoPagamento, setTipoPagamento] = useState([]);
  const [Pagamento, setPagamento] = useState(0);
  const [Clicou, setClicou] = useState(false);

  const [SaldoDevedor, setSaldoDevedor] = useState(Dados.devedor);
  const [CPFDevedor, setCPFDevedor] = useState(Dados.cpf);
  const [NomeDevedor, setNomeDevedor] = useState(Dados.nome);
  const [CelularDevedor, setCelularDevedor] = useState(Dados.celular);
  const [ValorPago, setValorPago] = useState(0);
  const [Codigo, setCodigo] = useState(Dados.codigo);

  const [CodigoCaixa, setCodigoCaixa] = useState(0);

  useEffect(() => {
    API.get("v1/tipo/pagamento/").then(function (dados) {
      setPagamento(dados.data[0].codigo);
      // //console.log(dados.data);
      setTipoPagamento(dados.data);
    });
    Caixa();
  }, []);

  async function Caixa() {
    const result = await getCaixa();

    //console.log(result);

    if (result) {
      setCodigoCaixa(result.id);
    }
  }

  function Receber() {
    var tolerancia = 0.0001; // Ajuste a tolerância conforme necessário

    let body = {
      documento: CPFDevedor,
      nome: NomeDevedor,
      celular: CelularDevedor,
      valor: ValorPago,
      pagamento: Pagamento,
      caixa: CodigoCaixa,
      codigo: Codigo,
    };

    if (
      TransformarValor(ValorPago) > TransformarValor(SaldoDevedor.toFixed(2))
    ) {
      NotificaWarning(`O Valor De Recebimento É Maior Que O Saldo Devedor!`);
      setClicou(false);
      return false;
    }

    API.post("/v2/entrada/pagamento/fiado", body).then((response) => {
      Notifica(
        `Recebido ${MascaraMonetaria(
          TransformarValor(ValorPago)
        )} de ${PrimeiraMaiuscula(NomeDevedor)}`
      );
      Close();
      setClicou(false);
    });
  }

  return (
    <>
      <ShowModal closeButton={Close}>
        <div style={{ width: "50vw" }}>
          <h1
            style={{
              textAlign: "center",
              fontSize: "1.2rem",
              color: "#A8001C",
            }}
          >
            <b>Recebimento Fiado</b>
          </h1>

          <FormControl fullWidth>
            <InputLabel id="forma-pagamentp">Forma De Pagamento</InputLabel>
            <Select
              labelId="forma-pagamentp"
              id="forma-pagamentp"
              value={Pagamento}
              label="Forma De Pagamento"
              onChange={(e) => setPagamento(e.target.value)}
              sx={{ width: "100%", marginBottom: "10px" }}
              size="small"
            >
              {TipoPagamento.map((item, index) => (
                <MenuItem key={index} value={item.codigo}>
                  {PrimeiraMaiuscula(item.descricao)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* <div style={{}}>
            <h3 style={{ color: "#999999" }}>Forma De Pagamento</h3>
            <select
              style={{
                height: "34px",
                width: "100%",
                border: "1px solid",
                marginBottom: "10px",
              }}
              onChange={(e) => setPagamento(e.target.value)}
            >
              {TipoPagamento.map((item, index) =>
                item.movimentacao === 1 ? (
                  <option key={index} value={item.codigo}>
                    {PrimeiraMaiuscula(item.descricao)}
                  </option>
                ) : null
              )}
            </select>
          </div> */}

          <InputPedido
            Valor={PrimeiraMaiuscula(NomeDevedor)}
            Descricao="Cliente"
            sx={{ marginBottom: "15px" }}
          />

          <div style={{ display: "flex", gap: "10px", marginBottom: "15px" }}>
            <div style={{ width: "50%" }}>
              <InputPedido
                Valor={MascaraMonetaria(SaldoDevedor)}
                Descricao="Saldo Devedor"
              />
            </div>
            <div style={{ width: "50%" }}>
              <InputPedido
                Valor={MascaraMonetaria(TransformarValor(ValorPago))}
                Set={setValorPago}
                Descricao="Receber"
              />
            </div>
          </div>
          {CodigoCaixa > 0 ? (
            <>
              <Button
                fontColor={"black"}
                borderColor={"black"}
                descricao={"Receber"}
                BorderRadius={"5px"}
                evento={Receber}
              />
            </>
          ) : null}
        </div>
      </ShowModal>
    </>
  );
}
