import { getUsuario } from "../data";


export function UserPermissionDelete() {
    let data = getUsuario();
    //////console.log(data[0].deleta == 1)
    return data[0].deleta == 1;
}

export function UserPermissionFinalizar() {
    let data = getUsuario();
    return data[0].encerra == 1;
}