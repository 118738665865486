import { MascaraMonetaria } from "../../../../../controller/data";
import { PrimeiraMaiuscula } from "../../../../../controller/util";

export function CardFormaPagamento({ Titulo, Valor }) {
  const StyleTituloPagamento = {
    fontSize: "0.8rem",
  };

  const StyleTituloPagamentoEntrada = {
    fontSize: "0.8rem",
    color: "#67B86F",
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          borderBottom: "1px dotted rgba(0,0,0,0.5)",

          padding: "5px",
        }}
      >
        <h3 style={StyleTituloPagamento}>{PrimeiraMaiuscula(Titulo)}</h3>
        <h3 style={StyleTituloPagamentoEntrada}>+ {MascaraMonetaria(Valor)}</h3>
      </div>
    </>
  );
}

export function CardFormaPagamentoSangria({ Titulo, Valor, id, Delete }) {
  const StyleTituloPagamento = {
    fontSize: "0.8rem",
  };
  const StyleTituloPagamentoSaida = {
    fontSize: "0.8rem",
    color: "#F00028",
  };
  function removerPrefixoSangria(descricao) {
    const prefixo = "SANGRIA - ";
    // Verifica se a descrição começa com o prefixo "SANGRIA - "
    if (descricao.startsWith(prefixo)) {
      // Se sim, remove o prefixo e retorna o restante da string
      return descricao.substring(prefixo.length);
    } else {
      // Se não, retorna a string original
      return descricao;
    }
  }

  return (
    <>
      <div
        style={{
          padding: "5px",
          borderBottom: "1px dotted rgba(0,0,0,0.5)",
        }}
      >
        <h3 style={StyleTituloPagamento}>
          {PrimeiraMaiuscula(removerPrefixoSangria(Titulo))}
        </h3>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <h3 style={StyleTituloPagamento}>Sangria</h3>
          <h3 style={StyleTituloPagamentoSaida}>- {MascaraMonetaria(Valor)}</h3>
        </div>
      </div>
    </>
  );
}

export function CardFormaPagamentoTotalizador({ Valor }) {
  const StyleTituloValue = {
    fontSize: "1.1rem",
    // color: "#67B86F",
  };
  return (
    <>
      {" "}
      <div style={{ padding: "10px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "5px 21px 5px 5px",
          }}
        >
          <h3 style={StyleTituloValue}>Total</h3>
          <h3 style={StyleTituloValue}>{MascaraMonetaria(Valor)}</h3>
        </div>
      </div>
    </>
  );
}
