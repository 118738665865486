import { HeaderComponent } from "../../component/header";
import { API } from "../../controller/api/api";
import { isMobileDevice } from "../../controller/mobile";
import { DesktopPage } from "./componente/desktop";
import { MobilePage } from "./componente/mobile";

export function HomePage() {
  return (
    <>
      <HeaderComponent>
        {isMobileDevice() ? <MobilePage /> : <DesktopPage />}
      </HeaderComponent>
    </>
  );
}
