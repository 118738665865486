import React from 'react';
import './LoadingOverlay.css';
import { FaSpinner } from 'react-icons/fa';  // Importa o ícone de Spinner do FontAwesome

const LoadingOverlay = () => {
    return (
        <div className="loading-overlay">
            <div className="loading-icon">
                <FaSpinner className="icon-spin" size={50} />  {/* Usa o ícone aqui */}
            </div>
        </div>
    );
}

export default LoadingOverlay;
