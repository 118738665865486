import React from "react";
import { isMobileDevice } from "../../controller/mobile";

const CardLegenda = ({
  Titulo,
  SubTitulo,
  Legenda,
  Color,
  Click,
  FontColor,
}) => {
  const Font = FontColor || "#65676799";

  const cardStyleDesktop = {
    flex: "0 0 275px",
    height: "120px",
    borderRadius: "12px",
    backgroundColor: `rgba(${Color}, 0.6)`,
    margin: "10px",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    padding: "15px",
    border: `3px solid rgba(${Color}, 1)`,
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
    transition: "transform 0.3s ease, box-shadow 0.3s ease",
    cursor: "pointer",
  };

  const cardStyleMobile = {
    ...cardStyleDesktop,
    flexBasis: "100%", // Ocupar 100% da largura no mobile
  };

  const tituloStyle = {
    textAlign: "right",
    fontWeight: "bold",
    color: Font,
    fontSize: "18px",
    marginBottom: "5px",
  };

  const subtituloStyle = {
    color: "black",
    textAlign: "right",
    fontWeight: "bold",
    fontSize: "14px",
    marginBottom: "10px",
  };

  const legendaStyle = {
    color: "black",
    textAlign: "left",
    fontWeight: "normal",
    fontSize: "12px",
  };

  const handleClick = () => {
    if (typeof Click === "function") {
      Click();
    }
  };

  return (
    <div
      style={isMobileDevice() ? cardStyleMobile : cardStyleDesktop}
      onClick={handleClick}
      onMouseEnter={(e) => {
        e.currentTarget.style.transform = "scale(1.05)";
        e.currentTarget.style.boxShadow = "0px 8px 20px rgba(0, 0, 0, 0.2)";
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.transform = "scale(1)";
        e.currentTarget.style.boxShadow = "0px 4px 15px rgba(0, 0, 0, 0.1)";
      }}
    >
      <h3 style={tituloStyle}>{Titulo}</h3>
      <h6 style={subtituloStyle}>{SubTitulo}</h6>
      <span style={legendaStyle}>{Legenda}</span>
    </div>
  );
};

export default CardLegenda;
