import { FaPrint, FaTrash } from "react-icons/fa";
import { MascaraMonetaria } from "../../../../../controller/data";
import { useState } from "react";
import { ModalComponent } from "../../../../../component/modal";
import { API } from "../../../../../controller/api/api";
import { Notifica } from "../../../../../controller/util";

function Icon({ children }) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="icon"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        transform: isHovered ? "scale(1.1)" : "scale(1)",
        color: isHovered ? "#A8001C" : "inherit",
      }}
    >
      {children}
    </div>
  );
}

export function CardSangria({
  Titulo,
  Valor,
  estorno,
  impressao,
  id,
  atualiza,
}) {
  const StyleTitulo = {
    fontSize: "0.8rem",
    // color: "#CCCCCC",
  };
  const StyleTituloPagamentoSaida = {
    fontSize: "0.8rem",
    color: "#F00028",
    textAlign: "right",
    width: "100%",
    marginRight: "10px",
  };

  const [isModal, setisModal] = useState(false);

  function handleYesClick() {
    API.post(`/v2/caixa/deleta/sangria/${id}`).then(function (response) {
      atualiza();
      Notifica("Sangria Excluida Com Sucesso");
      setisModal(false);
    });
  }

  function handlePrinter() {
    API.post(`/v2/caixa/imprime/sangria/${id}`).then(function (response) {
      setisModal(false);
      Notifica("Sangria Reimpressa");
    });
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          borderBottom: "1px dotted rgba(0,0,0,0.5)",

          padding: "5px",
        }}
      >
        <h3 style={StyleTitulo}>{Titulo}</h3>
        <h3 style={StyleTituloPagamentoSaida}>-{MascaraMonetaria(Valor)}</h3>
        <div
          style={{
            display: "flex",
            gap: "5px",
            cursor: "pointer",
            width: "45px",
          }}
        >
          {estorno ? (
            <>
              <Icon>
                <FaTrash onClick={() => setisModal(true)} />
              </Icon>
            </>
          ) : null}
          {impressao ? (
            <>
              <Icon>
                <FaPrint onClick={handlePrinter} />
              </Icon>
            </>
          ) : null}
        </div>
      </div>

      {isModal ? (
        <ModalComponent
          question={"Deseja Excluir a Sangria?"}
          onYesClick={handleYesClick}
          onNoClick={() => setisModal(false)}
        />
      ) : null}
    </>
  );
}

export function CardTotalSangria({ Total }) {
  const StyleTituloValue = {
    fontSize: "1.1rem",
    // color: "#67B86F",
  };
  return (
    <>
      {" "}
      <div style={{ padding: "10px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "5px 21px 5px 5px",
          }}
        >
          <h3 style={StyleTituloValue}>Total</h3>
          <h3 style={StyleTituloValue}>{MascaraMonetaria(Total)}</h3>
        </div>
      </div>
    </>
  );
}
