// import * as React from 'react';
import React, { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';

import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { API } from '../../controller/api/api';
import { Alert } from '@mui/material';
import { GravaLocal } from '../../controller/storage';


export function LoginPageMotoboy() {
    const defaultTheme = createTheme();
    const [Senha, setSenha] = useState('');
    const [Usuario, setUsuario] = useState('');
    const [senhaErrada, setSenhaErada] = useState(false);
    const [Test, setTest] = useState(false);



    function Login() {
        // setTest('http://192.168.0.64:9000/v1/usuario/'+Usuario+'/'+Senha);
        API.get(`/v1/consulta/generica/motoboy/*/nome="${Usuario}"/*`).then(
            function (dados) {
                //////console.log(dados);
                // alert(dados.data);  
                if (dados.data.length > 0) {
                    GravaLocal('motoboy', JSON.stringify(dados.data[0]));
                    window.location = '/principal';
                } else {
                    setSenhaErada(true);
                }
                // setSenhaErada(false);
            }).catch(function (error) {
                alert(error);
            });

    }

    return <> <ThemeProvider theme={defaultTheme}>
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            {Test}
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Login Motoboy
                </Typography>


                <Box component="form" noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="usuario"
                        label="Motoboy"
                        name="usuario"
                        autoComplete="usuario"
                        autoFocus
                        value={Usuario}
                        onChange={(e) => (setUsuario(e.target.value))}
                    />
                    <Button
                        onClick={Login}
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Acessar
                    </Button>
                    {/* <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid> */}
                </Box>
            </Box>
            {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
        </Container>
    </ThemeProvider>
    </>
}