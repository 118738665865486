import { createTheme } from "@mui/material";
import { API } from "../api/api";
import { BuscaLocal, GravaLocal } from "../storage";
import { PermissaoAcesso } from "../parametros";

export function isMobileDevice() {
  let Garcom = PermissaoAcesso("garcom");

  const Mobile = /Mobi|Android/i.test(navigator.userAgent);

  // API.post(`v2/user/agent/${BuscaLocal("user_agent")}`);

  // API.get(`v2/user/agent/${BuscaLocal("user_agent")}`).then((response) => {
  //   GravaLocal("user_agent_response", JSON.stringify(response.data[0]));
  // });

  if (Garcom) {
    return true;
  }
  return Mobile;
}

export function isMobileDeviceBloqueado() {
  try {
    const dados = JSON.parse(BuscaLocal("user_agent_response"));

    return dados?.status == 0;
  } catch (error) {}
}

export function ThemaUi() {
  const defaultTheme = createTheme({
    typography: {
      fontFamily: "Roboto, sans-serif",
    },
    palette: {
      text: {
        primary: "#000",
        secondary: "rgb(168, 0, 28)",
      },
      primary: {
        light: "rgb(168, 0, 28)",
        main: "rgb(168, 0, 28)",
        dark: "rgb(168, 0, 28)",
        contrastText: "#000 ",
      },
      secondary: {
        light: "#ff7961",
        main: "#f44336",
        dark: "#ba000d",
        contrastText: "#000",
      },
      blue: {
        light: "rgba(22,83,126,0.8)",
        main: "rgba(22,83,126,0.8)",
        dark: "rgba(22,83,126,0.8)",
        contrastText: "#fff",
      },
      black: {
        light: "rgba(0,0,0,0.8)",
        main: "rgba(0,0,0,0.8)",
        dark: "rgba(0,0,0,0.8)",
        contrastText: "#fff",
      },
      alert: {
        light: "rgba(255,127,80,0.8)",
        main: "rgba(255,127,80,0.8)",
        dark: "rgba(255,127,80,0.8)",
        contrastText: "#fff",
      },
      sucesso: {
        light: "rgba(	38, 153, 49,0.8)",
        main: "rgba(	38, 153, 49,0.8)",
        dark: "rgba(	38, 153, 49,0.8)",
        contrastText: "#fff",
      },
    },
  });
  return defaultTheme;
}
