import React, { useEffect, useState } from "react";
import { FaHashtag, FaPrint, FaTrash } from "react-icons/fa";
import { ModalComponent } from "../../../../component/modal";
import { API } from "../../../../controller/api/api";
import { PrimeiraMaiuscula } from "../../../../controller/util";
import { UserPermissionDelete } from "../../../../controller/user";
import { MascaraMonetaria, TextIdioma } from "../../../../controller/data";
import { Checkbox, IconButton, Tooltip } from "@mui/material";
import { GravaLocal } from "../../../../controller/storage";

var carrega = false;

export function ListaProduto(props) {
  let faturado = false;
  if (props.Faturado != undefined) {
    faturado = props.Faturado;
  }

  const [produtos, setProdutos] = useState([]);
  const [produtoDeletado, setprodutoDeletado] = useState(0);
  const [isModal, setModal] = useState(false);
  const [isIconHovered, setIsIconHovered] = useState(false);
  const [iconHoveredProductId, setIconHoveredProductId] = useState(null); // Estado para armazenar o ID do produto sobre o qual o mouse está passando
  const [selectedProductCodes, setSelectedProductCodes] = useState([]);
  // const [Foto, setFoto] = useState('https://fotos.goopedir.com/fotos/sem-foto');

  const handleCheckboxChange = (codigo) => {
    setSelectedProductCodes((prevSelected) => {
      if (prevSelected.includes(codigo)) {
        // Se o código já estiver selecionado, removê-lo
        return prevSelected.filter((item) => item !== codigo);
      } else {
        // Caso contrário, adicioná-lo
        return [...prevSelected, codigo];
      }
    });
  };

  const handleYesClick = () => {
    API.post("/v1/delete/pedido/produto/" + produtoDeletado).then(function (
      dados
    ) {
      props.atualizar();

      setProdutos((prevProdutos) => {
        const produtosFiltrados = prevProdutos.filter((produto) => {
          const deveFiltrar = produto.codigo === produtoDeletado;

          return !deveFiltrar;
        });

        return produtosFiltrados;
      });

      setModal(false);
    });
  };

  useEffect(() => {
    setProdutos(props.dados);
  }, [props.dados]);

  useEffect(() => {
    GravaLocal("IMPRESSAO_SELECIONADA", JSON.stringify(selectedProductCodes));
  }, [selectedProductCodes]);

  const handleExcluirProduto = (id) => {
    setprodutoDeletado(id);
    setModal(true);
  };

  function Foto(produto) {
    if (produto.ifood != null && produto.ifood != "") {
      return produto.ifood;
    } else {
      if (produto.site != null) {
        return "https://fotos.goopedir.com/fotos/" + btoa(produto.site);
      }
    }

    return "https://fotos.goopedir.com/fotos/sem-foto";
  }
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  return (
    <>
      <div>
        {produtos.length > 0 ? (
          produtos.map((produto) => (
            <div
              key={produto.codigo}
              style={{
                display: "flex",
                border: "1px solid #e0e0e0",
                borderRadius: "8px",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                marginBottom: "10px",
                padding: "10px",
                backgroundColor: "#ffffff",
                transition: "box-shadow 0.3s ease-in-out",
                alignItems: "center", // Adicionado para centralizar verticalmente
              }}
              onMouseEnter={(e) =>
                (e.currentTarget.style.boxShadow =
                  "0 8px 12px rgba(0, 0, 0, 0.1)")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.boxShadow =
                  "0 4px 6px rgba(0, 0, 0, 0.1)")
              }
            >
              <img
                src={Foto(produto)}
                alt={produto.nomeProduto}
                style={{
                  width: "100px",
                  marginRight: "10px",
                  height: "100px",
                  borderRadius: "5px",
                }}
              />
              <div
                style={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <span style={{ fontWeight: "bold" }}>
                    {PrimeiraMaiuscula(produto.nomeProduto)}
                  </span>
                  <div style={{ display: "flex", gap: "5px" }}>
                    <Tooltip
                      title={
                        produto.impressao == 0 ? "Não Impresso" : "Impresso"
                      }
                    >
                      <IconButton
                        aria-label="visualizar"
                        sx={{
                          color:
                            produto.impressao == 0
                              ? "rgb(168, 0, 28)"
                              : "#269931",
                        }}
                      >
                        <FaPrint />
                      </IconButton>
                    </Tooltip>

                    {faturado || UserPermissionDelete() == false ? null : (
                      <Tooltip title="Excluir Produto">
                        <IconButton
                          aria-label="excluir"
                          onClick={() => handleExcluirProduto(produto.codigo)}
                          sx={{ color: "rgb(168, 0, 28)" }}
                        >
                          <FaTrash />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Checkbox
                      checked={selectedProductCodes.includes(produto.codigo)}
                      onChange={() => handleCheckboxChange(produto.codigo)}
                      {...label}
                    />
                  </div>
                </div>

                <div style={{ marginTop: "5px" }}>
                  {PrimeiraMaiuscula(produto.obs) == "Observação "
                    ? null
                    : PrimeiraMaiuscula(produto.obs)}
                </div>
                <div style={{ marginTop: "5px" }}>
                  {produto.quantidade} x {MascaraMonetaria(produto.valorTotal)}
                </div>
              </div>
            </div>
          ))
        ) : (
          <>
            <div
              style={{
                display: "flex",
                flexFlow: "column nowrap",
                justifyContent: "center",
                alignItems: "center",
                // height: "100%", // 100% da altura da janela
                width: "100%", // 100% da largura da janela
                marginTop: "5%",
              }}
            >
              <FaHashtag
                size={100} // Tamanho grande do ícone
                style={{
                  color: "rgb(168, 0, 28)", // Cor do ícone
                }}
              />
              <div
                style={{
                  marginTop: "20px", // Espaçamento superior entre o ícone e o texto
                  fontSize: "24px", // Tamanho do texto
                }}
              >
                <b>Ops... Não há produtos</b>
              </div>
            </div>
          </>
        )}
      </div>
      {isModal ? (
        <ModalComponent
          question={"Deseja excluir o produto?"}
          onYesClick={handleYesClick}
          onNoClick={() => setModal(false)}
        />
      ) : null}
    </>
  );
}
