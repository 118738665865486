import { PageAtivaBloqueiaItens } from "../../page/ativabloqueiaItens";
import { ComponentPausa } from "../../page/horario/pausa";
import PedidoMap from "../../page/localizacao";
import { AppCortar } from "../cortarImage";

export default function Test() {
  return (
    <>
      <PageAtivaBloqueiaItens />
    </>
  );
}
