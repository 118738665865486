import axios from "axios";
import { BuscaLocal } from "../storage";

export function BASEURL() {
  const url = process.env.REACT_APP_BASE_URL
    ? process.env.REACT_APP_BASE_URL
    : JSON.parse(BuscaLocal("base-url"));

  if (url?.host) {
    document.title = "GooPedir - " + url?.value;
  }

  return url?.host
    ? url?.host
    : document.querySelector('link[name="url-api"]').href;
}

export const API = axios.create({
  baseURL: BASEURL(),
  // baseURL: 'http://192.168.0.64:9000/',
  headers: {
    "Content-Type": "application/json; text/html; charset=utf-8",
  },
});

export const GooPedirAPI = axios.create({
  baseURL: "https://goopedir.com/",
  headers: {
    "Content-Type": "application/json; text/html; charset=utf-8",
  },
});

// Interceptador de requisição para registrar a hora de início
API.interceptors.request.use((request) => {
  request.metadata = { startTime: new Date() };
  return request;
});

// Interceptador de resposta para calcular o tempo de resposta e armazenar as informações
API.interceptors.response.use(
  (response) => {
    const endTime = new Date();
    const duration = endTime - response.config.metadata.startTime;
    if (
      response.config.url !== "whatsapp/goopedir/data" &&
      response.config.url !== "v2/status/site" &&
      response.config.url !== "v1/util/ifood/status"
    ) {
      // //console.log(
      //   `Rota: ${response.config.url} | Tempo de resposta: ${duration} ms`
      // );
    }

    // Aqui você pode armazenar a rota e o tempo de resposta onde desejar
    return response;
  },
  (error) => {
    const endTime = new Date();
    const duration = endTime - error.config.metadata.startTime;

    // //console.log(
    //   `Rota: ${error.config.url} | Tempo de resposta: ${duration} ms`
    // );
    // Aqui você pode armazenar a rota e o tempo de resposta onde desejar
    return Promise.reject(error);
  }
);
