import { useEffect } from "react";
import { TextIdioma } from "../../controller/data";
import { ShowModal } from "../modalPai";
import { Button } from "@mui/material";

export function ModalComponent(props) {
  const handleClose = () => {
    props.onNoClick();
  };

  const handleYesClick = () => {
    props.onNoClick();
    props.onYesClick();
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        handleYesClick();
      } else if (event.key === "Escape") {
        handleClose();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const modalStyle = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "#fff",
    padding: "20px",
    zIndex: "999999",
    borderRadius: "5px",
    border: "1px solid",
    height: "auto",
    width: "auto",
  };

  const overlayStyle = {
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    background: "rgba(0, 0, 0, 0.7)",
    zIndex: "999998",
    backdropFilter: "blur(5px)", // Add blur effect to the overlay
  };

  return (
    <>
      <div style={overlayStyle}></div>

      <div style={modalStyle}>
        <h1>
          <b>{TextIdioma(props.question)}</b>
        </h1>
        <div style={{ display: "flex", gap: "10px", marginTop: "20px" }}>
          <Button
            variant="outlined"
            size="small"
            sx={{ width: "50%", color: "#67B86F", borderColor: "#67B86F" }}
            onClick={handleYesClick}
          >
            <b> {TextIdioma("Sim")}</b>
          </Button>
          <Button
            variant="outlined"
            size="small"
            sx={{ width: "50%", color: "#F00028", borderColor: "#F00028" }}
            onClick={handleClose}
          >
            <b> {TextIdioma("Não")}</b>
          </Button>
          {/* <div
            style={{
              width: "50%",
              border: "3px solid #67B86F",
              color: "#67B86F",
              borderRadius: "10px",
              backgroundColor: "transparent",
              transition: "background-color 0.3s ease",
              textAlign: "center",
              cursor: "pointer",
            }}
            onMouseOver={(e) =>
              (e.currentTarget.style.backgroundColor =
                "rgba(103, 184, 111,0.3)")
            }
            onMouseOut={(e) =>
              (e.currentTarget.style.backgroundColor = "transparent")
            }
            onClick={handleYesClick}
          >
            <b> {TextIdioma("Sim")}</b>
          </div>
          <div
            style={{
              width: "50%",
              border: "3px solid #F00028",
              color: "#F00028",
              borderRadius: "10px",
              backgroundColor: "transparent",
              transition: "background-color 0.3s ease",
              textAlign: "center",
              cursor: "pointer",
            }}
            onMouseOver={(e) =>
              (e.currentTarget.style.backgroundColor = "rgba(240, 0, 40,0.3)")
            }
            onMouseOut={(e) =>
              (e.currentTarget.style.backgroundColor = "transparent")
            }
            onClick={handleClose}
          >
            <b> {TextIdioma("Não")}</b>
          </div> */}
        </div>
      </div>
    </>
  );
}
