import "./style.css";
import { FaAsterisk, FaTimes } from "react-icons/fa";

export function ShowModal({ children, closeButton, zIndex }) {
  const modalStyle = {
    animation: "slideIn 0.5s forwards",
    position: "fixed",
    top: 0,
    right: 0,
    background: "#fff",
    padding: "20px",
    zIndex: zIndex ? zIndex : 999,
    height: "100%",
    overflow: "auto",
  };

  const overlayStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "rgba(0, 0, 0, 0.7)",
    zIndex: 999,
    backdropFilter: "blur(8px)",
  };

  const closeButtonStyle = {
    position: "absolute",
    top: "10px",
    right: "10px",
    cursor: "pointer",
  };

  return (
    <>
      <div style={overlayStyle}></div>
      <div style={modalStyle}>
        {closeButton != null ? (
          <>
            <div style={closeButtonStyle} onClick={closeButton}>
              <FaTimes />
            </div>
          </>
        ) : null}
        {children}
      </div>
    </>
  );
}
