import Table from "../../component/tabela";
import React, { useEffect, useMemo, useState } from "react";
import { API } from "../../controller/api/api";
import { PrimeiraMaiuscula } from "../../controller/util";
import { HeaderComponent } from "../../component/header";
import { ShowModal } from "../../component/modalPai";
import { InputPedido } from "../pedido/componente/input";
import { MascaraMonetaria, RemoveMascara } from "../../controller/data";
import { Button } from "../../component/button";
import { FaEllipsisV, FaPen } from "react-icons/fa";
import {
  Autocomplete,
  IconButton,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { ModalGravarDados } from "../../component/gravar";

let DadosReserva = [];
export function PageClient() {
  const [Dados, setDados] = useState([]);
  const [Selecionado, setSelecionado] = useState(null);
  const [TaxaEntrega, setTaxaEntrega] = useState([]);
  const [Bairro, setBairo] = useState("");
  const [Cidade, setCidade] = useState("");
  const [Estado, setEstado] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(0);

  function formatarCelular(numero) {
    // Removendo quaisquer caracteres que não sejam dígitos
    const apenasNumeros = numero.replace(/\D/g, "");

    // Checar se tem 11 dígitos (com o nono dígito)
    if (apenasNumeros.length === 11) {
      return `(${apenasNumeros.substring(0, 2)}) ${apenasNumeros.substring(
        2,
        7
      )}-${apenasNumeros.substring(7)}`;
    }
    // Checar se tem 10 dígitos (sem o nono dígito)
    else if (apenasNumeros.length === 10) {
      return `(${apenasNumeros.substring(0, 2)}) ${apenasNumeros.substring(
        2,
        6
      )}-${apenasNumeros.substring(6)}`;
    }
    // Se não for nenhum dos anteriores, retorna o número original
    else {
      return numero;
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "Nome",
        accessor: "nome",
        size: "20%",
        format: PrimeiraMaiuscula,
      },
      {
        Header: "Celular",
        accessor: "celular",
        size: "10%",
        format: formatarCelular,
        textAlign: "center",
      },
      {
        Header: "CPF",
        accessor: "cpf",
        size: "10%",
        format: PrimeiraMaiuscula,
        textAlign: "center",
      },
      {
        Header: "Nascimento",
        accessor: "dataNascimento",
        size: "10%",
        format: revertDateFormat,
        textAlign: "center",
      },
      {
        Header: "Rua",
        accessor: "rua",
        size: "20%",
        format: PrimeiraMaiuscula,
      },
      {
        Header: "Bairro",
        accessor: "bairro",
        size: "20%",
        format: PrimeiraMaiuscula,
        textAlign: "center",
      },
      {
        Header: "Editar",
        size: "50px",
        component: (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IconButton color="primary">
              <FaPen />
            </IconButton>
          </div>
        ),
        func: AlterarDadosCliente,
      },
      // ... outros cabeçalhos da tabela
    ],
    []
  );

  function BuscaDadosPrincipal() {
    API.get("v2/dados/clientes").then(function (dados) {
      setDados(dados.data);
      DadosReserva = dados.data;
    });
  }

  useEffect(() => {
    BuscaDadosPrincipal();

    API.get("/v1/consulta/generica/taxa_entrega/*/*/*").then(function (dados) {
      setTaxaEntrega(dados.data);
    });
  }, []);

  useEffect(() => {
    //console.log(Bairro);
    //console.log(TaxaEntrega);

    if (Bairro != "") {
      for (var i = 0; i < TaxaEntrega.length; i++) {
        if (
          PrimeiraMaiuscula(TaxaEntrega[i].bairro) === PrimeiraMaiuscula(Bairro)
        ) {
          setSelectedIndex(i);
          break;
        }
      }
    }
  }, [Bairro]);

  function atualizarPorCodigo(codigo, novoObjeto) {
    const novosDados = Dados.map((item) => {
      if (item.codigo === codigo) {
        return {
          ...novoObjeto,
        };
      }
      return item;
    });
    ////console.log(novosDados)
    setDados(novosDados);
  }

  function AlterarDadosCliente(codigo) {
    setSelecionado(codigo);
    const resultado = DadosReserva.find((item) => item.codigo === codigo);

    if (resultado) {
      resultado.dataNascimento = resultado.dataNascimento
        ? revertDateFormat(resultado?.dataNascimento)
        : "";
      resultado.rua = PrimeiraMaiuscula(resultado.rua);
      resultado.complemento = PrimeiraMaiuscula(resultado.complemento);
      setSelecionado(resultado);
      setBairo(resultado.bairro);
      setCidade(resultado.cidade);
      setEstado(resultado.estado);
    } else {
      console.error(`Nenhum item encontrado com o código ${codigo}`);
    }
  }

  function AlteraValor(campo, valor) {
    let value = Selecionado;
    ////console.log(campo)
    if (campo == "Nome") {
      value.nome = PrimeiraMaiuscula(valor);
    }
    if (campo == "Celular") {
      value.celular = RemoveMascara(valor);
    }
    if (campo == "CPF") {
      value.cpf = valor;
      ////console.log(value.cpf)
    }
    if (campo == "Complemento") {
      value.complemento = PrimeiraMaiuscula(valor);
    }
    if (campo == "Rua") {
      value.rua = PrimeiraMaiuscula(valor);
    }
    if (campo == "Número") {
      value.numero = PrimeiraMaiuscula(valor);
    }
    if (campo == "Nascimento") {
      value.dataNascimento = valor;
    }

    setSelecionado(value);
  }

  function convertDateFormat(input) {
    const [day, month, year] = input.split("/");
    return `${year}-${month}-${day}`;
  }

  function Salvar() {
    let body = {
      codigo: Selecionado.codigo,
      nome: Selecionado.nome,
      celular: Selecionado.celular,
      cpf: Selecionado.cpf ? Selecionado.cpf : "",
      nascimento: Selecionado?.dataNascimento
        ? convertDateFormat(Selecionado?.dataNascimento)
        : "",
      numero: Selecionado.numero,
      rua: Selecionado.rua,
      complemento: Selecionado.complemento,
      bairro: Bairro,
      cidade: Cidade,
      estado: Estado,
    };

    atualizarPorCodigo(Selecionado.codigo, body);
    API.post("/v2/dados/clientes", body).then(function (response) {
      setSelecionado(null);
      BuscaDadosPrincipal();
    });
  }

  function revertDateFormat(input) {
    if (typeof input !== "string") return input;

    const [year, month, day] = input.split("-");
    return `${day}/${month}/${year}`;
  }

  const handleChange = (event, newValue) => {
    if (newValue) {
      const index = newValue.index;
      setBairo(TaxaEntrega[index].bairro);
      setCidade(TaxaEntrega[index].cidade);
      setEstado(TaxaEntrega[index].estado);
      setSelectedIndex(index);
    } else {
      setBairo("");
      setCidade("");
      setEstado("");
      setSelectedIndex(null);
    }
  };

  return (
    <>
      <HeaderComponent>
        <Table columns={columns} data={Dados} codigo={"codigo"} />
      </HeaderComponent>
      {Selecionado != null ? (
        <>
          <ModalGravarDados
            Descricao={"Alteração"}
            closeButton={() => setSelecionado(null)}
            Funcao={Salvar}
          >
            <div
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <InputPedido
                Descricao="Nome"
                Retorno={AlteraValor}
                Valor={PrimeiraMaiuscula(Selecionado.nome)}
              />
              <InputPedido
                Descricao="Celular"
                Retorno={AlteraValor}
                Valor={RemoveMascara(Selecionado.celular)}
                Tipo={"celular"}
              />
              <InputPedido
                Descricao="CPF"
                Retorno={AlteraValor}
                Valor={Selecionado.cpf ? Selecionado.cpf : ""}
                Tipo={"cpf"}
              />
              <InputPedido
                Descricao="Nascimento"
                Retorno={AlteraValor}
                Valor={Selecionado?.dataNascimento}
                Tipo={"data"}
              />
              <InputPedido
                Descricao="Rua"
                Retorno={AlteraValor}
                Valor={Selecionado.rua}
              />
              <InputPedido
                Descricao="Número"
                Retorno={AlteraValor}
                Valor={
                  Selecionado.complemento
                    ? PrimeiraMaiuscula(Selecionado.complemento)
                    : ""
                }
              />
              <InputPedido
                Descricao="Complemento"
                Retorno={AlteraValor}
                Valor={Selecionado.numero}
              />
              {/* <InputPedido Descricao="Bairro" Retorno={null} Valor={Selecionado.bairro} /> */}

              <Autocomplete
                value={TaxaEntrega[selectedIndex] || null}
                onChange={handleChange}
                options={TaxaEntrega.map((item, index) => ({ ...item, index }))}
                getOptionLabel={(option) =>
                  `${PrimeiraMaiuscula(option.bairro)} (${PrimeiraMaiuscula(
                    option.cidade
                  )}/${option.estado}) ${MascaraMonetaria(option.valorTaxa)}`
                }
                fullWidth
                size="small"
                sx={{ marginBottom: "10px" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Selecione uma taxa de entrega"
                  />
                )}
              />

              {/* <Select
                value={selectedIndex} // assumindo que selectedIndex seja o estado que controla o item selecionado
                onChange={(e) => {
                  const index = e.target.value;
                  setBairo(TaxaEntrega[index].bairro);
                  setCidade(TaxaEntrega[index].cidade);
                  setEstado(TaxaEntrega[index].estado);
                  setSelectedIndex(index);
                }}
                style={{
                  height: "34px",
                  width: "100%",
                  border: "1px solid",
                  marginBottom: "10px",
                }}
              >
                {TaxaEntrega.map((item, index) => (
                  <MenuItem key={index} value={index}>
                    {`${PrimeiraMaiuscula(item.bairro)} (${PrimeiraMaiuscula(
                      item.cidade
                    )}/${item.estado}) ${MascaraMonetaria(item.valorTaxa)}`}
                  </MenuItem>
                ))}
              </Select> */}
            </div>
          </ModalGravarDados>
        </>
      ) : null}
    </>
  );
}
