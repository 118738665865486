import { useEffect, useState } from "react";
import { isMobileDevice } from "../../../controller/mobile";
import {
  Input,
  InputAdornment,
  InputLabel,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import { API } from "../../../controller/api/api";

export function CardTempo({ tipo, valor }) {
  const [Valor, setValor] = useState(valor);

  useEffect(() => {
    if (Valor > 0) {
      const apiEndpoint =
        tipo === 1 ? "v2/tempo/vembuscar/" : "v2/tempo/delivery/";
      API.get(apiEndpoint + Valor);
    }
  }, [Valor]);

  useEffect(() => {
    setValor(valor);
  }, [valor]);

  const cardStyle = {
    borderRadius: "12px",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
    border: "1px solid #ddd",
    margin: "10px",
    flex: "0 0 275px",
    height: "auto",
    transition: "transform 0.3s ease, box-shadow 0.3s ease",
    cursor: "pointer",
    backgroundColor: "#fff",
  };

  const handleMouseEnter = (e) => {
    e.currentTarget.style.transform = "scale(1.05)";
    e.currentTarget.style.boxShadow = "0px 8px 20px rgba(0, 0, 0, 0.2)";
  };

  const handleMouseLeave = (e) => {
    e.currentTarget.style.transform = "scale(1)";
    e.currentTarget.style.boxShadow = "0px 4px 15px rgba(0, 0, 0, 0.1)";
  };

  return (
    <Card
      style={cardStyle}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <CardContent>
        <Typography variant="h6" component="div" gutterBottom>
          Estimativa
        </Typography>
        <Input
          id={tipo}
          value={Valor}
          onChange={(e) => setValor(e.target.value)}
          fullWidth
          startAdornment={
            <InputAdornment position="start">
              <img
                src={
                  tipo === 2
                    ? "/do-icone-localizacao.png"
                    : "/do-icone-balcao.png"
                }
                alt=""
                style={{ height: "1.5rem", width: "1.5rem" }}
              />
            </InputAdornment>
          }
        />
      </CardContent>
    </Card>
  );
}
