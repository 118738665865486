import Chart from "react-apexcharts";
import { PrimeiraMaiuscula } from "../../../../controller/util";
import { MascaraMonetaria } from "../../../../controller/data";

export function GraficoProdutos({ dados, tipo }) {
  // Extrair as categorias e os dados da série a partir dos dados fornecidos
  const categories = dados.map((item) => PrimeiraMaiuscula(item.produto));
  const seriesData =
    tipo === 0
      ? dados.map((item) => item.quantidade)
      : dados.map((item) => item.total);

  const generateColor = (name) => {
    // Gerar valores aleatórios para R, G, B entre 0 e 255
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);

    // Retornar cor no formato RGBA
    return `rgba(${r}, ${g}, ${b}, 1)`;
  };

  const colors = categories.map((category) => generateColor(category));

  //console.log(colors);

  const options = {
    chart: {
      type: "bar",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
        // colors: colors,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: categories,
    },
    yaxis: {
      title: {
        text: tipo === 0 ? "Vendas (em unidades)" : "Vendas (em valor R$)",
      },
    },
    fill: {
      opacity: 1,
      colors: ["#F44336", "#E91E63", "#9C27B0"],
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return tipo === 0 ? val + " unidades" : MascaraMonetaria(val);
        },
      },
    },
  };

  const series = [
    {
      name: "Vendas",
      data: seriesData,
      // color: ["red", "green", "blue"],
    },
  ];

  return (
    <div id="chart">
      <Chart options={options} series={series} type="bar" height={350} />
    </div>
  );
}
