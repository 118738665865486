import { useEffect, useState } from "react";
import { API } from "../../../../controller/api/api";
import {
  MascaraMonetaria,
  formatarData,
  formatarHora,
  formatarHoraMin,
} from "../../../../controller/data";
import {
  Notifica,
  NotificaWarning,
  PrimeiraMaiuscula,
} from "../../../../controller/util";
import { FaEdit, FaExclamation, FaPrint, FaRegEye } from "react-icons/fa";
import { ShowModal } from "../../../../component/modalPai";
import Select from "react-select";
import { Button } from "../../../../component/button";
import { InputPedido } from "../../../pedido/componente/input";
import { IconButton, Tooltip } from "@mui/material";

export function Movimentacoes({ id, estorno }) {
  const [Dados, setDados] = useState([]);
  const [PedidoEstorno, setPedidoEstorno] = useState(0);
  const [ModalMesa, setModalMesa] = useState(false);
  const [ModalCancelamentoNFCE, setModalCancelamentoNFCE] = useState(false);
  const [Motivo, setMotivo] = useState("");
  const [Comandas, setComandas] = useState([]);
  const [ComandaSelecioanda, setComandaSelecioanda] = useState(null);

  const customStyles1 = {
    control: (provided) => ({
      ...provided,
      // backgroundColor: "#f5f5f5",
      minHeight: "34px",
      height: "34px",
      borderColor: "black",
      transition: "border-color 0.1s ease 0s",
      width: "100%",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#A8001C"
        : state.isFocused
        ? "#e5e5e5"
        : null,
      color: state.isSelected ? "white" : null,
    }),
  };

  useEffect(() => {
    BuscaDados();
  }, []);

  function BuscaDados() {
    API.get(`/v2/movimentacoes/caixa/${id}`).then((response) => {
      setDados(response.data);
      //console.log(response.data);
      setComandaSelecioanda(null);
      setModalMesa(false);
    });
  }

  function ExecutarCancelamento() {
    if (Motivo.length < 15) {
      NotificaWarning("Motivo deve possuir pelomenos 15 caractères");
      return false;
    }
    //console.log({ code: 2, chave: PedidoEstorno.chave, obs: Motivo });

    API.post(`/nfce/code`, {
      code: 2,
      chave: PedidoEstorno.chave,
      obs: Motivo,
      id: PedidoEstorno.codigo,
    }).then(function (response) {
      setModalCancelamentoNFCE(false);
      setMotivo("");
      Notifica(
        "Solicitação de cancelamento enviada ao Sefaz, caso não seja cancelada abaixo aparecera o motivo!"
      );
    });
  }

  function Estorno(dados) {
    setPedidoEstorno(dados);
    //console.log(dados);

    if (dados.chave?.length == 44) {
      setModalCancelamentoNFCE(true);
      return false;
    }

    if (dados.idFicha == 0) {
      API.post(`v2/estorno/pedido/${dados.codigo}`).then(function (response) {
        BuscaDados();
        Notifica("Estorno efetuado com sucesso!");
        return true;
      });
    } else {
      API.get("/v1/mesas").then(function (dados) {
        //console.log(dados.data);
        let data = [];
        for (var i = 0; i < dados.data.length; i++) {
          if (dados.data[i].selecionada == 0) {
            data.push({
              value: dados.data[i].idMesa,
              label: PrimeiraMaiuscula(
                dados.data[i].descricao1 + " " + dados.data[i].nrMesa
              ),
            });
          }
        }

        setComandas(data);
        //console.log(data);
        setModalMesa(true);
      });
    }
  }

  function ExecutarEstorno() {
    if (ComandaSelecioanda == null) {
      NotificaWarning("Deve-se Selecionar a Comanda / Mesa!");
      return false;
    }
    API.post(
      `/v2/estorno/pedido/${PedidoEstorno.codigo}/${ComandaSelecioanda.value}`
    ).then(function (response) {
      BuscaDados();
    });
  }

  function SegundaVia(chave, codigo) {
    let body = { code: 1, chave: chave, obs: "2", id: codigo };
    //console.log(body);
    API.post(`/nfce/code`, body).then(function (response) {
      // Notification('')
      // BuscaDados();
    });
  }

  function LiberaExtorno(pedido) {
    //console.log(pedido);

    return true;
  }

  return (
    <>
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ flex: "1", overflowY: "auto" }}>
          <table style={{ width: "100%" }}>
            <thead>
              <tr style={{ borderBottom: "1px solid black" }}>
                <th style={{ textAlign: "center" }}>#</th>
                <th style={{ textAlign: "center" }}>Código</th>
                <th style={{ textAlign: "center" }}>Data / Hora</th>
                <th style={{ textAlign: "left" }}>Nome do Cliente / Nº Mesa</th>
                <th style={{ textAlign: "left" }}>Chave NFC-e</th>
                <th style={{ textAlign: "center" }}>Total</th>
                {estorno ? (
                  <th style={{ textAlign: "center", width: "100px" }}>
                    Estorno
                  </th>
                ) : null}
                <th style={{ textAlign: "center" }}>2º NFC-e </th>
              </tr>
            </thead>
            <tbody>
              {Dados.map((item, index) => (
                <tr key={index}>
                  <td style={{ textAlign: "center", width: "100px" }}>
                    <Tooltip title="Visualizar Dados">
                      <IconButton aria-label="visualizar" sx={{}}>
                        <FaRegEye
                          onClick={() =>
                            (window.location.href = `/pedido/${item.codigo}`)
                          }
                        />
                      </IconButton>
                    </Tooltip>
                    #{item.codigo}
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      alignItems: "center",
                      width: "100px",
                    }}
                  >
                    <b>{item.idFicha > 0 ? null : item.codigoPedidoDia}</b>
                  </td>
                  <td style={{ textAlign: "center", width: "200px" }}>
                    {formatarData(item.dataPedido)} ás{" "}
                    {formatarHoraMin(item.horaPedido)}
                  </td>
                  <td style={{ textAlign: "left" }}>
                    {PrimeiraMaiuscula(item.cliente)}
                  </td>
                  <td style={{ textAlign: "left" }}>
                    <p>{PrimeiraMaiuscula(item.chave)}</p>
                    <p>{PrimeiraMaiuscula(item.motivo)}</p>
                  </td>
                  <td style={{ textAlign: "center", width: "150px" }}>
                    <b>{MascaraMonetaria(item.valorTotalPedido)}</b>
                  </td>
                  {estorno && LiberaExtorno(item) ? (
                    <td style={{ textAlign: "center" }}>
                      <Tooltip title="Extornar Pedido">
                        <IconButton aria-label="extornar" sx={{}}>
                          <FaEdit onClick={() => Estorno(item)} />
                        </IconButton>
                      </Tooltip>
                      {/* <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => Estorno(item)}
                      >
                        <FaEdit />
                      </div> */}
                    </td>
                  ) : (
                    <td style={{ textAlign: "center" }}></td>
                  )}
                  <td style={{ textAlign: "center" }}>
                    <Tooltip title="Imprimir Segunda Via NFC-e">
                      <IconButton aria-label="segunda" sx={{}}>
                        <FaPrint
                          onClick={() => SegundaVia(item.chave, item.codigo)}
                        />
                      </IconButton>
                    </Tooltip>
                    {/* <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => SegundaVia(item.chave, item.codigo)}
                    >
                      <FaPrint />
                    </div> */}
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              {/* <tr>
              <td style={{ textAlign: "center" }}></td>
              <td style={{ textAlign: "center" }}>
                <strong>{Quantidade()}</strong>
              </td>
              <td style={{ textAlign: "right" }}>
                <strong>{MascaraMonetaria(Total())}</strong>
              </td>
            </tr> */}
            </tfoot>
          </table>
        </div>
      </div>
      {ModalMesa ? (
        <>
          <ShowModal closeButton={() => setModalMesa(false)}>
            <div style={{ width: "20vw", marginTop: "10px" }}>
              <h1 style={{ textAlign: "center", marginBottom: "10px" }}>
                <b>Selecione a Comanda / Mesa de Destino</b>
              </h1>
              <span>Comanda / Mesa</span>
              <Select
                value={ComandaSelecioanda}
                onChange={setComandaSelecioanda}
                options={Comandas}
                isSearchable={true}
                placeholder=""
                styles={customStyles1}
              />
              <div style={{ marginTop: "10px" }}>
                <Button
                  descricao="Executar Estorno"
                  evento={ExecutarEstorno}
                  color="white"
                  borderColor="#269931"
                  fontColor="#269931"
                  BorderRadius="5px"
                />
              </div>
            </div>
          </ShowModal>
        </>
      ) : null}

      {ModalCancelamentoNFCE ? (
        <>
          <ShowModal closeButton={() => setModalCancelamentoNFCE(false)}>
            <div style={{ width: "20vw", marginTop: "10px" }}>
              <h1 style={{ textAlign: "center", marginBottom: "10px" }}>
                <b>Informe o Motivo do Cancelamento da NFC-e</b>
              </h1>

              <InputPedido
                Descricao="Motivo"
                Valor={Motivo}
                Set={setMotivo}
                sx={{ width: "100%" }}
              />
              <span style={{ fontSize: "0.75rem", color: "#ccc" }}>
                Mínimo é 15 caractères
              </span>

              <div style={{ marginTop: "10px" }}>
                <>
                  <Button
                    descricao="Cancelar NFC-e"
                    evento={ExecutarCancelamento}
                    color="white"
                    borderColor="#269931"
                    fontColor="#269931"
                    BorderRadius="5px"
                  />
                </>
              </div>
            </div>
          </ShowModal>
        </>
      ) : null}
    </>
  );
}
