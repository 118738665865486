import React, { useState, useEffect } from "react";
import { HeaderComponent } from "../../../../component/header";
import { useNavigate, useParams } from "react-router-dom";
import { API } from "../../../../controller/api/api";
import { BuscaLocal } from "../../../../controller/storage";
import { PrimeiraMaiuscula } from "../../../../controller/util";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Autocomplete, TextField } from "@mui/material";
import { Button } from "../../../../component/button";
import { CarregaProdutos } from "./component/CardProduct";

export function TransferenciaPage() {
  const { id } = useParams();

  const [MesaSelecionada, setMesaSelecionada] = useState("");
  const [Mesas, setMesas] = useState([]);
  const [DescricaoMesa, setDescricaoMesa] = useState("");
  const [CodigoPedido, setCodigoPedido] = useState(0);

  useEffect(() => {
    API.get("/v1/mesas").then(function (dados) {
      setMesas(dados.data);
    });

    API.get("/v1/mesa/" + id).then(function (dados) {
      setDescricaoMesa(
        dados.data[0].descricaoMesa + " " + dados.data[0].nrMesa
      );
      setCodigoPedido(dados.data[0].pedido);

      API.get("/v1/pedido/produtos/ " + dados.data[0].pedido).then(function (
        dados
      ) {});
    });
    handleOpen();
  }, []);

  function Sair() {}

  const navigate = useNavigate();
  function FinalizaTransferencia() {
    let DadosSelecionados = JSON.parse(BuscaLocal("QUANTIDADE_SELECIONADA"));

    API.post(
      `v2/transferencia/produtos/${MesaSelecionada.value}`,
      DadosSelecionados
    )
      .then((response) => {
        navigate(BuscaLocal("VOLTAR"));
      })
      .catch((error) => {});
    return false;
  }

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    // border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const handleAutocompleteChange = (event, newValue) => {
    setMesaSelecionada(newValue);
  };

  return (
    <>
      <HeaderComponent navegar={"/salao/" + id} onVoltar={Sair}>
        {/* <Button onClick={handleOpen}>Open modal</Button> */}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Mesa / Comanda
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Selecione a comanda / mesa que recebera os produtos para a
              transferencia
            </Typography>

            <Autocomplete
              value={MesaSelecionada}
              onChange={handleAutocompleteChange}
              style={{ marginTop: "10px", marginBottom: "10px" }}
              options={Mesas.map((item) => ({
                label: `${item.descricao1} ${item.idMesa} ${
                  item.descricao ? `(${item.descricao})` : ""
                }`,
                value: item.idMesa,
              }))}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Mesa / Comanda"
                  variant="outlined"
                />
              )}
              fullWidth
              size="small"
            />

            <Button descricao="Selecionar" evento={handleClose} />
          </Box>
        </Modal>
        <div
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingLeft: "30px",
            height: "50px",
            color: "#A8001C",
          }}
        >
          <div
            style={{ textAlign: "center", cursor: "pointer" }}
            onClick={handleOpen}
          >
            <span style={{ fontSize: "18px" }}>
              <b>
                {MesaSelecionada
                  ? `De ${PrimeiraMaiuscula(DescricaoMesa)} Para ${
                      MesaSelecionada.label
                    }`
                  : PrimeiraMaiuscula(DescricaoMesa)}
              </b>
            </span>
          </div>
        </div>

        {/* {Dados.map((dados) => (
          <Card
            Codigo={dados.codigo}
            Produto={dados.nomeProduto}
            Obs={dados.obs}
            Quantidade={dados.quantidade}
            Total={dados.valorTotal}
          ></Card>
        ))} */}
        {CodigoPedido > 0 ? (
          <CarregaProdutos Codigo={CodigoPedido} Servico={0} />
        ) : null}

        {MesaSelecionada != "" ? (
          <Button descricao="Transferir" evento={FinalizaTransferencia} />
        ) : null}
      </HeaderComponent>
    </>
  );
}
