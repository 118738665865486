import React, { useEffect, useState } from "react";
import {
  FaHamburger,
  FaWhatsapp,
  FaCog,
  FaWifi,
  FaLayerGroup,
  FaPrint,
} from "react-icons/fa";
import { ApagaLocal, BuscaLocal, GravaLocal } from "../../controller/storage";
import { Navigate, useNavigate } from "react-router-dom";
import { SidebarComponent } from "./componente/sidebar";
import { API } from "../../controller/api/api";
import { ShowModal } from "../modalPai";
import { Button } from "../button";
import {
  ThemaUi,
  isMobileDevice,
  isMobileDeviceBloqueado,
} from "../../controller/mobile";
import { ParametroConfiguracao } from "../../controller/parametros";
import { Toggle } from "../toggle";
import IconeFiltro from "./componente/iconeFiltro";
import { PageBloqueado } from "../../page/bloqueado";
import { MobileBloqueado } from "../../page/bloqueado/mobile";
import { Badge, IconButton, ThemeProvider } from "@mui/material";
import styled from "@emotion/styled";
import { PermissaoModulo } from "../../controller/modulos";

let intervalId = [];
let intervalIfood = [];
export function HeaderComponent(props) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isLogoHovered, setIsLogoHovered] = useState(false);
  const [isWppLogg, setIsWppLogg] = useState(false);

  const [ModalWhatsapp, setModalWhatsapp] = useState(false);
  const [ModalImpressao, setModalImpressao] = useState(false);
  const [ModalSite, setModalSite] = useState(false);
  const [base64, setbase64] = useState("");
  const [Numero, setNumero] = useState("");
  const [ApiWhatsapp, setApiWhatsapp] = useState(false);
  const [ApiiFood, setApiiFood] = useState(null);
  const [siteGoopedir, setSiteGoopedir] = useState(null);
  const [StatusHorario, setStatusHorario] = useState(false);
  const [HorarioInicial, setHorarioInicial] = useState("");
  const [HorarioFinal, setHorarioFinal] = useState("");
  const [isIfood, setIsIfood] = useState(false);
  const [iFood, setIfood] = useState(false);

  const [dadosgeral, setDadosGeral] = useState({});

  const whatsappIconColor = isWppLogg ? "green" : "red";
  const siteIconColor = siteGoopedir == 1 ? "green" : "red";

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleHover = () => {
    setIsHovered(!isHovered);
  };

  const handleLogoHover = () => {
    setIsLogoHovered(!isLogoHovered);
  };

  function ColorNotificacaoImpressao() {
    if (!dadosgeral) {
      return "alert";
    }
    if (!dadosgeral?.impressora?.comanda) {
      return "alert";
    }

    if (!dadosgeral?.impressora?.cozinha) {
      return "alert";
    }

    return "sucesso";
  }

  function Navegar(caminho) {
    if (typeof props.onVoltar === "function") {
      // A propriedade onVoltar é uma função, podemos chamá-la
      props.onVoltar();
    }

    var local = "";
    local = BuscaLocal("VOLTAR_PEDIDO");
    ApagaLocal("VOLTAR_PEDIDO");
    if (local == undefined) {
      local = caminho || BuscaLocal("VOLTAR");
    } else {
      ApagaLocal("VOLTAR");
    }
    navigate(local);
    return local;
  }

  useEffect(() => {
    if (isMobileDevice()) {
    } else {
    }
    fetchParam();
  }, []);

  const isHoraEntreLimites = (horaInicial, horaFinal) => {
    const now = new Date();
    const horaAtual = now.getHours();
    const minutoAtual = now.getMinutes();
    const segundoAtual = now.getSeconds();

    const [horaInicio, minutoInicio, segundoInicio] = horaInicial
      .split(":")
      .map(Number);
    const [horaFim, minutoFim, segundoFim] = horaFinal.split(":").map(Number);

    const horaAtualEmMinutos = horaAtual * 60 + minutoAtual;
    const horaInicioEmMinutos = horaInicio * 60 + minutoInicio;
    const horaFimEmMinutos = horaFim * 60 + minutoFim;

    if (
      horaAtualEmMinutos > horaInicioEmMinutos &&
      horaAtualEmMinutos < horaFimEmMinutos
    ) {
      return true;
    } else if (
      horaAtualEmMinutos === horaInicioEmMinutos &&
      minutoAtual >= minutoInicio &&
      segundoAtual >= segundoInicio
    ) {
      return true;
    } else if (
      horaAtualEmMinutos === horaFimEmMinutos &&
      minutoAtual <= minutoFim &&
      segundoAtual <= segundoFim
    ) {
      return true;
    }
    return false;
  };

  const getDiaSemanaAtual = () => {
    const diasDaSemana = [
      "domingo",
      "segunda",
      "terca",
      "quarta",
      "quinta",
      "sexta",
      "sabado",
    ];
    const dataAtual = new Date();
    const diaSemanaAtual = dataAtual.getDay(); // 0 = Domingo, 1 = Segunda-feira, ..., 6 = Sábado
    return diasDaSemana[diaSemanaAtual];
  };

  const fetchParam = async () => {
    const diaSemanaAtual = getDiaSemanaAtual();

    let Atual = (await ParametroConfiguracao(diaSemanaAtual)) == 1;

    if (Atual) {
      let horaInicial = await ParametroConfiguracao("horarioAbertura");
      setHorarioInicial(horaInicial);
      let horaFinal = await ParametroConfiguracao("horarioFechamento");
      setHorarioFinal(horaFinal);
      if (isHoraEntreLimites(horaInicial, horaFinal)) {
        setStatusHorario(true);
      } else {
        setStatusHorario(false);
      }
    } else {
      setStatusHorario(false);
    }

    intervalId = setInterval(() => {
      DadosGeral();
    }, 1 * 3000);
  };

  function DadosGeral() {
    if (props.InativarAtualizacoes) {
      return false;
    }

    API.get("v2/status")
      .then((dados) => {
        setDadosGeral(dados.data);
        setSiteGoopedir(parseInt(dados.data.site.status_loja));
        setApiWhatsapp(true);
        GravaLocal("modulos", JSON.stringify(dados.data.modulos));
        GravaLocal("site", JSON.stringify(dados.data.site));
        setIsWppLogg(dados.data.whatsapp.status);
        setbase64("data:image/jpeg;base64," + dados.data.whatsapp.base64);
        setNumero(dados.data.whatsapp.celular);
        setApiiFood(dados.data.ifood);
      })
      .catch((error) => {
        // console.error('Ocorreu um erro ao buscar os dados:', error);
      });
  }

  useEffect(() => {
    if (isWppLogg) {
      clearInterval(intervalId);
    }
  }, [isWppLogg]);

  function DadosiFood() {
    if (props.InativarAtualizacoes) {
      return false;
    }

    API.get("v1/util/ifood/status")
      .then((dados) => {
        setApiiFood(dados.data[0]);
      })
      .catch((error) => {
        // console.error('Ocorreu um erro ao buscar os dados:', error);
      });
  }

  function DadosSite() {
    if (props.InativarAtualizacoes) {
      return false;
    }

    API.get("v2/status/site")
      .then((dados) => {
        setSiteGoopedir(parseInt(dados.data[0].status_loja));
      })
      .catch((error) => {
        // console.error('Ocorreu um erro ao buscar os dados:', error);
      });
  }

  function DadosWhatsapp() {
    API.get("whatsapp/goopedir/data")
      .then((dados) => {
        setApiWhatsapp(true);

        // //console.log(dados.data);

        setIsWppLogg(dados.data.status);
        setbase64("data:image/jpeg;base64," + dados.data.base64);
        setNumero(dados.data.celular);
      })
      .catch((error) => {
        // console.error('Ocorreu um erro ao buscar os dados:', error);
      });
  }

  function Exibir() {
    API.post("/whatsapp/goopedir/visualizar");
  }

  function Atualizar() {
    API.post("/whatsapp/goopedir/atualizar");
  }

  function Desconectar() {
    API.post("/whatsapp/goopedir/desconectar");
    // intervalId = setInterval(() => {
    //   DadosWhatsapp();
    // }, 1000);
  }

  const url = document.querySelector('link[name="url-server"]').href;
  const iconColor = isHovered ? "#FFFFFF" : "#000000";
  const logoSource = isLogoHovered
    ? "logo-goopedir-b.png"
    : "logo-goopedir-p.png";
  const navigate = useNavigate();

  const ImagemQrcod = ({ base64 }) => {
    return (
      <>
        {ApiWhatsapp ? (
          <img
            src={base64}
            style={{ display: "block", margin: "0 auto", marginBottom: "10px" }}
            alt="Base64 Image"
          />
        ) : (
          <>Sem conexão com api</>
        )}
      </>
    );
  };

  function Parametro() {
    let url = window.location.origin + `/parametros`;
    window.open(url, "_self");
  }

  function AbrirSiteFechar() {
    if (siteGoopedir == 1) {
      API.post("/v2/status/site/close");
    } else {
      API.post("/v2/status/site/open");
    }
    DadosSite();
  }

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      right: -3,
      top: 13,
      // border: `1px solid ${theme.palette.background.paper}`,
      padding: "0 4px",
    },
  }));

  return (
    <>
      <ThemeProvider theme={ThemaUi()}>
        {isMobileDevice() && isMobileDeviceBloqueado() ? (
          <MobileBloqueado />
        ) : (
          <>
            <PageBloqueado>
              <header
                style={{
                  backgroundColor: "#A8001C",
                  height: "60px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between", // Isso alinhará o ícone do WhatsApp à direita
                  position: "fixed",
                  top: 0,
                  width: "100%",
                  zIndex: 98,
                  fontSize: 24,
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <FaHamburger
                    style={{
                      marginLeft: "20px",
                      cursor: "pointer",
                      color: iconColor,
                      transition: "transform 0.3s",
                      transform: isHovered ? "rotate(90deg)" : "rotate(0deg)",
                    }}
                    onClick={handleMenuToggle}
                    onMouseEnter={handleHover}
                    onMouseLeave={handleHover}
                  />
                  <img
                    className="logo"
                    src={url + logoSource}
                    alt="Logo"
                    style={{
                      width: "100px",
                      marginLeft: "20px",
                      cursor: isLogoHovered ? "pointer" : "initial",
                    }}
                    onMouseEnter={handleLogoHover}
                    onMouseLeave={handleLogoHover}
                    onClick={() => Navegar(props.navegar)}
                  />
                </div>

                {isMobileDevice() ? null : (
                  <>
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        marginRight: "20px",
                        alignItems: "center",
                      }}
                    >
                      {iFood && (
                        <>
                          <IconeIfood onClick={() => setIsIfood(!isIfood)} />
                        </>
                      )}

                      <IconeSite
                        onClick={() => setModalSite(!ModalSite)}
                        whatsappIconColor={siteIconColor}
                      />

                      {PermissaoModulo("whatsapp") ? (
                        <IconWhatsapp
                          onClick={() => setModalWhatsapp(!ModalWhatsapp)}
                          whatsappIconColor={whatsappIconColor}
                        />
                      ) : null}

                      <IconButton
                        aria-label="cart"
                        onClick={() => setModalImpressao(!ModalImpressao)}
                      >
                        <Badge
                          color={ColorNotificacaoImpressao()}
                          variant="dot"
                        >
                          <FaPrint />
                        </Badge>
                      </IconButton>

                      <IconConfiguracao onClick={() => Parametro()} />

                      {props.ModalFiltro ? (
                        <>
                          <IconeFiltro onClick={() => props.ModalFiltro()} />
                        </>
                      ) : null}
                    </div>
                  </>
                )}
                {isMenuOpen && (
                  <SidebarComponent
                    Close={handleMenuToggle}
                    Navegacao={Navegar}
                  />
                )}

                {ModalWhatsapp && (
                  <ShowModal
                    altura={20}
                    largura={20}
                    closeButton={() => setModalWhatsapp(!ModalWhatsapp)}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h2>
                        <strong>Whatsapp</strong>
                      </h2>
                      {isWppLogg ? (
                        <>
                          <h3>{Numero}</h3>
                          <div style={{ marginBottom: "10px", width: "100%" }}>
                            <Button
                              descricao={"Desconectar"}
                              color="#4bbb44"
                              borderColor="#A8001C"
                              fontColor="#A8001C"
                              evento={Desconectar}
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          <ImagemQrcod base64={base64} />
                          <div style={{ marginBottom: "10px", width: "100%" }}>
                            <Button
                              descricao={"Atualizar"}
                              color="#4bbb44"
                              borderColor="#A8001C"
                              fontColor="#A8001C"
                              evento={Atualizar}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </ShowModal>
                )}

                {ModalSite && (
                  <ShowModal
                    altura={20}
                    largura={20}
                    closeButton={() => setModalSite(!ModalSite)}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        // alignItems: "center",
                        // justifyContent: "center",
                      }}
                    >
                      <h2 style={{ textAlign: "center" }}>
                        <strong>Site</strong>
                      </h2>
                      <p style={{ fontSize: "1rem" }}>
                        <b>Horário de Funcionamento</b>
                      </p>
                      <p style={{ fontSize: "0.9rem" }}>
                        {StatusHorario
                          ? `Aberto até ${HorarioFinal.slice(0, 5)}`
                          : "Fechado"}
                      </p>

                      <div style={{ fontSize: "0.9rem" }}>
                        <Toggle
                          Titulo={`Status de Funcionamento do Site`}
                          Change={AbrirSiteFechar}
                          Checked={siteGoopedir == 1}
                        />
                      </div>
                    </div>
                  </ShowModal>
                )}

                {isIfood && (
                  <ShowModal
                    altura={20}
                    largura={20}
                    closeButton={() => setIsIfood(!isIfood)}
                  >
                    <div style={{ width: "15vw", padding: "20px" }}>
                      <p>
                        <b>{ApiiFood?.messagetitle}</b>
                      </p>
                      <p>{ApiiFood?.messagesubtitle}</p>
                      <p>{ApiiFood?.validationdescription}</p>
                    </div>
                  </ShowModal>
                )}

                {ModalImpressao ? (
                  <>
                    <ShowModal
                      altura={20}
                      largura={20}
                      closeButton={() => setModalImpressao(!ModalImpressao)}
                    >
                      <div style={{ padding: "20px" }}>
                        <div>
                          <div
                            style={{
                              display: "flex",
                              gap: "10px",
                              alignItems: "center",
                            }}
                          >
                            <Badge
                              color={
                                dadosgeral?.impressora?.comanda
                                  ? "sucesso"
                                  : "alert"
                              }
                              variant="dot"
                            >
                              <FaPrint />
                            </Badge>
                            <h3>
                              Serviço Impressão{" "}
                              <span>
                                <b>Comanda</b>
                              </span>
                            </h3>
                          </div>
                          <h4 style={{ textAlign: "center", fontSize: "16px" }}>
                            {dadosgeral?.impressora?.comanda
                              ? ""
                              : "Sem comunicação"}
                          </h4>
                        </div>

                        <div>
                          <div
                            style={{
                              display: "flex",
                              gap: "10px",
                              alignItems: "center",
                            }}
                          >
                            <Badge
                              color={
                                dadosgeral?.impressora?.cozinha
                                  ? "sucesso"
                                  : "alert"
                              }
                              variant="dot"
                            >
                              <FaPrint />
                            </Badge>
                            <h3>
                              Serviço Impressão{" "}
                              <span>
                                <b>Cozinha</b>
                              </span>
                            </h3>
                          </div>
                          <h4 style={{ textAlign: "center", fontSize: "16px" }}>
                            {dadosgeral?.impressora?.cozinha
                              ? ""
                              : "Sem comunicação"}
                          </h4>
                        </div>

                        <div>
                          <div
                            style={{
                              display: "flex",
                              gap: "10px",
                              alignItems: "center",
                            }}
                          >
                            <Badge
                              color={
                                dadosgeral?.impressora?.outros
                                  ? "sucesso"
                                  : "alert"
                              }
                              variant="dot"
                            >
                              <FaPrint />
                            </Badge>
                            <h3>
                              Serviço Impressão{" "}
                              <span>
                                <b>Outros</b>
                              </span>
                            </h3>
                          </div>
                          <h4 style={{ textAlign: "center", fontSize: "16px" }}>
                            {dadosgeral?.impressora?.outros
                              ? ""
                              : "Sem comunicação"}
                          </h4>
                        </div>
                      </div>
                    </ShowModal>
                  </>
                ) : null}
              </header>
              <div style={{ marginTop: "60px", padding: "10px" }}>
                {props.children}
              </div>
            </PageBloqueado>
          </>
        )}
      </ThemeProvider>
    </>
  );
}

function IconWhatsapp({ onClick, whatsappIconColor }) {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <>
      <FaWhatsapp
        style={{
          cursor: "pointer",
          color: whatsappIconColor,
          transform: isHovered ? "scale(1.3)" : "scale(1)",
        }}
        onClick={onClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      />
    </>
  );
}

function IconConfiguracao({ onClick }) {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const rotationAngle = isHovered ? "rotate(90deg)" : "rotate(0)";
  const Transform = isHovered ? "scale(1.3)" : "scale(1)";

  return (
    <FaCog
      style={{
        cursor: "pointer",
        color: "black",
        transform: `${Transform} ${rotationAngle}`,
      }}
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    />
  );
}

function IconeIfood({ onClick }) {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
    <>
      <div>
        <img
          src="./img/ifood.png"
          alt=""
          style={{
            height: "24px",
            transform: isHovered ? "scale(1.3)" : "scale(1)",
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={onClick}
        />
      </div>
    </>
  );
}

function IconeSite({ onClick, whatsappIconColor }) {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
    <>
      <FaWifi
        style={{
          cursor: "pointer",
          color: whatsappIconColor,
          transform: isHovered ? "scale(1.3)" : "scale(1)",
        }}
        onClick={onClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      />
    </>
  );
}
