import { API } from "../../../../controller/api/api";
import { RemoveMascara } from "../../../../controller/data";
import TableItem from "./item";

const TableProduto = ({ DadosProduto, Set, Tipo, OnLoad, pesquisa }) => {
  const moveItem = (fromIndex, toIndex) => {
    const updatedItems = [...DadosProduto];
    const itemToMove = updatedItems[fromIndex];
    updatedItems.splice(fromIndex, 1);
    updatedItems.splice(toIndex, 0, itemToMove);
    API.post(
      `v1/atualiza/produto/${updatedItems[fromIndex].id}/position/${toIndex}`
    );
    Set(updatedItems);
  };

  const UpdateStatus = (fromIndex, value) => {
    if (!DadosProduto.length) return;
    const updatedItems = [...DadosProduto];
    updatedItems[fromIndex].status = value;
    API.post(
      `v1/atualiza/produto/${updatedItems[fromIndex].id}/ativo/${updatedItems[fromIndex].status}`
    );
    Set(updatedItems);
  };

  const UpdateValue = (fromIndex, value) => {
    if (!DadosProduto.length) return;
    const updatedItems = [...DadosProduto];
    updatedItems[fromIndex].value = RemoveMascara(value);
    //console.log("up1");
    API.post(
      `v1/atualiza/produto/${updatedItems[fromIndex].id}/valor_venda/${updatedItems[fromIndex].value}`
    );
    Set(updatedItems);
  };

  const UpdatePosition = (fromIndex, value) => {
    if (!DadosProduto.length) return;
    const updatedItems = [...DadosProduto];
    updatedItems[fromIndex].position = RemoveMascara(value);
    //console.log("up2");
    API.post(
      `v1/atualiza/produto/${updatedItems[fromIndex].id}/position/${updatedItems[fromIndex].position}`
    );
    Set(updatedItems);
  };

  return (
    <div>
      {/* <div style={{ display: "flex", height: "2.5rem" }}>
        <div style={{ width: "50px" }}></div>
        <div style={{ width: "100%" }}>
          <strong>Item</strong>
        </div>
        <div style={{ width: "110px" }}>
          {Tipo == 0 ? <strong>Preço</strong> : null}
        </div>
        <div style={{ width: "175px" }}>
          <strong>Status de Venda</strong>
        </div>
      </div> */}

      {DadosProduto?.map((item, index) => {
        // Verifica se a pesquisa não está vazia e se o nome do item não contém a pesquisa
        if (
          pesquisa !== "" &&
          !item.name.toLowerCase().includes(pesquisa.toLowerCase())
        ) {
          return null; // Não renderiza o item se não corresponder à pesquisa
        }

        // Renderiza o TableItem se a pesquisa estiver vazia ou se o nome do item contém a pesquisa
        return (
          <TableItem
            key={item.id}
            item={item}
            index={index}
            moveItem={moveItem}
            UpdateStatus={UpdateStatus}
            UpdateValue={UpdateValue}
            UpdatePosition={UpdatePosition}
            Tipo={Tipo}
            OnLoad={OnLoad}
          />
        );
      })}

      {/* {DadosProduto?.map((item, index) => (
        <TableItem
          key={item.id}
          item={item}
          index={index}
          moveItem={moveItem}
          UpdateStatus={UpdateStatus}
          UpdateValue={UpdateValue}
          UpdatePosition={UpdatePosition}
          Tipo={Tipo}
          OnLoad={OnLoad}
        />
      ))} */}
    </div>
  );
};

export default TableProduto;
