import { useEffect, useMemo, useState } from "react";
import { FaArrowUp, FaGift, FaPencilAlt } from "react-icons/fa";
import { API, GooPedirAPI } from "../../controller/api/api";
import { HeaderComponent } from "../../component/header";
import Table from "../../component/tabela";
import { ShowModal } from "../../component/modalPai";
import {
  MascaraCelular,
  NotificaWarning,
  PrimeiraMaiuscula,
  formatarCPForCNPJ,
} from "../../controller/util";
import {
  MascaraInteiro,
  RemoveMascara,
  formatarDataHora,
} from "../../controller/data";
import { Button } from "../../component/button";
import { InputPedido } from "../pedido/componente/input";
import LoadingOverlay from "../../component/load";
import { IconButton } from "@mui/material";

let DadosEstatico = [];
export function FideldiadePage() {
  const [Dados, setDados] = useState([]);
  const [loading, setLoading] = useState(false);
  const [DadosSelecionados, setDadosSelecionados] = useState(null);
  const [DadosHistorico, setDadosHistorico] = useState([]);

  const [isHistorico, setIsHistorico] = useState(true);
  const [isAdicionarRemover, setIsAdicionarRemover] = useState(false);
  const [Pontos, setPontos] = useState(0);

  function BuscaPontos() {
    setLoading(true);
    API.get("v2/pontos/fidelidade").then(function (response) {
      DadosEstatico = response.data;
      //console.log(DadosEstatico);
      setDados(response.data);
      setDadosSelecionados(null);
      setPontos(0);
      setLoading(false);
    });
  }

  useEffect(() => {
    BuscaPontos();
  }, []);

  useEffect(() => {
    if (DadosSelecionados) {
      API.get("v2/pontos/fidelidade/historico/" + DadosSelecionados.id).then(
        function (response) {
          //console.log(response.data);
          setDadosHistorico(response.data);
        }
      );
    }
  }, [DadosSelecionados]);

  const columns = useMemo(
    () => [
      {
        Header: "CPF",
        accessor: "cpf",
        size: "100px",
        format: formatarCPForCNPJ,
        textAlign: "center",
      },
      {
        Header: "Nome",
        accessor: "nome",
        size: "60%",
        format: PrimeiraMaiuscula,
      },
      {
        Header: "Celular",
        accessor: "celular",
        size: "100px",
        // format: PintaValores,
        format: MascaraCelular,
        textAlign: "center",
      },
      {
        Header: "Pontuação",
        accessor: "pontos",
        size: "100px",
        // format: PintaValores,
        format: MascaraCelular,
        textAlign: "center",
      },
      {
        Header: "Notificação WPP",
        accessor: "naoreceber",
        size: "100px",
        // format: PintaValores,
        format: RecebeNotificacao,
        textAlign: "center",
      },

      {
        Header: "Histórico",
        size: "50px",
        component: (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IconButton color="primary">
              <FaGift />
            </IconButton>
          </div>
        ),

        func: AlterarDados,
      },
    ],
    []
  );

  function RecebeNotificacao(sim) {
    if (sim == 1) {
      return <b style={{ color: "red" }}>Não Recebe</b>;
    } else {
      return <b style={{ color: "green" }}>Recebe</b>;
    }
  }

  function AlterarDados(dados) {
    const objetoEncontrado = DadosEstatico.find(
      (objeto) => objeto.id === dados
    );

    setDadosSelecionados(objetoEncontrado);
  }

  function CardResgatou(dados) {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-start",
            gap: "10px",
            marginTop: "15px",
          }}
        >
          <div style={{ textAlign: "right" }}>
            <p style={{ fontSize: "0.95rem" }}>Resgatou</p>
            <p style={{ fontSize: "1.05rem", marginTop: "-7px" }}>
              <b>{dados.pontos} pontos</b>
            </p>
            <p
              style={{
                fontSize: "0.85rem",
                color: "#CCCCCC",
                marginTop: "-5px",
              }}
            >
              {formatarDataHora(dados.data)}
            </p>
          </div>
          <div style={{ marginRight: "10px" }}>
            <FaGift
              size={50}
              // color="#fff"
              style={{
                cursor: "pointer",
                backgroundColor: "rgb(30, 180, 200)",
                padding: "10px",
                borderRadius: "5px",
                border: "1px solid black",
              }}
            />
          </div>
        </div>
      </>
    );
  }

  function CardRecebeu(dados) {
    return (
      <>
        <div style={{ display: "flex", gap: "20px", marginTop: "15px" }}>
          <FaGift
            size={50}
            // color="#fff"
            style={{
              cursor: "pointer",
              backgroundColor: "rgb(30, 180, 100)",
              padding: "10px",
              borderRadius: "5px",
              border: "1px solid black",
            }}
          />

          <div>
            <p style={{ fontSize: "0.95rem" }}>Recebeu</p>
            <p style={{ fontSize: "1.05rem", marginTop: "-7px" }}>
              <b>{dados.pontos} pontos</b>
            </p>
            <p
              style={{
                fontSize: "0.85rem",
                color: "#CCCCCC",
                marginTop: "-5px",
              }}
            >
              {formatarDataHora(dados.data)}
            </p>
          </div>
        </div>
      </>
    );
  }

  function AdicionarPontos() {
    setLoading(true);
    let body = {
      fidelidade: DadosSelecionados.id,
      pontos: parseInt(RemoveMascara(Pontos)),
      tipo: 1,
    };

    if (parseInt(RemoveMascara(Pontos)) < 1) {
      NotificaWarning("Pontos invalído!");
      return false;
      setLoading(false);
    }

    API.post("/v2/pontos/fidelidade", body)
      .then(function (response) {
        //console.log(response);
        BuscaPontos();
      })
      .catch(function (error) {
        setLoading(false);
      });
  }

  function RemoverPontos() {
    setLoading(true);
    let body = {
      fidelidade: DadosSelecionados.id,
      pontos: parseInt(RemoveMascara(Pontos)),
      tipo: 2,
    };

    if (parseInt(RemoveMascara(Pontos)) < 1) {
      NotificaWarning("Pontos invalído!");
      return false;
      setLoading(false);
    }

    API.post("/v2/pontos/fidelidade", body)
      .then(function (response) {
        //console.log(response);
        BuscaPontos();
      })
      .catch(function (error) {
        setLoading(false);
      });
  }

  return (
    <>
      <div>
        <HeaderComponent>
          {loading && <LoadingOverlay />}{" "}
          <Table
            columns={columns}
            data={Dados}
            codigo={"id"}
            //   func={AlterarDadosCliente}
          />
        </HeaderComponent>
      </div>
      {DadosSelecionados ? (
        <>
          <ShowModal closeButton={() => setDadosSelecionados(null)}>
            <div style={{ padding: "10px", width: "30vw" }}>
              <div>
                {" "}
                <h1
                  style={{
                    textAlign: "center",
                    fontSize: "1.3rem",
                    marginBottom: "20px",
                  }}
                >
                  {isHistorico
                    ? "Histórico de Pontos"
                    : "Adicionar ou Remover Pontos"}
                </h1>
                <div style={{ textAlign: "right" }}>
                  <h1 style={{ float: "left", margin: 0 }}>
                    {PrimeiraMaiuscula(DadosSelecionados.nome)}
                  </h1>
                  <span style={{ textAlign: "right" }}>
                    <b>{DadosSelecionados.pontos} pontos</b>
                  </span>
                </div>
                <div style={{ display: "flex", width: "100%", gap: "10px" }}>
                  <Button
                    descricao={"Histórico"}
                    borderColor={"rgb(30, 180, 200)"}
                    fontColor={"rgb(30, 180, 200)"}
                    icon={<FaGift />}
                    BorderRadius={"5px"}
                    evento={() => [
                      setIsHistorico(true),
                      setIsAdicionarRemover(false),
                    ]}
                  ></Button>
                  <Button
                    descricao={"Adicionar / Remover"}
                    borderColor={"black"}
                    fontColor={"black"}
                    icon={<FaPencilAlt />}
                    BorderRadius={"5px"}
                    evento={() => [
                      setIsAdicionarRemover(true),
                      setIsHistorico(false),
                    ]}
                  ></Button>
                </div>
              </div>

              {isHistorico ? (
                <>
                  <div style={{ marginTop: "10px" }}>
                    {DadosHistorico.map((dados, index) =>
                      parseInt(dados.origem) == 1
                        ? CardRecebeu(dados)
                        : CardResgatou(dados)
                    )}
                  </div>
                </>
              ) : null}
              {isAdicionarRemover ? (
                <>
                  <div style={{ marginTop: "10px" }}>
                    <InputPedido
                      Valor={Pontos}
                      Change={setPontos}
                      Set={setPontos}
                      Descricao={"Pontos"}
                      sx={{ marginBottom: "15px" }}
                    />

                    <div
                      style={{ display: "flex", width: "100%", gap: "10px" }}
                    >
                      <Button
                        descricao={"Adicionar Pontos"}
                        borderColor={"#269931"}
                        fontColor={"#269931"}
                        icon={<FaGift />}
                        BorderRadius={"5px"}
                        evento={AdicionarPontos}
                      ></Button>
                      <Button
                        descricao={"Remover Pontos"}
                        borderColor={"#F00028"}
                        fontColor={"#F00028"}
                        icon={<FaPencilAlt />}
                        BorderRadius={"5px"}
                        evento={RemoverPontos}
                      ></Button>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </ShowModal>
        </>
      ) : null}
    </>
  );
}
