import { useEffect, useMemo, useState } from "react";
import { PrimeiraMaiuscula } from "../../controller/util";
import { API } from "../../controller/api/api";
import Table from "../tabela";
import { InputPedido } from "../../page/pedido/componente/input";
import { Toggle } from "../toggle";

export function GarcomComponent() {
  const [Dados, setDados] = useState([]);

  useEffect(() => {
    API.get(`/v1/consulta/generica/agent/*/*/*`)
      .then((response) => {
        setDados(response.data);
      })
      .catch((err) => {});
  }, []);

  const columns = useMemo(() => [
    {
      Header: "Autenticação",
      accessor: "id",
      size: "150px",
      format: PrimeiraMaiuscula,
      textAlign: "center",
    },
    {
      Header: "Descrição",
      accessor: "nome",
      size: "80%",
      formatOption: ImputDescricao,
      textAlign: "center",
    },
    {
      Header: "Status",
      accessor: "status",
      size: "100px",
      formatOption: InputSelect,

      textAlign: "center",
    },
  ]);
  function InputSelect(valor, dataFiltro) {
    return (
      <div
        style={{
          //   color: "#f06e18",
          display: "flex",
          justifyContent: "center",
          gap: "3px",
          alignItems: "center",
        }}
      >
        <Toggle
          Checked={parseInt(valor.status) === 1 ? true : false}
          ID={valor.id}
          Clique={UpdateStatus}
        />
        {/* <FaInfoCircle style={{ cursor: "pointer" }} />
        <span>{MascaraNumerica(item?.qtd)}</span> */}
      </div>
    );
  }

  function UpdateStatus(index, valor) {
    const Body = { id: index, status: valor === true ? 1 : 0 };
    //console.log(valor);
    API.post("/v2/user/agent/status", Body);
  }

  function UpdateDescricao(index, valor) {
    const Body = { id: index, nome: valor };
    API.post("/v2/user/agent/name", Body);
  }

  function ImputDescricao(valor, dataFiltro) {
    // //console.log(valor);

    return (
      <div
        style={{
          //   color: "#f06e18",
          display: "flex",
          justifyContent: "center",
          gap: "3px",
          alignItems: "center",
        }}
      >
        <InputPedido
          Valor={PrimeiraMaiuscula(valor.nome)}
          Retorno={UpdateDescricao}
          index={valor.id}
        />
        {/* <FaInfoCircle style={{ cursor: "pointer" }} />
        <span>{MascaraNumerica(item?.qtd)}</span> */}
      </div>
    );
  }

  return (
    <>
      <Table
        columns={columns}
        data={Dados}
        codigo={"id"}
        //   func={AlterarDadosCliente}
      />
    </>
  );
}
