import { MascaraMonetaria } from "../../../../controller/data";
import { PrimeiraMaiuscula } from "../../../../controller/util";

export function TotalizadorProduto({ dados }) {
  function Total() {
    let total = 0;
    for (var i = 0; i < dados.length; i++) {
      total += dados[i].total;
    }
    return total;
  }

  function Quantidade() {
    let total = 0;
    for (var i = 0; i < dados.length; i++) {
      total += dados[i].quantidade;
    }
    return total;
  }

  return (
    <div
      style={{
        border: "1px solid black",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{ flex: "1", overflowY: "auto" }}>
        <table style={{ width: "100%" }}>
          <thead>
            <tr style={{ borderBottom: "1px solid black" }}>
              <th style={{ textAlign: "left" }}>Produto</th>
              <th style={{ width: "100px", textAlign: "center" }}>
                Quantidade
              </th>
              <th style={{ width: "150px", textAlign: "right" }}>Total</th>
            </tr>
          </thead>
          <tbody>
            {dados.map((item, index) => (
              <tr key={index} style={{ borderBottom: "1px solid black" }}>
                <td style={{ textAlign: "left" }}>
                  {PrimeiraMaiuscula(item.produto)}
                </td>
                <td style={{ textAlign: "center" }}>{item.quantidade}</td>
                <td style={{ textAlign: "right" }}>
                  {MascaraMonetaria(item.total)}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td style={{ textAlign: "center" }}></td>
              <td style={{ textAlign: "center" }}>
                <strong>{Quantidade()}</strong>
              </td>
              <td style={{ textAlign: "right" }}>
                <strong>{MascaraMonetaria(Total())}</strong>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
}
