import { useEffect, useState } from "react";
import { API } from "../../../../controller/api/api";
import {
  MascaraMonetaria,
  formatarData,
  formatarHora,
  formatarHoraMin,
} from "../../../../controller/data";

export function ResumoCaixa({ id }) {
  const StyleTitulo = {
    fontSize: "0.8rem",
    color: "#CCCCCC",
  };
  const StyleTituloValue = {
    fontSize: "1.1rem",
    // color: "#67B86F",
  };

  const StyleTituloValueRigth = {
    fontSize: "1.1rem",
    // color: "#67B86F",
    textAlign: "right",
  };

  const [Dados, setDados] = useState({});

  useEffect(() => {
    API.get(`v1/consulta/generica/caixa/*/id=${id}/*`).then((response) => {
      //console.log(response.data[0]);
      setDados(response.data[0]);
    });
  }, []);

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "50%",
          //   border: "1px solid #cccccc",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "100%",
            margin: "0 auto", // Adicionando esta linha para centralizar a div
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "10px",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div style={{ width: "50%" }}>
              <div>
                <h3 style={StyleTitulo}>Nº Caixa</h3>
                <h1 style={StyleTituloValue}>
                  <b>#{id}</b>
                </h1>
                <h3 style={StyleTitulo}>Abertura</h3>
                <h1 style={StyleTituloValue}>
                  <b>
                    {formatarData(Dados.dataAbertura)} ás{" "}
                    {formatarHoraMin(Dados?.horaAbertura)}h
                  </b>
                </h1>
              </div>

              <div>
                <h1 style={StyleTituloValue}>
                  <b>{MascaraMonetaria(Dados?.valorAbertura)}</b>
                </h1>
              </div>
            </div>

            <div>
              <div>
                <br></br>
                <br></br>
                <h3 style={StyleTitulo}>Fechamento</h3>
                <h1 style={StyleTituloValue}>
                  <b>
                    {formatarData(Dados.dataFechamento)} ás{" "}
                    {formatarHoraMin(Dados?.horaFechamento)}h
                  </b>
                </h1>
              </div>

              <div>
                <h1 style={StyleTituloValueRigth}>
                  <b>{MascaraMonetaria(Dados.valorFechamento)}</b>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
