import { FormControl, InputLabel, MenuItem } from "@mui/material";
import { API } from "../../../../controller/api/api";
import { PrimeiraMaiuscula } from "../../../../controller/util";
import { useState, useEffect } from "react";
import Select, { SelectChangeEvent } from "@mui/material/Select";

export function SelectControl({ Descricao, Campo, Valores, Selecionado }) {
  function AtualizaParametro(campo, valor) {
    let Body = { campo: campo, valor: valor };

    API.post(`/v2/parametro`, Body);
  }
  const [Dados, setDados] = useState(Selecionado);

  return (
    <>
      <FormControl fullWidth>
        <InputLabel id={Descricao}>{Descricao}</InputLabel>
        <Select
          labelId={Descricao}
          id={Descricao}
          value={Dados}
          label={Descricao}
          onChange={(e) => [
            setDados(e.target.value),
            AtualizaParametro(Campo, e.target.value),
          ]}
          sx={{ width: "100%", marginBottom: "15px" }}
          size="small"
        >
          {Valores.map((item, index) => (
            <MenuItem key={index} value={item.value}>
              {PrimeiraMaiuscula(item.descricao)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {/* <div style={{ flex: '1 0 200px', marginRight: '10px' }}>
            <span>{Descricao}</span>
            <select style={{ height: '34px', width: '100%', border: '1px solid', marginBottom: '10px' }}
                onChange={(e) => AtualizaParametro(Campo, e.target.value)}
            >

                {Valores.map((item, index) => (
                    <option key={index} value={item.value} selected={Selecionado == item.value ? true : false}>
                        {PrimeiraMaiuscula(item.descricao)}
                    </option>
                ))}
            </select>


        </div> */}
    </>
  );
}
